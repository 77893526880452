import { Box, DialogContent, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSiteTimezone } from '../../hooks/useSiteTimezone';
import { loadAlertEventLog } from '../../store/alertDetails/actions';
import {
  alertDetailsErrorSelector,
  alertDetailsSelector,
  alertEventLogErrorSelector,
  alertEventLogItemsSelector,
  alertEventLogPageSelector,
  alertEventLogPerPageSelector,
  alertEventLogTotalResultsSelector,
  isLoadingAlertEventLogSelector,
} from '../../store/alertDetails/selectors';
import type { DeviceEventLogRequest } from '../../types/DeviceEvent';
import { DeviceEventLogTable } from '../deviceEventLogTable';
import { ContentLoader } from './contentLoader';
import { ErrorMsg } from './errorMsg';

export interface Props {
  height: number | 'unset';
  overrides?: {
    siteId: string;
    systemId: string;
    deviceId: string;
    dateFromISO: string;
    dateToISO: string;
  };
}

export const EventLogContent = ({ height, overrides }: Props) => {
  const dispatch = useDispatch();
  const siteTimezone = useSiteTimezone();
  const items = useSelector(alertEventLogItemsSelector);
  const page = useSelector(alertEventLogPageSelector);
  const perPage = useSelector(alertEventLogPerPageSelector);
  const totalResults = useSelector(alertEventLogTotalResultsSelector);
  const hasEventLogError = useSelector(alertEventLogErrorSelector);
  const hasAlertDetailError = useSelector(alertDetailsErrorSelector);
  const isLoading = useSelector(isLoadingAlertEventLogSelector);
  const {
    siteId: siteIdFromSelector,
    systemId: systemIdFromSelector,
    deviceId: deviceIdFromSelector,
    deviceType,
  } = useSelector(alertDetailsSelector) || {};
  const nowDateTime = DateTime.now().startOf('second');
  const defaultDateTo = nowDateTime.toISO({ suppressMilliseconds: true });
  const defaultDateFrom = nowDateTime.minus({ years: 1 }).toISO({ suppressMilliseconds: true });

  const siteId = overrides?.siteId ?? siteIdFromSelector;
  const systemId = overrides?.systemId ?? systemIdFromSelector;
  const deviceId = overrides?.deviceId ?? deviceIdFromSelector;
  const dateFrom = overrides?.dateFromISO ?? defaultDateFrom;
  const dateTo = overrides?.dateToISO ?? defaultDateTo;

  const loadAlerts = useCallback(
    (details: Partial<DeviceEventLogRequest>) => {
      const { siteId, systemId, deviceId, page, perPage, dateFrom, dateTo } = details;

      if (siteId && systemId && deviceId && page) {
        dispatch(
          loadAlertEventLog.request({
            siteId,
            systemId,
            deviceId,
            page,
            perPage,
            dateFrom,
            dateTo,
          }),
        );
      }
    },
    [dispatch],
  );

  // Load the first page of event logs when the modal is opened
  useEffect(() => {
    loadAlerts({ siteId, systemId, deviceId, page, perPage, dateFrom, dateTo });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch new events if timeframe changes. Compatibility shim for timeframe system (via overrides)
  useEffect(() => {
    loadAlerts({ siteId, systemId, deviceId, page, perPage, dateFrom, dateTo });
  }, [dateFrom, dateTo]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (newPage: number) => {
    loadAlerts({ siteId, systemId, deviceId, page: newPage, perPage, dateFrom, dateTo });
  };

  const handleRowsPerPageChange = (newPerPage: number) => {
    loadAlerts({ siteId, systemId, deviceId, page, perPage: newPerPage, dateFrom, dateTo });
  };

  if (!hasEventLogError && !items && isLoading) {
    return <ContentLoader />;
  }

  return (
    <DialogContent dividers style={{ height }}>
      {hasEventLogError || hasAlertDetailError ? (
        <ErrorMsg />
      ) : items ? (
        <DeviceEventLogTable
          deviceId={deviceId}
          deviceType={deviceType}
          deviceEvents={items}
          timezone={siteTimezone}
          currentPage={page}
          perPage={perPage}
          totalResults={totalResults}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      ) : (
        <Box textAlign="center" mt={1}>
          <Typography variant="body2">No event logs found.</Typography>
        </Box>
      )}
    </DialogContent>
  );
};
