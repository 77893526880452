import type { SelectChangeEvent } from '@mui/material';
import { Box, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import humanizeDuration from 'humanize-duration';
import startCase from 'lodash/startCase';
import { DateTime } from 'luxon';
import type { ChangeEvent } from 'react';

import { DATE_TIME_FORMAT } from '../../constants/dates';
import { useAppSelector } from '../../hooks/useAppSelector';
import { alertDetailsSelector } from '../../store/alertDetails/selectors';
import { COLORS } from '../../styles/theme';
import type { AlertInstanceHistory } from '../../types/Alert';
import { AlertStatus } from '../../types/Alert';
import type { RowComponentProps, TableColumn } from '../../types/NewTable';
import { getTimezoneDisplay } from '../../utils';
import { AlertSeverityIcon } from '../alertSeverityIcon';
import { NewTable } from '../newTable';
import { PWRtooltip } from '../pwrTooltip';

const StyledTableRow = styled(TableRow)`
  .MuiTableCell-root {
    height: 28px;
    border-bottom: 1px solid ${COLORS.SHADOW_GREY};
  }
`;

const StyledHoverableContainer = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

const firstColumnStyle = { width: 30, paddingLeft: 20 };

enum TableColumnId {
  INSTANCES = 'instances',
  OPENED_AT = 'openedAt',
  CLOSED_AT = 'closedAt',
  DURATION = 'duration',
}

const columns: TableColumn<AlertInstanceHistory>[] = [
  { id: TableColumnId.INSTANCES, label: '' },
  { id: TableColumnId.OPENED_AT, label: 'Opened At' },
  { id: TableColumnId.CLOSED_AT, label: 'Closed At' },
  { id: TableColumnId.DURATION, label: 'Duration' },
];

const RowComponent = ({
  row,
  TableRowProps,
}: RowComponentProps<AlertInstanceHistory & { timezone: string | undefined }>) => {
  const { openedAt, transitionedAt, status, timezone } = row;
  const alertDetails = useAppSelector(alertDetailsSelector);
  const severity = alertDetails?.severity;

  const isResolved = status === AlertStatus.RESOLVED;

  return (
    <StyledTableRow {...TableRowProps}>
      <TableCell style={firstColumnStyle}>
        {severity && status ? (
          <StyledHoverableContainer>
            <PWRtooltip
              message={`${startCase(status.toLowerCase())} ${startCase(
                severity.toLowerCase(),
              )} Severity Alert`}
            >
              <AlertSeverityIcon margin="auto" alertSeverity={severity} alertStatus={status} />
            </PWRtooltip>
          </StyledHoverableContainer>
        ) : (
          '--'
        )}
      </TableCell>
      <TableCell>
        {!!openedAt
          ? DateTime.fromMillis(openedAt).setZone(timezone).toFormat(DATE_TIME_FORMAT)
          : '--'}
      </TableCell>
      <TableCell>
        {!!status && !!transitionedAt ? (
          isResolved ? (
            DateTime.fromMillis(transitionedAt).setZone(timezone).toFormat(DATE_TIME_FORMAT)
          ) : (
            <strong>ACTIVE</strong>
          )
        ) : (
          '--'
        )}
      </TableCell>
      <TableCell>
        {!!status && !!openedAt && !!transitionedAt
          ? isResolved
            ? humanizeDuration(DateTime.fromMillis(transitionedAt - openedAt).valueOf(), {
                largest: 1,
              })
            : DateTime.fromMillis(openedAt).toRelative()?.replace(' ago', '')
          : '--'}
      </TableCell>
    </StyledTableRow>
  );
};

export interface Props {
  alerts: AlertInstanceHistory[];
  timezone?: string;
  totalResults: number;
  currentPage: number;
  perPage: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (perPage: number) => void;
}

export const AlertInstanceHistoryTable = ({
  alerts,
  timezone,
  totalResults,
  currentPage,
  perPage,
  onPageChange,
  onRowsPerPageChange,
}: Props) => {
  const handlePageChange = (_event: ChangeEvent<unknown>, page: number) => {
    onPageChange(page);
  };

  const handleRowsPerPageChange = (_event: SelectChangeEvent<unknown>, perPage: number) => {
    onRowsPerPageChange(perPage);
  };

  const formattedRows = alerts.map((alert) => ({
    ...alert,
    timezone,
  }));

  const formattedColumns = columns.map((column) => {
    if (column.id === TableColumnId.OPENED_AT || column.id === TableColumnId.CLOSED_AT) {
      return {
        ...column,
        label: `${column.label} (${getTimezoneDisplay(timezone)})`,
      };
    }
    return column;
  });

  return (
    <NewTable
      columns={formattedColumns}
      rows={formattedRows}
      RowComponent={RowComponent}
      idExtractor="alertId"
      stickyHeader
      HeadProps={{ align: 'left' }}
      PaginationProps={{
        count: totalResults,
        onChange: handlePageChange,
        page: currentPage - 1,
        rowsPerPage: perPage,
        onRowsPerPageChange: handleRowsPerPageChange,
      }}
    />
  );
};
