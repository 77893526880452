import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import React from 'react';

import { down } from '../../styles';

const Inner = styled('div')<{ $disableBottomPadding?: boolean; $paddingVertical?: number }>`
  padding: ${({ $disableBottomPadding, $paddingVertical }) => {
    if ($disableBottomPadding) {
      return $paddingVertical !== undefined ? `${$paddingVertical}px 0 0 0` : '40px 0 0 0';
    }
    return $paddingVertical !== undefined ? `${$paddingVertical}px 0` : '40px 0';
  }};

  ${down('sm')} {
    padding: ${({ $disableBottomPadding, $paddingVertical }) => {
      if ($disableBottomPadding) {
        return $paddingVertical !== undefined ? `${$paddingVertical}px 0 0 0` : '20px 0 0 0';
      }
      return $paddingVertical !== undefined ? `${$paddingVertical}px 0` : '20px 0';
    }};
  }
`;

// --

export interface Props {
  children: ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  disableBottomPadding?: boolean;
  paddingVertical?: number;
}

export const ScreenContainer = (props: Props) => {
  const { children, maxWidth, disableBottomPadding, paddingVertical, ...rest } = props;

  // @todo implement react-helmet to update <head /> for each screen

  return (
    <Container maxWidth={maxWidth} {...rest}>
      <Inner $disableBottomPadding={disableBottomPadding} $paddingVertical={paddingVertical}>
        {children}
      </Inner>
    </Container>
  );
};
