import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

const DEFAULT_DELAY = 200;

export interface WindowSize {
  width: number;
  height: number;
}

export default function useWindowSize(delay?: number): WindowSize {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const _delay = delay || DEFAULT_DELAY;

  useEffect(() => {
    const handleResize = throttle(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, _delay);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [_delay]);

  return windowSize;
}
