import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';

import { Bubble } from '../bubble';
import { Icon } from '../icon';

type ItemProps = {
  serialNumber: string;
  value: string;
  inverters: { serialNumber: string }[];
  onChange: (loadManagerSerialNumber: string, inverterSerialNumber: string) => void;
  isPaired: (serialNumber: string) => boolean;
};

export const PairItem = ({ serialNumber, inverters, value, onChange, isPaired }: ItemProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange(serialNumber, value);
  };
  return (
    <Bubble p={2} mb={2} bgcolor="background.default" display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <Icon icon="octopus" size={21} />
        <Box ml={1}>
          <Typography variant="subtitle2">Load Manager {serialNumber}</Typography>
        </Box>
      </Box>
      <Box mt={1} display="flex" alignItems="center">
        <Box display="flex" flexDirection="column">
          <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
            {inverters.map(({ serialNumber }) => (
              <FormControlLabel
                key={serialNumber}
                value={serialNumber}
                control={<Radio size="small" color="primary" />}
                label={`Pair with Inverter ${serialNumber}`}
                disabled={value && value === serialNumber ? false : isPaired(serialNumber)}
              />
            ))}
          </RadioGroup>
        </Box>
      </Box>
    </Bubble>
  );
};
