import type { Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import { EMPTY, from } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { trackEvent } from '../../analyticsClient';
import {
  activateCompanyAssociation,
  associateCompanies as associateCompaniesApi,
  getCompanyAssociations,
  removeCompanyAssociation,
} from '../../api';
import { AnalyticsEvent } from '../../types/Analytics';
import type {
  AssociateCompaniesSuccessResponse,
  CompanyAssociationsFailedResponse,
  CompanyAssociationsSuccessResponse,
  RemoveCompanyAssociationSuccessResponse,
} from '../../types/CompanyAssociation';
import { CompanyAssociationRemovalMode } from '../../types/CompanyAssociation';
import { AlertContainer } from '../../types/ScreenAlerts';
import { callActionAlert, customAlert } from '../../utils/alerts';
import { companyIdSelector } from '../company/selectors';
import {
  acceptAssociation,
  associateCompanies,
  loadCompanyAssociations,
  removeAssociation,
} from './actions';
import { associatedCompanyNameSelector, removeModeSelector } from './selectors';

const loadCompanyAssociationsEpic: Epic = (action$, $state) =>
  action$.pipe(
    filter(
      isActionOf([
        loadCompanyAssociations.request,
        acceptAssociation.success,
        associateCompanies.success,
      ]),
    ),
    mergeMap(() => {
      const companyId = companyIdSelector($state.value);
      if (!companyId) return EMPTY;
      return from(getCompanyAssociations(companyId)).pipe(
        map((a) => loadCompanyAssociations.success(a as CompanyAssociationsSuccessResponse)),
        catchError(async (e: CompanyAssociationsFailedResponse) =>
          loadCompanyAssociations.failure(e),
        ),
      );
    }),
  );

const associateCompaniesEpic: Epic = (action$, $state) =>
  action$.pipe(
    filter(isActionOf(associateCompanies.request)),
    mergeMap((action) => {
      const emails = action.payload;
      const companyId = companyIdSelector($state.value);
      const associations = emails.map((email) => ({ inviteeAdminEmail: email }));
      return from(
        associateCompaniesApi({
          inviterCompanyId: companyId,
          associations,
        }),
      ).pipe(
        map((value) => {
          // [analytics] Company Association Request
          trackEvent(AnalyticsEvent.CompanyAssociationRequest);
          return associateCompanies.success({
            emails,
            value: value as AssociateCompaniesSuccessResponse,
          });
        }),
        catchError(async (e) => associateCompanies.failure({ emails, value: e?.response?.data })),
      );
    }),
  );

const afterAssociateCompaniesEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf([associateCompanies.success, associateCompanies.failure])),
    map((response) => {
      const { emails } = response.payload;
      callActionAlert(response, associateCompanies.failure, {
        successText: `Your association request has been sent to ${emails.join(', ')} for approval.`,
        errorText: 'Failed to send an association request.',
        errorContainerId: AlertContainer.Modal,
      });
    }),
    ignoreElements(),
  );

const acceptAssociationEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf([acceptAssociation.request])),
    mergeMap((action) => {
      const associationId = action.payload;
      return from(activateCompanyAssociation(associationId)).pipe(
        map(() => {
          // [analytics] Company Association Accept
          trackEvent(AnalyticsEvent.CompanyAssociationAccept);
          return acceptAssociation.success(associationId);
        }),
        catchError(async (e) => acceptAssociation.failure(e)),
      );
    }),
  );

const afterAcceptAssociationEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([acceptAssociation.success, acceptAssociation.failure])),
    map((response) => {
      const isError = isActionOf(acceptAssociation.failure)(response);
      const companyName = !isError
        ? associatedCompanyNameSelector(state$.value, { associationId: response.payload as string })
        : '';
      callActionAlert(response, acceptAssociation.failure, {
        successText: `You’ve accepted an association request from ${companyName}. They will now able to share their sites with you.`,
        errorText: 'Failed to accept an association request.',
      });
    }),
    ignoreElements(),
  );

const removeAssociationEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf([removeAssociation.request])),
    mergeMap((action) => {
      const associationId = action.payload;
      return from(removeCompanyAssociation(associationId)).pipe(
        map((value) => {
          // [analytics] Company Association Remove
          trackEvent(AnalyticsEvent.CompanyAssociationRemove);
          return removeAssociation.success({
            associationId,
            value: value as RemoveCompanyAssociationSuccessResponse,
          });
        }),
        catchError(async (e) => removeAssociation.failure({ associationId, value: e })),
      );
    }),
  );

const getRemoveNotificationText = (
  removeMode: CompanyAssociationRemovalMode,
  isError: boolean,
  companyName: string,
) => {
  switch (removeMode) {
    case CompanyAssociationRemovalMode.DECLINE:
      return isError
        ? 'Failed to decline an association request.'
        : `You've declined a request from ${companyName} to associate with your company.`;
    case CompanyAssociationRemovalMode.REMOVE_ASSOCIATION:
      return isError
        ? 'Failed to remove an association request.'
        : `You’ve removed your association with ${companyName}.`;
    case CompanyAssociationRemovalMode.REMOVE_COMPANY:
      return isError
        ? 'Failed to remove company.'
        : `${companyName} is no longer associated with your company.`;
  }
};

const afterRemoveAssociationEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([removeAssociation.success, removeAssociation.failure])),
    map((response) => {
      const { associationId } = response.payload;
      const removeMode = removeModeSelector(state$.value, { associationId });
      const companyName = associatedCompanyNameSelector(state$.value, { associationId });
      const isError = isActionOf(removeAssociation.failure)(response);
      const title = getRemoveNotificationText(removeMode, isError, companyName!);
      const type = isError ? 'error' : 'success';
      customAlert({ title }, { type });
    }),
    ignoreElements(),
  );

export default combineEpics(
  loadCompanyAssociationsEpic,
  associateCompaniesEpic,
  acceptAssociationEpic,
  afterAcceptAssociationEpic,
  afterAssociateCompaniesEpic,
  removeAssociationEpic,
  afterRemoveAssociationEpic,
);
