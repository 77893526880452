export enum UserPermission {
  COMPANY_BULK_EDIT = 'company_bulk_edit',
  COMPANY_EDIT = 'company_edit',
  COMPANY_VIEW = 'company_view',
  SITE_DELETE = 'site_delete',
  SITE_MOVE = 'site_move',
  SITE_EDIT = 'site_edit',
  SITE_SHARE = 'site_share',
  SITE_VIEW = 'site_view',
  SYSTEM_ADD = 'system_add',
  SYSTEM_DELETE = 'system_delete',
  SYSTEM_MOVE = 'system_move',
  USER_EDIT = 'user_edit',
  USER_VIEW = 'user_view',
  USER_EDIT_SUPPORT = 'user_edit_support',
  ALERT_DISMISS = 'alert_dismiss',
  DEVICE_DELETE = 'device_delete',
  POWERCORE_SWAP = 'powercore_swap',
  ODIN_VIEW = 'odin_view',
  MCU_SWAP = 'mcu_swap',
  LOAD_MANAGER_PAIRING_EDIT = 'load_manager_pairing_edit',
  LOAD_MANAGER_DETAILS_VIEW = 'load_manager_details_view',
  AFU_CAMPAIGN_MANAGER_VIEW = 'afu_campaign_view',
  AFU_CAMPAIGN_MANAGER_CREATE = 'afu_campaign_create',
  MICROINVERTER_SYSTEM_COMMAND_SEND = 'microinverter_system_command_send',
  LOAD_MANAGER_FETCH_LOG = 'load_manager_log_fetch',
  CASE_CREATE = 'case_create',
}
