import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../types';

export const alertDetailsSelector = (state: RootState) => state.alertDetails.alertDetails;
export const isLoadingAlertDetailsSelector = (state: RootState) =>
  state.alertDetails.loadingAlertDetails;
export const alertDetailsErrorSelector = (state: RootState) => state.alertDetails.alertDetailsError;
export const alertCompanyInfoSelector = (state: RootState) => state.alertDetails.alertCompanyInfo;
export const isLoadingAlertCompanyInfoSelector = (state: RootState) =>
  state.alertDetails.loadingCompanyInfo;
export const alertCompanyInfoErrorSelector = (state: RootState) =>
  state.alertDetails.alertCompanyInfoError;
export const alertTypeInfoSelector = (state: RootState) => state.alertDetails.alertTypeInfo;
export const isLoadingAlertTypeInfoSelector = (state: RootState) =>
  state.alertDetails.loadingAlertTypeInfo;
export const alertTypeInfoErrorSelector = (state: RootState) =>
  state.alertDetails.alertTypeInfoError;

const alertHistoryItemsRawSelector = (state: RootState) => state.alertDetails.alertHistoryItems;
export const alertHistoryItemsSelector = createSelector(
  alertHistoryItemsRawSelector,
  (alerts) =>
    alerts?.map(({ transitionedAt, openedAt, ...rest }) => ({
      ...rest,
      // mapping timestamps seconds to milliseconds
      transitionedAt: transitionedAt * 1000,
      openedAt: openedAt * 1000,
    })) || null,
);
export const isLoadingAlertHistorySelector = (state: RootState) =>
  state.alertDetails.loadingAlertHistory;
export const alertHistoryErrorSelector = (state: RootState) => state.alertDetails.alertHistoryError;
export const alertHistoryPageSelector = (state: RootState) => state.alertDetails.alertHistoryPage;
export const alertHistoryPerPageSelector = (state: RootState) =>
  state.alertDetails.alertHistoryPerPage;
export const alertHistoryTotalResultsSelector = (state: RootState) =>
  state.alertDetails.alertHistoryTotalResults;

export const alertEventLogItemsRawSelector = (state: RootState) =>
  state.alertDetails.alertEventLogItems;
export const alertEventLogItemsSelector = createSelector(
  alertEventLogItemsRawSelector,
  (logs) =>
    logs?.map(({ startedAt, ...rest }) => ({ ...rest, startedAt: startedAt * 1000 })) || null,
);
export const isLoadingAlertEventLogSelector = (state: RootState) =>
  state.alertDetails.loadingAlertEventLog;
export const alertEventLogErrorSelector = (state: RootState) =>
  state.alertDetails.alertEventLogError;
export const alertEventLogPageSelector = (state: RootState) => state.alertDetails.alertEventLogPage;
export const alertEventLogPerPageSelector = (state: RootState) =>
  state.alertDetails.alertEventLogPerPage;
export const alertEventLogTotalResultsSelector = (state: RootState) =>
  state.alertDetails.alertEventLogTotalResults;
