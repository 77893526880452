import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import React from 'react';

import { getColor } from '../../styles';
import { Bubble } from '../bubble';
import { Icon } from '../icon';
import type { IconType } from '../icon/icons';

const Container = styled(Bubble)`
  box-shadow: 0 6px 14px 0 rgba(176, 180, 171, 0.2);
`;

const IconContainer = styled('div')`
  width: 60px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  background-color: ${getColor('ORANGE')};
  justify-content: center;
  align-items: center;
  display: flex;
`;

interface Props {
  title: ReactNode;
  description: ReactNode;
  icon?: IconType;
}

export const MessageWithDescription = ({ title, description, icon = 'coming soon' }: Props) => (
  <Container display="flex" borderRadius={'10px'} width={764}>
    <IconContainer>
      <Icon icon={icon} size={29} />
    </IconContainer>
    <Box p={3} justifyContent="space-between" flexDirection="column" flex={1}>
      <Box mb={2}>
        <Typography variant="subtitle1" textTransform="uppercase">
          {title}
        </Typography>
      </Box>
      <Box flex={1}>
        <Typography component="div" variant="body1">
          {description}
        </Typography>
      </Box>
    </Box>
  </Container>
);
