import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';
import React, { useEffect, useRef } from 'react';

interface ScrollShadowBoxProps {
  children: React.ReactNode;
  id?: string;
  sx?: SxProps;
}

export const ScrollShadowBox = ({ children, id, sx = {} }: ScrollShadowBoxProps) => {
  const scrollRef = useRef<HTMLElement>(null);

  const updateShadows = () => {
    if (scrollRef?.current) {
      const scrollContainer = scrollRef?.current;
      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;

      const shadowTopVisible = scrollTop > 0;
      const shadowBottomVisible = !(scrollHeight - scrollTop - clientHeight < 1);
      scrollContainer.style.setProperty('--shadow-top-opacity', shadowTopVisible ? '1' : '0');
      scrollContainer.style.setProperty('--shadow-bottom-opacity', shadowBottomVisible ? '1' : '0');
    }
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', updateShadows);

      return () => {
        scrollContainer.removeEventListener('scroll', updateShadows);
      };
    }
  }, []);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
    updateShadows(); // just to make sure that shadows get updated when content changes
  }, [id]);

  return (
    <Box
      ref={scrollRef}
      sx={{
        marginRight: -2, // Added to fix horizontal scrollbar issue
        paddingRight: 2, // Added to fix horizontal scrollbar issue,
        position: 'relative',
        overflowY: {
          xs: 'auto',
          md: 'auto',
        },
        '&::before, &::after': {
          content: '""',
          position: 'sticky',
          left: 0,
          right: 0,
          height: '24px', // Shadow height
          pointerEvents: 'none',
          zIndex: 2,
          display: {
            xs: 'none',
            md: 'block',
          },
        },
        '&::before': {
          top: 0,
          background:
            'radial-gradient(100% 50% at 50% 0%, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.00) 100%)',
          opacity: 'var(--shadow-top-opacity, 0)',
          transition: 'opacity 0.1s ease-in-out',
        },
        '&::after': {
          bottom: 0,
          background:
            'radial-gradient(100% 50% at 50% 0%, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.00) 100%)',
          opacity: 'var(--shadow-bottom-opacity, 0)',
          transition: 'opacity 0.1s ease-in-out',
          transform: 'scaleY(-1)',
        },
        ...sx,
      }}
    >
      <Box sx={{ position: 'relative', zIndex: 1 }}>{children}</Box>
    </Box>
  );
};
