export const REGEX_ZIP = /^(\d{5}?|\d{5}-\d{4}|[a-zA-Z]\d[a-zA-Z] ?\d[a-zA-Z]\d)$/;
export const REGEX_ZIP_CANADA = /[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d/;
export const REGEX_ZIP_US = /^\d{5}(?:-\d{4})?$/;

export const REGEX_PHONE_NUMBER =
  /^(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3}( |-|\.)?\d{4,})[\s]*$/;

export const REGEX_DEALER_ID = /^\d{1,10}$/;

export const REGEX_LEGAL_NAME = /^[a-zA-Z_ _-]*$/;
export const REGEX_ONLY_NUMBERS = /^-?\d+$/;
export const REGEX_ONLY_FLOAT_WITH_TWO_DECIMALS = /^-?\d*(\.\d{0,2})?$/;

export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const REGEX_LOAD_MANAGER_SERIAL = /^(LM1260[-][a-zA-Z0-9]+)$/; // "LM1260-" and then 1+ alphanumeric

export const REGEX_INVERTER_SERIAL = /^(?!LM1260)([a-zA-Z0-9]+-[a-zA-Z0-9]+)$/; // does not start with LM1260, alphanumeric, dash, alphanumeric

export const REGEX_ACTIVATION_CODE = /^([a-zA-Z0-9]{4}[-][a-zA-Z0-9]{4})$/; // two sets of 4-length alphanumeric, separated by a dash

export const REGEX_RCP_NUMBER = /^([0-9A-Fa-f]{12})$/; // 12 hexadecimal digits
export const REGEX_RCP_NUMBER_CODE = /^([0-9a-zA-Z]{4}[-][0-9a-zA-Z]{4})$/; // two sets of 4-length alphanumeric, separated by a dash TODO if this is the same as REGEX_ACTIVATION_CODE can we just re-use that?

export const REGEX_MICROINVERTER_GATEWAY_SERIAL = /^[0-9a-fA-F]{8}$/; // 8-digit hexadecimal. See https://neurio.atlassian.net/wiki/spaces/CHIL/pages/2470871263/Generac+Chilicon+Hardware+Product+Serial+Number+Formats

export const REGEX_ES2_GATEWAY_SERIAL_V1 = /^[A-Za-z]{2}\d{2}\d{2}[0-9A-Fa-f]{5}$/;
//  new serial number format for r2 systems
export const REGEX_ES2_GATEWAY_SERIAL_V2 = /^[A-Za-z]{2}[A-Za-z]{2}\d{1}\d{2}\d{2}[0-9A-Fa-f]{5}$/;
export const REGEX_ES2_PART_NUMBER = /^APCB[A-Za-z0-9]{5}$/;
export const REGEX_ES2_REVISION = /^[A-Z0-9]{2}$/;

const REGEX_VALID_SYSTEM_SERIALS = [
  REGEX_LOAD_MANAGER_SERIAL,
  REGEX_INVERTER_SERIAL,
  REGEX_MICROINVERTER_GATEWAY_SERIAL,
  REGEX_ES2_GATEWAY_SERIAL_V1,
  REGEX_ES2_GATEWAY_SERIAL_V2,
];

export const isValidSystemSerial = (serialNumber: string) =>
  REGEX_VALID_SYSTEM_SERIALS.some((regex) => regex.test(serialNumber)); // Is valid if serial matches any of the regex tests
