import type { BoxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import React from 'react';

const DEFAULT_MESSAGE = "We're unable to retrieve the alert data. Please try again later.";

export type Props = BoxProps & {
  message?: string;
};

export const ErrorMsg = (props: Props) => {
  const { message = DEFAULT_MESSAGE, ...rest } = props;

  return (
    <Box textAlign="center" {...rest}>
      <Typography variant="body2" align="center">
        {message}
      </Typography>
    </Box>
  );
};
