import type { FormControlProps, SelectProps } from '@mui/material';
import {
  FormControl,
  FormHelperText as MuiFormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';

import { getColor } from '../../styles';

interface Option {
  value: string | number;
  label: string | ReactNode;
  disabled?: boolean;
}

interface OwnProps {
  value?: string | number;
  options: Option[];
  label?: string;
  id: string;
  className?: string;
  helperText?: ReactNode;
  formControlProps?: FormControlProps;
}

type Props = SelectProps & OwnProps;

const FormHelperText = styled(MuiFormHelperText)`
  color: ${({ error }) => getColor(error ? 'ERROR' : 'BLACK')} !important;
`;

export const Select = ({
  className,
  label,
  id,
  options,
  error,
  helperText,
  formControlProps,
  ...props
}: Props) => {
  const [lastKey, setLastKey] = useState('');
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const onKeyPressHandle = (e: React.KeyboardEvent) => {
    const open = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      key: 'Enter',
    });
    document.activeElement?.dispatchEvent(open);
    setLastKey(e.key);
  };

  useEffect(() => {
    if (ref && lastKey) {
      const pressLastKey = new KeyboardEvent('keydown', {
        bubbles: true,
        cancelable: true,
        key: lastKey,
      });
      ref?.dispatchEvent(pressLastKey);
      setLastKey('');
    }
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormControl
      variant="standard"
      className={className}
      error={error}
      sx={{ minWidth: 120 }}
      {...formControlProps}
    >
      {label && <InputLabel id={`${id}-label`}>{label}</InputLabel>}
      <MuiSelect
        labelId={`${id}-label`}
        id={id}
        error={error}
        data-test-id={`select-${props.name}`}
        {...props}
        onKeyPress={onKeyPressHandle}
      >
        {options.map((o, i) => (
          <MenuItem
            key={`${id}-option-${o.value}${i}`}
            value={o.value}
            disabled={o.disabled}
            id={`item-${props.name}-${i}`}
            ref={i === 0 ? setRef : undefined}
            data-test-id={`select-${props.name}-option-${o.value}`}
          >
            {o.label}
          </MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
