export enum AttachmentStatus {
  UPLOADED = 'uploaded',
  PENDING = 'pending',
  FAILED = 'failed',
}

export interface AttachmentMetaRequest {
  siteId: string;
  attachmentId: string;
}

export interface AttachmentMetaResponse {
  attachmentId: string;
  siteId: string;
  fileName: string;
  md5: string;
  sizeBytes: number;
  objectKey: string;
  status: AttachmentStatus;
}

export interface AttachmentUploadRequest {
  siteId: string;
  fileName?: string;
  md5?: string;
  sizeBytes?: number;
  file: File;
}

export interface AttachmentUploadResponse {
  attachmentId: string;
  putUrl: string;
}
