import { default as MaterialDrawer } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import React, { useCallback, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { useRoutes } from '../../../hooks/useRoutes';
import { Icon } from '../../icon';
import { Navbar } from '../Navbar';

const StyledButton = styled('a')`
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;

  > span {
    padding-left: 15px;
  }

  > img {
    margin-top: -4px;
    vertical-align: middle;
  }
`;

interface Props {
  toggleDrawer: (hide?: boolean) => void;
}

export const MobileMenuBar = ({ toggleDrawer }: Props) => {
  const Routes = useRoutes();
  const [isOpen, toggleMenu] = useState(false);

  const onOpen = useCallback((e) => {
    e.preventDefault();
    toggleMenu(true);
  }, []);

  const onClose = useCallback((e) => {
    e.preventDefault();
    toggleMenu(false);
  }, []);

  return (
    <>
      <StyledButton onClick={onOpen}>
        <Icon icon="menu" />
        <span>
          <Switch>
            <Route exact path="/">
              Home
            </Route>
            <Route path={[Routes.CompanySitesList]}>All Sites</Route>
            <Route path={[Routes.CompanyAlerts]}>All Alerts</Route>
          </Switch>
        </span>
      </StyledButton>
      <MaterialDrawer anchor="left" onClose={onClose} open={isOpen}>
        <Navbar onClick={onClose} toggleDrawer={toggleDrawer} />
      </MaterialDrawer>
    </>
  );
};
