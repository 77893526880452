import { DateTime } from 'luxon';

import { ChartMetric, ChartTimeframeType, Granularity } from '../types/SiteMetrics';

export type ChartTimeframe = {
  title?: string;
  metric: ChartMetric;
  granularity: Granularity;
  panGranularity: Granularity;
  granularityTypes?: Granularity[];
  panStep: number;
  getDataStartDate: () => string;
  getDataEndDate: () => string;
};

/** Timeframes that do not pad the end date, to avoid issues surrounding midnight of an end date */
export const ChartTimeframeDefinitions: {
  [key in Partial<Record<ChartTimeframeType, string>> as string]: ChartTimeframe; // Can only be one of the keys in the enum, but must return a ChartTimeframe. (vs [key in ChartTimeframeType]? which will return ChartTimeframe | undefined)
} = {
  /** We use these for libs (like react-calendar) that use vanilla JS dates. We want the end date to actually be the last millisecond of the end date, not the midnight thereafter. **/

  [ChartTimeframeType.TODAY]: {
    title: 'Today',
    metric: ChartMetric.POWER,
    granularity: Granularity.MINUTES,
    panGranularity: Granularity.DAYS,
    panStep: 1,
    getDataStartDate: () => DateTime.now().startOf('day').toISO(),
    getDataEndDate: () => DateTime.now().endOf('day').toISO(),
  },
  [ChartTimeframeType.YESTERDAY]: {
    title: 'Yesterday',
    metric: ChartMetric.POWER,
    granularity: Granularity.MINUTES,
    panGranularity: Granularity.DAYS,
    panStep: 1,
    getDataStartDate: function () {
      return DateTime.now()
        .minus({ [this.panGranularity]: this.panStep })
        .startOf('day')
        .toISO();
    },
    getDataEndDate: function () {
      return DateTime.now()
        .minus({ [this.panGranularity]: this.panStep })
        .endOf('day')
        .toISO();
    },
  },
  [ChartTimeframeType.DAYS_7]: {
    title: '7 Days',
    metric: ChartMetric.POWER,
    granularity: Granularity.HOURS,
    panGranularity: Granularity.DAYS,
    panStep: 7,
    getDataStartDate: function () {
      return DateTime.now()
        .minus({ [this.panGranularity]: this.panStep - 1 })
        .startOf('day')
        .toISO();
    },
    getDataEndDate: () => DateTime.now().endOf('day').toISO(),
  },
  [ChartTimeframeType.DAYS_30]: {
    title: '30 Days',
    metric: ChartMetric.ENERGY,
    granularity: Granularity.DAYS,
    panGranularity: Granularity.DAYS,
    panStep: 30,
    getDataStartDate: function () {
      return DateTime.now()
        .minus({ [this.panGranularity]: this.panStep - 1 })
        .startOf('day')
        .toISO();
    },
    getDataEndDate: () => DateTime.now().endOf('day').toISO(),
  },
  [ChartTimeframeType.MONTHS_12]: {
    title: '12 Months',
    metric: ChartMetric.ENERGY,
    granularity: Granularity.MONTHS,
    granularityTypes: [Granularity.DAYS, Granularity.MONTHS],
    panGranularity: Granularity.MONTHS,
    panStep: 12,
    getDataStartDate: function () {
      return DateTime.now()
        .minus({ [this.panGranularity]: this.panStep - 1 })
        .startOf('month')
        .toISO();
    },
    getDataEndDate: () => DateTime.now().endOf('month').toISO(),
  },
};

/** Create a custom timeframe from Luxon start/end dates, so that the pan distance will be the same and the end date is correctly adjusted */
export const CustomTimeframeFromLuxonDateTimesWithAutoGranularityAndMetric = (
  startDateTime: DateTime,
  endDateTime: DateTime,
  maxDaysFromStart?: number,
): ChartTimeframe => {
  const numberOfDaysInInitiallySelectedRange = startDateTime.until(endDateTime).count('days');

  const numberOfDaysInRangeCappedToMaxDays = maxDaysFromStart
    ? Math.max(Math.min(maxDaysFromStart - 1, numberOfDaysInInitiallySelectedRange - 1), 1)
    : numberOfDaysInInitiallySelectedRange;

  const cappedEndDateTime = DateTime.min(
    endDateTime.endOf('day'),
    startDateTime.plus({ days: numberOfDaysInRangeCappedToMaxDays }).endOf('day'),
  );

  const defaultTimeFrameProps = {
    getDataStartDate: () => startDateTime.toISO(),
    getDataEndDate: () => cappedEndDateTime.toISO(),
    panStep: numberOfDaysInInitiallySelectedRange,
  };

  /** Borrowing this logic from `getDateDependentData()` in timeframeHelpers.ts
   * Rewrote it here because I couldn't follow what it was doing...
   * */

  if (numberOfDaysInInitiallySelectedRange <= 1) {
    return {
      ...defaultTimeFrameProps,
      granularityTypes: [Granularity.HOURS, Granularity.MINUTES],
      metric: ChartMetric.POWER,
      granularity: Granularity.MINUTES,
      panGranularity: Granularity.DAYS,
    };
  }

  if (numberOfDaysInInitiallySelectedRange <= 7) {
    return {
      ...defaultTimeFrameProps,
      granularityTypes: [Granularity.DAYS, Granularity.HOURS, Granularity.MINUTES],
      metric: ChartMetric.POWER,
      granularity: Granularity.HOURS,
      panGranularity: Granularity.DAYS,
    };
  }

  if (numberOfDaysInInitiallySelectedRange <= 62) {
    return {
      ...defaultTimeFrameProps,
      granularityTypes: [Granularity.DAYS, Granularity.HOURS],
      metric: ChartMetric.ENERGY,
      granularity: Granularity.DAYS,
      panGranularity: Granularity.DAYS,
    };
  }

  return {
    ...defaultTimeFrameProps,
    panStep: startDateTime.until(endDateTime).count('months'),
    granularityTypes: [Granularity.MONTHS, Granularity.DAYS],
    metric: ChartMetric.ENERGY,
    granularity: Granularity.MONTHS,
    panGranularity: Granularity.MONTHS,
  };
};
