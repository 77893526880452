/*
 * used to check for equality against event.keys property
 */

export enum KeyboardKeys {
  ENTER = 'Enter',
  SHIFT = 'Shift',
  DELETE = 'Delete',
  BACKSPACE = 'Backspace',
  ARROW_LEFT = 'ArrowLeft',
  SPACE = ' ',
  COMMA = ',',
}
