import type { OutlinedInputProps } from '@mui/material';
import { FormControl, FormHelperText, OutlinedInput } from '@mui/material';
import type { ChangeEvent, ReactNode } from 'react';
import slug from 'slug';

import { sanitizeString } from '../../utils';
import { PWRlabel } from '../pwrLabel';

export interface PWRinputProps extends OutlinedInputProps {
  id: string;
  name: string;
  label: ReactNode;
  helperText?: string;
  sanitize?: boolean;
}

export const PWRinput = ({
  id,
  name,
  label,
  required = true,
  helperText,
  sanitize,
  error,
  onChange,
  ...props
}: PWRinputProps) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (sanitize) {
      event.target.value = sanitizeString(event.target.value);
    }

    onChange?.(event);
  };

  return (
    <FormControl fullWidth>
      <PWRlabel htmlFor={id} $required={required}>
        {label}
      </PWRlabel>
      <OutlinedInput
        inputProps={{ 'data-test-id': `form-input-${slug(name)}` }} // Lowercase inputProps affects that actual DOM element <input>, which lets us add the test ID on the actual input field itself and not one of the MUI abstractions above it
        id={id}
        name={name}
        required={required}
        error={error}
        {...props}
        onChange={handleChange}
      />
      <FormHelperText
        data-test-id={`form-input-${slug(name)}-helper-text`}
        error={error}
        sx={{ fontSize: '12px', marginLeft: 0 }}
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};
