import { Wifi as WifiIcon, WifiOff as WifiOffIcon } from '@mui/icons-material';
import { Box, DialogContent, Divider, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import type { ReactNode } from 'react';

import { alertCategory } from '../../constants/alertCategory';
import { DATE_LONG_FORMAT, DATE_TIME_WITH_TIMEZONE_FORMAT } from '../../constants/dates';
import { COLORS } from '../../styles';
import type { AlertCompanyInfo, AlertDetails, AlertTypeInfo } from '../../types/Alert';
import type { DeviceStatus } from '../../types/DeviceStatus';
import { DeviceType } from '../../types/DeviceType';
import type { SiteDetails, System } from '../../types/SiteDetails';
import { SystemStatusName } from '../../types/SiteDetails';
import { parseAlertConditions } from '../../utils';
import { Loader } from '../loader';
import { ErrorMsg } from './errorMsg';

interface DetailRowProps {
  title: string;
  content: ReactNode;
  marginBottom?: number;
}

const DetailRow = (props: DetailRowProps) => {
  const { title, content, marginBottom = 1 } = props;
  return (
    <Box display="flex" mb={marginBottom}>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ flex: 1, textAlign: 'right', pr: '12px' }}
      >
        {title}
      </Typography>
      <Typography variant="body2" component="div" flex={1}>
        {content}
      </Typography>
    </Box>
  );
};

export interface Props {
  alertDetails: AlertDetails | null;
  siteDetails: SiteDetails | null;
  companyInfo: AlertCompanyInfo | null;
  deviceStatus: DeviceStatus | null;
  systemInfo: System | null;
  alertTypeInfo: AlertTypeInfo | null;
  isLoadingSiteDetails: boolean;
  isLoadingCompanyInfo: boolean;
  isLoadingDeviceStatus: boolean;
  isLoadingAlertTypeInfo: boolean;
  isLoadingSiteStatus: boolean;
  alertDetailsError: string | null;
  alertTypeInfoError: string | null;
  height: number | 'unset';
}

export const DetailsContent = (props: Props) => {
  const {
    alertDetails,
    siteDetails,
    companyInfo,
    deviceStatus,
    alertTypeInfo,
    systemInfo,
    isLoadingSiteDetails,
    isLoadingCompanyInfo,
    isLoadingDeviceStatus,
    isLoadingAlertTypeInfo,
    isLoadingSiteStatus,
    alertDetailsError,
    alertTypeInfoError,
    height,
  } = props;

  const alertCategoryName =
    alertDetails?.category && alertCategory[alertDetails.category]
      ? alertCategory[alertDetails.category].name
      : '--';
  const internetConnectivity = systemInfo ? (
    systemInfo.statusName === SystemStatusName.SUCCESS ? (
      <Box height={20}>
        <WifiIcon style={{ fontSize: 17 }} />
      </Box>
    ) : (
      <Box height={20}>
        <WifiOffIcon style={{ fontSize: 17, color: COLORS.MEDIUM_GREY }} />
      </Box>
    )
  ) : (
    '--'
  );
  const showDeviceCommunication =
    alertDetails?.deviceType === DeviceType.BATTERY ||
    alertDetails?.deviceType === DeviceType.PVLINK;

  return (
    <DialogContent dividers style={{ height }}>
      <Box my={4}>
        {!alertDetailsError ? (
          <Box display="flex" justifyContent="space-between">
            <Box flex={1}>
              <DetailRow title="Category" content={alertCategoryName} />
              <DetailRow title="Device RCPn" content={alertDetails?.deviceId || '--'} />
              <DetailRow
                title="Internet Connectivity"
                content={
                  !isLoadingSiteStatus ? (
                    internetConnectivity
                  ) : (
                    <Loader size={5} color={COLORS.MEDIUM_GREY} />
                  )
                }
              />
              <DetailRow
                title="Last Heard"
                content={
                  !isLoadingDeviceStatus ? (
                    deviceStatus ? (
                      DateTime.fromISO(deviceStatus.lastHeardAt)
                        .setZone(siteDetails?.timezone)
                        .toFormat(DATE_TIME_WITH_TIMEZONE_FORMAT)
                    ) : (
                      '--'
                    )
                  ) : (
                    <Loader size={5} color={COLORS.MEDIUM_GREY} />
                  )
                }
              />
              {showDeviceCommunication && (
                <DetailRow
                  title="Device Communication"
                  content={
                    !isLoadingDeviceStatus ? (
                      deviceStatus ? (
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">
                            {deviceStatus.isOnline ? 'Online' : 'Offline'}
                          </Typography>
                        </Box>
                      ) : (
                        '--'
                      )
                    ) : (
                      <Loader size={5} color={COLORS.MEDIUM_GREY} />
                    )
                  }
                />
              )}
            </Box>
            <Box flex={1}>
              <DetailRow title="Inverter RCPn" content={alertDetails?.hostId} />
              <DetailRow
                title="Installed By"
                content={
                  !isLoadingCompanyInfo ? (
                    companyInfo?.installerCompanyName ? (
                      companyInfo.installerCompanyName
                    ) : (
                      '--'
                    )
                  ) : (
                    <Loader size={5} color={COLORS.MEDIUM_GREY} />
                  )
                }
              />
              <DetailRow
                title="Installation Date"
                content={
                  !isLoadingSiteDetails ? (
                    siteDetails?.installDate ? (
                      DateTime.fromISO(siteDetails.installDate).toFormat(DATE_LONG_FORMAT)
                    ) : (
                      '--'
                    )
                  ) : (
                    <Loader size={5} color={COLORS.MEDIUM_GREY} />
                  )
                }
              />
              <DetailRow
                title="Owner Company"
                content={
                  !isLoadingCompanyInfo ? (
                    companyInfo?.ownerCompanyName ? (
                      companyInfo.ownerCompanyName
                    ) : (
                      '--'
                    )
                  ) : (
                    <Loader size={5} color={COLORS.MEDIUM_GREY} />
                  )
                }
              />
            </Box>
            <Box flex={1}>
              <DetailRow
                title="Site"
                content={
                  !isLoadingSiteDetails ? (
                    <Box>
                      <Typography variant="body2">
                        <strong>{siteDetails?.siteName || '--'}</strong>
                      </Typography>
                      <Typography variant="body2">
                        {siteDetails?.contact
                          ? `${siteDetails.contact.firstName} ${siteDetails.contact.lastName}`
                          : '--'}
                      </Typography>
                      <Typography variant="body2">{siteDetails?.contact?.phone || '--'}</Typography>
                    </Box>
                  ) : (
                    <Loader size={5} color={COLORS.MEDIUM_GREY} />
                  )
                }
              />
              <DetailRow
                title="Address"
                content={
                  !isLoadingSiteDetails ? (
                    <Box>
                      <Typography variant="body2">
                        {siteDetails?.siteAddress?.streetAddress || '--'}
                      </Typography>
                      <Typography variant="body2">
                        {siteDetails?.siteAddress
                          ? `${siteDetails.siteAddress.city} ${siteDetails.siteAddress.state} ${siteDetails.siteAddress.zip}`
                          : '--'}
                      </Typography>
                    </Box>
                  ) : (
                    <Loader size={5} color={COLORS.MEDIUM_GREY} />
                  )
                }
              />
            </Box>
          </Box>
        ) : (
          <ErrorMsg />
        )}
      </Box>
      <Box>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            Description
          </Typography>
        </Box>
        <Divider />
        <Box my={3}>
          {!isLoadingAlertTypeInfo ? (
            !alertDetailsError && !alertTypeInfoError && alertTypeInfo?.alertDescription ? (
              <Typography variant="body2">{alertTypeInfo.alertDescription}</Typography>
            ) : (
              <ErrorMsg />
            )
          ) : (
            <Box display="flex" justifyContent="center" height={30}>
              <Loader size={8} color={COLORS.MEDIUM_GREY} />
            </Box>
          )}
        </Box>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            Conditions
          </Typography>
        </Box>
        <Divider />
        <Box my={3}>
          {!alertDetailsError && alertDetails && !isEmpty(alertDetails.conditions) ? (
            alertDetails.conditions.length > 1 ? (
              <Typography variant="body2" component="ol" sx={{ pl: '12px' }}>
                {alertDetails.conditions.map((condition, i) => (
                  <li key={`${condition.conditionType}${i}`} style={{ marginBottom: '4px' }}>
                    {parseAlertConditions(
                      condition,
                      alertDetails.deviceType,
                      alertDetails.deviceState,
                    )}
                  </li>
                ))}
              </Typography>
            ) : (
              <Typography variant="body2">
                {parseAlertConditions(
                  alertDetails.conditions[0],
                  alertDetails.deviceType,
                  alertDetails.deviceState,
                )}
              </Typography>
            )
          ) : (
            <ErrorMsg />
          )}
        </Box>
      </Box>
    </DialogContent>
  );
};
