import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import type { ComponentProps } from 'react';
import React, { useMemo } from 'react';

import { down, getColor } from '../../styles';
import { Button } from '../button';

const useStyles = makeStyles(() => ({
  backdrop: {
    backgroundColor: getColor('OFF_WHITE', 0.5),
  },
}));

const StyledDialog = styled(Dialog)`
  background-color: ${getColor('WHITE', 0.5)};
  .MuiDialog-paper {
    margin: 0 !important;
    position: absolute !important;
    top: 120px !important;
  }
  .MuiPaper-elevation24 {
    border-radius: 10px !important;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1) !important;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: center !important;
  padding: 8px 8px 22px 8px !important;

  ${down('xs')} {
    flex-direction: column !important;
  }
`;

const StyledButtonSuccess = styled('div')`
  text-align: center;
  margin-top: 0;
  width: 200px;
  & button {
    padding: 2px 50px;
  }

  ${down('xs')} {
    padding: 10px 0;
  }
`;

type Props = ComponentProps<typeof Dialog> & {
  onClose(): void;
  onDiscard(): void;
};

export const FormDiscardAlert = ({ onClose, onDiscard, ...props }: Props) => {
  const styles = useStyles();
  const backdropProps = useMemo(
    () => ({
      classes: {
        root: styles.backdrop,
      },
    }),
    [styles.backdrop],
  );

  const handleClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onClose?.(event, reason);
  };

  return (
    <StyledDialog BackdropProps={backdropProps} onClose={handleClose} {...props}>
      <Box pt={2} pb={1} px={3}>
        <Typography variant="subtitle1">Please confirm</Typography>
      </Box>
      <DialogContent>
        <DialogContentText>
          You have unsaved information. Do you want to leave and discard information?
        </DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <StyledButtonSuccess>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </StyledButtonSuccess>
        <StyledButtonSuccess>
          <Button color="primary" onClick={onDiscard}>
            Discard
          </Button>
        </StyledButtonSuccess>
      </StyledDialogActions>
    </StyledDialog>
  );
};
