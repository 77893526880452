import { createAction, createAsyncAction } from 'typesafe-actions';

import type {
  ActivateCompanyAssociationFailedResponse,
  AssociateCompaniesFailedResponseData,
  AssociateCompaniesSuccessResponse,
  CompanyAssociationsFailedResponse,
  CompanyAssociationsSuccessResponse,
  RemoveCompanyAssociationFailedResponse,
  RemoveCompanyAssociationSuccessResponse,
} from '../../types/CompanyAssociation';
export { loggedOut } from '../auth/actions';

export const loadCompanyAssociations = createAsyncAction(
  'LOAD_COMPANY_ASSOCIATIONS_REQUEST',
  'LOAD_COMPANY_ASSOCIATIONS_SUCCESS',
  'LOAD_COMPANY_ASSOCIATIONS_FAILURE',
)<void, CompanyAssociationsSuccessResponse, CompanyAssociationsFailedResponse>();

export const associateCompanies = createAsyncAction(
  'ASSOCIATE_COMPANIES_REQUEST',
  'ASSOCIATE_COMPANIES_SUCCESS',
  'ASSOCIATE_COMPANIES_FAILURE',
)<
  string[],
  { emails: string[]; value: AssociateCompaniesSuccessResponse },
  { emails: string[]; value: AssociateCompaniesFailedResponseData }
>();

export const clearAssociationVariables = createAction('CLEAR_ASSOCIATION_VARIABLES')();

export const acceptAssociation = createAsyncAction(
  'ACCEPT_ASSOCIATION_REQUEST',
  'ACCEPT_ASSOCIATION_SUCCESS',
  'ACCEPT_ASSOCIATION_FAILURE',
)<string, string, ActivateCompanyAssociationFailedResponse>();

export const removeAssociation = createAsyncAction(
  'REMOVE_ASSOCIATION_REQUEST',
  'REMOVE_ASSOCIATION_SUCCESS',
  'REMOVE_ASSOCIATION_FAILURE',
)<
  string,
  { associationId: string; value: RemoveCompanyAssociationSuccessResponse },
  { associationId: string; value: RemoveCompanyAssociationFailedResponse }
>();
