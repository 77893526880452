import type { PaperProps } from '@mui/material';
import { Box, Collapse, Paper, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useMemo } from 'react';
import Draggable from 'react-draggable';

import useWindowSize from '../../hooks/useWindowSize';
import { COLORS, ThemeDimensions } from '../../styles';
import { useViewMode } from '../../styles/stylingUtils';
import type {
  AlertCompanyInfo,
  AlertDetails,
  AlertInstanceHistory,
  AlertTypeInfo,
} from '../../types/Alert';
import { AlertDetailsTab } from '../../types/Alert';
import type { DeviceStatus } from '../../types/DeviceStatus';
import type { SiteDetails, System } from '../../types/SiteDetails';
import { Button } from '../button';
import { Modal as ModalComponent } from '../modal';
import { ContentLoader } from './contentLoader';
import { DetailsContent } from './detailsContent';
import { EventLogContent } from './eventLogContent';
import { HistoryContent } from './historyContent';
import { Navigation } from './navigation';
import { Title } from './title';
import { Toolbar } from './toolbar';
import { TroubleshootingContent } from './troubleshootingContent';

const MODAL_MAX_WIDTH = 'lg';
const MODAL_HEIGHT = 635;
const MODAL_TOOLBAR_HEIGHT = 50;

const useStyles = makeStyles<Theme, { isDesktop: boolean }>((theme) => ({
  paper: {
    margin: 0,
    maxHeight: '100%',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'unset !important',
    },
  },
}));

export type Props = {
  alertId: string;
  alertDetails: AlertDetails | null;
  siteDetails: SiteDetails | null;
  alertHistoryItems: AlertInstanceHistory[] | null;
  companyInfo: AlertCompanyInfo | null;
  deviceStatus: DeviceStatus | null;
  alertTypeInfo: AlertTypeInfo | null;
  systemInfo: System | null;
  currentTab: AlertDetailsTab;
  onTabChanged: (tab: AlertDetailsTab) => void;
  onClose: () => void;
  onExited?: () => void;
  isLoadingAlertDetails: boolean;
  isLoadingCompanyInfo: boolean;
  isLoadingSiteDetails: boolean;
  isLoadingDeviceStatus: boolean;
  isLoadingAlertTypeInfo: boolean;
  isLoadingAlertHistory: boolean;
  isLoadingSiteStatus: boolean;
  alertDetailsError: string | null;
  alertTypeInfoError: string | null;
  open: boolean;
  isDraggable?: boolean;
};

export const Modal = (props: Props) => {
  const {
    alertId,
    alertDetails,
    siteDetails,
    alertHistoryItems,
    companyInfo,
    deviceStatus,
    alertTypeInfo,
    systemInfo,
    currentTab,
    onTabChanged,
    onClose,
    onExited,
    open,
    isLoadingAlertDetails,
    isLoadingCompanyInfo,
    isLoadingSiteDetails,
    isLoadingDeviceStatus,
    isLoadingAlertTypeInfo,
    isLoadingAlertHistory,
    isLoadingSiteStatus,
    alertDetailsError,
    alertTypeInfoError,
    isDraggable,
    ...rest
  } = props;
  const theme = useTheme();
  const mode = useViewMode();
  const isDesktop = mode === 'desktop';
  const classes = useStyles({ isDesktop });
  const modalWidth = theme.breakpoints.values[MODAL_MAX_WIDTH];
  const { width, height } = useWindowSize();
  const center = useMemo(
    () => ({
      x: Math.abs(Math.ceil(width / 2 - modalWidth / 2)),
      y: Math.abs(Math.ceil(height / 2 - MODAL_HEIGHT / 2)),
    }),
    [width, height, modalWidth],
  );

  const bounds = useMemo(
    () => ({ top: 0 - center.y, bottom: Math.ceil(height - MODAL_TOOLBAR_HEIGHT - center.y) }),
    [height, center.y],
  );

  /**
   * @see https://github.com/react-grid-layout/react-draggable/issues/279#issuecomment-331445732
   * for event.preventDefault() fix that prevents Safari from selecting background elements while dragging
   */
  const DraggableComponent = useMemo(() => {
    return (props: PaperProps) => (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
        bounds={bounds}
        onStart={(event) => {
          event?.preventDefault();
        }}
      >
        <Paper className={classes.paper} {...props} />
      </Draggable>
    );
  }, [bounds, classes]);

  const contentHeight = isDesktop ? ThemeDimensions.ALERT_DETAILS_CONTENT_HEIGHT : 'unset';

  return (
    <ModalComponent
      open={open}
      PaperComponent={DraggableComponent}
      hideBackdrop
      hideCloseButton
      backdropBlur={false}
      disableEnforceFocus
      maxWidth={MODAL_MAX_WIDTH}
      fullscreen={!isDesktop}
      TransitionProps={{
        onExited,
      }}
      {...rest}
    >
      <>
        <Toolbar
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
          alertDetails={alertDetails}
          onClose={onClose}
        />
        {/** @todo
         * when attempting to remove this placeholder Collapse block for test Dismiss UI, the modal width gets all messed up.
         * keeping it in for now as I don't have time to fix the issues with modal width.
         */}
        <Collapse in={false}>
          <Box
            py={2}
            px={5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`1px solid ${COLORS.LIGHT_GREY}`}
          >
            <Box pr={5}>
              <Typography variant="subtitle2">
                Are you sure you would like to dismiss this alert?
              </Typography>
              <Typography variant="body2">
                Dismissing will make this alert appear resolved, but can appear again as a new alert
                if/when the same issue arises.
              </Typography>
            </Box>
            <Box display="flex">
              <Button variant="text">Cancel</Button>
              <Box ml={3}>
                <Button color="primary" isLoading={true}>
                  Dismiss
                </Button>
              </Box>
            </Box>
          </Box>
        </Collapse>
        <Title title={alertDetails?.name} isLoading={isLoadingAlertDetails} pt={4} pb={2} />
        <Navigation currentTab={currentTab} onTabChange={onTabChanged} />
        {!isLoadingAlertDetails ? (
          <>
            {currentTab === AlertDetailsTab.DETAILS && (
              <DetailsContent
                alertDetails={alertDetails}
                siteDetails={siteDetails}
                companyInfo={companyInfo}
                systemInfo={systemInfo}
                deviceStatus={deviceStatus}
                isLoadingSiteDetails={isLoadingSiteDetails}
                isLoadingCompanyInfo={isLoadingCompanyInfo}
                isLoadingDeviceStatus={isLoadingDeviceStatus}
                isLoadingSiteStatus={isLoadingSiteStatus}
                alertDetailsError={alertDetailsError}
                alertTypeInfo={alertTypeInfo}
                isLoadingAlertTypeInfo={isLoadingAlertTypeInfo}
                alertTypeInfoError={alertTypeInfoError}
                height={contentHeight}
              />
            )}
            {currentTab === AlertDetailsTab.TROUBLESHOOTING && (
              <TroubleshootingContent
                alertDetails={alertDetails}
                alertDetailsError={alertDetailsError}
                alertTypeInfo={alertTypeInfo}
                isLoadingAlertTypeInfo={isLoadingAlertTypeInfo}
                alertTypeInfoError={alertTypeInfoError}
                height={contentHeight}
              />
            )}
          </>
        ) : (
          <ContentLoader />
        )}
        {currentTab === AlertDetailsTab.HISTORY &&
          (isLoadingAlertHistory ? (
            <ContentLoader />
          ) : (
            <HistoryContent
              siteDetails={siteDetails}
              items={alertHistoryItems}
              alertId={alertId}
              height={contentHeight}
            />
          ))}
        {currentTab === AlertDetailsTab.EVENT_LOG && (
          <EventLogContent siteDetails={siteDetails} height={contentHeight} />
        )}
      </>
    </ModalComponent>
  );
};
