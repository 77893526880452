import type { ComponentProps, ComponentType } from 'react';
import { useEffect } from 'react';
import type { ToastOptions } from 'react-toastify';
import { toast } from 'react-toastify';

import { Alert as AlertComponent } from '../components/alert';
import { CommonErrorAlert } from '../components/commonErrorAlert';
import { NoSystemsAddedToSiteAlert } from '../components/noSystemsAddedToSiteAlert';
import { Alert, AlertContainer } from '../types/ScreenAlerts';
import { useDebouncedEffect } from './useDebounceEffect';

const AlertsRecord = {
  [Alert.Generic]: AlertComponent,
  [Alert.SiteDataError]: CommonErrorAlert,
  [Alert.SitesDataError]: CommonErrorAlert,
  [Alert.SiteAlertsDataError]: CommonErrorAlert,
  [Alert.CompaniesDataError]: CommonErrorAlert,
  [Alert.CompanyAlertsDataError]: CommonErrorAlert,
  [Alert.UsersDataError]: CommonErrorAlert,
  [Alert.SignUpError]: CommonErrorAlert,
  [Alert.DevicesChartDataError]: CommonErrorAlert,
  [Alert.KpiDataError]: CommonErrorAlert,
  [Alert.CompanyUsersDataError]: CommonErrorAlert,
  [Alert.NeverConnectedError]: AlertComponent,
  [Alert.ConnectionLostError]: AlertComponent,
  [Alert.DialogError]: AlertComponent,
  [Alert.MaxMetricsError]: AlertComponent,
  [Alert.AttachmentsRetrieveError]: CommonErrorAlert,
  [Alert.AttachmentRetrieveError]: CommonErrorAlert,
  [Alert.AttachmentUploadError]: CommonErrorAlert,
  [Alert.AttachmentDeleteError]: CommonErrorAlert,
  [Alert.MoveSiteError]: AlertComponent,
  [Alert.AllCompanyAlertsSelected]: AlertComponent,
  [Alert.SiteAlertDetailsError]: CommonErrorAlert,
  [Alert.SiteLayoutLoadError]: CommonErrorAlert,
  [Alert.NoSystemsAddedToSite]: NoSystemsAddedToSiteAlert,
  [Alert.IncorrectSysModeError]: AlertComponent,
  [Alert.SettingUpdateError]: AlertComponent,
  [Alert.SettingUpdateSuccess]: AlertComponent,
  [Alert.InvalidSiteAddress]: AlertComponent,
};

type Props<K extends Alert> = ToastOptions & {
  alert: K;
  alertIdSuffix?: string;
  type?: 'success' | 'error';
  condition: boolean;
  dismissOnUnmount?: boolean;
  isLoading?: boolean;
};

/**
 * "dismissOnUnmount": This may be necessary if we do not need to hide the alert after we have left the screen from
 * which it was called. False value cancels "toast.dismiss" call on unmount.
 **/

export const useAlert = <K extends Alert>(
  {
    alert,
    type = 'error',
    condition,
    dismissOnUnmount = true,
    isLoading,
    containerId = AlertContainer.Root,
    alertIdSuffix,
    ...options
  }: Props<K>,
  args: ComponentProps<typeof AlertsRecord[K]>,
) => {
  const Component = AlertsRecord[alert] as ComponentType;
  const alertId = alertIdSuffix ? `${alert}-${alertIdSuffix}` : alert;

  useEffect(() => {
    if (dismissOnUnmount) {
      return () => {
        toast.dismiss(alertId);
      };
    }
  }, [dismissOnUnmount, alertId]);

  useEffect(() => {
    if (isLoading !== undefined) {
      toast.update(alertId, {
        render: (props) => <Component {...args} {...props} />,
        ...options,
        type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useDebouncedEffect(
    () => {
      if (condition) {
        toast[type]((props) => <Component {...args} {...props} />, {
          ...options,
          toastId: alertId,
          containerId,
        });
      } else {
        toast.dismiss(alertId);
      }
    },
    100,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [condition],
  );
};
