import type { BoxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import React from 'react';

const LOADING_TEXT = 'Loading...';

export type Props = BoxProps & {
  title?: string;
  isLoading: boolean;
};

export const Title = (props: Props) => {
  const { title, isLoading, ...rest } = props;

  const displayTitle = isLoading ? LOADING_TEXT : title;

  return (
    <Box {...rest}>
      <Typography variant="h4" align="center">
        {displayTitle}
      </Typography>
    </Box>
  );
};
