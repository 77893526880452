import type { TypographyProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import React from 'react';

import { Icon } from '../icon';

export const SimpleListItem = ({
  children,
  typographyProps,
}: {
  children: ReactNode;
  typographyProps?: TypographyProps;
}) => (
  <Box component="li">
    <Box mb={1} display="flex">
      <Box height={20} mr={2}>
        <Icon icon="arrow bullet" />
      </Box>
      <Typography variant="body1" {...typographyProps}>
        {children}
      </Typography>
    </Box>
  </Box>
);
