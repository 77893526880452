/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { Dispatch } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import browserHistory from '../browserHistory';
import { AlertContainer } from '../types/ScreenAlerts';
import { customAlert } from '../utils/alerts';
import { api } from './api';
import type { AuthActions } from './auth';
import type { CompaniesActions } from './companies';
import type { CompanyActions } from './company';
import type { CompanyAlertsActions } from './companyAlerts';
import type { MapSitesActions } from './mapSites';
import rootEpic from './rootEpic';
import rootReducer from './rootReducer';
import type { SiteAlertsActions } from './siteAlerts';
import type { SiteLayoutActions } from './siteLayout';
import type { SitesActions } from './sites';
import type { RootState } from './types';

export type RootAction =
  | AuthActions
  | CompanyAlertsActions
  | SiteAlertsActions
  | SitesActions
  | SiteLayoutActions
  | MapSitesActions
  | CompanyActions
  | CompaniesActions;

export type EpicDependencies = {
  history: typeof browserHistory;
};

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    history: browserHistory,
  },
});

// [redux-persist]
// only the whitelist array items will persist in localstorage between sessions
// using a "createWhitelistFilter" for "sites" store to only persist specific sub-state items
//
// autoMergeLevel2 is needed to properly merge rehydrated store against INITIAL_STATE on reload
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['account', 'company', 'sites'],
  transforms: [createWhitelistFilter('sites', ['selectedColumns'])],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

export const errorMiddleware = () => (next: Dispatch) => (action: any) => {
  if (action.payload?.error?.status >= 500) {
    const title = action.payload?.error?.error || 'Unknown error.';
    customAlert({ title, closeButton: true }, { containerId: AlertContainer.Root });
  }
  return next(action);
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    // At the moment, we store non-serializable objects in the state, for example, error objects.
    // Therefore, I had to turn off this (serializableCheck), more here
    // https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state
    getDefaultMiddleware({ serializableCheck: false }).concat(
      epicMiddleware,
      errorMiddleware,
      api.middleware,
    ),
});

const epic$ = new BehaviorSubject(rootEpic);
// @ts-ignore
const hotReloadingEpic = (...args) =>
  epic$.pipe(
    // @ts-ignore
    switchMap((epic) => epic(...args)),
  );

// @ts-ignore
epicMiddleware.run(hotReloadingEpic);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
  module.hot.accept('./rootEpic', () => {
    const nextRootEpic = require('./rootEpic').default;
    epic$.next(nextRootEpic);
  });
}

export default store;
export const persistor = persistStore(store);
