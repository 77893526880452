import type { RootState } from '../types';

export const companyAlertsSelector = (state: RootState) => state.companyAlerts.items;
export const companyAlertsErrorSelector = (state: RootState) => state.companyAlerts.error;
export const companyAlertsLoadingSelector = (state: RootState) => state.companyAlerts.loading;
export const companyAlertsLoadingFinishedSelector = (state: RootState) =>
  state.companyAlerts.loadingFinished;
export const companyAlertsCurrentPageSelector = (state: RootState) => state.companyAlerts.page || 0;
export const companyTotalAlertsSelector = (state: RootState) => state.companyAlerts?.total || 0;
export const companyAlertsPerPageSelector = (state: RootState) =>
  state.companyAlerts?.perPage || 10;
