import type { TableColumns } from '../types/Table';

export const MOBILE_COLUMNS = ['companyId', 'companyName', 'state', 'phone'];
export const TABLET_COLUMNS = ['companyId', 'companyName', 'address', 'city', 'state', 'phone'];
export const INITIAL_TABLET_COLUMNS = [
  'companyId',
  'companyName',
  'address',
  'city',
  'state',
  'phone',
];

export default {
  forced: {
    title: 'Company info',
    items: [
      { name: 'companyId', property: ['companyId'], title: '', sortable: false },
      { name: 'companyName', property: ['name'], title: 'Company Name', sortable: false },
      {
        name: 'address',
        property: ['address', 'address'],
        title: 'Address',
        sortable: false,
      },
      {
        name: 'city',
        property: ['address', 'city'],
        title: 'City',
        sortable: false,
      },
      {
        name: 'state',
        property: ['address', 'state'],
        title: 'State/Province',
        sortable: false,
      },
      {
        name: 'phone',
        property: ['phone'],
        title: 'Phone',
        sortable: false,
      },
    ],
  },
} as TableColumns;
