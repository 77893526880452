import {
  CheckCircle as SuccessIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import type { SxProps, TooltipProps } from '@mui/material';
import { Box, Tooltip, Typography } from '@mui/material';
import type { ReactNode } from 'react';

import { COLORS } from '../../styles';

type TooltipType = 'success' | 'info' | 'warning' | 'error';

export interface PWRtooltipProps extends Omit<TooltipProps, 'title'> {
  title?: string;
  message: ReactNode; // pass only ReactNodes with display: inline-block
  type?: TooltipType;
  tooltipSx?: SxProps;
}

const getIcon = (type?: TooltipType) => {
  switch (type) {
    case 'error':
      return <ErrorIcon fontSize="small" sx={{ color: '#FF453B' }} />;
    case 'warning':
      return <WarningIcon fontSize="small" sx={{ color: '#FED265' }} />;
    case 'info':
      return <InfoIcon fontSize="small" sx={{ color: '#30BCC3' }} />;
    case 'success':
      return <SuccessIcon fontSize="small" sx={{ color: '#A8DB65' }} />;
    default:
      return null;
  }
};

export const PWRtooltip = ({
  title,
  message,
  type,
  arrow = true,
  placement = 'top',
  children,
  id,
  tooltipSx,
  ...tooltipProps
}: PWRtooltipProps) => {
  const hasTitle = !!title;

  return (
    <Tooltip
      title={
        <>
          {hasTitle && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pb: 1,
              }}
            >
              <Typography
                color="inherit"
                sx={{ fontWeight: 'bold', mr: 2 }}
                data-test-class={`${type}-tooltip-title`}
              >
                {title}
              </Typography>
              {getIcon(type)}
            </Box>
          )}
          <Box sx={{ textAlign: 'left' }}>
            <Typography color="inherit" sx={{ fontSize: 12 }} data-test-class="tooltip-message">
              {message}
            </Typography>
          </Box>
        </>
      }
      arrow={arrow}
      placement={placement}
      componentsProps={{
        tooltip: {
          // @ts-ignore this is fine, it gets passed through to the DOM element
          'data-test-id': id,
          sx: {
            backgroundColor: COLORS.BLACK,
            '& .MuiTooltip-arrow': {
              color: COLORS.BLACK,
            },
            borderRadius: '8px',
            p: 2,
            ...(tooltipSx ?? {}),
          },
        },
      }}
      {...tooltipProps}
    >
      {children}
    </Tooltip>
  );
};
