import type { SelectChangeEvent } from '@mui/material';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { isEmpty, startCase } from 'lodash';
import { DateTime } from 'luxon';
import type { ChangeEvent, ComponentProps } from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { trackEvent } from '../../analyticsClient';
import { alertCategory } from '../../constants/alertCategory';
import { LOADING_ERROR_MESSAGE, NO_ALERTS_MESSAGE } from '../../constants/dataLoadingMessages';
import { DATE_TIME_FORMAT } from '../../constants/dates';
import deviceTypeDefinitions from '../../constants/deviceTypes';
import { useCompanyContext } from '../../hooks/useCompanyContext';
import { useFeatureToggle } from '../../hooks/useFeatureToggle';
import { useRoutes } from '../../hooks/useRoutes';
import { useSiteContext } from '../../hooks/useSiteContext';
import { useTableMaxHeight } from '../../hooks/useTableMaxHeight';
import { loadSiteAlerts } from '../../store/siteAlerts/actions';
import {
  alertsSelector,
  errorSelector as alertsErrorSelector,
  loadingSelector as alertsLoadingSelector,
  siteAlertsCurrentPageSelector,
  siteAlertsPerPageSelector,
  siteTotalAlertsSelector,
} from '../../store/siteAlerts/selectors';
import { ThemeDimensions } from '../../styles';
import type { AlertsTab, SiteAlert } from '../../types/Alert';
import { AlertStatus } from '../../types/Alert';
import { AnalyticsEvent, AnalyticsProperty } from '../../types/Analytics';
import { FeatureToggle } from '../../types/FeatureToggle';
import type { RowComponentProps, TableColumn } from '../../types/NewTable';
import { getTimezoneDisplay } from '../../utils';
import { AlertSeverityIcon } from '../alertSeverityIcon';
import { Loader } from '../loader';
import { NewTable } from '../newTable';
import { PWRtooltip } from '../pwrTooltip';

const defaultSort = 'openedAt:desc';

const OFFSET_BOTTOM = ThemeDimensions.TABLE_PAGINATION_HEIGHT + 56;

enum TableColumnId {
  DEVICE_TYPE = 'deviceType',
  DEVICE_ID = 'deviceId',
  SEVERITY = 'severity',
  NAME = 'name',
  CATEGORY = 'category',
  DURATION = 'duration',
  OPENED_AT = 'openedAt',
  VISIT_SEVERITY = 'visitRequired',
}

const columns: TableColumn<SiteAlert>[] = [
  { id: TableColumnId.DEVICE_TYPE, label: 'Device' },
  { id: TableColumnId.DEVICE_ID, label: 'RCP Number' },
  { id: TableColumnId.SEVERITY, label: '' },
  { id: TableColumnId.NAME, label: 'Alert Name' },
  { id: TableColumnId.CATEGORY, label: 'Category' },
  { id: TableColumnId.DURATION, label: 'Duration' },
  { id: TableColumnId.OPENED_AT, label: 'Opened At' },
];

const visitSeverityColumn = { id: TableColumnId.VISIT_SEVERITY, label: 'Visit Required' };

interface Props {
  activeAlertId?: string;
  selectedTab: AlertsTab;
  timezone?: string;
}

const CenteredBox = (props: ComponentProps<typeof Box>) => (
  <Box display="flex" alignItems="center" justifyContent="center" {...props} />
);

const RowComponent = ({
  row,
  TableRowProps,
}: RowComponentProps<SiteAlert & { timezone: string | undefined }>) => {
  const visitSeverityToggleEnabled = useFeatureToggle(FeatureToggle.ALERTS_VISIT_SEVERITY_FEATURE);

  const {
    deviceType,
    deviceId,
    status,
    severity,
    name,
    category,
    openedAt,
    visitRequired,
    timezone,
  } = row;
  const deviceTypeName =
    deviceType && deviceTypeDefinitions[deviceType] ? deviceTypeDefinitions[deviceType].name : '--';
  const alertCategoryName = alertCategory[category] ? alertCategory[category].name : '--';
  const visitRequiredDisplay = visitRequired ? startCase(visitRequired.toLowerCase()) : '--';

  return (
    <TableRow {...TableRowProps}>
      <TableCell>{deviceTypeName}</TableCell>
      <TableCell>{deviceId || '--'}</TableCell>
      <TableCell style={{ width: 30, paddingRight: 0 }}>
        <CenteredBox>
          {severity && status ? (
            <PWRtooltip message={`${startCase(severity.toLowerCase())} Severity`}>
              <AlertSeverityIcon alertSeverity={severity} alertStatus={status} />
            </PWRtooltip>
          ) : (
            '--'
          )}
        </CenteredBox>
      </TableCell>
      <TableCell>{name || '--'}</TableCell>
      <TableCell>{alertCategoryName}</TableCell>
      <TableCell>
        {!!openedAt
          ? DateTime.fromMillis(openedAt).setZone(timezone).toRelative()?.replace(' ago', '')
          : '--'}
      </TableCell>
      <TableCell>
        {!!openedAt
          ? DateTime.fromMillis(openedAt).setZone(timezone).toFormat(DATE_TIME_FORMAT)
          : '--'}
      </TableCell>
      {visitSeverityToggleEnabled && <TableCell>{visitRequiredDisplay}</TableCell>}
    </TableRow>
  );
};

export const SiteActiveAlertsTable = (props: Props) => {
  const { activeAlertId, selectedTab, timezone } = props;
  const tableRef = useRef<HTMLDivElement>(null);
  const { companyId } = useCompanyContext();
  const { siteId = '' } = useSiteContext();
  const tableMaxHeight = useTableMaxHeight({
    tableRef,
    defaultHeight: '40vh',
    offsetBottom: OFFSET_BOTTOM,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const Routes = useRoutes();

  const visitSeverityToggleEnabled = useFeatureToggle(FeatureToggle.ALERTS_VISIT_SEVERITY_FEATURE);

  const siteAlerts = useSelector(alertsSelector);
  const siteAlertsLoading = useSelector(alertsLoadingSelector);
  const siteAlertsError = useSelector(alertsErrorSelector);

  const page = useSelector(siteAlertsCurrentPageSelector);
  const count = useSelector(siteTotalAlertsSelector);
  const rowsPerPage = useSelector(siteAlertsPerPageSelector);

  useEffect(() => {
    dispatch(
      loadSiteAlerts.request({ siteId, page: 1, status: [AlertStatus.ACTIVE], sort: defaultSort }),
    );
  }, [dispatch, siteId]);

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    dispatch(
      loadSiteAlerts.request({
        siteId,
        page,
        perPage: rowsPerPage,
        status: [AlertStatus.ACTIVE],
        sort: defaultSort,
      }),
    );
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent<unknown>, perPage: number) => {
    dispatch(
      loadSiteAlerts.request({
        siteId,
        page: 1,
        perPage,
        status: [AlertStatus.ACTIVE],
        sort: defaultSort,
      }),
    );
  };

  const handleRowClick = ({ alertId }: SiteAlert) => {
    trackEvent(AnalyticsEvent.AlertDetailsClick, {
      [AnalyticsProperty.AlertId]: alertId,
      [AnalyticsProperty.SiteId]: siteId,
    });
    history.push(generatePath(Routes.SiteAlerts, { companyId, siteId, tab: selectedTab, alertId }));
  };

  const formattedRows = siteAlerts.map((alert) => ({
    ...alert,
    timezone,
  }));

  const formattedColumns = columns.map((column) => {
    if (column.id === TableColumnId.OPENED_AT) {
      return {
        ...column,
        label: `${column.label} (${getTimezoneDisplay(timezone)})`,
      };
    }
    return column;
  });

  if (visitSeverityToggleEnabled) {
    formattedColumns.push(visitSeverityColumn);
  }

  return (
    <Box py={1}>
      <div ref={tableRef}>
        {siteAlertsLoading ? (
          <Box display="flex" justifyContent="center" my={2}>
            <Loader size={14} color="orange" />
          </Box>
        ) : !isEmpty(siteAlerts) ? (
          <NewTable
            activeId={activeAlertId}
            columns={formattedColumns}
            rows={formattedRows}
            RowComponent={RowComponent}
            onRowClick={handleRowClick}
            idExtractor="alertId"
            stickyHeader
            containerMaxHeight={tableMaxHeight}
            HeadProps={{
              align: 'left',
            }}
            PaginationProps={{
              onRowsPerPageChange: handleRowsPerPageChange,
              count,
              onChange: handlePageChange,
              page,
              rowsPerPage,
            }}
          />
        ) : (
          <Typography variant="body1" color="textSecondary">
            {siteAlertsError ? LOADING_ERROR_MESSAGE : NO_ALERTS_MESSAGE}
          </Typography>
        )}
      </div>
    </Box>
  );
};
