import type { ReactNode } from 'react';
import React from 'react';

// source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

type Props = {
  children: ReactNode;
  textToHighlight?: string;
};

export const Highlighter = ({ children, textToHighlight }: Props) => {
  if (textToHighlight) {
    return (
      <>
        {React.Children.map(children, (child) => {
          if (child && typeof child === 'string') {
            const parts = child.split(new RegExp(`(${escapeRegExp(textToHighlight)})`, 'gi'));
            if (parts.length === 1) {
              return child;
            }
            return (
              <span>
                {parts.map((part: string, i: number) => {
                  const match = part.toLowerCase() === textToHighlight.toLowerCase();
                  return match ? (
                    <mark key={i} style={{ fontWeight: 700, backgroundColor: 'transparent' }}>
                      {part}
                    </mark>
                  ) : (
                    part
                  );
                })}
              </span>
            );
          }
          return child;
        })}
      </>
    );
  }
  return <>{children}</>;
};
