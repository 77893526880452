import React, { useCallback } from 'react';

import type { SystemDetails } from '../../types/Site';
import { PairItem } from '../pairItem';

type PairingRecord = Record<string, string>;

type Props = {
  pairingRecord: PairingRecord;
  setPairingRecord: (record: PairingRecord) => void;
  loadManagers: SystemDetails[];
  inverters: { serialNumber: string }[];
};

export const PairItemsList = ({
  loadManagers,
  inverters,
  pairingRecord,
  setPairingRecord,
}: Props) => {
  const handleChange = useCallback(
    (loadManagerSerialNumber: string, inverterSerialNumber: string) => {
      setPairingRecord({ ...pairingRecord, [loadManagerSerialNumber]: inverterSerialNumber });
    },
    [pairingRecord, setPairingRecord],
  );

  const isPaired = useCallback(
    (serialNumber: string) => {
      return !!Object.entries(pairingRecord).find(
        ([, inverterSerialNumber]) => inverterSerialNumber === serialNumber,
      );
    },
    [pairingRecord],
  );
  return (
    <>
      {loadManagers.map(({ serialNumber }) => (
        <PairItem
          value={pairingRecord[serialNumber] || ''}
          key={serialNumber}
          serialNumber={serialNumber}
          inverters={inverters}
          onChange={handleChange}
          isPaired={isPaired}
        />
      ))}
    </>
  );
};
