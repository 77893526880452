import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { GENERAC_PRIVACY_POLICY, GENERAC_TERMS_PDF } from '../../../constants/general';
import { getColor } from '../../../styles';
import { SupportLink } from '../../supportLink';
import logo from './assets/pwr_fleet_logo.svg';

const Wrapper = styled('div')`
  align-self: flex-end;
  font-weight: 600;
  font-size: 12px;
  color: ${getColor('MEDIUM_GREY')};
  width: 100%;
  margin-top: 20px;
  padding: 0 16px 100px 16px;

  .links {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .links {
    display: block;
  }

  .links__item {
    display: inline-block;
    padding-left: 24px;
    line-height: 10px;

    a {
      text-decoration: none;
    }

    :first-child {
      padding-left: 0;
    }
  }
`;

const InnerWrapper = styled(Box)`
  border-top: 1px solid ${getColor('LIGHT_GREY')};
`;

export const LegalContent = () => (
  <Wrapper>
    <InnerWrapper pt={3}>
      <Box maxWidth={90}>
        <Link to="/">
          <img src={logo} alt="Generac PWRfleet Logo" />
        </Link>
      </Box>
      <Box pt={3}>
        <Typography variant="caption" color="textSecondary">
          &copy; Generac Inc {new Date().getFullYear()}
        </Typography>
      </Box>
      <Box pt={1}>
        <ul className="links">
          <li className="links__item">
            <SupportLink>
              <Typography variant="caption" color="textSecondary">
                Support
              </Typography>
            </SupportLink>
          </li>
          <li className="links__item">
            <Typography
              variant="caption"
              color="textSecondary"
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={GENERAC_PRIVACY_POLICY}
            >
              Privacy
            </Typography>
          </li>
          <li className="links__item">
            <Typography
              variant="caption"
              color="textSecondary"
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={GENERAC_TERMS_PDF}
              download
            >
              Legal
            </Typography>
          </li>
        </ul>
      </Box>
    </InnerWrapper>
  </Wrapper>
);
