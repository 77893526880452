import 'react-calendar/dist/Calendar.css';

import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import type {
  CalendarProps,
  CalendarTileProperties,
  OnChangeDateRangeCallback,
} from 'react-calendar';

import type { TimezonelessDate } from '../../types/Date';
import { convertNativeDateToTimezonelessDate } from '../../utils';
import { Flex } from '../flex';
import { PWRbutton } from '../pwrButton';
import { StyledReactCalendar } from './StyledReactCalendar';

const MILLIS_IN_DAY = 86400000;

interface DatePickerProps {
  initialStartDateIso: string; // using timezone offset
  initialEndDateIso: string; // using timezone offset
  handleSelection: (startDate: TimezonelessDate, endDate: TimezonelessDate) => void;
  handleCancel?: () => void;
  timezone?: string;
  earliestPossibleDateIso?: string; // using timezone offset
  latestPossibleDateIso?: string; // using timezone offset
  selectRange?: boolean; // set to false to make this a single day date picker
  maxNumberOfDays?: number; // greater than 1
  calendarProps?: CalendarProps;
}

/*
 * This component uses Native Date classes, in part for convenience, because react-calendar
 * requires them, but also because it needs to be "tricked" into thinking the timezone passed
 * to it is the browser timezone. This cannot be done with luxon since it's default (browser)
 * timezone is "overwritten" with the site timezone inside SiteContainer.
 */

export const DatePicker = ({
  initialStartDateIso,
  initialEndDateIso,
  handleSelection,
  handleCancel,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  earliestPossibleDateIso,
  latestPossibleDateIso,
  selectRange = true,
  maxNumberOfDays,
  calendarProps,
}: DatePickerProps) => {
  const [initialStartDateInTimezone, initialEndDateInTimezone] = useMemo(() => {
    const start = new Date(initialStartDateIso).toLocaleString('en-US', {
      timeZone: timezone,
    });
    const end = new Date(initialEndDateIso).toLocaleString('en-US', {
      timeZone: timezone,
    });

    return [start, end];
  }, [timezone, initialStartDateIso, initialEndDateIso]);

  const minDate = useMemo(() => {
    if (earliestPossibleDateIso) {
      const earliestPossibleDateInTimezone = new Date(earliestPossibleDateIso).toLocaleString(
        'en-US',
        { timeZone: timezone },
      );
      return new Date(earliestPossibleDateInTimezone);
    }
  }, [earliestPossibleDateIso, timezone]);

  const maxDate = useMemo(() => {
    if (latestPossibleDateIso) {
      const latestPossibleDateInTimezone = new Date(latestPossibleDateIso).toLocaleString('en-US', {
        timeZone: timezone,
      });
      return new Date(latestPossibleDateInTimezone);
    }

    return new Date(); // if prop is not provided use the current date as max
  }, [latestPossibleDateIso, timezone]);

  const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date]>([
    new Date(initialStartDateInTimezone),
    new Date(initialEndDateInTimezone),
  ]);
  const [clickedDay, setClickedDay] = useState<Date | undefined>();

  const handleChange: OnChangeDateRangeCallback = (dates) => {
    if (dates.length === 2) {
      setSelectedDateRange(dates);
    } else {
      setSelectedDateRange([dates[0], dates[0]]);
    }
  };

  const handleApply = () => {
    handleSelection(
      convertNativeDateToTimezonelessDate(selectedDateRange[0]),
      convertNativeDateToTimezonelessDate(selectedDateRange[1]),
    );
  };

  const tileDisabler = (tile: CalendarTileProperties): boolean => {
    // this enforces the maxNumberOfDays prop, when date is clicked,
    // disables dates greater and less than the max
    const hasValidMax = maxNumberOfDays && maxNumberOfDays > 1;
    const isApplicable = hasValidMax && selectRange && clickedDay;

    if (isApplicable) {
      const clickedDayMillis = clickedDay.getTime();
      const tileMillis = tile.date.getTime();

      const diffMillis = Math.abs(clickedDayMillis - tileMillis);

      if (diffMillis > maxNumberOfDays * MILLIS_IN_DAY) {
        return true;
      }
    }

    return false;
  };

  const tileStyler = (tile: CalendarTileProperties) => {
    const tileMillis = tile.date.getTime();

    const todayInTimezone = new Date().toLocaleString('en-US', { timeZone: timezone });
    const startOfTodayMillis = new Date(todayInTimezone).setHours(0, 0, 0, 0);

    if (tileMillis === startOfTodayMillis) {
      // applies 'now' styles if browser today and timezone today differ
      return 'react-calendar__tile--now';
    }

    const hasValidMax = maxNumberOfDays && maxNumberOfDays > 1;
    const isApplicable = hasValidMax && selectRange && clickedDay;

    if (isApplicable) {
      const clickedDayMillis = clickedDay.getTime();

      const maxOffsetMillis = maxNumberOfDays * MILLIS_IN_DAY;

      const earliestValidTileMillis = clickedDayMillis - maxOffsetMillis;
      const latestValidTileMillis = clickedDayMillis + maxOffsetMillis;

      // apply border-radius earliest and latest 'pickable' dates
      if (tileMillis === earliestValidTileMillis) {
        return 'earliest-valid';
      }
      if (tileMillis === latestValidTileMillis) {
        return 'latest-valid';
      }
      if (Math.abs(clickedDayMillis - tileMillis) >= maxOffsetMillis) {
        return 'invalid';
      }
    }

    return null;
  };

  return (
    <Box p={3}>
      <StyledReactCalendar
        data-test-class="date-picker-calendar"
        returnValue="range" // technically optional (gets overridden by selectRange) but let's be explicit anyway
        minDetail="month" // don't let users zoom out beyond the month view (to mimic the simplicity of the old date picker)
        locale="en-US" // Don't use browser locale (because otherwise this widget would be localized while the rest of PWRfleet is still in en-US)
        calendarType="US" // Force the American calendar type (Sundays are the start of week)
        allowPartialRange={true} // when only one day is selected, allow applying and assume they meant just that one day
        showDoubleView={false} // change this to show two months side by side
        showNeighboringMonth={false} // whether we should show "1,2,3" etc. of the next month at the end (and "30, 31" at the start) of this month
        showFixedNumberOfWeeks={false} // in two month view, turning this off prevents behavior that's similar to showNeighboringMonths
        goToRangeStartOnSelect={false} // don't shift the viewed months when the last date of a range is selected; that causes a layout shift
        prev2Label={null} // hide "Prev" label
        next2Label={null}
        prevAriaLabel="Previous month" // we can be more precise than Prev/Next since we only ever show the month view
        nextAriaLabel="Next month"
        formatShortWeekday={(_, date) =>
          date.toLocaleDateString('en-US', { weekday: 'short' }).substring(0, 2)
        } // show only 2 letters instead of the default 3 (e.g. Th instead of Thu)
        {...calendarProps} // allow overwritting of above config but not below dynamic props
        selectRange={selectRange}
        onClickDay={(date: Date) => setClickedDay(date)}
        value={selectedDateRange}
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        tileDisabled={tileDisabler}
        tileClassName={tileStyler}
      />
      <Flex sx={{ gap: 2, pt: 2 }}>
        <PWRbutton
          data-test-class="date-picker-cancel-btn"
          text="Cancel"
          variant="outlined"
          color="secondary"
          onClick={handleCancel}
          sx={{ width: '100%' }}
        />
        <PWRbutton
          data-test-class="date-picker-apply-btn"
          text="Apply"
          variant="contained"
          backgroundColor="black"
          onClick={handleApply}
          sx={{ width: '100%' }}
        />
      </Flex>
    </Box>
  );
};
