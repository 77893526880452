export interface CountriesInterface {
  code2: string;
  code3: string;
  capital: string;
  region: string;
  value: string;
  label: string;
  subregion: string;
  states: StateInterface[];
}

export interface StateInterface {
  code: string;
  subdivision: string;
  value: string;
  label: string;
}

export const provincesCan = [
  {
    code: 'AB',
    subdivision: 'province',
    value: 'AB',
    label: 'Alberta',
  },
  {
    code: 'BC',
    value: 'BC',
    label: 'British Columbia',
    subdivision: 'province',
  },
  {
    code: 'MB',
    value: 'MB',
    label: 'Manitoba',
    subdivision: 'province',
  },
  {
    code: 'NB',
    value: 'NB',
    label: 'New Brunswick',
    subdivision: 'province',
  },
  {
    code: 'NL',
    value: 'NL',
    label: 'Newfoundland and Labrador',
    subdivision: 'province',
  },
  {
    code: 'NS',
    value: 'NS',
    label: 'Nova Scotia',
    subdivision: 'province',
  },
  {
    code: 'ON',
    value: 'ON',
    label: 'Ontario',
    subdivision: 'province',
  },
  {
    code: 'PE',
    value: 'PE',
    label: 'Prince Edward Island',
    subdivision: 'province',
  },
  {
    code: 'QC',
    value: 'QC',
    label: 'Quebec',
    subdivision: 'province',
  },
  {
    code: 'SK',
    value: 'SK',
    label: 'Saskatchewan',
    subdivision: 'province',
  },
  {
    code: 'NT',
    value: 'NT',
    label: 'Northwest Territories',
    subdivision: 'territory',
  },
  {
    code: 'NU',
    value: 'NU',
    label: 'Nunavut',
    subdivision: 'territory',
  },
  {
    code: 'YT',
    value: 'YT',
    label: 'Yukon',
    subdivision: 'territory',
  },
];

export const statesUsa = [
  {
    code: 'DC',
    value: 'DC',
    label: 'District of Columbia',
    subdivision: 'district',
  },
  {
    code: 'AS',
    value: 'AS',
    label: 'American Samoa',
    subdivision: 'outlying territory',
  },
  {
    code: 'GU',
    value: 'GU',
    label: 'Guam',
    subdivision: 'outlying territory',
  },
  {
    code: 'MP',
    value: 'MP',
    label: 'Northern Mariana Islands',
    subdivision: 'outlying territory',
  },
  {
    code: 'UM',
    value: 'UM',
    label: 'United States Minor Outlying Islands',
    subdivision: 'outlying territory',
  },
  {
    code: 'VI',
    value: 'VI',
    label: 'Virgin Islands, U.S.',
    subdivision: 'outlying territory',
  },
  {
    code: 'AL',
    value: 'AL',
    label: 'Alabama',
    subdivision: 'state',
  },
  {
    code: 'AK',
    value: 'AK',
    label: 'Alaska',
    subdivision: 'state',
  },
  {
    code: 'AZ',
    value: 'AZ',
    label: 'Arizona',
    subdivision: 'state',
  },
  {
    code: 'AR',
    value: 'AR',
    label: 'Arkansas',
    subdivision: 'state',
  },
  {
    code: 'CA',
    value: 'CA',
    label: 'California',
    subdivision: 'state',
  },
  {
    code: 'CO',
    value: 'CO',
    label: 'Colorado',
    subdivision: 'state',
  },
  {
    code: 'CT',
    value: 'CT',
    label: 'Connecticut',
    subdivision: 'state',
  },
  {
    code: 'DE',
    value: 'DE',
    label: 'Delaware',
    subdivision: 'state',
  },
  {
    code: 'FL',
    value: 'FL',
    label: 'Florida',
    subdivision: 'state',
  },
  {
    code: 'GA',
    value: 'GA',
    label: 'Georgia',
    subdivision: 'state',
  },
  {
    code: 'HI',
    value: 'HI',
    label: 'Hawaii',
    subdivision: 'state',
  },
  {
    code: 'ID',
    value: 'ID',
    label: 'Idaho',
    subdivision: 'state',
  },
  {
    code: 'IL',
    value: 'IL',
    label: 'Illinois',
    subdivision: 'state',
  },
  {
    code: 'IN',
    value: 'IN',
    label: 'Indiana',
    subdivision: 'state',
  },
  {
    code: 'IA',
    value: 'IA',
    label: 'Iowa',
    subdivision: 'state',
  },
  {
    code: 'KS',
    value: 'KS',
    label: 'Kansas',
    subdivision: 'state',
  },
  {
    code: 'KY',
    value: 'KY',
    label: 'Kentucky',
    subdivision: 'state',
  },
  {
    code: 'LA',
    value: 'LA',
    label: 'Louisiana',
    subdivision: 'state',
  },
  {
    code: 'ME',
    value: 'ME',
    label: 'Maine',
    subdivision: 'state',
  },
  {
    code: 'MD',
    value: 'MD',
    label: 'Maryland',
    subdivision: 'state',
  },
  {
    code: 'MA',
    value: 'MA',
    label: 'Massachusetts',
    subdivision: 'state',
  },
  {
    code: 'MI',
    value: 'MI',
    label: 'Michigan',
    subdivision: 'state',
  },
  {
    code: 'MN',
    value: 'MN',
    label: 'Minnesota',
    subdivision: 'state',
  },
  {
    code: 'MS',
    value: 'MS',
    label: 'Mississippi',
    subdivision: 'state',
  },
  {
    code: 'MO',
    value: 'MO',
    label: 'Missouri',
    subdivision: 'state',
  },
  {
    code: 'MT',
    value: 'MT',
    label: 'Montana',
    subdivision: 'state',
  },
  {
    code: 'NE',
    value: 'NE',
    label: 'Nebraska',
    subdivision: 'state',
  },
  {
    code: 'NV',
    value: 'NV',
    label: 'Nevada',
    subdivision: 'state',
  },
  {
    code: 'NH',
    value: 'NH',
    label: 'New Hampshire',
    subdivision: 'state',
  },
  {
    code: 'NJ',
    value: 'NJ',
    label: 'New Jersey',
    subdivision: 'state',
  },
  {
    code: 'NM',
    value: 'NM',
    label: 'New Mexico',
    subdivision: 'state',
  },
  {
    code: 'NY',
    value: 'NY',
    label: 'New York',
    subdivision: 'state',
  },
  {
    code: 'NC',
    value: 'NC',
    label: 'North Carolina',
    subdivision: 'state',
  },
  {
    code: 'ND',
    value: 'ND',
    label: 'North Dakota',
    subdivision: 'state',
  },
  {
    code: 'OH',
    value: 'OH',
    label: 'Ohio',
    subdivision: 'state',
  },
  {
    code: 'OK',
    value: 'OK',
    label: 'Oklahoma',
    subdivision: 'state',
  },
  {
    code: 'OR',
    value: 'OR',
    label: 'Oregon',
    subdivision: 'state',
  },
  {
    code: 'PR',
    value: 'PR',
    label: 'Puerto Rico',
    subdivision: 'state',
  },
  {
    code: 'PA',
    value: 'PA',
    label: 'Pennsylvania',
    subdivision: 'state',
  },
  {
    code: 'RI',
    value: 'RI',
    label: 'Rhode Island',
    subdivision: 'state',
  },
  {
    code: 'SC',
    value: 'SC',
    label: 'South Carolina',
    subdivision: 'state',
  },
  {
    code: 'SD',
    value: 'SD',
    label: 'South Dakota',
    subdivision: 'state',
  },
  {
    code: 'TN',
    value: 'TN',
    label: 'Tennessee',
    subdivision: 'state',
  },
  {
    code: 'TX',
    value: 'TX',
    label: 'Texas',
    subdivision: 'state',
  },
  {
    code: 'UT',
    value: 'UT',
    label: 'Utah',
    subdivision: 'state',
  },
  {
    code: 'VT',
    value: 'VT',
    label: 'Vermont',
    subdivision: 'state',
  },
  {
    code: 'VA',
    value: 'VA',
    label: 'Virginia',
    subdivision: 'state',
  },
  {
    code: 'WA',
    value: 'WA',
    label: 'Washington',
    subdivision: 'state',
  },
  {
    code: 'WV',
    value: 'WV',
    label: 'West Virginia',
    subdivision: 'state',
  },
  {
    code: 'WI',
    value: 'WI',
    label: 'Wisconsin',
    subdivision: 'state',
  },
  {
    code: 'WY',
    value: 'WY',
    label: 'Wyoming',
    subdivision: 'state',
  },
];

const COUNTRIES: CountriesInterface[] = [
  {
    code2: 'CA',
    code3: 'CAN',
    value: 'CA',
    capital: 'Ottawa',
    region: 'Americas',
    subregion: 'Northern America',
    label: 'Canada',
    states: provincesCan,
  },
  {
    code2: 'US',
    code3: 'USA',
    value: 'US',
    label: 'United States',
    capital: 'Washington, D.C.',
    region: 'Americas',
    subregion: 'Northern America',
    states: statesUsa,
  },
];

export default COUNTRIES;
