import type {
  DataGridProProps,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro';
import styled from 'styled-components';

import { COLORS } from '../../styles';
import { Icon } from '../icon';
import { OverlayContent } from './OverlayContent';

const StyledDataGridPro = styled(DataGridPro)<DataGridProProps>`
  font-size: 14px;
  width: 100%;
  border: 0 !important;

  .MuiDataGrid {
    &-main {
      border-radius: 24px;
      background: ${COLORS.WHITE};
    }

    &-pinnedColumnHeaders,
    &-pinnedColumns {
      box-shadow: none;
      border-right: 1px solid rgba(224, 224, 224, 1);
    }

    &-pinnedColumns {
      min-height: 0 !important;
    }

    &-row--lastVisible > .MuiDataGrid-cell {
      border-bottom: none;
    }

    &-columnHeaders {
      font-size: 12px;
      font-weight: bold;
    }

    &-columnHeaderTitle {
      text-transform: uppercase;
    }

    &-cell,
    &-columnHeader {
      padding-left: 24px;
    }

    &-cell:focus,
    &-cell:focus-within,
    &-columnHeader:focus,
    &-columnHeader:focus-within {
      outline: none;
    }

    &-footerContainer {
      border-top: none;

      .MuiIconButton-root:not(:disabled) {
        color: ${COLORS.BLACK};
      }
    }

    &-columnSeparator {
      color: rgba(0, 0, 0, 0.25);
    }

    &-columnSeparator:not(.MuiDataGrid-columnSeparator--resizable) {
      display: none;
    }
  }

  .MuiDataGrid-iconButtonContainer {
    visibility: visible;

    .MuiDataGrid-sortIcon {
      opacity: inherit !important;
    }

    .MuiIconButton-root {
      background-color: ${COLORS.PWRTABLE_HEADER};
    }
  }
`;

interface PWRtableProps {
  rows: GridValidRowModel[];
  columns: GridColDef<GridValidRowModel>[];
  tableProps: Omit<DataGridProProps, 'rows' | 'columns'>;
  sortModel?: GridSortModel;
  handleSortModelChange?: (newSortModel: GridSortModel) => void;
  paginationModel?: GridPaginationModel;
  handlePaginationModelChange?: (newPaginationModel: GridPaginationModel) => void;
}

export const PWRtable = ({
  columns,
  rows,
  sortModel,
  handleSortModelChange,
  paginationModel,
  handlePaginationModelChange,
  tableProps,
}: PWRtableProps) => {
  return (
    <StyledDataGridPro
      data-test-class="pwrtable"
      columns={columns}
      rows={rows}
      filterMode="server"
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      paginationMode="server"
      pageSizeOptions={[10, 25, 50, 100]}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      disableColumnSelector
      disableDensitySelector
      slots={{
        columnUnsortedIcon: () => <Icon icon="sort inactive" />,
        columnSortedAscendingIcon: () => <Icon icon="sort active" />,
        columnSortedDescendingIcon: () => <Icon icon="sort active" rotate={180} />,
        noRowsOverlay: () => (
          <GridOverlay>
            <OverlayContent
              title="No Results"
              message="The filters you have applied have turned up no results. Please change your filter options to see content."
              rootId="no-results-overlay"
            />
          </GridOverlay>
        ),
      }}
      slotProps={{
        row: { 'data-test-class': 'pwrtable-row' },
        pagination: {
          showFirstButton: true,
          showLastButton: true,
        },
        columnHeaderFilterIconButton: {
          counter: undefined, // prevent column from noting the number of filters in its header
        },
        baseButton: {
          sx: {
            height: '36px',
            color: COLORS.BLACK,
            backgroundColor: COLORS.WHITE,
            border: `1px solid ${COLORS.SHADOW_GREY}`,
            p: 2,
            '&:hover': { backgroundColor: COLORS.WHITE },
          },
        },
      }}
      sx={tableProps.sx}
      {...tableProps}
    />
  );
};
