/*
 * OWNER and ADMIN have the same exact same permissions
 * but Admin is used for clarity when the homeowner owns the system,
 * and Owner is used when the company owns it (TPO)
 */

export enum CompanyAccessLevel {
  NONE = 'NONE',
  VIEWER = 'VIEWER',
  EDITOR = 'EDITOR',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
}
