// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type {
  AssociateCompaniesFailedResponseData,
  CompanyAssociationsSuccessResponse,
} from '../../types/CompanyAssociation';
import * as actions from './actions';

export interface State {
  associations: CompanyAssociationsSuccessResponse | null;
  loading: boolean;
  error: string | null;
  associationRequestSent: boolean;
  sendingAssociationRequest: boolean;
  sendingAssociationRequestError: AssociateCompaniesFailedResponseData | null;
}

const INITIAL_STATE: State = {
  associations: null,
  loading: false,
  error: null,
  associationRequestSent: false,
  sendingAssociationRequest: false,
  sendingAssociationRequestError: null,
};

export type Actions = ActionType<typeof actions>;

export default createReducer<State, Actions>(INITIAL_STATE, {
  [getType(actions.loadCompanyAssociations.request)]: produce((state: State) => {
    state.loading = true;
    state.error = null;
  }),
  [getType(actions.loadCompanyAssociations.success)]: produce(
    (state: State, action: ReturnType<typeof actions.loadCompanyAssociations.success>) => {
      state.associations = action.payload;
      state.loading = false;
      state.error = null;
    },
  ),
  [getType(actions.loadCompanyAssociations.failure)]: produce(
    (state: State, action: ReturnType<typeof actions.loadCompanyAssociations.failure>) => {
      state.error = action.payload.message;
      state.loading = false;
    },
  ),
  [getType(actions.associateCompanies.request)]: produce((state: State) => {
    state.sendingAssociationRequest = true;
    state.sendingAssociationRequestError = null;
    state.associationRequestSent = false;
  }),
  [getType(actions.associateCompanies.success)]: produce((state: State) => {
    state.associationRequestSent = true;
    state.sendingAssociationRequest = false;
    state.sendingAssociationRequestError = null;
  }),
  [getType(actions.associateCompanies.failure)]: produce(
    (state: State, action: ReturnType<typeof actions.associateCompanies.failure>) => {
      state.sendingAssociationRequestError = action.payload.value;
      state.sendingAssociationRequest = false;
    },
  ),
  [getType(actions.clearAssociationVariables)]: produce((state: State) => {
    state.associationRequestSent = false;
    state.sendingAssociationRequestError = null;
    state.sendingAssociationRequest = false;
  }),
});
