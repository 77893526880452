import difference from 'lodash/difference';

import UIFeatureDefinitions from '../constants/uiFeatures';
import type { CompanyAccessLevel } from '../types/CompanyAccessLevel';
import type { UIFeature, UIFeatureDefinition } from '../types/UIFeature';
import type { UserInterfaceType } from '../types/UserInterfaceType';
import type { UserPermission } from '../types/UserPermission';

/**
 * This will return an array of UIFeatures that the User has access
 * to given their set of User Permissions
 * This is utilized in authCallback after initial login and stored in auth state
 */
export const getUIFeaturesList = (
  userPermissions: UserPermission[],
  userInterfaceType: UserInterfaceType,
): UIFeature[] => {
  const uiFeatureDefinitionKeys = Object.keys(UIFeatureDefinitions) as UIFeature[];

  return uiFeatureDefinitionKeys.filter((uiFeature) => {
    const { requiredPermissions, requiredUserInterfaceType }: UIFeatureDefinition =
      UIFeatureDefinitions[uiFeature];
    if (requiredUserInterfaceType && userInterfaceType !== requiredUserInterfaceType) {
      return false;
    } else {
      const hasPermission = difference(requiredPermissions, userPermissions).length === 0;
      return hasPermission;
    }
  });
};

/**
 * This is used to check access to a specific UI Feature, by passing in the array of
 * UIFeatures that is stored in redux and the specific UIFeature being tested against
 *
 * example usage:
 * const allowedFeatures = useSelector(uiFeaturesSelector);
 * const hasInviteUserAccess = hasUIFeatureAccess(UIFeature.USER_INVITE, allowedFeatures);
 */
export const hasUIFeatureAccess = (feature: UIFeature, allowedFeatures: UIFeature[]): boolean => {
  return allowedFeatures.includes(feature);
};

/**
 * This is used to check access to a specific UI Feature based on resource Access Levels
 * Resources that require access level checks will include an accessLevel attribute
 * @see types/SiteDetails.ts:interface SiteDetails
 */
export const hasRequiredAccessLevel = (
  feature: UIFeature,
  accessLevel: CompanyAccessLevel,
): boolean => {
  const uiFeatureDefinition = UIFeatureDefinitions[feature];
  const requiredAccessLevels = uiFeatureDefinition.requiredAccessLevels;
  return requiredAccessLevels ? requiredAccessLevels?.includes(accessLevel) : true;
};
