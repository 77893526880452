import { Box, Link, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import type { AxiosError } from 'axios';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { boolean, object, ref, string } from 'yup';

import { identify as analyticsIdentify, trackEvent } from '../../../analyticsClient';
import { Button, Input, PasswordInput } from '../../../components';
import { PWRcheckbox } from '../../../components/pwrCheckbox';
import { GENERAC_PRIVACY_POLICY, GENERAC_TERMS_PDF } from '../../../constants/general';
import {
  DEALER_ID_VALIDATION_MESSAGE,
  PASSWORD_VALIDATION_MESSAGE,
} from '../../../constants/validation';
import { useAlert } from '../../../hooks/useAlert';
import { useRoutes } from '../../../hooks/useRoutes';
import { signUpRequest } from '../../../store/signup/actions';
import {
  errorSelector,
  signUpSuccessSelector,
  userIdSelector,
  validationErrorSelector,
} from '../../../store/signup/selectors';
import { AnalyticsEvent, AnalyticsProperty } from '../../../types/Analytics';
import { Alert } from '../../../types/ScreenAlerts';
import { SignUpMethod } from '../../../types/SignUp';
import { REGEX_DEALER_ID, REGEX_PASSWORD, REGEX_PHONE_NUMBER } from '../../../utils';
import { getMinMessage, getRequiredMessage } from '../../../utils/forms';
import logo from '../assets/pwr_fleet_logo.svg';
import {
  StyledAgreementLabel,
  StyledContainer,
  StyledLogo,
  StyledSection,
  StyledSections,
  StyledSignUpButtons,
  StyledSignUpForm,
} from '../styles';

const EMAIL_ENSURE =
  'Be sure you can access this email. We will send communications to it, and you will use it as your login ID.';

const validationSchema = object().shape({
  firstName: string().required(getRequiredMessage('first name')),
  lastName: string().required(getRequiredMessage('last name')),
  email: string().required('Please enter an email').email('Please use a valid email'),
  emailConfirm: string()
    .required('Please enter an email')
    .email('Please use a valid email')
    .oneOf([ref('email'), null], 'Emails must match'),
  companyName: string().required(getRequiredMessage('company name')),
  companyPhoneNumber: string()
    .required(getRequiredMessage('area code and phone number'))
    .matches(REGEX_PHONE_NUMBER, 'Please use a valid 10+ digits phone number')
    .min(10, ({ min }) => getMinMessage('Area code and phone number', min)),
  companyDealerIdString: string()
    .notRequired()
    .matches(REGEX_DEALER_ID, DEALER_ID_VALIDATION_MESSAGE),
  password: string()
    .required(getRequiredMessage('password'))
    .matches(REGEX_PASSWORD, PASSWORD_VALIDATION_MESSAGE),
  passwordConfirm: string()
    .required(getRequiredMessage('password'))
    .oneOf([ref('password'), null], 'Passwords must match'),
  agreement: boolean().oneOf([true]),
  agreement2: boolean().oneOf([true]),
});

const inviteModeValidationSchema = object().shape({
  firstName: string().required(getRequiredMessage('first name')),
  lastName: string().required(getRequiredMessage('last name')),
  email: string().required(getRequiredMessage('email')).email('Please use a valid email.'),
  password: string()
    .required(getRequiredMessage('password'))
    .matches(REGEX_PASSWORD, PASSWORD_VALIDATION_MESSAGE),
  passwordConfirm: string()
    .required(getRequiredMessage('password'))
    .oneOf([ref('password'), null], 'Passwords must match'),
  agreement: boolean().oneOf([true]),
  agreement2: boolean().oneOf([true]),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Signup = () => {
  const Routes = useRoutes();
  const query = useQuery();
  const inviteMode = query.has('email');
  const email = query.get('email');
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(userIdSelector);
  const error = useSelector(errorSelector) as AxiosError;
  const [isDealerIdPresent, setIsDealerIdPresent] = useState<boolean>(false);
  const signUpSuccess = useSelector(signUpSuccessSelector);
  const asyncErrors = useSelector(validationErrorSelector) || {};
  const errorText = error?.response?.data?.defaultMessage || error?.response?.data?.error || '';

  useEffect(() => {
    trackEvent(AnalyticsEvent.SignUpFormView, {
      [AnalyticsProperty.SignUpMethod]: inviteMode ? SignUpMethod.INVITE : SignUpMethod.DIRECT,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signUpSuccess) {
      analyticsIdentify(userId, {
        [AnalyticsProperty.SignUpMethod]: inviteMode ? SignUpMethod.INVITE : SignUpMethod.DIRECT,
      });
      trackEvent(AnalyticsEvent.SignUp, {
        [AnalyticsProperty.SignUpMethod]: inviteMode ? SignUpMethod.INVITE : SignUpMethod.DIRECT,
        [AnalyticsProperty.ProvidedDealerId]: isDealerIdPresent,
      });
      history.push(`${Routes.Login}?registered`);
    }
  }, [signUpSuccess, userId, inviteMode, history, Routes, isDealerIdPresent]);

  useAlert(
    { alert: Alert.SignUpError, condition: !!error },
    { title: errorText, closeButton: true },
  );

  const handleBackButton = useCallback(() => {
    history.push(Routes.Login);
  }, [history, Routes]);

  const {
    handleSubmit,
    handleChange,
    values,
    errors: syncErrors,
    getFieldMeta,
    getFieldHelpers,
    isValid,
    dirty,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      emailConfirm: '',
      companyName: '',
      companyPhoneNumber: '',
      companyDealerIdString: '',
      password: '',
      passwordConfirm: '',
      agreement: false,
      agreement2: false,
    },
    validationSchema: inviteMode ? inviteModeValidationSchema : validationSchema,
    onSubmit: ({
      firstName,
      lastName,
      email,
      password,
      companyName,
      companyPhoneNumber,
      companyDealerIdString,
    }) => {
      let payload = inviteMode
        ? { firstName, lastName, email, password }
        : { firstName, lastName, email, password, companyName, companyPhoneNumber };

      if (!companyDealerIdString) {
        setIsDealerIdPresent(false);
        dispatch(signUpRequest.request(payload));
      } else {
        setIsDealerIdPresent(true); // Used for analytics
        const companyDealerId = Number(companyDealerIdString);
        const payloadWithDealerId = { ...payload, companyDealerId };
        dispatch(signUpRequest.request(payloadWithDealerId));
      }
    },
  });

  useEffect(() => {
    if (email) {
      setFieldValue('email', email);
    }
  }, [email, setFieldValue]);

  const errors = { ...asyncErrors, ...syncErrors };

  type FieldName = keyof typeof values;
  const isTouched = (fieldName: FieldName) => {
    return getFieldMeta(fieldName).touched;
  };

  const isTouchedThen = (fieldName: FieldName, str = '') => {
    if (str && isTouched(fieldName)) {
      return str;
    }
    return '';
  };

  const fieldHasError = (fieldName: FieldName) => {
    return !!errors[fieldName] && isTouched(fieldName);
  };

  const setTouched = (e: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = e.target.name as FieldName;
    getFieldHelpers(fieldName).setTouched(true);
  };

  return (
    <StyledContainer>
      <StyledLogo src={logo} onClick={handleBackButton} alt="Generac PWRFleet Logo" />
      <StyledSignUpForm onSubmit={handleSubmit} data-test-id="installer-signup-form">
        <StyledSections>
          <StyledSection>
            <Box mt={1}>
              <Typography variant="h3">Sign up as an installer</Typography>
            </Box>
            <Box mt={3}>
              <Typography variant="subtitle1">Personal information</Typography>
            </Box>
            <FormControl fullWidth className="form__field">
              <Input
                name="firstName"
                fullWidth
                label="First name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={setTouched}
                error={fieldHasError('firstName')}
                helperText={isTouchedThen('firstName', errors.firstName)}
              />
            </FormControl>
            <FormControl fullWidth className="form__field">
              <Input
                name="lastName"
                fullWidth
                label="Last name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={setTouched}
                error={fieldHasError('lastName')}
                helperText={isTouchedThen('lastName', errors.lastName)}
              />
            </FormControl>
            <FormControl fullWidth className="form__field">
              <Input
                name="email"
                type="email"
                fullWidth
                label="Email address"
                value={inviteMode ? email : values.email}
                onChange={handleChange}
                onBlur={setTouched}
                error={fieldHasError('email')}
                helperText={fieldHasError('email') ? errors.email : EMAIL_ENSURE}
                disabled={inviteMode}
              />
            </FormControl>
            {!inviteMode ? (
              <FormControl fullWidth className="form__field">
                <Input
                  name="emailConfirm"
                  fullWidth
                  label="Confirm your email address"
                  value={values.emailConfirm}
                  onChange={handleChange}
                  onBlur={setTouched}
                  error={fieldHasError('emailConfirm')}
                  helperText={isTouchedThen('emailConfirm', errors.emailConfirm)}
                />
              </FormControl>
            ) : null}
          </StyledSection>
          <StyledSection>
            {!inviteMode ? (
              <>
                <Box mt={{ md: 12 }}>
                  <Typography variant="subtitle1">Company information</Typography>
                </Box>
                <FormControl fullWidth className="form__field">
                  <Input
                    name="companyName"
                    label="Company name"
                    value={values.companyName}
                    onChange={handleChange}
                    onBlur={setTouched}
                    error={fieldHasError('companyName')}
                    helperText={isTouchedThen('companyName', errors.companyName)}
                  />
                </FormControl>
                <FormControl fullWidth className="form__field">
                  <Input
                    name="companyPhoneNumber"
                    label="Area code and phone number"
                    value={values.companyPhoneNumber}
                    onChange={handleChange}
                    onBlur={setTouched}
                    error={fieldHasError('companyPhoneNumber')}
                    helperText={isTouchedThen('companyPhoneNumber', errors.companyPhoneNumber)}
                  />
                </FormControl>
                <FormControl fullWidth className="form__field">
                  <Input
                    name="companyDealerIdString"
                    label="Generac Dealer ID (Optional)"
                    value={values.companyDealerIdString}
                    onChange={handleChange}
                    onBlur={setTouched}
                    error={fieldHasError('companyDealerIdString')}
                    helperText={
                      fieldHasError('companyDealerIdString') ? (
                        errors.companyDealerIdString
                      ) : (
                        <Typography fontSize="inherit">
                          {'Provide a Dealer ID for prioritized Tech Support. Contact '}
                          <Link color="inherit" href={`mailto:pwrcell.sales@generac.com`}>
                            pwrcell.sales@generac.com
                          </Link>
                          {' for info on how to obtain a Dealer ID.'}
                        </Typography>
                      )
                    }
                  />
                </FormControl>
              </>
            ) : null}

            <Typography variant="subtitle1">Account security</Typography>
            <FormControl fullWidth className="form__field">
              <PasswordInput
                name="password"
                label="Password"
                autoComplete="new-password"
                value={values.password}
                onChange={handleChange}
                onBlur={setTouched}
                error={fieldHasError('password')}
                helperText={isTouchedThen('password', errors.password)}
              />
            </FormControl>
            <FormControl fullWidth className="form__field">
              <PasswordInput
                name="passwordConfirm"
                label="Confirm password"
                autoComplete="new-password"
                value={values.passwordConfirm}
                onChange={handleChange}
                onBlur={setTouched}
                error={fieldHasError('passwordConfirm')}
                helperText={isTouchedThen('passwordConfirm', errors.passwordConfirm)}
              />
            </FormControl>
            {/*
              --- FUTURE FIELDS, DO NOT SUPPORTED BY BE YET ---
              <>
                <h3>Generac ID numbers</h3>
                <p>
                  If you have a Generac Tech ID and Dealer ID, please enter them here. We might use them to provide
                  additional fuctionality for you and your company.
                </p>
                <FormControl fullWidth className="form__field">
                  <Input label="Tech ID" helperText="A minimum of 8 alphanumeric characters." />
                </FormControl>
                <FormControl fullWidth className="form__field">
                  <Input label="Dialer ID" />
                </FormControl>
              </>
              */}
          </StyledSection>
        </StyledSections>
        <div className="form__agreement">
          <Box mb={5}>
            <StyledAgreementLabel>By registering, you confirm that:</StyledAgreementLabel>
          </Box>
          <PWRcheckbox
            data-test-id="signup-terms-checkbox"
            label={
              <span style={{ fontWeight: 'bold' }}>
                You agree to Generac's{' '}
                <a
                  className="form__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={GENERAC_TERMS_PDF}
                  download
                >
                  Terms
                </a>{' '}
                and{' '}
                <a
                  className="form__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={GENERAC_PRIVACY_POLICY}
                >
                  Privacy Policy
                </a>
                .
              </span>
            }
            name="agreement"
            onChange={handleChange}
            checked={values.agreement}
          />
          <PWRcheckbox
            data-test-id="signup-acknowledge-checkbox"
            label={
              <span style={{ fontWeight: 'bold' }}>
                You further acknowledge and accept that you are solely responsible for obtaining the
                homeowner's consent to monitor any site you, or any other user in your account, will
                register on PWRfleet, and absolve Generac from any responsibility or obligation
                thereof.
              </span>
            }
            name="agreement2"
            onChange={handleChange}
            checked={values.agreement2}
          />
          <StyledSignUpButtons>
            <Button size="small" color="secondary" onClick={handleBackButton}>
              Back
            </Button>
            <Button
              size="small"
              color="primary"
              data-test-id="installer-sign-up-button"
              type="submit"
              disabled={!dirty || !isValid}
            >
              Sign Up
            </Button>
          </StyledSignUpButtons>
        </div>
      </StyledSignUpForm>
    </StyledContainer>
  );
};
