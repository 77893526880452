import crypto from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import SHA256 from 'crypto-js/sha256';

const CODE_CHALLENGE_LENGTH = 31;

const fixString = (s: string) => s.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');

export const generateCode = () => {
  const array = crypto.lib.WordArray.random(CODE_CHALLENGE_LENGTH);
  const code = fixString(Base64.stringify(array));
  return code;
};

export const hashCode = (code: string) => {
  const hashedArray = SHA256(code);
  return fixString(Base64.stringify(hashedArray));
};
