import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PublicRoutes, Routes, SupportRoutes } from '../constants/routes';
import { isSupportModeSelector } from '../store/auth/selectors';

/**
 * This hook returns routes constants depending on user type.
 * Routes are divided into main routes (Routes), SUPPORT user routes (SupportRoutes)
 * and other public routes (PublicRoutes).
 * Some SUPPORT user routes do not exist for other types of users, but if they are not returned as a result,
 * then we will receive a typescript error due to the fact that the route may not exist.
 * Therefore, other types of users will receive the routes of the SUPPORT user, but a proxy is imposed on them
 * and when user will try to get their value, he will get "/forbidden-route".
 * This is all done primarily to avoid typescript errors.
 **/

export const useRoutes = () => {
  const isSupportMode = useSelector(isSupportModeSelector);
  return useMemo(() => {
    if (isSupportMode) {
      return { ...Routes, ...SupportRoutes };
    }
    return {
      ...Routes,
      ...new Proxy(SupportRoutes, {
        get: () => '/forbidden-route',
      }),
      ...PublicRoutes,
    };
  }, [isSupportMode]);
};
