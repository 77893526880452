import type { AxiosError } from 'axios';

import type { UserRole } from './UserRole';

export interface CompanyUser {
  id: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  role: UserRole;
}

export enum CompanyUserStatus {
  Pending = 'Pending',
  Active = 'Active',
}

export type CompanyUsersSuccessResponse = CompanyUser[];
export type CompanyUsersFailedResponse = AxiosError;
export type CompanyUsersResponse = CompanyUsersSuccessResponse | CompanyUsersFailedResponse;

export interface PendingCompanyUser {
  id: string;
  email: string;
  role: UserRole;
  signupLink: string;
}

export type PendingCompanyUsersFailedData = {
  defaultMessage: { defaultMessage: string; error: string; status: number };
  error: string;
  status: number;
};

export type PendingCompanyUsersSuccessResponse = PendingCompanyUser[];
export type PendingCompanyUsersFailedResponse = AxiosError<PendingCompanyUsersFailedData>;
export type PendingCompanyUsersResponse =
  | PendingCompanyUsersSuccessResponse
  | PendingCompanyUsersFailedResponse;

// -- Update User Role
export type UpdateCompanyUserRoleRequest = {
  userId: string;
  role: UserRole;
};

export type UpdateCompanyUserRoleFailedResponse = AxiosError;
export type UpdateCompanyUserRoleSuccessResponse = Record<string, unknown>;
export type UpdateCompanyUserRoleResponse =
  | UpdateCompanyUserRoleSuccessResponse
  | UpdateCompanyUserRoleFailedResponse;

// -- Remove User
export type RemoveCompanyUserRequest = {
  userId: string;
};

export type RemoveCompanyUserFailedResponse = AxiosError;
export type RemoveCompanyUserSuccessResponse = Record<string, unknown>;
export type RemoveCompanyUserResponse =
  | RemoveCompanyUserSuccessResponse
  | RemoveCompanyUserFailedResponse;
