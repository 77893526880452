export const monthsShortArray = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

export const DEFAULT_TIMEZONE = 'UTC';

// luxon .toFormat() strings
export const TIMEZONE_ABBREVIATED_FORMAT = 'ZZZZ'; // ie. PSTexport const DEFAULT_TIMEZONEl = 'UTC';
export const DATE_TIME_FORMAT = 'L/d/yy h:mm a'; // ie. 11/7/23 2:32 PM
export const DATE_TIME_WITH_TIMEZONE_FORMAT = `${DATE_TIME_FORMAT} ${TIMEZONE_ABBREVIATED_FORMAT}`; // ie. 11/7/23 2:32 PM PST
export const DATE_TIME_FILENAME_FORMAT = 'y-LL-dd-HHmm'; // ie. 2023-07-04-0730
export const DATE_SHORT_TIME_FORMAT = 'DD, h:mm a'; // ie. Nov 7, 2023 7:30 AM
export const DATE_SHORT_TIME_PADDED_FORMAT = 'DD, hh:mm a'; // ie. Nov 08, 07:00 AM
export const DATE_NUMERIC_FORMAT = 'LL/dd/yy'; // ie. 11/08/23
export const DATE_TABLE_FORMAT = 'y LLL dd'; // ie. 2023 Apr 01
export const DATE_LONG_FORMAT = 'DDD'; // ie. November 7, 2023
export const DATE_SHORT_FORMAT = 'DD'; // ie. Nov 7, 2023
export const HOURS_MINUTES_FORMAT = 'h:mm'; // ie. 8:30
export const HOURS_PADDED_MINUTES_FORMAT = 'hh:mm'; // ie. 08:30
export const MONTH_YEAR_FORMAT = 'LLL, y'; // ie. Nov 2023
export const MONTH_UNPADDED_DAY_FORMAT = 'LLL d'; // ie. Nov 8
export const YEAR_FORMAT = 'y'; // ie. 1989
export const URL_FORMAT = 'yyyy-MM-dd'; // ie. 2020-07-04
