import { createAsyncAction } from 'typesafe-actions';

import type {
  SiteAlertsFailedResponse,
  SiteAlertsRequest,
  SiteAlertsSuccessResponse,
} from '../../types/Alert';

export const loadSiteAlerts = createAsyncAction(
  'LOAD_SITE_ALERTS_REQUEST',
  'LOAD_SITE_ALERTS_SUCCESS',
  'LOAD_SITE_ALERTS_FAILURE',
)<SiteAlertsRequest, SiteAlertsSuccessResponse, SiteAlertsFailedResponse>();
