import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type { MapSite } from '../../types/Site';
import * as actions from './actions';

export interface MapSitesState {
  items: MapSite[];
  loading: boolean;
  loadingFinished: boolean;
  error: string | null;
}

const INITIAL_STATE: MapSitesState = {
  items: [],
  loading: false,
  loadingFinished: false,
  error: null,
};

export type MapSitesActions = ActionType<typeof actions>;

export default createReducer<MapSitesState, MapSitesActions>(INITIAL_STATE, {
  [getType(actions.loadFleetMapSites.request)]: produce((state: MapSitesState) => {
    state.loading = true;
    state.error = null;
    state.loadingFinished = false;
  }),
  [getType(actions.loadFleetMapSites.success)]: produce(
    (state: MapSitesState, action: ReturnType<typeof actions.loadFleetMapSites.success>) => {
      state.loading = false;
      state.items = action.payload;
      state.loadingFinished = true;
    },
  ),
  [getType(actions.loadFleetMapSites.failure)]: produce(
    (state: MapSitesState, action: ReturnType<typeof actions.loadFleetMapSites.failure>) => {
      state.error = action.payload.message;
      state.loading = false;
      state.loadingFinished = true;
    },
  ),
  [getType(actions.loggedOut)]: () => {
    return { ...INITIAL_STATE };
  },
});
