import { styled } from '@mui/material/styles';
import React from 'react';
import { Slide, toast, ToastContainer } from 'react-toastify';
import type { ToastContainerProps } from 'react-toastify/dist/types';

import { up } from '../../styles';

const StyledAlertsContainer = styled(ToastContainer)`
  ${up('sm')} {
    &.Toastify__toast-container {
      width: unset;
      min-width: 355px;
    }
  }
  .Toastify__toast {
    padding: unset;
    border-radius: 8px;
  }
  .Toastify__toast-body {
    padding: unset;
  }
  .Toastify__toast--error {
    background: unset;
  }
  .Toastify__toast--success {
    background: unset;
  }
`;

export const AlertsContainer = (props: ToastContainerProps) => (
  <StyledAlertsContainer
    position={toast.POSITION.TOP_CENTER}
    hideProgressBar
    draggable={false}
    autoClose={false}
    closeButton={false}
    closeOnClick={false}
    limit={3}
    enableMultiContainer
    transition={Slide}
    {...props}
  />
);
