import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import type { SxProps } from '@mui/material';
import {
  Box,
  ButtonBase,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import type { DateTime } from 'luxon';
import type { ReactElement } from 'react';
import { useState } from 'react';

import type { AlertState } from '../../constants/alerts';
import { COLORS } from '../../styles';
import { PWRalert } from '../pwrAlert';
import type { PWRbuttonProps } from '../pwrButton';
import { PWRbutton } from '../pwrButton';
import type { PWRtooltipProps } from '../pwrTooltip';
import { PWRtooltip } from '../pwrTooltip';
import { TimeLastHeardTooltip } from '../timeagoTooltip';

const textButtonSx: SxProps = {
  ml: {
    md: 2,
    xs: 0,
  },
  ':last-child': {
    mb: 0,
  },
};

export interface IconButtonParams {
  text: string;
  onClick?: () => void;
  icon: ReactElement;
}

export interface CardHeaderProps {
  name: string;
  headerIconElement: ReactElement;
  collapsible: boolean;
  infoTooltipProps?: Omit<PWRtooltipProps, 'children'>;
  lastHeardDateTime?: DateTime;
  headerSlotElement?: ReactElement;
  headerStatusSlotElement?: ReactElement;
  headerAlerts?: AlertState[];
  handleHeaderAlertClose?: (index: number) => void;
  headerButtonsListProps?: PWRbuttonProps[];
  headerIconButtonsListProps?: IconButtonParams[];
  bodyPanelCollapsed: boolean;
  handleCollapseButtonClick: () => void;
  timezone: string | null;
}

export default ({
  name,
  headerIconElement,
  infoTooltipProps,
  lastHeardDateTime,
  collapsible,
  headerAlerts,
  handleHeaderAlertClose,
  headerSlotElement,
  headerIconButtonsListProps,
  headerButtonsListProps,
  bodyPanelCollapsed,
  handleCollapseButtonClick,
  headerStatusSlotElement,
  timezone,
}: CardHeaderProps) => {
  const [mobileHeaderAnchorEl, setMobileHeaderAnchorEl] = useState<HTMLElement | undefined>();

  const handleMobileHeaderButtonClick = (buttonParams: IconButtonParams) => {
    buttonParams.onClick?.();
    setMobileHeaderAnchorEl(undefined);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItem: 'flex-start' }}>
        <Box
          sx={{
            width: 32,
            mr: {
              xs: 1,
              md: 2,
            },
            mt: {
              xs: 0.5,
              md: 1,
            },
          }}
        >
          {headerIconElement}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: {
                  md: 1,
                },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  mr: 1,
                  fontSize: {
                    md: 24,
                    xs: 20,
                  },
                  lineHeight: '32px',
                }}
                data-test-class="device-card-header-name"
              >
                {name}
              </Typography>
              {!!infoTooltipProps && (
                <PWRtooltip {...infoTooltipProps}>
                  <InfoOutlinedIcon
                    data-test-class="device-card-header-info-icon"
                    sx={{ color: COLORS.TEAL, fontSize: 24 }}
                  />
                </PWRtooltip>
              )}
            </Box>
            {headerStatusSlotElement ||
              (lastHeardDateTime && timezone && (
                <Box sx={{ display: 'flex' }}>
                  <TimeLastHeardTooltip
                    timezone={timezone}
                    prefixText="Last heard from "
                    timestamp={lastHeardDateTime.toMillis()}
                    typographyProps={{
                      color: COLORS.MEDIUM_EMPHASIS,
                      fontSize: 12,
                      mr: 0.5,
                    }}
                  />
                </Box>
              ))}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, flexDirection: 'row-reverse' }}>
            {collapsible && (
              <IconButton
                sx={{
                  width: 40,
                  height: 40,
                  border: `1px solid ${COLORS.SILVER}`,
                  display: {
                    xs: 'none',
                    md: 'flex',
                  },
                  ml: 2,
                }}
                onClick={handleCollapseButtonClick}
                data-test-class="device-card-header-collapse-button"
              >
                <ArrowUpIcon
                  sx={{
                    color: COLORS.BLACK,
                    fontSize: 24,
                    rotate: bodyPanelCollapsed ? '180deg' : 0,
                  }}
                />
              </IconButton>
            )}
            {!bodyPanelCollapsed && (
              <>
                {!isEmpty(headerIconButtonsListProps) &&
                  headerIconButtonsListProps?.map((iconButtonParams) => (
                    <IconButton
                      key={`${name}-${iconButtonParams.text}-icon-button-desktop`}
                      data-test-id={`${name}-${iconButtonParams.text}-icon-button-desktop`}
                      sx={{
                        width: 40,
                        height: 40,
                        border: `1px solid ${COLORS.SILVER}`,
                        ml: 2,
                        display: {
                          xs: 'none',
                          md: 'flex',
                        },
                      }}
                      onClick={iconButtonParams.onClick}
                    >
                      {iconButtonParams.icon}
                    </IconButton>
                  ))}
                {!isEmpty(headerIconButtonsListProps) && (
                  <IconButton
                    sx={{
                      border: `1px solid ${COLORS.SILVER}`,
                      width: 40,
                      height: 40,
                      display: {
                        xs: 'flex',
                        md: 'none',
                      },
                    }}
                    onClick={(event) => setMobileHeaderAnchorEl(event.currentTarget)}
                    data-test-class="device-card-header-more-button"
                  >
                    <MoreVertIcon sx={{ color: COLORS.BLACK, fontSize: 24 }} />
                  </IconButton>
                )}
                {!isEmpty(headerButtonsListProps) &&
                  headerButtonsListProps?.map(({ text, sx, ...rest }) => (
                    <PWRbutton
                      key={`${name}-${text}-button-desktop`}
                      id={`${name}-${text}-button-desktop`}
                      text={text}
                      color="secondary"
                      variant="outlined"
                      sx={{
                        ...textButtonSx,
                        display: { xs: 'none', md: 'flex' },
                        mb: {
                          md: 0,
                          xs: 2,
                        },
                        ...sx,
                      }}
                      {...rest}
                    />
                  ))}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        {!isEmpty(headerButtonsListProps) &&
          headerButtonsListProps?.map(({ text, sx, ...rest }) => (
            <PWRbutton
              key={`${name}-${text}-button-mobile`}
              id={`${name}-${text}-button-mobile`}
              text={text}
              color="secondary"
              variant="outlined"
              sx={{
                ...textButtonSx,
                mt: 2,
                my: 2,
                mb: 3,
                width: '100%',
                display: { xs: 'flex', md: 'none' },
                ...sx,
              }}
              {...rest}
            />
          ))}
        {headerSlotElement && <Box sx={{ pt: 2 }}>{headerSlotElement}</Box>}
      </Box>
      {headerAlerts?.map((alert, index) => {
        const { dismissible, ...alertProps } = alert;
        const isDismissible = !!handleHeaderAlertClose && dismissible;

        return (
          <Box sx={{ pt: 2 }} key={`${index}-${name}-alert-container-`}>
            <PWRalert
              {...alertProps}
              handleClose={isDismissible ? () => handleHeaderAlertClose(index) : undefined}
            />
          </Box>
        );
      })}
      {headerIconButtonsListProps && (
        <Menu
          open={Boolean(mobileHeaderAnchorEl)}
          anchorEl={mobileHeaderAnchorEl}
          onClose={() => setMobileHeaderAnchorEl(undefined)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {headerIconButtonsListProps.map((buttonParams) => {
            return (
              <MenuItem
                key={`${name}-${buttonParams.text}-icon-button-mobile`}
                data-test-id={`${name}-${buttonParams.text}-icon-button-mobile`}
                component={ButtonBase}
                onClick={() => handleMobileHeaderButtonClick(buttonParams)}
                sx={{ minWidth: 300, height: 48 }}
              >
                <ListItemIcon>{buttonParams.icon}</ListItemIcon>
                <ListItemText sx={{ textAlign: 'start' }}>{buttonParams.text}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </Box>
  );
};
