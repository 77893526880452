import { DateTime, Interval } from 'luxon';

import type { ChartTimeframe } from '../constants/chartTimeframes';
import { CustomTimeframeFromLuxonDateTimesWithAutoGranularityAndMetric } from '../constants/chartTimeframes';
import type { GranularityOption } from '../constants/granularityItems';
import GranularityOptions from '../constants/granularityItems';
import type { Granularity } from '../types/SiteMetrics';

export interface TimeframeFunctions {
  getDataStartDate: () => string; // ISO8601
  getDataEndDate: () => string; // ISO8601
}

/**
 * Compares two timeframes using the date ranges they return
 */
export const compareTimeframes = (
  currentTimeframe: ChartTimeframe,
  newTimeframe: ChartTimeframe,
) => {
  return (
    currentTimeframe.getDataStartDate() === newTimeframe.getDataStartDate() &&
    currentTimeframe.getDataEndDate() === newTimeframe.getDataEndDate()
  );
};

/**
 * Returns a string indicating the date or range of dates specified by the timeframe
 */
export const getTimeIntervalString = (timeframe: ChartTimeframe, timezone: string | undefined) => {
  const startDateTime = DateTime.fromISO(timeframe.getDataStartDate()).setZone(timezone);
  const endDateTime = DateTime.fromISO(timeframe.getDataEndDate()).setZone(timezone);

  const startDay = startDateTime.toFormat('MMM d');
  const endDay = endDateTime.toFormat('MMM d');
  const startYear = startDateTime.toFormat('y');
  const endYear = endDateTime.toFormat('y');

  if (startDay === endDay && startYear === endYear) {
    return `${endDay}, ${endYear}`;
  } else if (startYear === endYear) {
    return `${startDay} - ${endDay}, ${endYear}`;
  }
  return `${startDay}, ${startYear} - ${endDay}, ${endYear}`;
};

/** Returns a string indicating the date or range of dates specified by the timeframe, explicitly from midnight of the start date to 11:59pm of the end date */
export const getMorePreciseTimeIntervalString = (timeframe: ChartTimeframe): string => {
  const startDateTime = DateTime.fromISO(timeframe.getDataStartDate(), { setZone: true });
  const endDateTime = DateTime.fromISO(timeframe.getDataEndDate(), { setZone: true });
  const dateRange = Interval.fromDateTimes(startDateTime, endDateTime);
  if (dateRange.count('days') === 1) {
    return `${startDateTime.toFormat('DD')}, 12:00 AM - 11:59 PM`;
  }
  return dateRange.toFormat('DD, h:mm a');
};

export const getGranularityOptions = (granularities: Granularity[]) => {
  return GranularityOptions.filter((o) => granularities.includes(o.value));
};

type DateDependentData = {
  granularityOptions: GranularityOption[];
  timeframe: Partial<ChartTimeframe>;
};

export const getDateDependentData = (startDate: string, endDate: string): DateDependentData => {
  const startDateTime = DateTime.fromISO(startDate);
  const endDateTime = DateTime.fromISO(endDate);

  const { granularity, metric } = CustomTimeframeFromLuxonDateTimesWithAutoGranularityAndMetric(
    startDateTime,
    endDateTime,
  );

  // granularity options was used for the granularity <select /> feature, which was removed
  // it is no longer used anywhere in the app, but TS expects it
  // @todo remove granularityOptions from useTimeframe
  return { granularityOptions: [], timeframe: { granularity, metric } };
};
