/* eslint-disable @typescript-eslint/naming-convention */
import { styled } from '@mui/material/styles';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Button, Loader, MessageWithDescription } from '../../components';
import { LocalStorageItem } from '../../constants/localStorage';
import { Routes } from '../../constants/routes';
import { useQuery } from '../../hooks/useQuery';
import { initializeAuth } from '../../store/auth/actions';
import { isLoggedInSelector } from '../../store/auth/selectors';
import type { AuthTokenResponse } from '../../types/Auth';

const StyledContainer = styled('div')`
  justify-content: center;
  height: 100vh;
  align-items: center;
  text-align: center;
  display: flex;

  a {
    text-decoration: none;
  }
`;

const StyledLoader = styled(Loader)`
  margin: 0 auto;
`;

export const AuthCallback = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const [hasError, setError] = useState(false);
  const authCode = query.get('code');
  const getToken = useCallback(
    async (code: string) => {
      if (!process.env.REACT_APP_AUTH_CLIENT_ID) {
        throw new Error('REACT_APP_AUTH_CLIENT_ID env required.');
      }
      const queryStr = new URLSearchParams({
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
        redirect_uri: `${window.location.origin}/auth-callback`,
        authorization_code: authCode ?? '',
        code_verifier: code,
      });

      /** This auth-callback needs to have the same redirect URL as the login screen's initial request
       * See screens/login/index.tsx line 40 ish
       **/
      if (process.env.REACT_APP_COGNITO_AUTH_PROXY_URL && process.env.REACT_APP_VERCEL_URL) {
        queryStr.set('redirect_uri', process.env.REACT_APP_COGNITO_AUTH_PROXY_URL);
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL_PREFIX}/sessions/v1/pkce/tokens?${queryStr}`,
        );
        const authTokenResponse: AuthTokenResponse = response.data;
        dispatch(initializeAuth(authTokenResponse));
      } catch {
        setError(true);
      }
    },
    [authCode, dispatch],
  );
  useEffect(() => {
    const code = window.localStorage.getItem(LocalStorageItem.AUTH_CODE);

    if (!code) {
      throw new Error("AUTH_CODE couldn't be found in local storage.");
    }

    getToken(code);
  }, [getToken]);

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  if (hasError) {
    return (
      <StyledContainer>
        <MessageWithDescription
          icon="delete"
          title="Oops, something went wrong!"
          description={
            <Button href={Routes.Login} color="primary">
              Go back to login page
            </Button>
          }
        />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <MessageWithDescription title="Logging in, please wait" description={<StyledLoader />} />
    </StyledContainer>
  );
};
