import type { AxiosError } from 'axios';

import type { SortOrder } from '../utils/sort';
import type { DeviceType } from './DeviceType';

export enum AlertsTab {
  ACTIVE = 'active',
  PINNED = 'pinned',
  DISMISSED = 'dismissed',
  HISTORY = 'history',
}

export enum AlertStatus {
  ACTIVE = 'ACTIVE',
  RESOLVED = 'RESOLVED',
}

export enum AlertType {
  INVERTER_IN_ERROR_GENERIC = 'INVERTER_IN_ERROR_GENERIC', // 0x7000
  INVERTER_IN_ERROR_GENERIC_INVERTER_NOT_IN_STATE_OF_OPERATION = 'INVERTER_IN_ERROR_GENERIC_INVERTER_NOT_IN_STATE_OF_OPERATION', // 0x700F,
  INVERTER_IN_ERROR_REBUS_FAULT = 'INVERTER_IN_ERROR_REBUS_FAULT', // 0x7510,
  INVERTER_IN_ERROR_INTERNAL_BUS_FAULT = 'INVERTER_IN_ERROR_INTERNAL_BUS_FAULT', // 0x7520,
  INVERTER_IN_ERROR_GEN_NEEDS_AT_LEAST_ONE_ATS = 'INVERTER_IN_ERROR_GEN_NEEDS_AT_LEAST_ONE_ATS', // 0x7705,
  INVERTER_IN_ERROR_ZERO_IMPORT_REQUIRES_CTCAL = 'INVERTER_IN_ERROR_ZERO_IMPORT_REQUIRES_CTCAL', // 0x770B,
  INVERTER_IN_ERROR_HARDWARE_VERSION_MISMATCH = 'INVERTER_IN_ERROR_HARDWARE_VERSION_MISMATCH', // 0x770C,
  INVERTER_IN_ERROR_BAD_RESET = 'INVERTER_IN_ERROR_BAD_RESET', // 0x7710,
  INVERTER_IN_ERROR_UNDEFINED = 'INVERTER_IN_ERROR_UNDEFINED',
  INVERTER_IN_REPEATED_ERROR_GENERIC = 'INVERTER_IN_REPEATED_ERROR_GENERIC',
  INVERTER_IN_REPEATED_ERROR_GENERIC_INVERTER_NOT_IN_STATE_OF_OPERATION = 'INVERTER_IN_REPEATED_ERROR_GENERIC_INVERTER_NOT_IN_STATE_OF_OPERATION',
  INVERTER_IN_REPEATED_ERROR_REBUS_FAULT = 'INVERTER_IN_REPEATED_ERROR_REBUS_FAULT',
  INVERTER_IN_REPEATED_ERROR_INTERNAL_BUS_FAULT = 'INVERTER_IN_REPEATED_ERROR_INTERNAL_BUS_FAULT',
  INVERTER_IN_REPEATED_ERROR_GEN_NEEDS_AT_LEAST_ONE_ATS = 'INVERTER_IN_REPEATED_ERROR_GEN_NEEDS_AT_LEAST_ONE_ATS',
  INVERTER_IN_REPEATED_ERROR_ZERO_IMPORT_REQUIRES_CTCAL = 'INVERTER_IN_REPEATED_ERROR_ZERO_IMPORT_REQUIRES_CTCAL',
  INVERTER_IN_REPEATED_ERROR_HARDWARE_VERSION_MISMATCH = 'INVERTER_IN_REPEATED_ERROR_HARDWARE_VERSION_MISMATCH',
  INVERTER_IN_REPEATED_ERROR_BAD_RESET = 'INVERTER_IN_REPEATED_ERROR_BAD_RESET',
  BATTERY_IN_ERROR_GENERIC = 'BATTERY_IN_ERROR_GENERIC',
  BATTERY_IN_ERROR_UNDEFINFINED = 'BATTERY_IN_ERROR_UNDEFINFINED',
  BATTERY_IN_REPEATED_ERROR_GENERIC = 'BATTERY_IN_REPEATED_ERROR_GENERIC',
  PVLINK_IN_ERROR_GENERIC = 'PVLINK_IN_ERROR_GENERIC',
  PVLINK_IN_ERROR_INPUT_OVER_VOLTAGE = 'PVLINK_IN_ERROR_INPUT_OVER_VOLTAGE',
  PVLINK_IN_ERROR_ARC_FAULT_LOCKOUT = 'PVLINK_IN_ERROR_ARC_FAULT_LOCKOUT',
  PVLINK_IN_ERROR_UNDEFINED = 'PVLINK_IN_ERROR_UNDEFINED',
  PVLINK_IN_REPEATED_ERROR_GENERIC = 'PVLINK_IN_REPEATED_ERROR_GENERIC',
  PVLINK_IN_REPEATED_ERROR_INPUT_OVER_VOLTAGE = 'PVLINK_IN_REPEATED_ERROR_INPUT_OVER_VOLTAGE',
  PVLINK_IN_REPEATED_ERROR_ARC_FAULT_LOCKOUT = 'PVLINK_IN_REPEATED_ERROR_ARC_FAULT_LOCKOUT',
  DEVICE_OFFLINE = 'DEVICE_OFFLINE',
  DEFAULT = 'DEFAULT',
}

export enum AlertCategory {
  DEVICE_IN_ERROR_STATE = 'DEVICE_IN_ERROR_STATE', // State code between 0x7000 to 0x7FFF
  DEVICE_OFFLINE = 'DEVICE_OFFLINE',
}

export enum AlertVisitSeverity {
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
  SOMEWHAT_LIKELY = 'SOMEWHAT_LIKELY',
  NOT_LIKELY = 'NOT_LIKELY',
  LIKELY = 'LIKELY',
  URGENT = 'URGENT',
}

// @todo rename to Alert when we remove PoC code
export type SiteAlert = {
  alertId: string;
  type: AlertType;
  category: AlertCategory;
  status: AlertStatus;
  severity: AlertSeverity;
  siteId: string;
  name: string;
  systemId?: string;
  hostId?: string;
  deviceId?: string;
  deviceType?: DeviceType;
  deviceState?: string;
  transitionedAt: number;
  openedAt: number;
  closedAt?: number; // @todo might only exist on AlertInstanceHistory items
  visitRequired: AlertVisitSeverity;
};

export type AlertSortProperty = keyof SiteAlert;
export type AlertSortPropertyWithOrder = `${AlertSortProperty}:${SortOrder}`;

export type AlertsSort =
  | AlertSortPropertyWithOrder
  | `${AlertSortPropertyWithOrder},${AlertSortPropertyWithOrder}`;

// @todo remove this with Alerts PoC
export interface Alert {
  // id: string; [alerts-poc] no UUIDs for Alerts
  updatedAt: string;
  site: {
    id: string;
    name: string;
  };
  device: {
    stateCode: number;
    stateName: string;
    id: string;
    type: DeviceType;
  };
}

// ---

export enum DeviceAlertState {
  ALERT_STATE_A = 'Alert State A',
  ALERT_STATE_B = 'Alert State B',
}

export enum AlertSeverity {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum AlertDetailsTab {
  DETAILS = 'details',
  TROUBLESHOOTING = 'troubleshooting',
  HISTORY = 'history',
  EVENT_LOG = 'event_log',
  NOTES = 'notes',
}

export enum ConditionType {
  DEVICE_OFFLINE_CONDITION = 'DEVICE_OFFLINE_CONDITION',
  DEVICE_STATE_EQUALITY_CONDITION = 'DEVICE_STATE_EQUALITY_CONDITION',
  DEVICE_STATE_COUNT_THRESHOLD_CONDITION = 'DEVICE_STATE_COUNT_THRESHOLD_CONDITION',
  DEVICE_STATE_UNDEFINED_CONDITION = 'DEVICE_STATE_UNDEFINED_CONDITION',
}

export interface Condition {
  conditionType: ConditionType;
  alertType: AlertType;
  deviceType?: DeviceType;
  deviceState?: string;
  durationThreshold?: string;
  threshold?: number;
  thresholdWindow?: string;
}

export interface TroubleshootingStep {
  content: string;
  steps?: TroubleshootingStep[];
  notes?: string[];
}

export interface TroubleshootingProcedure {
  name: string;
  procedure: string;
  deviceType: DeviceType;
  steps: TroubleshootingStep[];
  content?: string;
  notes?: string[];
}

export interface AlertDetails {
  alertId: string;
  siteId: string;
  openedAt: number;
  systemId: string;
  name: string;
  type: AlertType;
  category: AlertCategory;
  deviceState: string;
  deviceId: string;
  deviceType: DeviceType;
  status: AlertStatus;
  severity: AlertSeverity;
  transitionedAt: number;
  hostId: string;
  conditions: Condition[];
  alertTypeInfoUrl: string;
  // Not implemented yet
  visitRequired?: AlertVisitSeverity;
}

export interface AlertTypeInfo {
  deviceType: DeviceType;
  alertType: AlertType;
  alertDescription: string;
  procedures: TroubleshootingProcedure[];
}

export interface AlertCompanyInfo {
  installerCompanyName: string;
  ownerCompanyName: string;
}

export type SiteAlertsRequest = {
  siteId: string;
  page: number;
  perPage?: number;
  status?: AlertStatus[]; // status is not implemented on BE yet, may need to change later
  sort?: AlertsSort;
};

export type SiteAlertsSuccessResponse = {
  alerts: SiteAlert[];
  page: number;
  perPage: number;
  totalResults: number;
};

export type SiteAlertsFailedResponse = AxiosError;
export type SiteAlertsResponse = SiteAlertsSuccessResponse | SiteAlertsFailedResponse;

// New Alerts

export enum AlertDate {
  LAST_24H = 'last_24h',
  YESTERDAY = 'yesterday',
  LAST_7D = 'last_7d',
  LAST_30D = 'last_30d',
}

// @todo remove CompanyAlert, it's old
export type CompanyAlert = {
  alertId: string;
  siteId: string;
  siteName: string; // Getting it from another endpoint
  systemId: string;
  hostId: string;
  name: string;
  type: AlertType;
  category: AlertCategory;
  deviceId: string;
  deviceState?: string; // Not yet confirmed
  deviceType: DeviceType;
  status: AlertStatus;
  severity: AlertSeverity;
  transitionedAt: number;
  openedAt: number;
  visitRequired: AlertVisitSeverity;
  // Getting it from another endpoint
  address: {
    address1?: string;
    address2?: string;
    zip?: string;
    city?: string;
    state?: string;
    country?: string;
    latitude?: string;
    longitude?: string;
    timezone?: string;
  };
};

export type CompanyAlertsRequest = {
  companyId: string;
  page: number;
  perPage?: number;
  sort?: AlertsSort;
};

export type CompanyAlertsSuccessResponse = {
  alerts: CompanyAlert[];
  page: number;
  perPage: number;
  totalResults: number;
};

export type CompanyAlertsFailedResponse = AxiosError;

export type CompanyAlertsResponse = CompanyAlertsSuccessResponse | CompanyAlertsFailedResponse;

export type AlertDetailsRequest = {
  alertId: string;
};

export type AlertDetailsSuccessResponse = {
  openedAt: number;
  name: string;
  alertId: string;
  category: AlertCategory;
  deviceId: string;
  deviceState: string;
  deviceType: DeviceType;
  hostId: string;
  severity: AlertSeverity;
  siteId: string;
  status: AlertStatus;
  systemId: string;
  transitionedAt: number;
  type: AlertType;
  conditions: Condition[];
  alertTypeInfoUrl: string;
  // Not implemented yet
  visitRequired?: AlertVisitSeverity;
};

export type AlertDetailsFailedResponse = AxiosError;
export type AlertDetailsResponse = AlertDetailsSuccessResponse | AlertDetailsFailedResponse;

export type AlertCompanyInfoRequest = {
  siteId: string;
};

export type AlertCompanyInfoSuccessResponse = {
  installerCompanyName: string;
  ownerCompanyName: string;
};

export type AlertCompanyInfoFailedResponse = AxiosError;
export type AlertCompanyInfoResponse =
  | AlertCompanyInfoSuccessResponse
  | AlertCompanyInfoFailedResponse;

export type AlertInstanceHistory = {
  alertId: string;
  type: AlertType;
  deviceId: string;
  siteId: string;
  status: AlertStatus;
  transitionedAt: number;
  openedAt: number;
};

// @todo waiting for BE design
export type AlertInstanceHistoryRequest = {
  alertId: string;
  page: number;
  perPage?: number;
};

// @todo waiting for BE design
export type AlertInstanceHistorySuccessResponse = {
  alerts: AlertInstanceHistory[];
  page: number;
  perPage: number;
  totalResults: number;
};

export type AlertInstanceHistoryFailedResponse = AxiosError;
export type AlertInstanceHistoryResponse =
  | AlertInstanceHistorySuccessResponse
  | AlertInstanceHistoryFailedResponse;

export type AlertTypeInfoSuccessResponse = {
  deviceType: DeviceType;
  alertType: AlertType;
  alertDescription: string;
  procedures: TroubleshootingProcedure[];
};

export type AlertTypeInfoFailedResponse = AxiosError;
export type AlertTypeInfoResponse = AlertTypeInfoSuccessResponse | AlertTypeInfoFailedResponse;
