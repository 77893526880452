import { Box } from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import type { CSSProperties } from 'react';
import React from 'react';

import type { IconType } from './icons';
import { icons } from './icons';

interface Props {
  rotate?: number;
  size?: number;
  icon: IconType;
  className?: string;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  style?: CSSProperties;

  // Uses 'Box' instead of 'img' and used 'mask-image' property to set image.
  // Image color can be affected by setting 'backgroundColor'.
  // This allows us to use one svg icon and change its color.
  hasWrapper?: boolean;
  width?: string | number;
  sx?: SxProps;
}

export const Icon = React.forwardRef<HTMLImageElement, Props>(
  (
    {
      icon,
      className,
      rotate = 0,
      size,
      onMouseOver,
      onMouseLeave,
      onClick,
      hasWrapper,
      sx,
      ...props
    }: Props,
    ref,
  ) => {
    if (hasWrapper) {
      return (
        <Box
          ref={ref}
          className={className}
          sx={{
            ...sx,
            maskImage: `url('${icons[icon]}')`,
            maskRepeat: 'no-repeat',
            width: `${size || 0}px`,
            height: `${size || 0}px`,
            transform: `rotate(${rotate}deg)`,
          }}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          {...props}
        />
      );
    }
    return (
      <img
        ref={ref}
        style={{ transform: `rotate(${rotate}deg)` }}
        className={className}
        src={icons[icon]}
        alt={icon}
        width={size}
        height={size}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        {...props}
      />
    );
  },
);

Icon.displayName = 'Icon';
