const getDynamicallyPluralText = (noun: string, count: number, hasVerb = true) => {
  const plural = count !== 1;
  const text = `${count} ${noun}${plural ? 's' : ''}`;
  if (hasVerb) {
    return `${text} ${plural ? 'are' : 'is'}`;
  }
  return text;
};

export default getDynamicallyPluralText;
