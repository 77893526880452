/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { trackEvent } from '../../analyticsClient';
import { Button } from '../../components';
import { LocalStorageItem } from '../../constants/localStorage';
import { useQuery } from '../../hooks/useQuery';
import { useRoutes } from '../../hooks/useRoutes';
import { isLoggedInSelector } from '../../store/auth/selectors';
import { AnalyticsEvent, AnalyticsProperty } from '../../types/Analytics';
import { Platform } from '../../types/Platform';
import { UserInterfaceType } from '../../types/UserInterfaceType';
import { generateCode, hashCode } from '../../utils';
import appStoreImage from './assets/app_store.svg';
import googlePlayImage from './assets/google_play.svg';
import bottomImage from './assets/login_bottom_image.jpg';
import logo from './assets/pwr_fleet_logo.svg';
import * as S from './styles';

type SignUpState = 'NO_ACCOUNT' | 'IS_INSTALLER' | 'IS_HOMEOWNER';

export const VERCEL_AUTH_PROXY_URL = `https://cognito-auth-proxy.vercel.app/api/auth-callback`; // Vercel edge function, acting as an auth proxy, to allow Vercel preview builds to login to Cognito. See https://github.com/neurio/pwrfleet/pull/1270 and  https://github.com/neurio/cognito-auth-proxy/blob/68334b87c22fc1bb1adf1768bb049ffb8d37bfb4/pages/api/auth-callback.ts // TODO change domain name to a better one

const redirectToLogin = () => {
  if (!process.env.REACT_APP_AUTH_CLIENT_ID) {
    throw new Error('REACT_APP_AUTH_CLIENT_ID env required.');
  }
  const code = window.localStorage.getItem(LocalStorageItem.AUTH_CODE) ?? generateCode();
  window.localStorage.setItem(LocalStorageItem.AUTH_CODE, code);
  const query = new URLSearchParams({
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    redirect_uri: `${window.location.origin}/auth-callback`,
    code_challenge_method: 'S256',
    code_challenge: hashCode(code),
  });

  /** If we're running on Vercel, use our auth proxy instead of the regular PWRfleet backend
   *
   * This workaround is in place because Cognito doesn't accept wildcards in its CALLBACK_URL.
   * So instead, we pass it the origin (like https://pwrfleet-abcdef-generac-ces.vercel.app) as the `state` query param.
   * Cognito will forward that `state` variable, along with an auth code, to our auth proxy.
   * Our auth proxy will then redirect back to the origin's own auth-callback file with the auth code appended
   *
   * This allows us to whitelist only the auth proxy in Cognito and then use that as a single redirect point for all Vercel login requests
   * */
  if (process.env.REACT_APP_COGNITO_AUTH_PROXY_URL && process.env.REACT_APP_VERCEL_URL) {
    // if the auth proxy is defined AND we're running on Vercel
    query.set('response_type', 'code'); // Cognito needs this; normally the PWRfleet backend proxy will append it, but here we'll set it directly
    query.set('redirect_uri', process.env.REACT_APP_COGNITO_AUTH_PROXY_URL); // Tell Cognito to redirect to our auth proxy instead of myself
    query.set('state', `${encodeURIComponent(window.location.origin)}/auth-callback`); // Redirect here after Cognito
    window.location.href = `https://neurio-pwrview-stg.auth.us-east-1.amazoncognito.com/login?${query}`; // Go directly to the Cognito hosted login page, NOT the PWRfleet backend proxy
  } else {
    /** If we're NOT running on Vercel, then don't add the other query params. Just send the request to the PWRfleet backend proxy as before */
    window.location.href = `${process.env.REACT_APP_API_URL_PREFIX}/sessions/v1/pkce/authorize?${query}`;
  }
};

export const Login = () => {
  const Routes = useRoutes();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const [signUpState, handleSignUpState] = useState<SignUpState>('NO_ACCOUNT');
  const history = useHistory();
  const isRegistered = useQuery().has('registered');

  const registerInstaller = useCallback(() => {
    trackEvent(AnalyticsEvent.SignUpButtonClick, {
      [AnalyticsProperty.UserType]: UserInterfaceType.INSTALLER,
    });
    handleSignUpState('IS_INSTALLER');
  }, []);

  const registerHomeowner = useCallback(() => {
    trackEvent(AnalyticsEvent.SignUpButtonClick, {
      [AnalyticsProperty.UserType]: UserInterfaceType.HOMEOWNER,
    });
    handleSignUpState('IS_HOMEOWNER');
  }, []);

  const continueToSignUp = useCallback(() => {
    history.push(Routes.Signup);
  }, [history, Routes]);

  const goBack = useCallback(() => {
    handleSignUpState('NO_ACCOUNT');
  }, []);

  const onLoginClick = useCallback(() => {
    trackEvent(AnalyticsEvent.LogInButtonClick);
    redirectToLogin();
  }, []);

  const handleStoreButtonClick = (platform: Platform) => {
    trackEvent(AnalyticsEvent.SignUpAppButtonClick, { [AnalyticsProperty.Platform]: platform });
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  const backButton = (
    <S.StyledBackButton onClick={goBack}>
      <S.StyledBackIcon icon="arrow bullet" rotate={180} />
      Go back
    </S.StyledBackButton>
  );

  const loginForm = (
    <S.StyledLoginButtonContainer>
      <Typography variant="h3">Log in to your fleet dashboard</Typography>
      <Box
        mt={{
          xs: 3,
          sm: 3,
          md: 5,
        }}
      >
        <Button
          type="submit"
          size="large"
          color="primary"
          data-test-id="login-button"
          variant="contained"
          width={230}
          onClick={onLoginClick}
        >
          Login
        </Button>
      </Box>
    </S.StyledLoginButtonContainer>
  );

  return (
    <>
      <S.StyledContainer>
        <S.StyledLogo src={logo} alt="Generac PWRFleet Logo" />
        <S.StyledSections>
          {!isRegistered && <S.StyledSection>{loginForm}</S.StyledSection>}
          {isRegistered ? (
            <S.StyledSection className="success-section">
              <Box mt={4}>
                <Typography variant="h3" data-test-id="signup-success-message">
                  Success!
                </Typography>
              </Box>
              <Box my={4}>
                <Typography variant="body1">
                  You’ve successfully signed up for a PWRfleet account. Now, login to manage and
                  monitor your company’s sites.
                </Typography>
              </Box>
              {loginForm}
            </S.StyledSection>
          ) : signUpState === 'IS_INSTALLER' ? (
            <S.StyledSection>
              <Typography variant="h3">Sign up as an installer</Typography>
              <Box my={4}>
                <Typography variant="body1">
                  If your company already has a PWRfleet account, please log in with that account.
                </Typography>
                <Box mt={2}>
                  <Typography variant="body1">
                    If you create a new account, you will not see any sites your coworkers
                    registered in the already existing company account, and can contact support at
                    pwrfleet support email to resolve the issue.
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="body1">
                    If your company does not have a PWRfleet account, continue with your sign up.
                  </Typography>
                </Box>
              </Box>
              <Button
                size="small"
                color="primary"
                onClick={continueToSignUp}
                data-test-id="installer-signup-continue-button"
              >
                Continue to sign up
              </Button>
              {backButton}
            </S.StyledSection>
          ) : signUpState === 'IS_HOMEOWNER' ? (
            <S.StyledSection>
              <Typography variant="h3">Sign up as a homeowner</Typography>
              <Box mt={4}>
                <Typography variant="body1">
                  The PWRfleet platform supports Installers who need to view their entire fleet of
                  sites.
                </Typography>
                <Box mt={2}>
                  <Typography variant="body1">
                    If your installer has already registered your site, you should have received an
                    email inviting you to monitor your home system on the PWRview platform.
                  </Typography>
                </Box>
              </Box>
              <Box mt={3} mb={2}>
                <S.StyledBoldTitle variant="caption">
                  Monitor your site on your mobile device
                </S.StyledBoldTitle>
              </Box>
              <S.StyledStoreLinks>
                <a
                  href="https://apps.apple.com/us/app/pwrview-for-pwrcell/id1470592171"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleStoreButtonClick(Platform.IOS)}
                >
                  <img src={appStoreImage} alt="Download on the App Store" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.neurio.generachome"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleStoreButtonClick(Platform.ANDROID)}
                >
                  <img src={googlePlayImage} alt="Get it on Google Play" />
                </a>
              </S.StyledStoreLinks>
              {backButton}
            </S.StyledSection>
          ) : (
            <S.StyledSection>
              <Typography variant="h3">Don&apos;t have an account?</Typography>
              <Box mt={4}>
                <Typography variant="body1">
                  If you are a generac PWRcell installer, create a PWRfleet account and register a
                  PWRcell site to access PWRfleet monitoring.
                </Typography>
              </Box>
              <S.StyledLoginButtons>
                <Button
                  width={300}
                  size="large"
                  color="primary"
                  onClick={registerInstaller}
                  data-test-id="installer-signup-button"
                >
                  I am an installer
                </Button>
                <Button width={300} size="large" color="primary" onClick={registerHomeowner}>
                  I am a homeowner
                </Button>
              </S.StyledLoginButtons>
            </S.StyledSection>
          )}
        </S.StyledSections>
        <img width="100%" src={bottomImage} alt="" />
      </S.StyledContainer>
    </>
  );
};
