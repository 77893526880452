import { useSelector } from 'react-redux';

import { featureTogglesSelector } from '../store/featureToggles/selectors';
import type { FeatureToggle } from '../types/FeatureToggle';

export function useFeatureToggle(featureToggle?: FeatureToggle): boolean {
  const enabledFeatures = useSelector(featureTogglesSelector);
  if (featureToggle) {
    return enabledFeatures.includes(featureToggle);
  }
  return false;
}
