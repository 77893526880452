import { AlertCategory } from '../types/Alert';

interface Definition {
  name: string;
}

export const alertCategory: { [key in AlertCategory]: Definition } = {
  [AlertCategory.DEVICE_IN_ERROR_STATE]: {
    name: 'Error',
  },
  [AlertCategory.DEVICE_OFFLINE]: {
    name: 'Connectivity',
  },
};
