export interface SignUpByInviteRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export enum SignUpMethod {
  DIRECT = 'Direct',
  INVITE = 'Invite',
}
export interface SignUpRequest extends SignUpByInviteRequest {
  companyName?: string;
  companyPhoneNumber?: string;
}

type SignUpField = keyof SignUpRequest;

export interface SignUpSuccessResponse {
  userId: string;
}

export type SignUpFailedResponse = {
  [key in SignUpField]: string;
};

export type SignUpResponse = SignUpSuccessResponse | SignUpFailedResponse;
