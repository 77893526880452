import { Box, DialogContent, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadAlertHistory } from '../../store/alertDetails/actions';
import {
  alertHistoryErrorSelector,
  alertHistoryPageSelector,
  alertHistoryPerPageSelector,
  alertHistoryTotalResultsSelector,
} from '../../store/alertDetails/selectors';
import type { AlertInstanceHistory } from '../../types/Alert';
import type { SiteDetails } from '../../types/SiteDetails';
import { AlertInstanceHistoryTable } from '../alertInstanceHistoryTable';
import { ErrorMsg } from './errorMsg';

export interface Props {
  alertId: string;
  siteDetails: SiteDetails | null;
  items: AlertInstanceHistory[] | null;
  height: number | 'unset';
}

export const HistoryContent = ({ alertId, siteDetails, items, height }: Props) => {
  const dispatch = useDispatch();
  const page = useSelector(alertHistoryPageSelector);
  const perPage = useSelector(alertHistoryPerPageSelector);
  const totalResults = useSelector(alertHistoryTotalResultsSelector);
  const hasError = useSelector(alertHistoryErrorSelector);

  const handlePageChange = (page: number) => {
    dispatch(loadAlertHistory.request({ alertId, page, perPage }));
  };

  const handleRowsPerPageChange = (perPage: number) => {
    dispatch(loadAlertHistory.request({ alertId, page: 1, perPage }));
  };

  return (
    <DialogContent dividers style={{ height, paddingBottom: 8 }}>
      {hasError ? (
        <ErrorMsg />
      ) : items ? (
        <AlertInstanceHistoryTable
          alerts={items}
          timezone={siteDetails?.timezone}
          currentPage={page}
          perPage={perPage}
          totalResults={totalResults}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      ) : (
        <Box textAlign="center" mt={1}>
          <Typography variant="body2">No alert history found.</Typography>
        </Box>
      )}
    </DialogContent>
  );
};
