import { Backdrop, Box, CircularProgress } from '@mui/material';
import type { ReactNode } from 'react';

interface Props {
  isLoading: boolean;
  children: ReactNode;
}

export const PWRloader = ({ isLoading, children }: Props) => {
  return (
    <Box position="relative">
      <Backdrop
        open={isLoading}
        sx={{
          position: 'absolute',
          zIndex: (theme) => theme.zIndex.modal,
          background: 'none',
          opacity: 0.25,
        }}
      >
        <CircularProgress data-test-class="pwrloader" />
      </Backdrop>
      <Box sx={{ opacity: isLoading ? 0.25 : 1 }}>{children}</Box>
    </Box>
  );
};
