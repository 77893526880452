import type { RadioProps, SxProps } from '@mui/material';
import { Box, FormControlLabel, Radio } from '@mui/material';
import type { ReactNode } from 'react';

import { COLORS } from '../../styles';

interface PWRradioProps extends RadioProps {
  label: string | ReactNode;
}

const wrapperSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  ml: 2.5,
};

const radioSx: SxProps = {
  '& input[disabled] + span > svg': {
    color: COLORS.LIGHT_EMPHASIS,
  },
  '& input[disabled][checked] + span > svg': {
    color: COLORS.MEDIUM_GREY,
  },
};

export const PWRradio = ({ label, id, disabled, color = 'info', ...radioProps }: PWRradioProps) => (
  <FormControlLabel
    label={label}
    control={
      <Box sx={{ mr: 3 }}>
        <Box
          sx={{
            ...wrapperSx,
            ...(disabled && { backgroundColor: COLORS.SILVER }),
          }}
        >
          <Radio
            data-test-id={`radio-${id}`}
            disabled={disabled}
            color={color}
            sx={radioSx}
            LinkComponent="button"
            size="small"
            {...radioProps}
          />
        </Box>
      </Box>
    }
  />
);
