import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAccessToken, setAccessToken } from '../../api/token';
import type { AuthRefreshTokenResponse } from '../../types/Auth';
import { getRefreshTokenFromStore } from '../../utils/token';
import { logout } from '../auth/actions';

export const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL_PREFIX}`,
  prepareHeaders: (headers) => {
    const token = getAccessToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, baseQueryApi, extraOptions) => {
  let result = await baseQuery(args, baseQueryApi, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshToken = getRefreshTokenFromStore();
    const refreshResult = await baseQuery(
      {
        url: '/sessions/v1/pkce/tokens/refresh',
        method: 'POST',
        body: {
          clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
          refreshToken,
        },
      },
      baseQueryApi,
      extraOptions,
    );

    if (refreshResult?.data) {
      const newAccessToken = (refreshResult.data as AuthRefreshTokenResponse).accessToken;
      setAccessToken(newAccessToken);
      result = await baseQuery(args, baseQueryApi, extraOptions);
    } else {
      baseQueryApi.dispatch(logout());
    }
  }
  return result;
};
