import type { FormLabelProps } from '@mui/material';
import { FormLabel } from '@mui/material';
import type { ReactNode } from 'react';

interface PWRlabelProps {
  children: ReactNode;
  htmlFor: string;
  $required?: boolean;
  formLabelProps?: FormLabelProps;
}

export const PWRlabel = ({ children, htmlFor, $required, formLabelProps }: PWRlabelProps) => {
  return (
    <FormLabel
      data-test-id={`${htmlFor}-label`}
      htmlFor={htmlFor}
      {...formLabelProps}
      sx={{
        fontSize: 12,
        fontWeight: 'bold',
        ...formLabelProps?.sx,
      }}
    >
      {children}
      {$required && '*'}
    </FormLabel>
  );
};
