export enum Alert {
  Generic,
  SiteDataError,
  SitesDataError,
  SettingUpdateError,
  SettingUpdateSuccess,
  CompaniesDataError,
  CompanyAlertsDataError,
  SiteAlertsDataError,
  UsersDataError,
  SignUpError,
  DevicesChartDataError,
  KpiDataError,
  CompanyUsersDataError,
  NeverConnectedError,
  ConnectionLostError,
  DialogError,
  MaxMetricsError,
  AttachmentsRetrieveError,
  AttachmentRetrieveError,
  AttachmentUploadError,
  AttachmentDeleteError,
  MoveSiteError,
  AllCompanyAlertsSelected,
  SiteAlertDetailsError,
  SiteLayoutLoadError,
  NoSystemsAddedToSite,
  IncorrectSysModeError,
  InvalidSiteAddress,
}

export enum AlertContainer {
  Root,
  Dialog,
  Modal,
}
