import { createSelector } from '@reduxjs/toolkit';

import { userRoleSelectGroups } from '../../constants/userRoles';
import { UIFeature } from '../../types/UIFeature';
import type { UserRole } from '../../types/UserRole';
import { hasUIFeatureAccess } from '../../utils';
import { accountCompanyIdSelector } from '../account/selectors';
import { isSupportModeSelector, uiFeaturesSelector } from '../auth/selectors';
import { companyIdSelector } from '../company/selectors';
import type { RootState } from '../types';

export const companyUsersSelector = (state: RootState) => state.companyUsers.items;
export const companyUsersLoadingSelector = (state: RootState) => state.companyUsers.loading;
export const companyUsersErrorSelector = (state: RootState) => state.companyUsers.error;

export const companyUsersSortPropertySelector = (state: RootState) =>
  state.companyUsers.selectedSortProperty;
export const companyUsersSortOrderSelector = (state: RootState) =>
  state.companyUsers.selectedSortOrder;

export const pendingCompanyUsersSelector = (state: RootState) =>
  state.companyUsers.pendingUsersItems;
export const pendingCompanyUsersLoadingSelector = (state: RootState) =>
  state.companyUsers.pendingUsersLoading;
export const pendingCompanyUsersErrorSelector = (state: RootState) =>
  state.companyUsers.pendingUsersError;

export const pendingCompanyUsersSortPropertySelector = (state: RootState) =>
  state.companyUsers.pendingUsersSortProperty;
export const pendingCompanyUsersSortOrderSelector = (state: RootState) =>
  state.companyUsers.pendingUsersSortOrder;

export const sendingInviteSelector = (state: RootState) => state.companyUsers.sendingInvite;
export const sendingInviteErrorSelector = (state: RootState) =>
  state.companyUsers.sendingInviteError;
export const inviteSentSelector = (state: RootState) => state.companyUsers.inviteSent;

export const updateCompanyUserRoleLoadingSelector = (state: RootState) =>
  state.companyUsers.updateUserRoleLoading;
export const updateCompanyUserRoleErrorSelector = (state: RootState) =>
  state.companyUsers.updateUserRoleError;

export const companyUserSelector = (state: RootState, { userId }: { userId: string }) =>
  state.companyUsers.items?.find((u) => u.id === userId);

export const pendingCompanyUserSelector = (state: RootState, { userId }: { userId: string }) =>
  state.companyUsers.pendingUsersItems?.find((u) => u.id === userId);

/**
 * There was an issue here with accountCompanyId not existing - and not being available at all
 * unless the User had previously visited the screens/fleet view and
 * dispatch(getAccountCompany.request(userId)) was called.
 *
 * @todo cleanup and bugfix for setting and saving logged-in User's accountId
 * @todo: load accountCompanyID and account company details at login (dispatch from login epic on successful login)
 * @note this has been temporarily fixed by adding "account" to the redux-persist whitelist
 */
export const userRolesSelector = createSelector(
  [companyIdSelector, accountCompanyIdSelector, isSupportModeSelector, uiFeaturesSelector],
  (companyId, accountCompanyId, isSupportMode, allowedUIFeatures): UserRole[] => {
    const isOwnCompany = accountCompanyId === companyId;

    if (isOwnCompany && isSupportMode) {
      if (hasUIFeatureAccess(UIFeature.SUPPORT_USER_EDIT, allowedUIFeatures)) {
        return userRoleSelectGroups[UIFeature.SUPPORT_USER_EDIT];
      } else {
        return [];
      }
    } else if (hasUIFeatureAccess(UIFeature.USER_EDIT, allowedUIFeatures)) {
      return userRoleSelectGroups[UIFeature.USER_EDIT];
    } else {
      return [];
    }
  },
);
