import { sortBy } from 'lodash';

import type { UpdateAddressRequestBody, UpdateAddressUrlParams } from '../../../types/Address';
import type { AddSiteRequest, AddSiteResponse } from '../../../types/api/addSite';
import type {
  AddressValidationRequest,
  AddressValidationResponse,
} from '../../../types/api/getAddressValidation';
import type { AccessDetails, SiteAccessResponse } from '../../../types/api/getSiteAccess';
import type {
  GetSiteAlertsArgs,
  GetSiteAlertsResponse,
  TransformedGetSiteAlertsResponse,
} from '../../../types/api/getSiteAlerts';
import type {
  SiteChartDataRequest,
  SiteChartDataResponse,
} from '../../../types/api/getSiteChartData';
import type { SiteSearchRequest, SiteSearchResponse } from '../../../types/api/getSiteSearch';
import type { GetSiteSystemsResponse, SiteSystem } from '../../../types/api/getSiteSystems';
import type { UtilitiesRequest, Utility } from '../../../types/api/getUtilities';
import { SolarFilterOptions } from '../../../types/api/getUtilities';
import type {
  AttachmentMetaRequest,
  AttachmentMetaResponse,
  AttachmentUploadRequest,
  AttachmentUploadResponse,
} from '../../../types/api/siteAttachments';
import { AttachmentStatus } from '../../../types/api/siteAttachments';
import type {
  SitePreferencesRequest,
  SitePreferencesResponse,
} from '../../../types/api/sitePreferences';
import type { TransferSiteRequest, TransferSiteResponse } from '../../../types/api/transferSite';
import type {
  UpdateSiteAccessPatchRequest,
  UpdateSiteAccessPostRequest,
} from '../../../types/api/updateSiteAccess';
import type { Kpi } from '../../../types/Kpi';
import type { SiteDetails, SiteRequestBody, SiteStatus } from '../../../types/SiteDetails';
import type { SiteDevices } from '../../../types/SiteDevices';
import { api } from '..';
import { uploadAttachmentQueryFn } from '../queryFunctions';
import { transformSiteAlertsResponse } from '../transformResponse';
import { getSiteAlertsFetchArgs, getSiteChartDataUrl } from '../utils';

const siteApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSiteSearch: builder.query<SiteSearchResponse, SiteSearchRequest>({
      query: ({ query, size, filters }) => ({
        method: 'POST',
        url: '/pwrfleet-ui/v1/sites/search',
        body: { query, size, filters },
      }),
    }),
    getSite: builder.query<SiteDetails, { siteId: string }>({
      query: ({ siteId }) => `/sites/v2/${siteId}`,
    }),
    updateSite: builder.mutation<void, { siteId: string; body: SiteRequestBody }>({
      query: ({ siteId, body }) => ({
        method: 'PATCH',
        url: `/sites/v2/${siteId}`,
        body,
      }),
      invalidatesTags: () => [{ type: 'CompanySitesPaginated' }],
    }),
    addSite: builder.mutation<AddSiteResponse, AddSiteRequest>({
      query: ({ companyId, body }) => ({
        url: `/pwrfleet-ui/v1/companies/${companyId}/sites`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'CompanySitesPaginated' }],
    }),
    deleteSite: builder.mutation<any, any>({
      query: ({ companyId, siteId }) => ({
        url: `/pwrfleet-ui/v1/companies/${companyId}/sites/${siteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'CompanySitesPaginated' }],
    }),
    getSiteStatus: builder.query<SiteStatus, { siteId: string }>({
      query: ({ siteId }) => `/sites/v3/${siteId}/status`,
      transformResponse: (response: SiteStatus) => {
        return {
          ...response,
          systems: sortBy(response.systems, 'serialNumber'),
        };
      },
      providesTags: (_result, _meta, args) => [{ type: 'SiteStatus', id: args.siteId }],
    }),
    getSiteDevices: builder.query<SiteDevices, { siteId: string }>({
      query: ({ siteId }) => `/sites/v3/${siteId}/devices`,
      transformResponse: (response: SiteDevices) => {
        return {
          ...response,
          systems: sortBy(response.systems, 'serialNumber'),
        };
      },
      providesTags: (_result, _meta, args) => [{ type: 'SiteDevices', id: args.siteId }],
    }),
    getSiteAccess: builder.query<SiteAccessResponse, { siteId: string }>({
      query: ({ siteId }) => `/pwrfleet-ui/v1/sites/${siteId}/access`,
      providesTags: (_result, _meta, args) => [{ type: 'SiteAccess', id: args.siteId }],
    }),
    updateSiteAccess: builder.mutation<
      void,
      {
        method: 'POST' | 'PATCH';
        siteId: string;
        body: UpdateSiteAccessPostRequest | UpdateSiteAccessPatchRequest;
      }
    >({
      query: ({ method, siteId, body }) => ({
        method,
        url: `/sites/v1/tpo/site/${siteId}/access`,
        body,
        responseHandler: (response) => response.text(), // response is not used, but throws an error if not handled correctly
      }),
      invalidatesTags: (_result, _meta, args) => [
        { type: 'SiteAccess', id: args.siteId },
        { type: 'CompanySitesPaginated' },
      ],
    }),
    updateSiteAccessV2: builder.mutation<
      void,
      { siteId: string; siteAccessDetailsList: AccessDetails[] }
    >({
      query: ({ siteId, siteAccessDetailsList }) => ({
        method: 'PATCH',
        url: `/pwrfleet-ui/v1/sites/${siteId}/access`,
        body: { siteAccessDetailsList },
      }),
      invalidatesTags: (_result, _meta, args) => [{ type: 'SiteAccess', id: args.siteId }],
    }), // unused for now, could replace PATCH updateSiteAccess, but the request body here is more complex to assemble
    transferSite: builder.mutation<TransferSiteResponse, TransferSiteRequest>({
      query: ({ siteId, ...params }) => ({
        method: 'POST',
        url: `/sites/v1/site/${siteId}/company-access/owner`,
        params,
      }),
    }),
    getSiteAttachments: builder.query<AttachmentMetaResponse[], { siteId: string }>({
      query: ({ siteId }) => `/sites/v2/${siteId}/attachments`,
      providesTags: (_result, _meta, args) => [{ type: 'SiteAttachments', id: args.siteId }],
    }),
    getSiteKpis: builder.query<Kpi[], { siteId: string }>({
      query: ({ siteId }) => `/sites/v5/${siteId}/kpi`,
    }),
    getSiteSystems: builder.query<SiteSystem[], { siteId: string }>({
      query: ({ siteId }) => `/sites/v1/${siteId}/systems`,
    }),
    getSiteDevicesV2: builder.query<GetSiteSystemsResponse, { siteId: string }>({
      query: ({ siteId }) => `/pwrfleet-ui/v1/sites/${siteId}/system-devices`,
    }),
    getSiteChartData: builder.query<SiteChartDataResponse, SiteChartDataRequest>({
      query: ({ granularity, dateTo, dateFrom, ...rest }) => ({
        url: getSiteChartDataUrl(rest),
        params: { granularity, dateTo, dateFrom },
      }),
    }),
    getAddressValidation: builder.mutation<
      AddressValidationResponse,
      { address: AddressValidationRequest }
    >({
      query: ({ address }) => ({
        method: 'POST',
        url: '/sites/v1/validation/address',
        body: address,
      }),
    }),
    updateAddress: builder.mutation<void, UpdateAddressRequestBody & UpdateAddressUrlParams>({
      query: ({ siteId, ...body }) => {
        return {
          url: `/sites/v2/${siteId}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (_result, _meta, args) => [{ type: 'SiteStatus', id: args.siteId }],
    }),
    getAttachment: builder.mutation<AttachmentMetaResponse, AttachmentMetaRequest>({
      query: ({ attachmentId }) => `/sites/v2/attachment/${attachmentId}`,
      invalidatesTags: (result, _meta, args) => {
        if (result?.status === AttachmentStatus.UPLOADED) {
          return [{ type: 'SiteAttachments', id: args.siteId }];
        }
        return [];
      },
    }),
    deleteAttachment: builder.mutation<void, AttachmentMetaRequest>({
      query: ({ attachmentId }) => ({
        method: 'DELETE',
        url: `/sites/v2/attachment/${attachmentId}`,
      }),
      invalidatesTags: (_result, _meta, args) => [{ type: 'SiteAttachments', id: args.siteId }],
    }),
    uploadAttachment: builder.mutation<AttachmentUploadResponse, AttachmentUploadRequest>({
      queryFn: uploadAttachmentQueryFn,
    }),
    downloadAttachment: builder.mutation<any, { attachmentId: string }>({
      query: ({ attachmentId }) => ({
        url: `/sites/v2/attachment/${attachmentId}/presigned-read`,
        responseHandler: (response) => response.text(),
      }),
    }),
    getSiteAlerts: builder.query<TransformedGetSiteAlertsResponse, GetSiteAlertsArgs>({
      query: (args) => getSiteAlertsFetchArgs(args),
      transformResponse: (response: GetSiteAlertsResponse) => transformSiteAlertsResponse(response),
    }),
    getSitePreferences: builder.query<SitePreferencesResponse, { siteId: string }>({
      query: ({ siteId }) => `/preferences/v1/site/${siteId}`,
    }), // unused for now, endpoint is live and may deliver additonal preferences in future
    updateSitePreferences: builder.mutation<SitePreferencesResponse, SitePreferencesRequest>({
      query: ({ siteId, body }) => ({
        url: `/preferences/v1/site/${siteId}`,
        method: 'POST',
        body,
      }),
    }), // unused for now, endpoint is live and may update additonal preferences in future
    getUtilities: builder.query<Utility[], UtilitiesRequest>({
      query: ({ zipcode }) => ({
        url: '/billings/v1/utilities',
        params: {
          zipcode,
          solar: SolarFilterOptions.UNKNOWN,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSiteSearchQuery: useGetSiteSearch,
  useLazyGetSiteSearchQuery: useLazyGetSiteSearch,
  useGetSiteQuery: useGetSite,
  useLazyGetSiteQuery: useLazyGetSite,
  useUpdateSiteMutation: useUpdateSite,
  useGetSiteStatusQuery: useGetSiteStatus,
  useGetSiteDevicesQuery: useGetSiteDevices,
  useGetSiteAccessQuery: useGetSiteAccess,
  useUpdateSiteAccessMutation: useUpdateSiteAccess,
  useTransferSiteMutation: useTransferSite,
  useGetSiteAttachmentsQuery: useGetSiteAttachments,
  useGetSiteKpisQuery: useGetSiteKpis,
  useGetSiteSystemsQuery: useGetSiteSystems,
  useGetSiteChartDataQuery: useGetSiteChartData,
  useGetAddressValidationMutation: useGetAddressValidation,
  useUpdateAddressMutation: useUpdateAddress,
  useAddSiteMutation: useAddSite,
  useDeleteSiteMutation: useDeleteSite,
  useGetAttachmentMutation: useGetAttachment,
  useDeleteAttachmentMutation: useDeleteAttachment,
  useUploadAttachmentMutation: useUploadAttachment,
  useDownloadAttachmentMutation: useDownloadAttachment,
  useGetUtilitiesQuery: useGetUtilities,
  useGetSiteAlertsQuery: useGetSiteAlerts,
  useGetSiteDevicesV2Query: useGetSiteDevicesV2,
} = siteApi;
