import type { DialogProps } from '@mui/material';
import { Box, ButtonBase, Dialog } from '@mui/material';
import { isEmpty } from 'lodash';
import type { ReactNode } from 'react';

import useWindowSize from '../../hooks/useWindowSize';
import { Flex } from '../flex';
import { Icon } from '../icon';
import type { PWRbuttonProps } from '../pwrButton';
import { PWRbutton } from '../pwrButton';
import { PWRiconButton } from '../pwrIconButton';
import { PWRloader } from '../pwrLoader';

interface PWRdialogProps {
  isOpen: boolean;
  id?: string;
  handleClose?: () => void;
  titleSlotElement?: ReactNode;
  bodySlotElement?: ReactNode;
  footerSlotElement?: ReactNode;
  isLoading?: boolean;
  isConfirmationDialog?: boolean;
  bodyButtonsProps?: PWRbuttonProps[];
  fullScreenBreakpoint?: number;
  dialogProps?: Omit<DialogProps, 'open'>;
}

export const PWRdialog = ({
  isOpen,
  id,
  handleClose,
  titleSlotElement,
  isLoading,
  isConfirmationDialog = false,
  bodySlotElement,
  bodyButtonsProps,
  footerSlotElement,
  fullScreenBreakpoint,
  dialogProps,
}: PWRdialogProps) => {
  const { width: windowWidth } = useWindowSize();

  const hasCloseButton = !!handleClose;
  const fullScreen = !!fullScreenBreakpoint && windowWidth < fullScreenBreakpoint;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullScreen={fullScreen}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: !fullScreen ? '24px' : 0,
        },
      }}
      data-test-id={id}
      {...dialogProps}
    >
      <PWRloader isLoading={isLoading ?? false}>
        <Flex
          sx={{
            p: 4,
            maxHeight: {
              md: 'calc(100vh - (48px * 2))',
            },
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <Flex sx={{ justifyContent: 'space-between', alignItems: 'flex-start', pb: 2 }}>
            {titleSlotElement && <>{titleSlotElement}</>}
            {hasCloseButton && (
              <Box pl={2}>
                {isConfirmationDialog ? (
                  <ButtonBase onClick={handleClose} data-test-class="dialog-close-btn">
                    <Icon icon="close" />
                  </ButtonBase>
                ) : (
                  <PWRiconButton data-test-class="dialog-close-btn" onClick={handleClose}>
                    <Icon icon="close" />
                  </PWRiconButton>
                )}
              </Box>
            )}
          </Flex>
          {bodySlotElement}
          <Flex
            sx={{
              width: '100%',
              flexDirection: { md: 'row-reverse', sm: 'column' },
              alignItems: 'flex-end',
              gap: { xs: 3 },
              pt: 3,
            }}
          >
            {!isEmpty(bodyButtonsProps) && (
              <Flex
                sx={{
                  width: { sm: '100%', xs: '100%' },
                  flexDirection: { md: 'row-reverse', sm: 'column', xs: 'column' },
                  pt: { md: 6, xs: 3 },
                  gap: 2,
                }}
              >
                {bodyButtonsProps?.map(({ id, text, ...rest }) => (
                  <PWRbutton
                    id={id}
                    key={text}
                    text={text ?? ''}
                    color="secondary"
                    variant="outlined"
                    {...rest}
                  />
                ))}
              </Flex>
            )}
            {footerSlotElement && <>{footerSlotElement}</>}
          </Flex>
        </Flex>
      </PWRloader>
    </Dialog>
  );
};
