import type { IconButtonProps } from '@mui/material';
import { IconButton } from '@mui/material';
import type { ReactNode } from 'react';

import { COLORS } from '../../styles';

interface PWRiconButtonProps extends IconButtonProps {
  children: ReactNode;
}

export const PWRiconButton = ({
  children,
  size = 'small',
  sx,
  ...iconButtonProps
}: PWRiconButtonProps) => {
  return (
    <IconButton
      size={size}
      sx={{
        // makes IconButton match styles of <Button variant="outlined" color="secondary" />
        color: COLORS.BLACK,
        border: `1px solid ${COLORS.SILVER}`,
        width: 40,
        height: 40,
        '&&:disabled': {
          backgroundColor: `${COLORS.SILVER} !important`,
          color: `${COLORS.WHITE} !important`,
        },
        '&:hover': {
          boxShadow: `0px 0px 0px 1px ${COLORS.MEDIUM_GREY}`,
          backgroundColor: 'rgba(204, 204, 204, 0.04)',
          borderColor: COLORS.MEDIUM_GREY,
        },
        ...sx,
      }}
      {...iconButtonProps}
    >
      {children}
    </IconButton>
  );
};
