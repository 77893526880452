import type { ComponentProps } from 'react';
import React from 'react';
import type { ToastOptions } from 'react-toastify';
import { toast } from 'react-toastify';
import { isActionOf } from 'typesafe-actions';
import type { ActionCreator } from 'typesafe-actions/dist/is-action-of';

import { Alert } from '../components/alert';
import { CommonErrorAlert } from '../components/commonErrorAlert';
import { AlertContainer } from '../types/ScreenAlerts';

type AlertProps = ComponentProps<typeof Alert>;
type ErrorAlertProps = ComponentProps<typeof CommonErrorAlert>;

export const customAlert = (props: AlertProps, options?: ToastOptions) =>
  toast(<Alert {...props} />, {
    containerId: AlertContainer.Root,
    autoClose: 6000,
    closeOnClick: true,
    ...options,
  });

export const commonErrorAlert = (props: ErrorAlertProps, options?: ToastOptions) =>
  toast(<CommonErrorAlert {...props} />, {
    containerId: AlertContainer.Root,
    autoClose: 6000,
    closeOnClick: true,
    ...options,
  });

export const callActionAlert = (
  action: {
    type: string;
  },
  failureAction: ActionCreator<{
    type: string;
  }>,
  {
    successText,
    errorText,
    successContainerId = AlertContainer.Root,
    errorContainerId = AlertContainer.Root,
  }: {
    successText: string;
    errorText: string;
    successContainerId?: AlertContainer;
    errorContainerId?: AlertContainer;
  },
  alertProps?: Omit<AlertProps, 'title'>,
  options?: ToastOptions,
) => {
  const isError = isActionOf(failureAction)(action);
  const title = isError ? errorText : successText;
  const type = isError ? 'error' : 'success';
  const containerId = isError ? errorContainerId : successContainerId;
  customAlert({ title, ...alertProps }, { type, containerId, ...options });
};
