import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Box, ButtonBase, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { COLORS } from '../../styles';
import { Flex } from '../flex';
import { Icon } from '../icon';
import type { IconType } from '../icon/icons';
import { PWRcrumbs } from '../pwrCrumbs';

interface CompanyScreenProps {
  title: string;
  bodySlotElement: ReactNode;
  icon?: IconType;
  titleEndSlotElement?: ReactNode;
  hasBackButton?: boolean;
}

export const CompanyScreen = ({
  title,
  bodySlotElement,
  icon,
  titleEndSlotElement,
  hasBackButton = false,
}: CompanyScreenProps) => {
  const history = useHistory();

  return (
    <>
      <Box sx={{ background: COLORS.WHITE, padding: '0 24px' }}>
        <Flex sx={{ minHeight: '48px', alignItems: 'center' }}>
          <PWRcrumbs hideStaticParams />
        </Flex>
        <Flex
          sx={{
            alignItems: { xs: 'flex-start', sm: 'center' },
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
            py: 3,
          }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            {hasBackButton && (
              <ButtonBase
                onClick={() => history.goBack()}
                sx={{ pr: 1, pb: 0.5 }}
                data-test-id="company-screen-back-btn"
              >
                <ArrowBackIcon sx={{ width: '24px' }} />
              </ButtonBase>
            )}
            {icon && (
              <Box pr={1}>
                <Icon icon={icon} size={32} />
              </Box>
            )}
            <Typography
              variant="h2"
              sx={{ whiteSpace: 'nowrap', fontSize: { md: 36, xs: 24 }, mr: 2, mb: 1 }}
              data-test-id="company-screen-title"
            >
              {title}
            </Typography>
          </Flex>
          {titleEndSlotElement}
        </Flex>
      </Box>
      <Box sx={{ padding: '24px 24px 0 24px' }}>{bodySlotElement}</Box>
    </>
  );
};
