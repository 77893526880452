import type { LinkProps, SxProps } from '@mui/material';
import { Link } from '@mui/material';
import type { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { COLORS } from '../../styles';

interface PwrLinkProps {
  children: ReactNode;
  id: string;
  to?: string;
  button?: boolean;
  handleClick?: () => void;
  color?: LinkProps['color'];
  underline?: LinkProps['underline'];
  sx?: SxProps;
}

export const PWRlink = ({
  children,
  id,
  to,
  button = false,
  handleClick,
  underline = 'always',
  color,
  sx,
}: PwrLinkProps) => {
  return (
    <Link
      data-test-id={id}
      to={to}
      component={button ? 'button' : RouterLink}
      onClick={handleClick}
      color={color}
      underline={underline}
      sx={{
        ...sx,
        ...(!color && { color: COLORS.TEAL, textDecorationColor: COLORS.LIGHT_TEAL }),
      }}
    >
      {children}
    </Link>
  );
};
