import type { KpiId, KpiSections } from '../types/Kpi';
import {
  CurrentType,
  DailyKilowattHourPerKilowattPeak,
  KilowattHour,
  KilowattHourPerKilowattPeak,
  Percentage,
} from '../types/Unit';

export const KPI_SECTION_SOLAR_ABSOLUTE_YIELD = 'solarProductionAbsoluteYield';
export const KPI_SECTION_SOLAR_SPECIFIC_YIELD = 'solarProductionSpecificYield';
export const KPI_SECTION_SOLAR_PERFORMANCE_RATIO = 'solarProductionPerformanceRatio';
export const KPI_SECTION_CONSUMPTION = 'consumption';
export const KPI_SECTION_SOLAR_PRODUCTION = 'production';
export const KPI_SECTION_STORAGE = 'storage';
export const KPI_SECTION_AGGREGATE_METRICS = 'aggregateMetrics';
export const KPI_SECTION_INVERTER_ENERGY = 'inverterEnergy';

export const SOLAR_CAPACITY_KPIS: KpiId[] = [
  'performanceToday',
  'performanceYesterday',
  'avgPerformanceThisMonth',
  'avgPerformanceLastMonth',
  'avgPerformanceThisYear',
];

export const EXPECTED_PRODUCTION_KPIS: KpiId[] = [
  'performanceRatioThisMonth',
  'performanceRatioLastMonth',
];

export const INITIAL_KPIS: KpiId[] = [
  'yieldToday',
  'yieldYesterday',
  'yieldThisMonth',
  'yieldLastMonth',
  'yieldThisYear',
];

export default {
  [KPI_SECTION_SOLAR_ABSOLUTE_YIELD]: {
    title: 'Solar production (absolute yield)',
    items: [
      {
        name: 'yieldToday',
        title: 'Yield today',
        icon: 'solar',
        unit: KilowattHour,
      },
      {
        name: 'yieldYesterday',
        title: 'Yield yesterday',
        icon: 'solar',
        unit: KilowattHour,
      },
      {
        name: 'yieldThisMonth',
        title: 'Yield this month',
        icon: 'solar',
        unit: KilowattHour,
      },
      {
        name: 'yieldLastMonth',
        title: 'Yield last month',
        icon: 'solar',
        unit: KilowattHour,
      },
      {
        name: 'yieldThisYear',
        title: 'Yield this year',
        icon: 'solar',
        unit: KilowattHour,
      },
    ],
  },
  [KPI_SECTION_SOLAR_SPECIFIC_YIELD]: {
    title: 'Solar production (specific yield)',
    items: [
      {
        name: 'performanceToday',
        title: 'Perf. today',
        icon: 'solar',
        unit: KilowattHourPerKilowattPeak,
      },
      {
        name: 'performanceYesterday',
        title: 'Perf. yesterday',
        icon: 'solar',
        unit: KilowattHourPerKilowattPeak,
      },
      {
        name: 'avgPerformanceThisMonth',
        title: 'Avg. perf. this month',
        icon: 'solar',
        unit: DailyKilowattHourPerKilowattPeak,
      },
      {
        name: 'avgPerformanceLastMonth',
        title: 'Avg. perf. last month',
        icon: 'solar',
        unit: DailyKilowattHourPerKilowattPeak,
      },
      {
        name: 'avgPerformanceThisYear',
        title: 'Avg. perf. this year',
        icon: 'solar',
        unit: DailyKilowattHourPerKilowattPeak,
      },
    ],
  },
  [KPI_SECTION_SOLAR_PERFORMANCE_RATIO]: {
    title: 'Solar production (performance ratio)',
    items: [
      {
        name: 'performanceRatioThisMonth',
        title: 'Perf. ratio this month',
        icon: 'solar',
        unit: Percentage,
      },
      {
        name: 'performanceRatioLastMonth',
        title: 'Perf. ratio last month',
        icon: 'solar',
        unit: Percentage,
      },
    ],
  },
  [KPI_SECTION_CONSUMPTION]: {
    title: 'Consumption',
    items: [
      {
        name: 'consumptionToday',
        title: 'Consumption today',
        icon: 'home',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'consumptionYesterday',
        title: 'Consumption yesterday',
        icon: 'home',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'consumptionThisMonth',
        title: 'Consumption this month',
        icon: 'home',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'consumptionLastMonth',
        title: 'Consumption last month',
        icon: 'home',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'consumptionThisYear',
        title: 'Consumption this year',
        icon: 'home',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
    ],
  },
  [KPI_SECTION_STORAGE]: {
    title: 'Storage',
    items: [
      { name: 'soc', title: 'SoC', icon: 'battery', unit: Percentage },
      { name: 'soh', title: 'SoH', icon: 'battery', unit: Percentage },
    ],
  },
  [KPI_SECTION_AGGREGATE_METRICS]: {
    title: 'Aggregate metrics',
    items: [
      {
        name: 'selfConsumptionToday',
        title: 'Self consumption today',
        icon: 'home',
        unit: Percentage,
      },
      {
        name: 'selfConsumptionYesterday',
        title: 'Self consumption yesterday',
        icon: 'home',
        unit: Percentage,
      },
      {
        name: 'selfConsumptionMonth',
        title: 'Self consumption current month',
        icon: 'home',
        unit: Percentage,
      },
      {
        name: 'selfConsumptionYear',
        title: 'Self consumption current year',
        icon: 'home',
        unit: Percentage,
      },
    ],
  },
  [KPI_SECTION_INVERTER_ENERGY]: {
    title: 'Site Energy',
    items: [
      {
        name: 'producedThisMonth',
        title: 'Energy Produced This Month',
        icon: 'inverter',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'producedLastMonth',
        title: 'Energy Produced Last Month',
        icon: 'inverter',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'producedLifetime',
        title: 'Energy Produced Lifetime',
        icon: 'inverter',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'importedThisMonth',
        title: 'Energy Imported This Month',
        icon: 'inverter',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'importedLastMonth',
        title: 'Energy Imported Last Month',
        icon: 'inverter',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'importedLifetime',
        title: 'Energy Imported Lifetime',
        icon: 'inverter',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
    ],
  },
} as KpiSections;
