import type { FieldProps, FormikProps } from 'formik';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, ref, string } from 'yup';

import { PASSWORD_VALIDATION_MESSAGE } from '../../constants/validation';
import type { FormPasswordValues } from '../../store/account';
import { updateAccountPassword } from '../../store/account/actions';
import { userIdSelector } from '../../store/auth/selectors';
import { REGEX_PASSWORD } from '../../utils';
import { getRequiredMessage } from '../../utils/forms';
import { Button } from '../button';
import { FormContentBlock, FormHeader, FormItem } from '../form';
import { FormDiscardAlert } from '../formDiscardAlert';
import { PasswordDots } from '../passwordDots';
import { PasswordInput } from '../passwordInput';

const SSchema = object().shape({
  currentPassword: string().required(getRequiredMessage('Current Password')),
  newPassword: string()
    .required(getRequiredMessage('New Password'))
    .matches(REGEX_PASSWORD, PASSWORD_VALIDATION_MESSAGE),
  confirmPassword: string()
    .required(getRequiredMessage('Confirm Password'))
    .oneOf([ref('newPassword'), null], 'Passwords must match'),
});

type FormValue = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const ProfileSecurityForm = () => {
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<FormValue>>(null);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [discardAlertIsOpen, setDiscardAlertIsOpen] = useState<boolean>(false);

  const userId = useSelector(userIdSelector);

  // --

  useEffect(() => {
    formRef?.current?.resetForm();
  }, [formRef, isEditing]);

  // --

  const handleDiscardAlert = useCallback(() => {
    const current = formRef?.current || { touched: {} };
    const isTouched = Object.keys(current.touched).length > 0;
    if (isTouched) {
      setDiscardAlertIsOpen(!discardAlertIsOpen);
    } else {
      setIsEditing(false);
    }
  }, [discardAlertIsOpen, formRef, setIsEditing]);

  const onSubmit = (values: FormPasswordValues, resetForm: () => void) => {
    if (userId) {
      dispatch(
        updateAccountPassword.request({
          userId,
          currentPassword: values.currentPassword,
          newPassword: values.confirmPassword,
        }),
      );
      setIsEditing(false);
      resetForm();
    }
  };

  // --

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      }}
      onSubmit={(values, params) => onSubmit(values, params.resetForm)}
      validationSchema={SSchema}
    >
      {(props) => {
        const handleDiscardForm = () => {
          setIsEditing(false);
          setDiscardAlertIsOpen(false);
          props.resetForm();
        };
        return (
          <Form>
            <FormDiscardAlert
              open={discardAlertIsOpen}
              onClose={() => setDiscardAlertIsOpen(false)}
              onDiscard={handleDiscardForm}
            />
            <FormHeader title="Security">
              {isEditing ? (
                <Button
                  color="secondary"
                  size="small"
                  onClick={handleDiscardAlert}
                  data-test-id="security-cancel-button"
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => setIsEditing(true)}
                  data-test-id="security-edit-button"
                >
                  Edit
                </Button>
              )}
            </FormHeader>
            {isEditing ? (
              <FormContentBlock isEditing>
                <FormItem input>
                  <Field name="currentPassword">
                    {({ field, form, meta }: FieldProps) => (
                      <PasswordInput
                        {...field}
                        fullWidth
                        label="Old Password"
                        autoComplete="current-password"
                        error={
                          meta.touched && meta.error ? Boolean(form.errors.currentPassword) : false
                        }
                        helperText={
                          meta.touched && meta.error && form.errors.currentPassword
                            ? String(form.errors.currentPassword)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <FormItem input>
                  <Field name="newPassword">
                    {({ field, form, meta }: FieldProps) => (
                      <PasswordInput
                        {...field}
                        fullWidth
                        label="New Password"
                        autoComplete="new-password"
                        error={
                          meta.touched && meta.error ? Boolean(form.errors.newPassword) : false
                        }
                        helperText={
                          meta.touched && meta.error && form.errors.newPassword
                            ? String(form.errors.newPassword)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <FormItem input>
                  <Field name="confirmPassword">
                    {({ field, form, meta }: FieldProps) => (
                      <PasswordInput
                        {...field}
                        fullWidth
                        label="Confirm Password"
                        autoComplete="new-password"
                        error={
                          meta.touched && meta.error ? Boolean(form.errors.confirmPassword) : false
                        }
                        helperText={
                          meta.touched && meta.error && form.errors.confirmPassword
                            ? String(form.errors.confirmPassword)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <Button
                  width={200}
                  size="small"
                  color="primary"
                  type="submit"
                  data-test-id="security-change-password-button"
                >
                  Change Password
                </Button>
              </FormContentBlock>
            ) : (
              <FormContentBlock>
                <FormItem title="Password">
                  <PasswordDots count={12} />
                </FormItem>
              </FormContentBlock>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
