import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Routes } from '../../constants/routes';
import { logout } from '../../store/auth/actions';
import { isLoggedInSelector } from '../../store/auth/selectors';

export const Logout = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return <div>{isLoggedIn ? <div>Logging out...</div> : <Redirect to={Routes.Login} />}</div>;
};
