import { Box } from '@mui/material';
import type { ComponentProps, MouseEvent } from 'react';
import React from 'react';
import type { match } from 'react-router-dom';
import { matchPath, NavLink, useLocation } from 'react-router-dom';

import type { PublicRoutes, Routes, SupportRoutes } from '../../constants/routes';
import { useFeatureToggle } from '../../hooks/useFeatureToggle';
import type { FeatureToggle } from '../../types/FeatureToggle';
import { Icon } from '../icon';
import type { IconType } from '../icon/icons';

type Props = Omit<ComponentProps<typeof NavLink>, 'key' | 'onClick'> & {
  title: string;
  icon: IconType;
  routes?: [Routes | PublicRoutes | SupportRoutes];
  featureToggle?: FeatureToggle;
  onClick?: (e: MouseEvent<HTMLLIElement>) => void;
};

export const NavigationMenuItem = ({
  title,
  icon,
  routes,
  featureToggle,
  onClick,
  children,
  ...rest
}: Props) => {
  const { pathname } = useLocation();
  const enabled = useFeatureToggle(featureToggle);
  const isActive = (match: match<Record<string, string | undefined>> | null | undefined) => {
    return (
      !!match ||
      !!routes?.some((path) =>
        matchPath(pathname, {
          path,
          exact: true,
          strict: false,
        }),
      )
    );
  };
  if (!featureToggle || enabled) {
    return (
      <li className="nav-menu__item" onClick={onClick}>
        <NavLink
          activeClassName="nav-menu__link--active"
          className="nav-menu__link"
          isActive={isActive}
          {...rest}
        >
          <Box p={1} borderRadius="100px" display="flex">
            <Icon hasWrapper icon={icon} className="nav-menu__icon" size={24} />
          </Box>
          <Box ml={1}>{title}</Box>
        </NavLink>
        {children}
      </li>
    );
  }
  return null;
};
