import { Box, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import React from 'react';

interface FormHeaderProps {
  title: string;
  children?: ReactNode;
}

export const FormHeader = (props: FormHeaderProps) => {
  const { title, children } = props;

  return (
    <Box my={3} display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h3">{title}</Typography>
      {children}
    </Box>
  );
};
