import { useRouteMatch } from 'react-router-dom';

import type { DevicesTabDefinition } from '../constants/devicesTabDefinitions';
import { DevicesTabDefinitions } from '../constants/devicesTabDefinitions';
import type { DeviceType } from '../types/DeviceType';
import type { SiteContextParams } from '../types/Routes';
import { DevicesTab } from '../types/Tabs';
import { useRoutes } from './useRoutes';

export interface SingleDeviceRouteParams extends SiteContextParams {
  systemId?: string;
  deviceType?: DeviceType;
  deviceId?: string;
}

export const useTab = (): {
  tab: DevicesTab | null;
  params: SingleDeviceRouteParams | null;
  tabDefinition: DevicesTabDefinition | null;
} => {
  const routes = useRoutes();
  const routeMatchDevicesRoutes = useRouteMatch([
    routes.SiteDevices,
    routes.SiteDevicesMetrics,
    routes.SiteDevicesConfig,
  ]);

  if (!routeMatchDevicesRoutes?.isExact) {
    return {
      tab: null,
      params: null,
      tabDefinition: null,
    };
  }

  const mapRouteToTab = {
    [routes.SiteDevices]: DevicesTab.OVERVIEW,
    [routes.SiteDevicesMetrics]: DevicesTab.METRICS,
    [routes.SiteDevicesConfig]: DevicesTab.CONFIGURATION,
  };

  const currentTab = mapRouteToTab[routeMatchDevicesRoutes.path as keyof typeof mapRouteToTab];

  return {
    tab: currentTab,
    params: routeMatchDevicesRoutes.params as SingleDeviceRouteParams,
    tabDefinition: DevicesTabDefinitions[currentTab],
  };
};
