import { CircularProgress, Fab } from '@mui/material';
import clsx from 'clsx';

import { useDownloadChartCsv } from '../../store/api/endpoints/system';
import type { ChartType } from '../../types/SiteMetrics';
import { Icon } from '../icon';
import type { IconType } from '../icon/icons';
import { useStyles } from './styles';

interface Props {
  type: ChartType;
  url: string;
  params?: any;
  body?: {};
  disabled?: boolean;
}

export const DownloadCsvButton = ({
  type,
  url,
  params,
  body,
  disabled = false,
  ...props
}: Props) => {
  const [downloadChartCsv, { isLoading, isError: hasError, isSuccess: hasDownload }] =
    useDownloadChartCsv();

  const classes = useStyles();

  const buttonClass = clsx({
    [classes.buttonError]: hasError,
    [classes.buttonSuccess]: hasDownload,
    [classes.buttonPrimary]: !disabled,
    [classes.buttonDisabled]: disabled,
  });

  let iconName: IconType = 'export csv';
  if (hasError) {
    iconName = 'failed';
  } else if (hasDownload) {
    iconName = 'complete';
  }

  const handleClick = () => {
    downloadChartCsv({
      type,
      url,
      params,
      body,
    });
  };

  return (
    <div className={classes.root} {...props}>
      <div className={classes.wrapper}>
        <Fab
          data-test-id={`${type}-download-csv-btn`}
          color="primary"
          className={buttonClass}
          onClick={handleClick}
          disabled={disabled}
          sx={{ zIndex: 1 }}
        >
          {isLoading && <CircularProgress size={44} className={classes.fabProgress} />}
          <Icon icon={iconName} size={20} />
        </Fab>
      </div>
    </div>
  );
};
