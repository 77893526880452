import { styled } from '@mui/material/styles';
import React from 'react';

import { screenLoaderId } from '../../constants/screenLoader';
import { up } from '../../styles';
import { Loader } from '../loader';
import { SitesScreenLoader } from '../sitesScreenLoader';

// up("md") padding-left is to center the loader accounting for nav width
const LoaderContainer = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: none;
  justify-content: center;
  padding-top: 150px;
  ${up('md')} {
    padding-top: 100px;
    padding-left: 220px;
  }
`;

export const ScreenLoader = () => {
  return (
    <>
      <LoaderContainer id={screenLoaderId}>
        <Loader size={12} color="orange" />
      </LoaderContainer>
      <SitesScreenLoader />
    </>
  );
};
