export enum SettingsMetaDataType {
  FLOAT = 'FLOAT',
  INTEGER = 'INTEGER',
  BOOLEAN = 'BOOLEAN',
  ENUM = 'ENUM',
  STRING = 'STRING',
  SPECIAL = 'SPECIAL',
}

export enum SettingAccessType {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  SUPPORT_EDIT = 'SUPPORT_EDIT',
}

export enum ConfigurableDeviceType {
  BATTERY = 'BATTERY',
  INVERTER = 'INVERTER',
}

export type DeviceSettingMetadata = {
  name: string;
  label: string;
  value: number | string;
  constraints: {
    minValue: number | null;
    maxValue: number | null;
    allowedValues: DeviceSettingMetadataEnumAllowedValueItem[] | string[] | null;
    lessOrEqualThan: string[];
    greaterOrEqualThan: string[];
  };
  type: SettingsMetaDataType;
  description: string;
  unit: string | null;
  access: SettingAccessType;
  isConfigurable: boolean;
  confirm: {
    required: boolean;
    description: string | null;
  };
} & (
  | DeviceSettingMetadataFloat
  | DeviceSettingMetadataInteger
  | DeviceSettingMetadataBoolean
  | DeviceSettingMetadataEnum
  | DeviceSettingMetadataString
  | DeviceSettingMetadataSpecial
);

export interface DeviceSettings {
  deviceId: string;
  deviceType: ConfigurableDeviceType;
  settings: DeviceSettingMetadata[];
}

export interface SystemSettings {
  systemId: string;
  devices: DeviceSettings[];
}

export type DeviceSettingMetadataEnum = {
  type: SettingsMetaDataType.ENUM;
  constraints: {
    allowedValues: DeviceSettingMetadataEnumAllowedValueItem[];
  };
};

export interface DeviceSettingMetadataEnumAllowedValueItem {
  name: string;
  value: number;
}

export type DeviceSettingMetadataFloat = {
  type: SettingsMetaDataType.FLOAT;
};

export type DeviceSettingMetadataInteger = {
  type: SettingsMetaDataType.INTEGER;
};

export type DeviceSettingMetadataBoolean = {
  type: SettingsMetaDataType.BOOLEAN;
};

export type DeviceSettingMetadataString = {
  type: SettingsMetaDataType.STRING;
  constraints: {
    regexMatch: string;
  };
};

export type DeviceSettingMetadataSpecial = {
  type: SettingsMetaDataType.SPECIAL;
};
