import { createSelector } from '@reduxjs/toolkit';

import {
  CompanyAssociationRemovalMode,
  CompanyAssociationStatus,
} from '../../types/CompanyAssociation';
import type { RootState } from '../types';

export const companyAssociationsSelector = (state: RootState) =>
  state.companyAssociations.associations;
export const companyAssociationsLoadingSelector = (state: RootState) =>
  state.companyAssociations.loading;
export const companyAssociationsErrorSelector = (state: RootState) =>
  state.companyAssociations.error;

//Child associations = companies that can share sites with us
//Parent associations = companies we can share with
export const companyParentAssociationsSelector = (state: RootState) =>
  state.companyAssociations.associations?.sentInvitations;
export const companyChildAssociationsSelector = (state: RootState) =>
  state.companyAssociations.associations?.receivedInvitations;

export const companyParentAssociationsWithPendingStatusSelector = createSelector(
  companyParentAssociationsSelector,
  (parent) => parent?.filter((p) => p.status === CompanyAssociationStatus.PENDING),
);

export const companyParentAssociationsWithActiveStatusSelector = createSelector(
  companyParentAssociationsSelector,
  (parent) => parent?.filter((p) => p.status === CompanyAssociationStatus.ACTIVE),
);

export const companyChildAssociationsWithPendingStatusSelector = createSelector(
  companyChildAssociationsSelector,
  (child) => child?.filter((c) => c.status === CompanyAssociationStatus.PENDING),
);

export const companyChildAssociationsWithActiveStatusSelector = createSelector(
  companyChildAssociationsSelector,
  (child) => child?.filter((c) => c.status === CompanyAssociationStatus.ACTIVE),
);

export const sendingAssociationRequestSelector = (state: RootState) =>
  state.companyAssociations.sendingAssociationRequest;
export const sendingAssociationRequestErrorSelector = (state: RootState) =>
  state.companyAssociations.sendingAssociationRequestError;
export const associationRequestSentSelector = (state: RootState) =>
  state.companyAssociations.associationRequestSent;

export const activeCompanyChildAssociationSelector = (
  state: RootState,
  { associationId }: { associationId: string },
) => {
  const items = companyChildAssociationsWithActiveStatusSelector(state);
  return items?.find((a) => a.associationId === associationId);
};

export const removeModeSelector = (
  state: RootState,
  { associationId }: { associationId: string },
) => {
  const isDeclineMode = companyChildAssociationsWithPendingStatusSelector(state)?.find(
    (a) => a.associationId === associationId,
  );
  const isRemoveAssociationMode = companyChildAssociationsWithActiveStatusSelector(state)?.find(
    (a) => a.associationId === associationId,
  );
  if (isDeclineMode) {
    return CompanyAssociationRemovalMode.DECLINE;
  } else if (isRemoveAssociationMode) {
    return CompanyAssociationRemovalMode.REMOVE_ASSOCIATION;
  }
  return CompanyAssociationRemovalMode.REMOVE_COMPANY;
};

export const associatedCompanyNameSelector = (
  state: RootState,
  { associationId }: { associationId: string },
) => {
  const childCompanyName = companyParentAssociationsSelector(state)?.find(
    (a) => a.associationId === associationId,
  )?.inviteeCompany.name;
  const parentCompanyName = companyChildAssociationsSelector(state)?.find(
    (a) => a.associationId === associationId,
  )?.inviterCompany.name;
  return childCompanyName || parentCompanyName;
};
