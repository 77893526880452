import type { AxiosError } from 'axios';
import type { ActionsObservable, Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import type { Observable } from 'rxjs';
import { from } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';
import type { Action } from 'typesafe-actions';
import { isActionOf } from 'typesafe-actions';

import {
  identify as analyticsClientIdentify,
  register as analyticsClientRegister,
} from '../../analyticsClient';
import {
  getAccountCompanies,
  getAccountData,
  updateAccountNameData,
  updateAccountPasswordData,
} from '../../api';
import { identify as messagingClientIdentify } from '../../messagingClient';
import { AnalyticsProperty } from '../../types/Analytics';
import { callActionAlert } from '../../utils/alerts';
import { setAuthData } from '../auth/actions';
import { updateCompany } from '../company/actions';
import {
  getAccountCompany,
  getAccountSettings,
  updateAccountName,
  updateAccountPassword,
} from './actions';
import { accountCompanyIdSelector } from './selectors';

const getAccountSettingsEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(getAccountSettings.request)),
    mergeMap((action): Observable<Action> => {
      return from(getAccountData(action.payload)).pipe(
        map(getAccountSettings.success),
        catchError(async (error) => getAccountSettings.failure(error)),
      );
    }),
  );
};

const updateAccountSettingsNameEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(updateAccountName.request)),
    mergeMap((action): Observable<Action> => {
      return from(
        updateAccountNameData(
          action.payload.userId,
          action.payload.firstName,
          action.payload.lastName,
        ),
      ).pipe(
        map(updateAccountName.success),
        catchError(async (error) => updateAccountName.failure(error)),
        map((res) => {
          callActionAlert(res, updateAccountName.failure, {
            successText: 'Your account name has been successfully updated.',
            errorText: 'Your account name was not updated.',
          });
          return getAccountSettings.request(state$.value.auth.userId);
        }),
      );
    }),
  );
};

const updateAccountSettingsPasswordEpic: Epic = (
  action$: ActionsObservable<Action>,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(updateAccountPassword.request)),
    mergeMap((action): Observable<Action> => {
      return from(
        updateAccountPasswordData(
          action.payload.userId,
          action.payload.currentPassword,
          action.payload.newPassword,
        ),
      ).pipe(
        map(() => updateAccountPassword.success(true)),
        catchError(async (error) => updateAccountPassword.failure(error)),
        map((res) => {
          let errorMsg = 'Your password was not updated.';
          const isError = isActionOf(updateAccountPassword.failure)(res);
          if (isError) {
            const { response } = res.payload as AxiosError;
            const { status, defaultMessage } = response?.data;
            if (status === 401 && defaultMessage.includes('Old password of user')) {
              errorMsg = 'Your old password is not valid.';
            }
          }
          callActionAlert(res, updateAccountPassword.failure, {
            successText: 'Your password has been successfully updated.',
            errorText: errorMsg,
          });
        }),
        ignoreElements(),
      );
    }),
  );
};

const getAccountCompanyEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(getAccountCompany.request)),
    mergeMap((action): Observable<Action> => {
      return from(getAccountCompanies(action.payload)).pipe(
        map((response) => {
          const userId = state$.value.auth.userId;
          const { companyId, name: companyName } = response[0];

          // @todo this tracking can be moved to auth epics after
          // https://neurio.atlassian.net/browse/FM-2119
          messagingClientIdentify(userId, {
            companyId,
            companyName,
          });
          analyticsClientIdentify(userId, {
            [AnalyticsProperty.CompanyId]: companyId,
            [AnalyticsProperty.CompanyName]: companyName,
          });
          analyticsClientRegister({
            [AnalyticsProperty.CompanyId]: companyId,
            [AnalyticsProperty.CompanyName]: companyName,
          });

          return getAccountCompany.success(response);
        }),
        catchError(async (error) => getAccountCompany.failure(error)),
      );
    }),
  );
};

const isAccountCompanyAction = (accountCompanyId?: string) => (action: Action) =>
  isActionOf(updateCompany.success)(action) && action?.payload?.companyId === accountCompanyId;

const updateAccountCompanyEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter((action) => {
      // selects only action of the company that is the account company
      const accountCompanyId = accountCompanyIdSelector(state$.value);
      return isAccountCompanyAction(accountCompanyId)(action);
    }),
    map(() => getAccountCompany.request(state$.value.auth.userId)),
  );
};

const refreshAccountDataEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(setAuthData)),
    mergeMap((action) => {
      const { userId } = action.payload;
      return [getAccountSettings.request(userId), getAccountCompany.request(userId)];
    }),
  );
};

export default combineEpics(
  getAccountSettingsEpic,
  updateAccountSettingsNameEpic,
  updateAccountSettingsPasswordEpic,
  getAccountCompanyEpic,
  updateAccountCompanyEpic,
  refreshAccountDataEpic,
);
