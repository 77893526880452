import { configureStore } from '@reduxjs/toolkit';
import { mount } from 'enzyme';
import React from 'react';
import { Provider } from 'react-redux';
import { StaticRouter } from 'react-router-dom';

import rootReducer from '../store/rootReducer';
import { defaultTheme, PWRfleetThemeProvider } from '../styles';

const EnvInner = ({ children }: { children: JSX.Element }) => {
  const store = configureStore({
    reducer: rootReducer,
  });

  return (
    <StaticRouter>
      <PWRfleetThemeProvider theme={defaultTheme}>
        <Provider store={store}>{children}</Provider>
      </PWRfleetThemeProvider>
    </StaticRouter>
  );
};

export const Env = React.memo(EnvInner);

export const mountWithEnv = (children: JSX.Element) => mount(<Env>{children}</Env>);
