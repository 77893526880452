import { Box, Drawer, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { ReactNode } from 'react';
import React from 'react';

import { getColor } from '../../styles';
import { Button } from '../button';
import { Icon } from '../icon';

interface Props {
  actionButtons?: ReactNode;
  title: string;
  subtitle?: string;
  open?: boolean;
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
}

interface StyleProps {
  $isDesktop: boolean;
}

const ActionBar = styled(Box)<StyleProps>`
  display: grid;

  grid-template-columns: max-content auto;
  grid-template-areas: 'toggle action-buttons';
  align-items: center;
  grid-area: actions;
  margin: auto;
  width: 100%;
  min-width: ${({ theme }) => theme.breakpoints.values.xs}px;
  max-width: ${({ theme }) => theme.breakpoints.values.xl}px;

  ${({ $isDesktop }) =>
    $isDesktop
      ? `border-bottom: 1px solid ${getColor('BLACK', 0.2)}`
      : `box-shadow: 0 0 5px ${getColor('BLACK', 0.2)}`};
`;

const ActionButtons = styled('div')`
  display: grid;
  grid-template-columns: auto max-content max-content;
  grid-template-areas: 'spacer button-secondary button-primary';

  text-align: right;

  button {
    font-weight: 700;
    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 11px;
    }
  }

  button + button {
    margin-left: 20px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-left: 5px;
    }
  }
`;

const StyledWrapper = styled('div')<StyleProps>`
  display: grid;
  min-height: 100%;
  grid-template-rows: max-content auto;
  grid-template-areas:
    'actions'
    'content';

  ${({ $isDesktop }) =>
    !$isDesktop &&
    `
		grid-template-rows: auto max-content;
		grid-template-areas:
			"content"
			"actions";
		`}

  .toggle-button {
    text-transform: none;

    :hover {
      background: none;
    }

    img {
      width: 20px;
      margin-right: 10px;
    }
  }
`;

const Content = styled(Box)`
  grid-area: content;
  margin: 0 auto;
  width: 100%;
  min-width: ${({ theme }) => theme.breakpoints.values.xs}px;
  max-width: ${({ theme }) => theme.breakpoints.values.xl}px;
`;

export const TopDrawer = ({ children, title, subtitle, isOpen, onClose, actionButtons }: Props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Drawer
      anchor={isDesktop ? 'top' : 'bottom'}
      onClose={onClose}
      open={isOpen}
      disableEnforceFocus
    >
      <StyledWrapper $isDesktop={isDesktop}>
        <ActionBar p={3} $isDesktop={isDesktop}>
          <Button variant="text" onClick={onClose}>
            <Icon icon="chevron down" rotate={isDesktop ? 180 : 0} />
            <Typography variant="subtitle2" color="textPrimary">
              {title}
            </Typography>
            {subtitle && (
              <Box ml={1}>
                <Typography variant="caption" color="textPrimary">
                  {subtitle}
                </Typography>
              </Box>
            )}
          </Button>
          <ActionButtons>
            <span />
            {actionButtons}
          </ActionButtons>
        </ActionBar>
        <Content py={3} px={8}>
          {children}
        </Content>
      </StyledWrapper>
    </Drawer>
  );
};
