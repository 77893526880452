import type { Theme, ThemeOptions } from '@mui/material';
import type { Breakpoint } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import invariant from 'invariant';

// Generate color names using http://chir.ag/projects/name-that-color/

export const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  MEDIUM_GREY: '#737373', // Secondary grey
  OFF_WHITE: '#F5F5F5', // Gray for BGs
  LIGHT_GREY: '#E1E1E1', // Gray for elevations
  SHADOW_GREY: '#DADADA', // Gray for container shadow
  ORANGE: '#FF6B00', // Accent orange
  LIGHT_ORANGE: '#FF993F', // Orange pressed
  BRAND_ORANGE: '#FF6900',
  YELLOW: '#FCA100', // Solar
  LIGHT_YELLOW: '#FDBF3F', // Solar highlight
  GREEN: '#6A9529', // Battery
  LIGHT_GREEN: '#9ABF57', // Battery highlight
  // MEDIUM_GREEN: "#91B464", // Battery negative (NOT USED YET)
  BRIGHT_GREEN: '#5AC25A', // Percentage line
  PURPLE: '#743A8D', // Grid
  LIGHT_PURPLE: '#A768BA', // Grid highlight
  // MEDIUM_PURPLE: "#9D75AF", // Grid negative (NOT USED YET)
  // BRIGHT_PURPLE: "#C458FF", // Tariff progress bar (NOT USED YET)
  TEAL: '#00889B', // Consumption
  LIGHT_TEAL: '#4CAAB8', // Consumption highlight
  BRIGHT_TEAL: '#30BCC3',
  SILVER: '#CCCCCC',
  ERROR: '#D0021B',
  TABLE_ROW_HOVER: 'rgba(0, 136, 155, 0.10)',
  TABLE_ROW_SELECTED: '#1E98D7',
  TABLE_ROW_ACTIVE: '#ddf1fb',
  ALERT_HIGH: '#D9001B',
  ALERT_MEDIUM: '#F59A23',
  ALERT_LOW: '#E2D45D',
  UNDEFINED_GREY: '#CED3DF',
  PV_MODULE_FILL: '#224870',
  ARRAY_LAYOUT_CANVAS_BG: '#D0D0D0',
  ARRAY_LAYOUT_SELECT_BLUE: '#2d9cdb',
  ARRAY_LAYOUT_SELECT_FILL: 'rgba(86, 204, 242, 0.1)',
  PWRTABLE_HEADER: '#EEEEEE', // Used by Finn for his new data tables, TODO integrate this into PWRTheme,
  MEDIUM_EMPHASIS: '#666666',
  LIGHT_EMPHASIS: '#BABABA',
  SUPPORT_ONLY: '#FFF4E3',
  SELECTED_BG: '#EAF8FB',
  BOX_SHADOW: '#00000040',
  ALERT_SUCCESS_BG: '#CDE2A4',
  ALERT_INFO_BG: '#B8E0ED',
  ALERT_WARNING_BG: '#FAD580',
  ALERT_ERROR_BG: '#B91B12',
};

export const BREAKPOINTS = {
  EXTRA_SMALL: 276,
  SMALL: 600,
  MEDIUM: 960,
  LARGE: 992,
  EXTRA_LARGE: 1440,
};

export type Color = keyof typeof COLORS;
export type Colors = Record<Color, string>;

export const ThemeDimensions = {
  // @todo this may need to be variable based on screen size in the future
  SUPPORT_APP_BAR_HEIGHT: 48,
  ALERT_MAX_WIDTH: 904,
  ALERT_WITH_CONTENT_MAX_WIDTH: 720,
  ALERT_FILTERS_DRAWER_WIDTH: 300,
  ALERT_DETAILS_CONTENT_HEIGHT: 470,
  TABLE_PAGINATION_HEIGHT: 52,
  ADD_EQUIPMENT_WIZARD_CONTENT_HEIGHT: 316,
};

/** Material-ui z-index documentation: https://material-ui.com/customization/z-index/ **/
export enum zIndex {
  SitesHeader = 97,
  AccountDrawer = 1051,
  Navbar = 1052,
  DrawerBelowAppBar = 1099,
  AppBar = 1100,
  DrawerAboveAppBar = 1200,
  Modal = 1300,
}

interface CommonData {
  name: string;
  colors: Colors;
}

export type FleetThemeOptions = ThemeOptions & CommonData;
export type FleetTheme = Theme & CommonData;

export const defaultThemeOptions: FleetThemeOptions = {
  name: 'Default Theme',
  colors: COLORS,
  spacing: 8,
  palette: {
    primary: {
      main: COLORS.ORANGE,
      light: COLORS.LIGHT_ORANGE,
    },
    secondary: {
      main: COLORS.SILVER,
    },
    info: {
      main: COLORS.TEAL,
      light: COLORS.LIGHT_TEAL,
    },
    background: {
      default: COLORS.OFF_WHITE,
      paper: COLORS.WHITE,
    },
    text: {
      primary: COLORS.BLACK,
      secondary: COLORS.MEDIUM_GREY,
    },
    error: {
      main: COLORS.ERROR,
    },
  },
  breakpoints: {
    values: {
      xs: BREAKPOINTS.EXTRA_SMALL,
      sm: BREAKPOINTS.SMALL,
      md: BREAKPOINTS.MEDIUM,
      lg: BREAKPOINTS.LARGE,
      xl: BREAKPOINTS.EXTRA_LARGE,
    },
  },
  zIndex: {
    appBar: zIndex.AppBar,
    drawer: zIndex.DrawerAboveAppBar,
    modal: zIndex.Modal,
  },
  typography: {
    fontFamily: 'Nunito Sans, sans-serif',
    fontSize: 16,
    h1: {
      fontWeight: 700,
      fontSize: 48,
    },
    h2: {
      fontWeight: 700,
      fontSize: 36,
    },
    h3: {
      fontWeight: 700,
      fontSize: 24,
    },
    // @todo update h4 fontSize to 18px when UserHeader component design is updated
    h4: {
      fontWeight: 700,
      fontSize: 16,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: 18,
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: 16,
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      color: COLORS.BLACK,
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      color: COLORS.BLACK,
    },
    caption: {
      fontWeight: 400,
      fontSize: 13,
      lineHeight: 1.5,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: COLORS.WHITE,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          padding: '20px',
          boxShadow: `0 0 5px rgba(0, 0, 0, 0.2)`,
        },
        colorDefault: {
          backgroundColor: COLORS.WHITE,
        },
        colorSecondary: {
          backgroundColor: COLORS.BLACK,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 2,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
          border: `1px solid ${COLORS.LIGHT_GREY}`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation8: {
          boxShadow: 'unset',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: COLORS.BLACK,
          fontSize: 16,
          '&.Mui-error': {
            '&&&&:hover:before': {
              borderBottom: `1px solid ${COLORS.ERROR}`,
            },
            '&&&&:before': {
              borderBottom: `1px solid ${COLORS.ERROR}`,
            },
            '&&&&:after': {
              borderBottom: `1px solid ${COLORS.ERROR}`,
            },
          },
        },
        underline: {
          '&&&&:hover:before': {
            borderBottom: `1px solid ${COLORS.MEDIUM_GREY}`,
          },
          '&&&&:before': {
            borderBottom: `1px solid ${COLORS.MEDIUM_GREY}`,
          },
          '&&&&:after': {
            borderBottom: `1px solid ${COLORS.MEDIUM_GREY}`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: 700,
          borderRadius: 48,
          height: 36,
          padding: '12px 32px',
          '&&:disabled': {
            backgroundColor: `${COLORS.SILVER} !important`,
            color: `${COLORS.WHITE} !important`,
            borderColor: `${COLORS.SILVER} !important`,
            borderWidth: 1,
          },
        },
        contained: {
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: COLORS.ORANGE,
          '&&:hover': {
            borderColor: COLORS.LIGHT_ORANGE,
            backgroundColor: COLORS.LIGHT_ORANGE,
          },
          '&&:focus': {
            borderColor: COLORS.ORANGE,
            backgroundColor: COLORS.LIGHT_ORANGE,
          },
        },
        containedPrimary: {
          color: COLORS.WHITE,
        },
        sizeSmall: {
          padding: '8px 24px',
          height: 28,
          fontSize: 12,
        },
        sizeLarge: {
          padding: '16px 32px',
          height: 46,
          fontSize: 14,
        },
        outlined: {
          padding: '12px 32px',
          fontSize: 12,
          height: 36,
          whiteSpace: 'nowrap',
          borderWidth: 1,
          borderStyle: 'solid',
        },
        outlinedPrimary: {
          color: COLORS.BLACK,
          borderColor: COLORS.ORANGE,
          '&&:hover': {
            borderColor: COLORS.LIGHT_ORANGE,
            boxShadow: `0px 0px 0px 1px ${COLORS.LIGHT_ORANGE}`,
            backgroundColor: `transparent`,
          },
          '&&:focus': {
            borderColor: COLORS.ORANGE,
            boxShadow: `0px 0px 0px 1px ${COLORS.LIGHT_ORANGE}`,
          },
        },
        outlinedSecondary: {
          color: COLORS.BLACK,
          borderColor: COLORS.SILVER,
          '&&:hover': {
            borderColor: COLORS.MEDIUM_GREY,
            boxShadow: `0px 0px 0px 1px ${COLORS.MEDIUM_GREY}`,
          },
          '&&:focus': {
            borderColor: COLORS.BLACK,
            boxShadow: `0px 0px 0px 1px ${COLORS.BLACK}`,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '& .MuiChip-deleteIcon': {
            color: COLORS.WHITE,
            '&:hover': {
              color: COLORS.WHITE,
            },
          },
          '&.MuiChip-sizeSmall': {
            fontSize: '12px',
            padding: '4px !important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: `${COLORS.BLACK} !important`,
        },
        shrink: {
          fontWeight: 700,
          fontSize: 12,
          textTransform: 'uppercase',
          transform: 'translate(0, 1.5px)',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: COLORS.ERROR,
          color: COLORS.WHITE,
          height: 'unset',
          minWidth: 'unset',
          padding: '2px 5px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: COLORS.WHITE,
          '&.Mui-focused': {
            '&& fieldset': {
              borderColor: COLORS.BLACK,
            },
          },
        },
        input: {
          padding: '12px !important',
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: `${COLORS.SILVER} !important`,
          '&.Mui-active': {
            color: `${COLORS.BLACK} !important`,
          },
          '&.Mui-completed': {
            color: `${COLORS.SILVER} !important`,
          },
        },
        text: {
          fill: COLORS.WHITE,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': {
            color: `${COLORS.BLACK} !important`,
          },
          '&.Mui-completed': {
            color: `${COLORS.SILVER} !important`,
          },
          color: `${COLORS.MEDIUM_GREY} !important`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: COLORS.SELECTED_BG,
            ':hover': { backgroundColor: COLORS.SELECTED_BG },
          },
          '&.Mui-selected.Mui-disabled': { opacity: 1 },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: 1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 700,
          textTransform: 'none',
          minWidth: '120px !important',
          '&:hover': {
            color: COLORS.BLACK,
            opacity: 1,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectIcon: {
          borderLeft: `none`,
          paddingLeft: 8,
        },
      },
    },
  },
};

export const up =
  (breakpoint: Breakpoint) =>
  ({ theme }: { theme: Theme }) => {
    invariant(typeof theme !== 'undefined', 'Theme required');
    return theme.breakpoints.up(breakpoint);
  };
export const down =
  (breakpoint: Breakpoint) =>
  ({ theme }: { theme: Theme }) => {
    invariant(typeof theme !== 'undefined', 'Theme required');
    return theme.breakpoints.down(breakpoint);
  };
export const defaultTheme = Object.assign(createTheme(defaultThemeOptions), {
  name: defaultThemeOptions.name,
}) as FleetTheme;
