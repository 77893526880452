/**
 * Messaging Service by Appcues
 *
 * @see https://www.appcues.com/
 * @see https://docs.appcues.com/
 *
 * To debug, append ?hey_appcues to URL
 */

const DEBUG_MODE = false;
const PRODUCTION_MODE = process.env.NODE_ENV === 'production';

/**
 * Appcues is automatically initialized with script in index.html
 *
 * window.Appcues is declared in declarations.ts
 */
export const init = () => {
  if (!PRODUCTION_MODE && DEBUG_MODE) {
    window.Appcues?.debug();
  }
};

/**
 * @see https://studio.appcues.com/settings/installation/guide/properties
 */
export const trackEvent = (eventName: string, eventProps?: any) => {
  window.Appcues?.track(eventName, eventProps);
};

/**
 * @see https://docs.appcues.com/article/22-appcues-page-vs-appcues-identify
 */
interface IdentifyProps {
  userRole?: string;
  email?: string;
  companyName?: string;
  companyId?: string;
}
export const identify = (userId: string, identifyProps: IdentifyProps) => {
  window.Appcues?.identify(userId, identifyProps);
};

export const trackPage = () => {
  window.Appcues?.page();
};
