import type { ActionsObservable, Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import type { Observable } from 'rxjs';
import { from } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import type { Action } from 'typesafe-actions';
import { isActionOf } from 'typesafe-actions';

import { trackEvent } from '../../analyticsClient';
import { signUp } from '../../api';
import { AnalyticsEvent, AnalyticsProperty } from '../../types/Analytics';
import type { SignUpSuccessResponse } from '../../types/SignUp';
import { signUpRequest, signUpValidationError } from './actions';

const signUpEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(signUpRequest.request)),
    mergeMap((action): Observable<Action> => {
      return from(signUp(action.payload)).pipe(
        map((value) => signUpRequest.success(value as SignUpSuccessResponse)),
        catchError(async (error) => {
          const status = error?.response?.status;
          const message = error?.response?.data?.defaultMessage;

          trackEvent(AnalyticsEvent.ApiError, {
            [AnalyticsProperty.ApiCallName]: 'signUp',
            [AnalyticsProperty.ApiErrorCode]: status,
            [AnalyticsProperty.ApiErrorMessage]: message,
          });

          if (status === 409) {
            return signUpValidationError(error);
          }
          return signUpRequest.failure(error);
        }),
      );
    }),
  );
};

export default combineEpics(signUpEpic);
