import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { rgba } from 'polished';
import { useState } from 'react';

import type { Color } from './theme';
import { COLORS } from './theme';

export const getColor = (color: Color, opacity?: number) => {
  const _color = COLORS && COLORS[color];
  return typeof opacity === 'undefined' || _color === undefined ? _color : rgba(_color, opacity);
};

export type ViewMode = 'desktop' | 'tablet' | 'mobile' | 'unknown';

export enum ResponsiveViewMode {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
  UNKOWN = 'unknown',
}

const getMode = (isDesktop: boolean, isTablet: boolean, isMobile: boolean): ViewMode => {
  if (isDesktop) {
    return 'desktop';
  } else if (isTablet) {
    return 'tablet';
  } else if (isMobile) {
    return 'mobile';
  } else {
    return 'unknown';
  }
};

export const useViewMode = (): ViewMode => {
  const [mode, setMode] = useState('desktop' as ViewMode);
  const {
    breakpoints: { up, between, down },
  } = useTheme();
  const isDesktop = useMediaQuery(up('md'));
  const isTablet = useMediaQuery(between('sm', 'md'));
  const isMobile = useMediaQuery(down('sm'));
  const newMode = getMode(isDesktop, isTablet, isMobile);

  if (newMode === 'unknown') {
    return mode;
  } else if (mode !== newMode) {
    setMode(newMode);
  }
  return newMode;
};
