import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ComponentProps } from 'react';
import React from 'react';

const StyledBox = styled(Box)<{ $disabled?: boolean }>`
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 'unset')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'unset')};
`;

type Props = ComponentProps<typeof Box> & { disabled?: boolean };

export const Bubble = ({ borderRadius = '8px', disabled, ...rest }: Props) => (
  <StyledBox
    borderRadius={borderRadius}
    bgcolor="background.paper"
    $disabled={disabled}
    {...rest}
  />
);
