import { Box, Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import React from 'react';
import { ifProp } from 'styled-tools';

const TableHeadTitleContainer = styled('div')<{ $inline?: boolean }>`
  ${ifProp(
    '$inline',
    css`
      display: inline-flex;
      align-items: center;
    `,
  )}
`;

interface Props {
  title: string;
  subTitle?: string;
  inline?: boolean;
}

export const TableHeadTitle = ({ title, subTitle, inline }: Props) => (
  <TableHeadTitleContainer $inline={inline}>
    <Typography
      color="textSecondary"
      sx={{
        fontSize: 11,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        lineHeight: '20px',
        letterSpacing: '0.1px',
      }}
    >
      {title}
    </Typography>
    {subTitle && (
      <Box ml={inline ? 0.5 : 0}>
        <Typography color="textSecondary" sx={{ fontSize: 11, lineHeight: '10px' }}>
          ({subTitle})
        </Typography>
      </Box>
    )}
  </TableHeadTitleContainer>
);
