import { DateTime } from 'luxon';
import { useState } from 'react';

import { DATE_TIME_FILENAME_FORMAT } from '../../constants/dates';
import { useDownloadUrl } from '../../store/api';
import { customAlert } from '../../utils/alerts';
import { CsvDownloadIcon } from '../csvDownloadIcon';
import { PWRiconButton } from '../pwrIconButton';
import { PWRtooltip } from '../pwrTooltip';

const CLEAR_DOWNLOAD_STATE_TIMEOUT = 3000;

interface Props {
  url: string;
  filenamePrefix?: string;
  buttonTooltipText?: string;
  disabled?: boolean;
}

export const DownloadUrlButton = (props: Props) => {
  const { url, filenamePrefix = 'export', buttonTooltipText = 'Export', disabled } = props;

  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const [downloadUrl, { isLoading }] = useDownloadUrl();

  const createFilename = () => {
    return `${filenamePrefix}_${DateTime.now().toFormat(DATE_TIME_FILENAME_FORMAT)}.csv`;
  };

  const handleDownload = async () => {
    // block downloads while another download is processing
    if (isLoading || disabled) {
      return;
    }

    try {
      await downloadUrl({ url, fileName: createFilename() }).unwrap();

      setIsSuccess(true);
    } catch (error: any) {
      setIsError(true);

      customAlert(
        {
          title: `There was a problem with your export. Please contact Generac Support for help.`,
        },
        { type: 'error' },
      );
    } finally {
      setTimeout(() => {
        setIsError(false);
        setIsSuccess(false);
      }, CLEAR_DOWNLOAD_STATE_TIMEOUT);
    }
  };

  return (
    <PWRtooltip placement="left" disableHoverListener={disabled} message={buttonTooltipText}>
      <PWRiconButton disabled={disabled} onClick={handleDownload}>
        <CsvDownloadIcon isLoading={isLoading} hasError={isError} hasDownload={isSuccess} />
      </PWRiconButton>
    </PWRtooltip>
  );
};
