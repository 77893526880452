import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { ifNotProp, ifProp } from 'styled-tools';

import { getColor, up, zIndex } from '../../../styles';

export const StyledFleetSections = styled('ul')`
  list-style-type: none;
  padding: 0;
  border-left: 1px solid ${getColor('MEDIUM_GREY')};
  cursor: pointer;
  li > a {
    letter-spacing: 0.5px;
  }
  li:first-child {
    color: ${getColor('BLACK')} !important;
    font-weight: 700;
    :before {
      content: '•';
      position: absolute;
      left: 36px;
      font-size: 17px;
    }
  }
  & > li {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 13px;
    margin-bottom: 4px;
  }
`;

interface SectionProps {
  $active?: boolean;
}

export const StyledSection = styled('li')<SectionProps>`
  display: flex;
  align-items: center;
  border-radius: 0 22px 22px 0;
  a {
    text-decoration: none;
    line-height: 12px;
    color: ${getColor('MEDIUM_GREY')};
    :hover {
      color: ${getColor('BLACK')};
    }
  }
  ${ifProp(
    '$active',
    css`
      background-color: ${getColor('OFF_WHITE')};
      a {
        color: ${getColor('BLACK')};
      }
    `,
  )}
`;

export const StyledButtons = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 20px;
  padding-left: 20px;
  align-items: center;
  > button {
    margin-bottom: 17px;
  }
`;

export const StyledNav = styled('nav')`
  display: grid;
  grid-area: nav;
  height: 100vh;
	width: 220px;
  font-size: 14px;
  font-weight: 700;
  background-color: ${getColor('WHITE')};
  box-shadow: 0 0 5px ${getColor('BLACK', 0.1)};
  z-index: ${zIndex.Navbar};

  .nav-menu {
    font-size: 14px;
    list-style: none;
    text-transform: uppercase;
  }

  .nav-menu__link {
    width: 100%;
    display: inline-block;
    text-decoration: none;
    color: ${getColor('BLACK')};
    :hover {
      color: ${getColor('BLACK')};
      .nav-menu__icon {
        background-color: ${getColor('BLACK')};
      }
    }
  }

  .nav-menu {
    padding: 32px 10px 16px 0;
  }

  .nav-menu--closed {
    border-bottom: 1px solid ${getColor('LIGHT_GREY')};
    margin-bottom: 32px;
  }

  .nav-menu__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .nav-menu__icon {
		background-color: ${getColor('MEDIUM_GREY')};
    vertical-align: text-bottom;
  }

  .nav-menu__link {
    display: flex;
    color: ${getColor('MEDIUM_GREY')};
    line-height: 40px;
    padding-left: 4px;
    padding-top: 4px;
  }

  .nav-menu__link--active {
    color: ${getColor('BLACK')};
    & :first-child {
      background-color: ${getColor('OFF_WHITE')};
      .nav-menu__icon {
        background-color: ${getColor('BLACK')};
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-rows: max-content max-content auto max-content;
    grid-template-areas:
      "back"
      "menu"
      "add-site"
      "legal";
    }

    .nav-menu {
      padding-top: 0;
      margin-top: 10px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-rows: max-content max-content auto max-content;
    grid-template-areas:
      "profile"
      "menu"
      "add-site"
      "legal";

    .nav-menu {
      margin-top: 24px;
    }
  }
`;

export const HeaderContainer = styled(Box)<{ $disableBorder?: boolean }>`
  ${up('md')} {
    ${ifNotProp(
      '$disableBorder',
      css`
        border-bottom: 1px solid ${getColor('LIGHT_GREY')};
      `,
    )}
  }
`;
