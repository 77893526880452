import {
  CheckCircleOutline as CheckIcon,
  Close as CloseIcon,
  ErrorOutline as ErrorIcon,
  InfoOutlined as InfoIcon,
  WarningAmber as WarningIcon,
} from '@mui/icons-material';
import type { AlertProps } from '@mui/material';
import { Alert, AlertTitle, Box, IconButton } from '@mui/material';
import type { ReactNode } from 'react';

import { COLORS } from '../../styles';

// UX designs have added new colors for this component, they should be
// added to the global COLORS once design tokens and names are decided
const alertColors = {
  success: COLORS.ALERT_SUCCESS_BG,
  info: COLORS.ALERT_INFO_BG,
  warning: COLORS.ALERT_WARNING_BG,
  error: COLORS.ALERT_ERROR_BG,
};

export interface PWRalertProps extends AlertProps {
  title: string;
  message: ReactNode; // pass only ReactNodes with display: inline-block
  type: 'success' | 'info' | 'warning' | 'error';
  handleClose?: () => void;
}

export const PWRalert = ({ title, message, type, handleClose, action, ...rest }: PWRalertProps) => {
  const hasCloseButton = !!handleClose;
  const backgroundColor = alertColors[type];
  const color = type === 'error' ? COLORS.WHITE : COLORS.BLACK;

  return (
    <Box data-test-class={`alert-${type}`}>
      <Alert
        icon={
          <Box
            sx={{
              color,
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            {type === 'success' ? (
              <CheckIcon />
            ) : type === 'info' ? (
              <InfoIcon />
            ) : type === 'warning' ? (
              <WarningIcon />
            ) : (
              <ErrorIcon />
            )}
          </Box>
        }
        action={
          action ||
          (hasCloseButton && (
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          ))
        }
        sx={{
          color,
          backgroundColor,
          borderRadius: '8px',
        }}
        {...rest}
      >
        <AlertTitle sx={{ color: 'inherit', fontWeight: 'bold' }} data-test-class="alert-title">
          {title}
        </AlertTitle>
        <Box data-test-class="alert-message">{message}</Box>
      </Alert>
    </Box>
  );
};
