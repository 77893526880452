import { useFormikContext } from 'formik';
import type { ReactElement } from 'react';
import { useEffect, useLayoutEffect, useRef } from 'react';

import { useDebouncedEffect } from '../../hooks/useDebounceEffect';

export const DependentFormField = <V,>({
  children,
  effect,
  dependencyFields,
  delay = 800,
  defaultValue = '',
}: {
  children: ReactElement;
  dependencyFields: (keyof V & string)[];
  effect: () => void;
  delay?: number;
  defaultValue?: number | string;
}) => {
  const innerFieldName = children.props.name;
  const { getFieldMeta, setFieldValue } = useFormikContext<V>();
  const noErrors = dependencyFields.every((name) => !getFieldMeta(name).error);
  const valuesArray = dependencyFields.map((f) => getFieldMeta(f).value);
  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    // clears dependent field value when dependencies values changed
    setFieldValue(innerFieldName, defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...valuesArray, innerFieldName, setFieldValue, defaultValue]);

  useDebouncedEffect(
    () => {
      if (noErrors) {
        effect();
      }
    },
    delay,
    [noErrors, ...valuesArray],
  );

  useEffect(() => {
    // clears dependent field value on component unmount or dependencies validation errors
    return () => setFieldValue(innerFieldName, defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerFieldName, setFieldValue, noErrors]);

  return noErrors ? children : null;
};
