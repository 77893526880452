import type { SxProps } from '@mui/material';
import { Typography } from '@mui/material';

import { COLORS } from '../../styles';
import { Flex } from '../flex';
import { Icon } from '../icon';

interface SupportOnlyContainerProps {
  children: React.ReactNode;
  title?: string;
  sx?: SxProps;
}

export const SupportOnlyContainer = ({
  children,
  title = 'Support Only',
  sx = {},
}: SupportOnlyContainerProps) => {
  return (
    <Flex
      sx={{
        backgroundColor: COLORS.SUPPORT_ONLY,
        borderRadius: '16px',
        p: 3,
        flexDirection: 'column',
        position: 'relative',
        ...sx,
      }}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography
          sx={{ fontSize: '22px', fontWeight: 'bold', letterSpacing: '3px' }}
          data-test-id="support-only-container-title"
        >
          {title}
        </Typography>
        <Icon icon="brand g" />
      </Flex>
      {children}
    </Flex>
  );
};
