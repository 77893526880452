import type { AxiosError } from 'axios';
import type { Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getSiteLayout, getSiteLayoutTelemetry, putSiteLayout } from '../../api';
import { Alert } from '../../types/ScreenAlerts';
import type {
  SiteLayoutSuccessResponse,
  SiteLayoutTelemetrySuccessResponse,
} from '../../types/SiteLayout';
import { customAlert } from '../../utils/alerts';
import type { RootAction } from '..';
import type { RootState } from '../types';
import { loadSiteLayout, loadSiteLayoutTelemetry, saveSiteLayout } from './actions';

const LOAD_LAYOUT_ERROR_MESSAGE =
  'PWRfleet encountered an error while attempting to retrieve this layout, please try refreshing the page in a few moments.';
const SAVE_LAYOUT_ERROR_MESSAGE =
  'PWRfleet encountered an error while attempting to save this layout. Please stay on this page to avoid losing your unsaved work, and re-attempt the operation later by clicking the save button.';

type EpicType = Epic<RootAction, RootAction, RootState>;

const loadSiteLayoutEpic: EpicType = (action$) =>
  action$.pipe(
    filter(isActionOf(loadSiteLayout.request)),
    mergeMap((action) =>
      from(getSiteLayout(action.payload)).pipe(
        map((res) => loadSiteLayout.success(res as SiteLayoutSuccessResponse)),
        catchError(async (error: AxiosError) => {
          // do not show default error notification for Array Layouts that
          // have not been created yet
          if (error.response?.status !== 404) {
            customAlert(
              {
                title: LOAD_LAYOUT_ERROR_MESSAGE,
                closeButton: true,
              },
              { type: 'error', toastId: Alert.SiteLayoutLoadError, autoClose: false },
            );
          }
          return loadSiteLayout.failure(error);
        }),
      ),
    ),
  );

const saveSiteLayoutEpic: EpicType = (action$) =>
  action$.pipe(
    filter(isActionOf(saveSiteLayout.request)),
    mergeMap((action) =>
      from(putSiteLayout(action.payload.siteId, action.payload.body)).pipe(
        map(() => {
          customAlert(
            {
              title: 'Layout saved successfully',
            },
            { type: 'success', autoClose: 2000 },
          );
          return saveSiteLayout.success(action.payload.body);
        }),
        catchError(async (error: AxiosError) => {
          customAlert(
            {
              title: SAVE_LAYOUT_ERROR_MESSAGE,
              closeButton: true,
            },
            { type: 'error', toastId: Alert.SiteLayoutLoadError, autoClose: false },
          );
          return saveSiteLayout.failure(error);
        }),
      ),
    ),
  );

const loadSiteTelemetryEpic: EpicType = (action$) =>
  action$.pipe(
    filter(isActionOf(loadSiteLayoutTelemetry.request)),
    mergeMap((action) =>
      from(getSiteLayoutTelemetry(action.payload.body)).pipe(
        map((res) => loadSiteLayoutTelemetry.success(res as SiteLayoutTelemetrySuccessResponse)),
        catchError(async (error: AxiosError) => {
          return loadSiteLayoutTelemetry.failure(error);
        }),
      ),
    ),
  );

export default combineEpics(loadSiteLayoutEpic, saveSiteLayoutEpic, loadSiteTelemetryEpic);
