const MILLION = 1000000;
const THOUSAND = 1000;

export const SUFFIXES = {
  energy: {
    w: 'Wh',
    kw: 'kWh',
    mw: 'MWh',
    gw: 'GWh',
  },
  power: {
    w: 'W',
    kw: 'kW',
    mw: 'MW',
    gw: 'GW',
  },
};

export const toFixed = (num: number, digits = 0) => {
  return num.toLocaleString('en-US', { maximumFractionDigits: digits, minimumFractionDigits: 0 });
};

export const getEnergy = (value: number): string => {
  if (!value) {
    return '0';
  }

  switch (true) {
    case value >= MILLION * 100 || value <= -100 * MILLION:
      return Math.round(value / MILLION).toString();
    case value >= MILLION * 10 || value <= -10 * MILLION:
      return toFixed(value / MILLION, 1);
    case value >= MILLION || value <= -1 * MILLION:
      return toFixed(value / MILLION, 2);
    case value >= MILLION / 10 || value <= (-1 * MILLION) / 10:
      return Math.round(value / THOUSAND).toString();
    case value >= THOUSAND * 10 || value <= -10 * THOUSAND:
      return toFixed(value / THOUSAND, 1);
    case value >= THOUSAND || value <= -1 * THOUSAND:
      return toFixed(value / THOUSAND, 2);
    case value >= THOUSAND / 10 || value <= (-1 * THOUSAND) / 10:
      return Math.round(value).toString();
    case value >= 10 || value <= -10:
      return toFixed(value, 1);
    case value >= 1 || value <= -1:
      return toFixed(value, 2);
    // for watts -- no figures after decimal
    case value >= 0.1 || value <= -0.1:
      return Math.round(value * 1000).toString();
    case value >= 10 / THOUSAND || value <= -10 / THOUSAND:
      return toFixed(value * 1000);
    case value >= 100 / MILLION || value <= -100 / MILLION:
      return toFixed(value * 1000);
    default:
      return toFixed(value * 1000);
  }
};

export const getEnergySuffix = (value: number, mode: 'energy' | 'power'): string => {
  const { w, kw, mw, gw } = SUFFIXES[mode];
  if (!value) {
    return w;
  }

  switch (true) {
    case value >= MILLION * 100 || value <= -100 * MILLION:
      return gw;
    case value >= MILLION * 10 || value <= -10 * MILLION:
      return gw;
    case value >= MILLION || value <= -1 * MILLION:
      return gw;
    case value >= MILLION / 10 || value <= (-1 * MILLION) / 10:
      return mw;
    case value >= THOUSAND * 10 || value <= -10 * THOUSAND:
      return mw;
    case value >= THOUSAND || value <= -1 * THOUSAND:
      return mw;
    case value >= THOUSAND / 10 || value <= (-1 * THOUSAND) / 10:
      return kw;
    case value >= 10 || value <= -10:
      return kw;
    case value >= 1 || value <= -1:
      return kw;
    case value >= 0.1 || value <= -0.1:
      return w;
    case value >= 10 / THOUSAND || value <= -10 / THOUSAND:
      return w;
    case value >= 100 / MILLION || value <= -100 / MILLION:
      return w;
    default:
      return w;
  }
};

export const getEnergyString = (value: number, mode: 'energy' | 'power') =>
  `${getEnergy(value)} ${getEnergySuffix(value, mode)}`;
