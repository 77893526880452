import { Box } from '@mui/material';
import React from 'react';

import { COLORS, ThemeDimensions } from '../../styles';
import { Loader } from '../loader';

export const ContentLoader = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height={ThemeDimensions.ALERT_DETAILS_CONTENT_HEIGHT}
  >
    <Loader size={14} color={COLORS.LIGHT_ORANGE} />
  </Box>
);
