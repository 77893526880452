import type { RootState } from '../types';

export const selectedSiteSelector = (state: RootState) => state.ui.selections.site;

export const searchValueSelector = (state: RootState) => state.ui.features.search.value;

export const selectedKpisSelector = (state: RootState) => state.ui.features.kpisCard.selectedKpis;

export const usersTableSortSelector = (state: RootState) => state.ui.features.usersTable.sort;

export const companySitesCompanyIdSelector = (state: RootState) =>
  state.ui.features.companySites.companyId;

export const companySitesViewModeSelector = (state: RootState) =>
  state.ui.features.companySites.viewMode;

export const sitesTableSortModelSelector = (state: RootState) =>
  state.ui.features.companySites.sitesTable.sortModel;

export const sitesTablePaginationModelSelector = (state: RootState) =>
  state.ui.features.companySites.sitesTable.paginationModel;

export const sitesTableColumnVisibilityModelSelector = (state: RootState) =>
  state.ui.features.companySites.sitesTable.columnVisibilityModel;

export const sitesTableFilterModelSelector = (state: RootState) =>
  state.ui.features.companySites.sitesTable.filterModel;
