import CloseIcon from '@mui/icons-material/Close';
import type { BoxProps } from '@mui/material';
import { Box, IconButton, Typography } from '@mui/material';
import startCase from 'lodash/startCase';
import React from 'react';

import deviceTypeDefinitions from '../../constants/deviceTypes';
import { useFeatureToggle } from '../../hooks/useFeatureToggle';
import { COLORS } from '../../styles';
import type { AlertDetails } from '../../types/Alert';
import { FeatureToggle } from '../../types/FeatureToggle';
import { AlertSeverityIcon } from '../alertSeverityIcon';

export type Props = BoxProps & {
  onClose: () => void;
  alertDetails: AlertDetails | null;
};

export const Toolbar = ({ alertDetails, onClose, ...rest }: Props) => {
  const visitSeverityToggleEnabled = useFeatureToggle(FeatureToggle.ALERTS_VISIT_SEVERITY_FEATURE);
  const deviceTypeName = alertDetails?.deviceType
    ? deviceTypeDefinitions[alertDetails.deviceType].name
    : '';
  const visitRequiredDisplay = alertDetails?.visitRequired
    ? startCase(alertDetails?.visitRequired.toLowerCase())
    : 'Unknown';

  return (
    <Box
      sx={{
        position: 'relative',
        pt: 2,
        pb: 1,
        px: 2,
        backgroundColor: COLORS.MEDIUM_GREY,
        color: COLORS.WHITE,
        minHeight: 50,
      }}
      {...rest}
    >
      <>
        {alertDetails && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box px={4}>
              <Typography variant="subtitle2" color="inherit" fontWeight="bold">
                {deviceTypeName} {alertDetails?.deviceId}
              </Typography>
            </Box>
            <Box px={4} display="flex" alignItems="center" whiteSpace="nowrap">
              {alertDetails?.severity && (
                <Box flexShrink="0">
                  <AlertSeverityIcon
                    mr={2}
                    mt={-0.25}
                    alertSeverity={alertDetails.severity}
                    alertStatus={alertDetails.status}
                    showContainer
                  />
                </Box>
              )}
              <Typography variant="subtitle2" color="inherit" fontWeight="bold">
                {alertDetails?.status} {alertDetails?.severity} SEVERITY ALERT
              </Typography>
            </Box>
            {visitSeverityToggleEnabled && (
              <Box px={4}>
                <Typography variant="subtitle2" color="inherit" fontWeight="bold">
                  Visit Required: {visitRequiredDisplay}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Box position="absolute" right={8} top={8}>
          <IconButton onClick={onClose} size="small">
            <CloseIcon style={{ color: COLORS.WHITE }} />
          </IconButton>
        </Box>
      </>
    </Box>
  );
};
