import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import React from 'react';

import { getColor } from '../../styles';
import { Button } from '../button';
import { Modal } from '../modal';

interface Props {
  title: string;
  text?: string;
  onSubmit: () => void;
  submitButtonTitle?: string;
  onCancel: () => void;
  open: boolean;
  maxWidth?: string;
  children?: ReactNode;
  isLoading?: boolean;
}

const StyledModal = styled(Modal)<{ $maxWidth: string }>`
  & .MuiPaper-root {
    max-width: ${({ $maxWidth }) => $maxWidth} !important;
    box-shadow: 0px 8px 40px ${getColor('LIGHT_GREY', 0.5)} !important;
  }
  & .MuiDialog-paperFullScreen {
    height: auto;
  }
`;

export const ConfirmationModal = ({
  onSubmit,
  onCancel,
  title,
  open,
  text,
  children,
  submitButtonTitle = 'OK',
  maxWidth = '500px',
  isLoading,
}: Props) => {
  return (
    <StyledModal open={open} hideCloseButton $maxWidth={maxWidth} onClose={onCancel}>
      <Box p={4} display="flex" flexDirection="column">
        <Typography variant="subtitle1">{title}</Typography>
        <Box mt={2}>
          {text ? (
            <Typography variant="body1" color="textSecondary" sx={{ overflowWrap: 'anywhere' }}>
              {text}
            </Typography>
          ) : null}
          {children}
        </Box>
        <Box mt={2} display="flex" justifyContent="space-evenly">
          <Button color="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onSubmit} isLoading={isLoading} disabled={isLoading}>
            {submitButtonTitle}
          </Button>
        </Box>
      </Box>
    </StyledModal>
  );
};
