import type { SelectChangeEvent } from '@mui/material';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import type { ChangeEvent, ComponentProps } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link, useHistory } from 'react-router-dom';

import { trackEvent } from '../../analyticsClient';
import { alertCategory } from '../../constants/alertCategory';
import { LOADING_ERROR_MESSAGE, NO_ALERTS_MESSAGE } from '../../constants/dataLoadingMessages';
import deviceTypeDefinitions from '../../constants/deviceTypes';
import { useCompanyContext } from '../../hooks/useCompanyContext';
import { useFeatureToggle } from '../../hooks/useFeatureToggle';
import { useRoutes } from '../../hooks/useRoutes';
import { useTableMaxHeight } from '../../hooks/useTableMaxHeight';
import { loadCompanyAlerts } from '../../store/companyAlerts/actions';
import {
  companyAlertsCurrentPageSelector,
  companyAlertsErrorSelector,
  companyAlertsLoadingFinishedSelector,
  companyAlertsLoadingSelector,
  companyAlertsPerPageSelector,
  companyAlertsSelector,
  companyTotalAlertsSelector,
} from '../../store/companyAlerts/selectors';
import { ThemeDimensions } from '../../styles';
import type { CompanyAlert } from '../../types/Alert';
import { AlertsTab } from '../../types/Alert';
import { AnalyticsEvent, AnalyticsProperty } from '../../types/Analytics';
import { FeatureToggle } from '../../types/FeatureToggle';
import type { RowComponentProps, TableColumn } from '../../types/NewTable';
import { AlertSeverityIcon } from '../alertSeverityIcon';
import { globalStyleScreenBlur } from '../globalStyleScreenBlur';
import { NewTable } from '../newTable';
import { PWRtooltip } from '../pwrTooltip';
import { TimeagoTooltip } from '../timeagoTooltip';

const defaultSort = 'openedAt:desc';

const OFFSET_BOTTOM = ThemeDimensions.TABLE_PAGINATION_HEIGHT + 16;
const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

const TextContainer = styled('div')<{ $maxWidth?: number }>`
  max-width: ${({ $maxWidth = 100 }) => $maxWidth}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

enum TableColumnId {
  SITE_NAME = 'siteName',
  DEVICE_TYPE = 'deviceType',
  DEVICE_ID = 'deviceId',
  SEVERITY = 'severity',
  NAME = 'name',
  CATEGORY = 'category',
  OPENED_AT = 'openedAt',
  CITY = 'city',
  STATE = 'state',
  VISIT_SEVERITY = 'visitSeverity',
}

const columns: TableColumn<CompanyAlert>[] = [
  { id: TableColumnId.SITE_NAME, label: 'Site Name' },
  { id: TableColumnId.DEVICE_TYPE, label: 'Device' },
  { id: TableColumnId.DEVICE_ID, label: 'RCP Number' },
  { id: TableColumnId.SEVERITY, label: '' },
  { id: TableColumnId.NAME, label: 'Alert Name' },
  { id: TableColumnId.CATEGORY, label: 'Category' },
  { id: TableColumnId.OPENED_AT, label: 'Duration' },
  { id: TableColumnId.CITY, label: 'City' },
  { id: TableColumnId.STATE, label: 'State/PR.' },
];

const visitSeverityColumn = { id: TableColumnId.VISIT_SEVERITY, label: 'Visit Required' };

const CenteredBox = (props: ComponentProps<typeof Box>) => (
  <Box display="flex" alignItems="center" justifyContent="center" {...props} />
);

const RowComponent = ({ row, index, TableRowProps }: RowComponentProps<CompanyAlert>) => {
  const { companyId = '' } = useCompanyContext();
  const Routes = useRoutes();
  const visitSeverityToggleEnabled = useFeatureToggle(FeatureToggle.ALERTS_VISIT_SEVERITY_FEATURE);

  const labelId = `table-checkbox-${index}`;
  const {
    siteName,
    deviceType,
    name,
    severity,
    status,
    category,
    deviceId,
    openedAt,
    address,
    visitRequired,
  } = row;
  const deviceTypeName =
    deviceType && deviceTypeDefinitions[deviceType] ? deviceTypeDefinitions[deviceType].name : '--';
  const alertCategoryName = alertCategory[category] ? alertCategory[category].name : '--';
  const visitRequiredDisplay = visitRequired ? startCase(visitRequired.toLowerCase()) : '--';
  const tooltipMessage = severity ? `${startCase(severity.toLowerCase())} Severity` : '';

  return (
    <TableRow {...TableRowProps} role="checkbox">
      {/* Disabled select row checkbox
      <TableCell padding="checkbox">
        <Checkbox
          color="default"
          size="small"
          checked={TableRowProps.selected}
          inputProps={{ "aria-labelledby": labelId }}
        />
      </TableCell> */}
      <TableCell id={labelId} scope="row">
        <TextContainer $maxWidth={180}>
          {siteName ? (
            <StyledLink
              to={generatePath(Routes.SiteAlerts, {
                companyId,
                siteId: row.siteId,
                tab: AlertsTab.ACTIVE,
              })}
              onClick={(e) => e.stopPropagation()}
            >
              {siteName}
            </StyledLink>
          ) : (
            '--'
          )}
        </TextContainer>
      </TableCell>
      <TableCell>
        <Box minWidth={70}>{deviceTypeName}</Box>
      </TableCell>
      <TableCell>{deviceId || '--'}</TableCell>
      <TableCell style={{ width: 30, paddingRight: 0 }}>
        <CenteredBox>
          {severity && status ? (
            <PWRtooltip message={tooltipMessage}>
              <AlertSeverityIcon alertSeverity={severity} alertStatus={status} />
            </PWRtooltip>
          ) : (
            '--'
          )}
        </CenteredBox>
      </TableCell>
      <TableCell>{name || '--'}</TableCell>
      <TableCell>{alertCategoryName}</TableCell>
      <TableCell>
        {openedAt && address?.timezone ? (
          <TimeagoTooltip
            timestamp={openedAt * 1000}
            timezone={address?.timezone}
            typographyProps={{ variant: 'body2' }}
            withoutAgoSuffix={true}
          />
        ) : (
          '--'
        )}
      </TableCell>
      <TableCell>{address?.city || '--'}</TableCell>
      <TableCell>{address?.state || '--'}</TableCell>
      {visitSeverityToggleEnabled && <TableCell>{visitRequiredDisplay}</TableCell>}
    </TableRow>
  );
};

type Props = {
  activeAlertId?: string;
  selectedTab: AlertsTab;
  selectedAlerts: string[];
  // setSelectedAlerts: (ids: string[]) => void;
  onSiteIdSelect: (siteId: string) => void;
};

export const CompanyAlertsTable = ({
  activeAlertId,
  selectedAlerts,
  // setSelectedAlerts,
  onSiteIdSelect,
  selectedTab,
}: Props) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { companyId = '' } = useCompanyContext();
  const Routes = useRoutes();
  const visitSeverityToggleEnabled = useFeatureToggle(FeatureToggle.ALERTS_VISIT_SEVERITY_FEATURE);
  const tableMaxHeight = useTableMaxHeight({ tableRef, offsetBottom: OFFSET_BOTTOM });
  const isLoading = useSelector(companyAlertsLoadingSelector);
  const loadingFinished = useSelector(companyAlertsLoadingFinishedSelector);
  const hasError = useSelector(companyAlertsErrorSelector);
  const alerts = useSelector(companyAlertsSelector);
  const page = useSelector(companyAlertsCurrentPageSelector);
  const count = useSelector(companyTotalAlertsSelector);
  const rowsPerPage = useSelector(companyAlertsPerPageSelector);

  const handlePageChange = (e: ChangeEvent<unknown>, page: number) => {
    dispatch(
      loadCompanyAlerts.request({ companyId, page, perPage: rowsPerPage, sort: defaultSort }),
    );
  };

  // const handleRequestSort = (e: MouseEvent<HTMLElement>, property: string, sortBy?: PropertySelector<CompanyAlert>) => {
  //   dispatch(setCompanyAlertsOrderBy({ property, sortBy }));
  // };

  const handleRowsPerPageChange = (event: SelectChangeEvent<unknown>, perPage: number) => {
    dispatch(loadCompanyAlerts.request({ companyId, page: 1, perPage, sort: defaultSort }));
  };

  // const handleRowSelect = useCallback(
  //   (ids: string[]) => {
  //     setSelectedAlerts(ids);
  //   },
  //   [setSelectedAlerts],
  // );

  const handleRowClick = ({ alertId, siteId }: CompanyAlert) => {
    onSiteIdSelect(siteId);
    trackEvent(AnalyticsEvent.AlertDetailsClick, {
      [AnalyticsProperty.AlertId]: alertId,
      [AnalyticsProperty.SiteId]: siteId,
    });
    history.push(generatePath(Routes.CompanyAlerts, { companyId, tab: selectedTab, alertId }));
  };

  const formattedColumns = [...columns];
  if (visitSeverityToggleEnabled) {
    formattedColumns.push(visitSeverityColumn);
  }

  return (
    <Box overflow="visible">
      <div ref={tableRef}>
        {isLoading && !loadingFinished && globalStyleScreenBlur}
        {!isLoading && loadingFinished && isEmpty(alerts) ? (
          <Typography variant="body1" color="textSecondary">
            {hasError ? LOADING_ERROR_MESSAGE : NO_ALERTS_MESSAGE}
          </Typography>
        ) : (
          <NewTable
            activeId={activeAlertId}
            rows={alerts}
            columns={formattedColumns}
            selectedIds={selectedAlerts}
            // Disabled the ability to select a row.
            // onRowSelect={handleRowSelect}
            // Disabled the checkbox for selecting all rows.
            // allowSelectAll
            stickyHeader
            containerMaxHeight={tableMaxHeight}
            onRowClick={handleRowClick}
            HeadProps={{
              align: 'left',
            }}
            PaginationProps={{
              onRowsPerPageChange: handleRowsPerPageChange,
              count,
              onChange: handlePageChange,
              page,
              rowsPerPage,
              rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
            }}
            RowComponent={RowComponent}
            idExtractor="alertId"
          />
        )}
      </div>
    </Box>
  );
};
