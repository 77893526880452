import { Box, CircularProgress } from '@mui/material';

import { COLORS } from '../../styles';
import { Icon } from '../icon';

interface CsvDownloadIconProps {
  isLoading: boolean;
  hasError: boolean;
  hasDownload: boolean;
}

/*
 * This component can be passed to a Mui IconButton's icon prop and is handy passing through the deeply nested props of the <Card /> component, which requires
 * the separation of icon and event handler, the positioning of loader will break if ever wrapped in position: static element, and probably most other scenarios
 */

export const CsvDownloadIcon = ({ isLoading, hasError, hasDownload }: CsvDownloadIconProps) => (
  <>
    {isLoading && <CircularProgress size={44} sx={{ position: 'absolute' }} />}
    <Box
      sx={{
        borderRadius: '50%',
        backgroundColor: hasError ? COLORS.ERROR : hasDownload ? COLORS.GREEN : 'unset',
      }}
    >
      <Box
        sx={{
          width: 44,
          height: 44,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Icon icon={hasError ? 'failed' : hasDownload ? 'complete' : 'export csv'} size={20} />
      </Box>
    </Box>
  </>
);
