import type { Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getFleetMapSites } from '../../api';
import { loadFleetMapSites } from './actions';

const loadFleetMapSitesEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(loadFleetMapSites.request)),
    mergeMap((action) => {
      return from(getFleetMapSites(action.payload)).pipe(
        map(loadFleetMapSites.success),
        catchError(async (e) => loadFleetMapSites.failure(e)),
      );
    }),
  );

export default combineEpics(loadFleetMapSitesEpic);
