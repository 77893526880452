import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type { CompanyAlert, CompanyAlertsFailedResponse } from '../../types/Alert';
import { SortOrder } from '../../utils/sort';
import * as actions from './actions';

export interface CompanyAlertsState {
  items: CompanyAlert[];
  loading: boolean;
  loadingFinished: boolean;
  error: CompanyAlertsFailedResponse | null;
  page: number;
  perPage: number;
  total: number;
  order: SortOrder;
  orderBy: string;
  itemsCopy: CompanyAlert[];
}

const INITIAL_STATE: CompanyAlertsState = {
  items: [],
  loading: false,
  loadingFinished: false,
  error: null,
  page: 1,
  perPage: 25,
  total: 0,
  order: SortOrder.DESC,
  orderBy: 'updatedAt',
  itemsCopy: [],
};

export type CompanyAlertsActions = ActionType<typeof actions>;

export default createReducer<CompanyAlertsState, CompanyAlertsActions>(INITIAL_STATE, {
  [getType(actions.loadCompanyAlerts.request)]: produce(
    (state: CompanyAlertsState, action: ReturnType<typeof actions.loadCompanyAlerts.request>) => {
      state.loading = true;
      state.loadingFinished = false;
      state.error = null;
      state.page = action.payload.page;
    },
  ),
  [getType(actions.loadCompanyAlerts.success)]: produce(
    (state: CompanyAlertsState, action: ReturnType<typeof actions.loadCompanyAlerts.success>) => {
      const { alerts, page, perPage, totalResults } = action.payload;
      state.items = alerts;
      state.itemsCopy = state.items;
      state.page = page;
      state.perPage = perPage;
      state.total = totalResults;
      state.loading = false;
      state.loadingFinished = true;
    },
  ),
  [getType(actions.loadCompanyAlerts.failure)]: produce(
    (state: CompanyAlertsState, action: ReturnType<typeof actions.loadCompanyAlerts.failure>) => {
      state.loading = false;
      state.loadingFinished = true;
      state.error = action.payload;
    },
  ),
});
