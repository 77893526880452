import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import React from 'react';

import { getColor } from '../../styles';

const StyledScrollableContainer = styled(Box)`
  ::-webkit-scrollbar-track {
    background-color: ${getColor('WHITE')};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: ${getColor('WHITE')};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${getColor('LIGHT_GREY')};
    border-radius: 3px;
  }
`;

type Props = BoxProps & {
  children: ReactNode;
};

export const ScrollableContainer = ({ children, ...boxProps }: Props) => {
  return (
    <StyledScrollableContainer overflow="auto" {...boxProps}>
      {children}
    </StyledScrollableContainer>
  );
};
