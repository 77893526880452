import { useSelector } from 'react-redux';

import { companyLoadingSelector } from '../../store/company/selectors';
import { sitesLoadingSelector } from '../../store/sites/selectors';
import { globalStyleScreenBlur } from '../globalStyleScreenBlur';

export const SitesScreenLoader = () => {
  const isLoadingSites = useSelector(sitesLoadingSelector);
  const isLoadingCompany = useSelector(companyLoadingSelector);

  if (!isLoadingSites && !isLoadingCompany) {
    return null;
  }

  return globalStyleScreenBlur;
};
