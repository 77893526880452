import type {
  DeviceChartDataRequest,
  DeviceChartDataResponse,
} from '../../../types/api/getDeviceChartData';
import type { LoadManagerConfig } from '../../../types/api/getLoadManagerConfigs';
import type { LoadManager } from '../../../types/api/getLoadManagerSummary';
import type { RemoveSystemDeviceArgs } from '../../../types/api/removeSystemDevice';
import type { DeviceStatus, DeviceStatusRequest } from '../../../types/DeviceStatus';
import type { LoadManagerPairRequest } from '../../../types/LoadManager';
import { api } from '..';
import { getLoadManagerConfigsQueryFn } from '../queryFunctions';

const deviceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDeviceStatus: builder.query<DeviceStatus, DeviceStatusRequest>({
      query: ({ siteId, deviceId }) => `/sites/v1/${siteId}/devices/${deviceId}/status`,
    }),
    getDeviceChartData: builder.query<DeviceChartDataResponse, DeviceChartDataRequest>({
      query: ({ urlParams, body }) => ({
        method: 'POST',
        url: `/pwrfleet-ui/v1/sites/${urlParams.siteId}/systems/${urlParams.systemId}/devices/${urlParams.deviceRcpn}/metrics`,
        body,
      }),
    }),
    removeDevice: builder.mutation<void, RemoveSystemDeviceArgs>({
      query: ({ siteId, systemId, type, deviceId }) => ({
        method: 'DELETE',
        url: `/pwrfleet-ui/v1/sites/${siteId}/systems/${systemId}/devices/${type}/${deviceId}`,
      }),
      invalidatesTags: (_result, _meta, args) => [{ type: 'SiteDevices', id: args.siteId }],
    }),
    getLoadManagerConfigs: builder.query<LoadManagerConfig[], { siteId: string }>({
      queryFn: getLoadManagerConfigsQueryFn,
    }),
    getLoadManagerSummary: builder.query<LoadManager, { systemId: string }>({
      query: ({ systemId }) => ({
        url: `/loadcontroller/v1/systems/summary`,
        params: { systemId },
      }),
    }), // returns 400 for unpaired load managers
    pairLoadManager: builder.mutation<void, LoadManagerPairRequest>({
      query: ({ systemId, beaconRcpn, inverterRcpn }) => ({
        method: 'POST',
        url: `/loadcontroller/v1/systems/${systemId}/essConfig`,
        body: { beaconRcpn, inverterRcpn },
      }),
    }),
    updateLoadManagerController: builder.mutation<unknown, { systemId: string; rcpNumber: string }>(
      {
        query: ({ systemId, rcpNumber }) => ({
          method: 'POST',
          url: `/loadcontroller/v1/systems/${systemId}/submodules`,
          body: {
            submodules: [
              {
                rcpNumber,
                deviceType: 'MCU',
              },
            ],
          },
        }),
      },
    ),
    requestLoadManagerLogs: builder.mutation<{ jobId: string }, { deviceId: string }>({
      query: ({ deviceId }) => ({
        method: 'POST',
        url: '/loadcontroller/v1/operators/job',
        body: { deviceId, jobType: 'FETCH_LOG' },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDeviceStatusQuery: useGetDeviceStatus,
  useGetDeviceChartDataQuery: useGetDeviceChartData,
  useRemoveDeviceMutation: useRemoveDevice,
  useGetLoadManagerConfigsQuery: useGetLoadManagerConfigs,
  useLazyGetLoadManagerConfigsQuery: useLazyGetLoadManagerConfigs,
  useGetLoadManagerSummaryQuery: useGetLoadManagerSummary,
  usePairLoadManagerMutation: usePairLoadManager,
  useUpdateLoadManagerControllerMutation: useUpdateLoadManagerController,
  useRequestLoadManagerLogsMutation: useRequestLoadManagerLogs,
} = deviceApi;
