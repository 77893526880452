import { Badge, Box, Link, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { useGetSiteStatus } from '../../store/api/endpoints/site';
import type { DeviceStatus } from '../../types/SiteDetails';
import { DeviceStatusName, SystemStatusName } from '../../types/SiteDetails';
import { UIFeature } from '../../types/UIFeature';
import { getSystemStatusIcon, getSystemStatusText, valueToHexString } from '../../utils';
import { GatedFeature } from '../gatedFeature';
import { Icon } from '../icon';
import type { IconType } from '../icon/icons';

const typographySx = { fontWeight: 'bold', textTransform: 'uppercase' };

interface DeviceIconProps {
  deviceName: IconType;
  deviceStatus: DeviceStatus;
  isSystemDisconnected: boolean;
}

const DeviceIcon = ({ deviceName, deviceStatus, isSystemDisconnected }: DeviceIconProps) => {
  const { statusName, unhealthyDeviceCount } = deviceStatus;

  let icon = deviceName;
  let badgeContent = 0; // results in no content
  if (isSystemDisconnected || statusName === DeviceStatusName.DISCONNECTED) {
    icon = `${icon} disconnected` as IconType;
  } else if (statusName === DeviceStatusName.ERROR || !statusName) {
    icon = `${icon} error` as IconType;
    badgeContent = unhealthyDeviceCount;
  }

  return (
    <Badge badgeContent={badgeContent} sx={{ mr: 0.5 }}>
      <Icon icon={icon} />
    </Badge>
  );
};

interface CapabilitiesProps {
  siteId: string;
}

export const Capabilities = ({ siteId }: CapabilitiesProps) => {
  const { data: siteStatusData, isError: hasError, isFetching } = useGetSiteStatus({ siteId });
  const systems = siteStatusData?.systems ?? [];

  return !isFetching && !isEmpty(systems) ? (
    <Box
      data-test-id="capability-container"
      sx={{ pb: 3, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
    >
      {systems.map((system, index) => {
        const {
          systemId,
          statusName,
          sysMode,
          serialNumber,
          inverter,
          battery,
          rgm,
          pvl,
          generator,
          loadController,
        } = system;
        const isSystemDisconnected = statusName === SystemStatusName.DISCONNECTED;

        const systemName = `System ${serialNumber ?? index + 1}`;
        const systemStatusText = getSystemStatusText(system, hasError);
        const systemStatusIcon = getSystemStatusIcon(system, hasError);

        return (
          <Box sx={{ mb: 2, mr: 4, '&:last-child': { mr: 0 } }} key={systemId}>
            {!hasError && (
              <Box sx={{ display: 'inline-flex', pb: 0.5 }}>
                {!!rgm && (
                  <DeviceIcon
                    deviceName="rgm"
                    deviceStatus={rgm}
                    isSystemDisconnected={isSystemDisconnected}
                  />
                )}
                {!!loadController && (
                  <DeviceIcon
                    deviceName="load manager"
                    deviceStatus={loadController}
                    isSystemDisconnected={isSystemDisconnected}
                  />
                )}
                {!!pvl && (
                  <DeviceIcon
                    deviceName="solar"
                    deviceStatus={pvl}
                    isSystemDisconnected={isSystemDisconnected}
                  />
                )}
                {!!battery && (
                  <DeviceIcon
                    deviceName="battery"
                    deviceStatus={battery}
                    isSystemDisconnected={isSystemDisconnected}
                  />
                )}
                {!!generator && (
                  <DeviceIcon
                    deviceName="generator"
                    deviceStatus={generator}
                    isSystemDisconnected={isSystemDisconnected}
                  />
                )}
              </Box>
            )}
            <Box>
              <GatedFeature
                uiFeature={UIFeature.SUPPORT_VIEW}
                onAllowed={() => (
                  <Link
                    href={`${process.env.REACT_APP_PWRTOOLS_URL}/systems/dashboard?serial_number=${serialNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="inherit"
                  >
                    <Typography
                      variant="subtitle2"
                      lineHeight="100%"
                      data-test-class="capabilities-system-name"
                    >
                      {systemName}
                    </Typography>
                  </Link>
                )}
                onDisallowed={() => <Typography variant="subtitle2">{systemName}</Typography>}
              />
            </Box>
            <Box display="flex" alignItems="center" mt={1.5}>
              {inverter && (
                <Box mr={0.5}>
                  <Typography variant="body2" data-test-id={`status-code-${serialNumber}`}>
                    {valueToHexString(inverter.statusCode)}
                  </Typography>
                </Box>
              )}
              <Box pr={1}>
                <Typography
                  variant="body2"
                  sx={typographySx}
                  data-test-id={`system-status-${serialNumber}`}
                >
                  {systemStatusText}
                </Typography>
              </Box>
              {!!systemStatusIcon && <Icon size={13} icon={systemStatusIcon} />}
            </Box>
            {!!sysMode && (
              <Box mt={1}>
                <Typography
                  variant="body2"
                  sx={typographySx}
                  data-test-id={`system-mode-${serialNumber}`}
                >
                  {sysMode.modeName}
                </Typography>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  ) : null;
};
