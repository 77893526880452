import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../types';

const alertsRawSelector = (state: RootState) => state.siteAlerts.items;
export const alertsSelector = createSelector(alertsRawSelector, (alerts) =>
  alerts.map(({ transitionedAt, openedAt, ...rest }) => ({
    ...rest,
    // mapping timestamps seconds to milliseconds
    transitionedAt: transitionedAt * 1000,
    openedAt: openedAt * 1000,
  })),
);
export const errorSelector = (state: RootState) => state.siteAlerts.error;
export const loadingSelector = (state: RootState) => state.siteAlerts.loading;

export const siteAlertsCurrentPageSelector = (state: RootState) => state.siteAlerts.page || 0;
export const siteTotalAlertsSelector = (state: RootState) => state.siteAlerts.totalResults || 0;
export const siteAlertsPerPageSelector = (state: RootState) => state.siteAlerts.perPage || 10;
