import { GlobalStyles } from '@mui/material';
import React from 'react';

import { screenLoaderId } from '../../constants/screenLoader';

const css = `
  #screen {
    filter: blur(14px);
  }
  #${screenLoaderId} {
    display: flex;
  }
`;

export const globalStyleScreenBlur = <GlobalStyles styles={css} />;
