import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import React, { forwardRef } from 'react';
import { ifProp, switchProp } from 'styled-tools';

import { COLORS } from '../../styles';
import { AlertSeverity, AlertStatus } from '../../types/Alert';

const DEFAULT_SIZE = 17;
const DEFAULT_BORDER_WIDTH = 2;

const StyledBoxContainer = styled(Box)<{ $size: number; $showContainer: boolean }>`
  width: ${({ $size }) => `${$size}px`};
  height: ${({ $size }) => `${$size}px`};
  border-radius: 50%;
  background-color: ${ifProp('$showContainer', COLORS.WHITE, 'transparent')};
`;

const StyledIcon = styled('div')<{
  $alertSeverity: AlertSeverity;
  $alertStatus: AlertStatus;
  $borderWidth: number;
  $size: number;
}>`
  width: ${({ $size }) => `${$size}px`};
  height: ${({ $size }) => `${$size}px`};
  border-radius: 50%;
  border-width: ${({ $borderWidth }) => `${$borderWidth}px`};
  border-style: solid;
  border-color: ${switchProp('$alertSeverity', {
    [AlertSeverity.HIGH]: COLORS.ALERT_HIGH,
    [AlertSeverity.MEDIUM]: COLORS.ALERT_MEDIUM,
    [AlertSeverity.LOW]: COLORS.ALERT_LOW,
  })};

  ${switchProp('$alertStatus', {
    [AlertStatus.ACTIVE]: css`
      background-color: ${switchProp('$alertSeverity', {
        [AlertSeverity.HIGH]: COLORS.ALERT_HIGH,
        [AlertSeverity.MEDIUM]: COLORS.ALERT_MEDIUM,
        [AlertSeverity.LOW]: COLORS.ALERT_LOW,
      })};
    `,
    [AlertStatus.RESOLVED]: css`
      background-color: 'transparent';
    `,
  })};
`;

export type Props = BoxProps & {
  alertSeverity: AlertSeverity;
  alertStatus: AlertStatus;
  showContainer?: boolean;
  size?: number;
};

export const AlertSeverityIcon = forwardRef<typeof Box, Props>((props: Props, ref) => {
  const { alertSeverity, alertStatus, showContainer = false, size = DEFAULT_SIZE, ...rest } = props;

  return (
    <StyledBoxContainer
      ref={ref}
      $showContainer={showContainer}
      $size={size}
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <StyledIcon
        $alertSeverity={alertSeverity}
        $alertStatus={alertStatus}
        $borderWidth={showContainer ? DEFAULT_BORDER_WIDTH + 1 : DEFAULT_BORDER_WIDTH}
        $size={size - 4}
      />
    </StyledBoxContainer>
  );
});
