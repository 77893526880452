import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { FieldProps, FormikProps } from 'formik';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';

import COUNTRIES from '../../constants/countries';
import { DEALER_ID_VALIDATION_MESSAGE } from '../../constants/validation';
import { useCompanyContext } from '../../hooks/useCompanyContext';
import { userIdSelector } from '../../store/auth/selectors';
import { loadCompany, updateCompany } from '../../store/company/actions';
import { selectedCompanySelector } from '../../store/company/selectors';
import { UIFeature } from '../../types/UIFeature';
import { REGEX_DEALER_ID, REGEX_PHONE_NUMBER, REGEX_ZIP } from '../../utils';
import { getMinMessage, getRequiredMessage, validateState } from '../../utils/forms';
import { Button } from '../button';
import { FormContentBlock, FormHeader, FormItem } from '../form';
import { FormDiscardAlert } from '../formDiscardAlert';
import { GatedFeature } from '../gatedFeature';
import { Icon } from '../icon';
import { Input } from '../input';
import { Select } from '../select';

const CDSchema = object().shape({
  name: string()
    .required(getRequiredMessage('company name'))
    .min(3, ({ min }) => getMinMessage('Company name', min)),
  address: string()
    .required(getRequiredMessage('company address'))
    .min(3, ({ min }) => getMinMessage('Company address', min)),
  city: string()
    .required(getRequiredMessage('company city'))
    .min(2, ({ min }) => getMinMessage('Company city', min)),
  country: string()
    .required(getRequiredMessage('company country'))
    .min(2, ({ min }) => getMinMessage('Company country', min)),
  zipCode: string()
    .required(getRequiredMessage('company zip or postal code'))
    .min(3, ({ min }) => getMinMessage('Company zip or postal code', min))
    .matches(REGEX_ZIP, 'Please enter a valid ZIP or postal code'),
  phone: string()
    .required(getRequiredMessage('area code and phone number'))
    .min(10, ({ min }) => getMinMessage('Area code and phone number', min))
    .matches(REGEX_PHONE_NUMBER, 'Please use a valid 10+ digits phone number'),
  companyDealerId: string().notRequired().matches(REGEX_DEALER_ID, DEALER_ID_VALIDATION_MESSAGE),
});

const StyledSelect = styled(Select)`
  width: 250px;
`;

type FormValue = {
  name: string;
  phone: string;
  address: string;
  city: string;
  country: string;
  state: string;
  zipCode: string;
  companyDealerId: string;
};

export const CompanyDetailsForm = () => {
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<FormValue>>(null);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [discardAlertIsOpen, setDiscardAlertIsOpen] = useState<boolean>(false);

  const userId = useSelector(userIdSelector);
  const { companyId } = useCompanyContext();
  const company = useSelector(selectedCompanySelector);

  // --

  // initial load of the company data
  useEffect(() => {
    if (companyId) {
      dispatch(loadCompany.request(companyId));
    }
  }, [dispatch, userId, companyId]);

  // resets form every time isEditing changes
  useEffect(() => {
    formRef?.current?.resetForm();
  }, [formRef, isEditing]);

  // --

  const handleDiscardAlert = useCallback(() => {
    const current = formRef?.current || { touched: {} };
    const isTouched = Object.keys(current.touched).length > 0;
    if (isTouched) {
      setDiscardAlertIsOpen(!discardAlertIsOpen);
    } else {
      setIsEditing(false);
    }
  }, [discardAlertIsOpen, formRef]);

  const onSubmit = (values: FormValue) => {
    if (company) {
      const { address, companyId } = company;
      const body = {
        name: values.name,
        phone: values.phone,
        address: values.address,
        city: values.city,
        country: values.country,
        state: values.state,
        zipcode: values.zipCode,
        latitude: Number(address?.latitude || 0),
        longitude: Number(address?.longitude || 0),
        companyDealerId: values.companyDealerId ? Number(values.companyDealerId) : undefined,
      };
      dispatch(updateCompany.request({ companyId, body }));
      setIsEditing(false);
    }
  };

  // --

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={{
        name: company?.name || '',
        phone: company?.phone || '',
        address: company?.address?.address || '',
        city: company?.address?.city || '',
        country: company?.address?.countryCode || '',
        state: company?.address?.state || '',
        zipCode: company?.address?.zipCode || '',
        companyDealerId: company?.companyDealerId?.toString() || '',
      }}
      onSubmit={onSubmit}
      validationSchema={CDSchema}
    >
      {(props) => {
        const { values } = props;
        const country = COUNTRIES.find((country) => country.value === values.country);
        const states = country ? country.states : [];
        const handleDiscardForm = () => {
          setIsEditing(false);
          setDiscardAlertIsOpen(false);
          props.resetForm();
        };
        return (
          <Form>
            <FormDiscardAlert
              open={discardAlertIsOpen}
              onClose={() => setDiscardAlertIsOpen(false)}
              onDiscard={handleDiscardForm}
            />
            <FormHeader title="Contact information">
              {isEditing ? (
                <Button
                  color="secondary"
                  size="small"
                  onClick={handleDiscardAlert}
                  data-test-id="company-details-cancel-button"
                >
                  Cancel
                </Button>
              ) : (
                <GatedFeature
                  uiFeature={UIFeature.COMPANY_EDIT}
                  onAllowed={() => (
                    <Button
                      color="secondary"
                      size="small"
                      onClick={() => setIsEditing(true)}
                      data-test-id="company-details-edit-button"
                    >
                      Edit
                    </Button>
                  )}
                />
              )}
            </FormHeader>
            {isEditing ? (
              <FormContentBlock isEditing>
                <FormItem input>
                  <Field name="name">
                    {({ field, form, meta }: FieldProps) => (
                      <Input
                        {...field}
                        fullWidth
                        label="Name"
                        error={meta.touched && meta.error ? Boolean(form.errors.name) : false}
                        helperText={
                          meta.touched && meta.error && form.errors.name
                            ? String(form.errors.name)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <FormItem input>
                  <Field name="address">
                    {({ field, form, meta }: FieldProps) => (
                      <Input
                        {...field}
                        fullWidth
                        label="Address line"
                        error={meta.touched && meta.error ? Boolean(form.errors.address) : false}
                        helperText={
                          meta.touched && meta.error && form.errors.address
                            ? String(form.errors.address)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <FormItem input>
                  <Field name="city">
                    {({ field, form, meta }: FieldProps) => (
                      <Input
                        {...field}
                        fullWidth
                        label="City"
                        error={meta.touched && meta.error ? Boolean(form.errors.city) : false}
                        helperText={
                          meta.touched && meta.error && form.errors.city
                            ? String(form.errors.city)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <FormItem input>
                  <Field name="country">
                    {({ field, form, meta }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        id="country"
                        options={[{ value: '', label: <em>None</em> }, ...COUNTRIES]}
                        label="Country"
                        onChange={(e) => {
                          form.setFieldValue(e.target.name as string, e.target.value);
                          form.setFieldValue('state', '', true);
                        }}
                        error={meta.touched && meta.error ? Boolean(form.errors.country) : false}
                        helperText={
                          meta.touched && meta.error && form.errors.country
                            ? String(form.errors.country)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <FormItem input>
                  <Field
                    name="state"
                    validate={(value: string) =>
                      validateState(value, states, 'site state or province')
                    }
                  >
                    {({ field, form, meta }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        id="state"
                        options={[{ value: '', label: <em>None</em> }, ...states]}
                        label="State or province"
                        error={meta.touched && meta.error ? Boolean(form.errors.state) : false}
                        helperText={
                          meta.touched && meta.error && form.errors.state
                            ? String(form.errors.state)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <FormItem input>
                  <Field name="zipCode">
                    {({ field, form, meta }: FieldProps) => (
                      <Input
                        {...field}
                        fullWidth
                        label="Zip or postal code"
                        error={meta.touched && meta.error ? Boolean(form.errors.zipCode) : false}
                        helperText={
                          meta.touched && meta.error && form.errors.zipCode
                            ? String(form.errors.zipCode)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <FormItem input>
                  <Field name="phone">
                    {({ field, form, meta }: FieldProps) => (
                      <Input
                        {...field}
                        fullWidth
                        label="Phone"
                        error={meta.touched && meta.error ? Boolean(form.errors.phone) : false}
                        helperText={
                          meta.touched && meta.error && form.errors.phone
                            ? String(form.errors.phone)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <FormItem input>
                  <Field name="companyDealerId">
                    {({ field, form, meta }: FieldProps) => (
                      <Input
                        {...field}
                        fullWidth
                        label="Generac Dealer ID (Optional)"
                        error={
                          meta.touched && meta.error ? Boolean(form.errors.companyDealerId) : false
                        }
                        helperText={
                          meta.touched && meta.error && form.errors.companyDealerId
                            ? String(form.errors.companyDealerId)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <Button
                  width={200}
                  color="primary"
                  size="small"
                  type="submit"
                  data-test-id="company-details-save-button"
                >
                  Save
                </Button>
              </FormContentBlock>
            ) : (
              <FormContentBlock>
                <FormItem title="Name">
                  <Typography data-test-id="company-details-name">{props.values.name}</Typography>
                </FormItem>
                <FormItem title="Address">
                  <div data-test-id="company-details-address">
                    {Boolean(props.values.address) ? (
                      <>
                        <Typography>{props.values.address}</Typography>
                        <Typography>
                          {props.values.city}, {props.values.state}
                        </Typography>
                        <Typography>{props.values.zipCode}</Typography>
                        <Typography>{props.values.country}</Typography>
                      </>
                    ) : (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        onClick={() => setIsEditing(true)}
                        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                      >
                        Please enter your company address
                      </Typography>
                    )}
                  </div>
                </FormItem>
                <FormItem title="Phone number">
                  <Box display="flex">
                    <Box mr={1}>
                      <Typography data-test-id="company-details-phone">
                        {props.values.phone}
                      </Typography>
                    </Box>
                    <Icon icon="phone" size={20} />
                  </Box>
                </FormItem>
                <FormItem title="Generac Dealer ID">
                  <Typography data-test-id="company-details-dealer-id">
                    {props.values.companyDealerId}
                  </Typography>
                </FormItem>
              </FormContentBlock>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
