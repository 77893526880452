import { GeneratorStatus } from '../types/SiteDevices';

interface Definition {
  name: string;
}

export const GeneratorStatusDefinitions: { [key in GeneratorStatus]: Definition } = {
  [GeneratorStatus.OK]: {
    name: 'OK',
  },
  [GeneratorStatus.WARNING]: {
    name: 'Warning',
  },
  [GeneratorStatus.WARNING_NOT_IN_AUTO_MODE]: {
    name: 'Warning + Not in Auto Mode',
  },
  [GeneratorStatus.NOT_IN_AUTO_MODE]: {
    name: 'Not in Auto Mode',
  },
  [GeneratorStatus.FAULT]: {
    name: 'Fault',
  },
  [GeneratorStatus.UNKNOWN]: {
    name: 'Unknown',
  },
};
export default GeneratorStatusDefinitions;
