import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type { Account } from '../../types/Account';
import type { Company } from '../../types/Company';
import * as actions from './actions';

export interface FormNameValues {
  email?: string;
  firstName: string;
  lastName: string;
  password?: string;
  id?: string;
}

export interface FormPasswordValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface UpdateName {
  userId: string;
  firstName: string;
  lastName: string;
}

export interface UpdatePassword {
  userId: string;
  currentPassword: string;
  newPassword: string;
}

export interface AccountSettingsState {
  account: Account | null;
  loading: boolean;
  accountName: boolean;
  accountNameLoading: boolean;
  accountNameError: Error | null;
  security: boolean;
  securityLoading: boolean;
  securityError: {
    name: string;
    message: string;
  };
  loadingAccountCompany: boolean;
  accountCompany: Company | null;
  accountCompanyError: Error | null;
}

const INITIAL_STATE: AccountSettingsState = {
  account: null,
  loading: false,
  accountName: false,
  accountNameLoading: false,
  accountNameError: null,
  security: false,
  securityLoading: false,
  securityError: {
    name: '',
    message: '',
  },
  loadingAccountCompany: false,
  accountCompany: null,
  accountCompanyError: null,
};

export type AccountSettingsActions = ActionType<typeof actions>;

export default createReducer<AccountSettingsState, AccountSettingsActions>(INITIAL_STATE, {
  [getType(actions.getAccountSettings.request)]: produce((state: AccountSettingsState) => {
    state.loading = true;
  }),
  [getType(actions.getAccountSettings.success)]: produce(
    (
      state: AccountSettingsState,
      action: ReturnType<typeof actions.getAccountSettings.success>,
    ) => {
      state.account = action.payload;
      state.loading = false;
    },
  ),
  [getType(actions.getAccountSettings.failure)]: produce((state: AccountSettingsState) => {
    state.loading = false;
  }),
  [getType(actions.updateAccountName.request)]: produce((state: AccountSettingsState) => {
    state.accountNameLoading = true;
  }),
  [getType(actions.updateAccountName.success)]: produce((state: AccountSettingsState) => {
    state.accountName = true;
    state.accountNameLoading = false;
  }),
  [getType(actions.updateAccountName.failure)]: produce(
    (state: AccountSettingsState, action: ReturnType<typeof actions.updateAccountName.failure>) => {
      state.accountNameError = action.payload;
      state.accountNameLoading = false;
    },
  ),
  [getType(actions.updateAccountPassword.request)]: produce((state: AccountSettingsState) => {
    state.securityLoading = true;
    state.security = false;
  }),
  [getType(actions.updateAccountPassword.success)]: produce(
    (
      state: AccountSettingsState,
      action: ReturnType<typeof actions.updateAccountPassword.success>,
    ) => {
      state.security = action.payload;
      state.securityLoading = false;
    },
  ),
  [getType(actions.updateAccountPassword.failure)]: produce(
    (
      state: AccountSettingsState,
      action: ReturnType<typeof actions.updateAccountPassword.failure>,
    ) => {
      state.securityError = action.payload;
      state.securityLoading = false;
    },
  ),
  [getType(actions.getAccountCompany.request)]: produce((state: AccountSettingsState) => {
    state.loadingAccountCompany = true;
  }),
  [getType(actions.getAccountCompany.success)]: produce(
    (state: AccountSettingsState, action: ReturnType<typeof actions.getAccountCompany.success>) => {
      state.accountCompany = action.payload[0] || null;
      state.loadingAccountCompany = false;
    },
  ),
  [getType(actions.getAccountCompany.failure)]: produce(
    (state: AccountSettingsState, action: ReturnType<typeof actions.getAccountCompany.failure>) => {
      state.loadingAccountCompany = false;
      state.accountCompanyError = action.payload;
    },
  ),
  // [redux-persist]
  // account store is persisted in store/index.ts persistConfig
  // we want to clear it on logout
  [getType(actions.loggedOut)]: () => {
    return { ...INITIAL_STATE };
  },
});
