import type { AxiosError } from 'axios';

export enum CompanyAssociationStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

type User = {
  userId: string;
  name: string;
  email: string;
};

type Company = {
  name: string;
  companyId: string;
};

export interface CompanyAssociation {
  associationId: string;
  status: CompanyAssociationStatus;
  inviterUser: User;
  inviteeUser: User;
}

export interface ParentCompanyAssociation extends CompanyAssociation {
  inviteeCompany: Company;
}

export interface ChildCompanyAssociation extends CompanyAssociation {
  inviterCompany: Company;
}

export type CompanyAssociationsSuccessResponse = {
  receivedInvitations: ChildCompanyAssociation[];
  sentInvitations: ParentCompanyAssociation[];
};

export type CompanyAssociationsFailedResponse = AxiosError;
export type CompanyAssociationsResponse =
  | CompanyAssociationsSuccessResponse
  | CompanyAssociationsFailedResponse;

type Association = {
  inviteeAdminEmail: string;
};
export type AssociateCompaniesRequest = {
  inviterCompanyId: string;
  associations: Association[];
};

export type AssociateCompaniesSuccessResponse = Record<string, unknown>;
export type AssociateCompaniesFailedResponseData = {
  timestamp: string;
  error: 'CONFLICT' | 'NOT_FOUND';
  status: 409 | 404;
  unavailableAssociations: string[];
  existingAssociations: string[];
  sameCompanyAssociations: string[];
};
export type AssociateCompaniesFailedResponse = AxiosError<AssociateCompaniesFailedResponseData>;
export type AssociateCompaniesResponse =
  | AssociateCompaniesSuccessResponse
  | AssociateCompaniesFailedResponse;

export type ActivateCompanyAssociationSuccessResponse = {
  companyName: string;
};
export type ActivateCompanyAssociationFailedResponseData = Record<string, unknown>;
export type ActivateCompanyAssociationFailedResponse =
  AxiosError<ActivateCompanyAssociationFailedResponseData>;
export type ActivateCompanyAssociationResponse =
  | ActivateCompanyAssociationSuccessResponse
  | ActivateCompanyAssociationFailedResponse;

export type RemoveCompanyAssociationSuccessResponse = Record<string, unknown>;
export type RemoveCompanyAssociationFailedResponseData = Record<string, unknown>;
export type RemoveCompanyAssociationFailedResponse =
  AxiosError<RemoveCompanyAssociationFailedResponseData>;
export type RemoveCompanyAssociationResponse =
  | RemoveCompanyAssociationSuccessResponse
  | RemoveCompanyAssociationFailedResponse;

export enum CompanyAssociationRemovalMode {
  DECLINE,
  REMOVE_ASSOCIATION,
  REMOVE_COMPANY,
}
