import { useSelector } from 'react-redux';

import { uiFeaturesSelector } from '../store/auth/selectors';
import type { CompanyAccessLevel } from '../types/CompanyAccessLevel';
import type { UIFeature } from '../types/UIFeature';
import { hasRequiredAccessLevel, hasUIFeatureAccess } from '../utils';

export const useUIFeatureAccess = (
  uiFeature: UIFeature,
  accessLevel?: CompanyAccessLevel,
): boolean => {
  const allowedFeatures = useSelector(uiFeaturesSelector);
  const hasFeatureAccess = hasUIFeatureAccess(uiFeature, allowedFeatures);
  const hasAccessLevel = accessLevel ? hasRequiredAccessLevel(uiFeature, accessLevel) : true;

  return hasFeatureAccess && hasAccessLevel;
};
