import { styled } from '@mui/material/styles';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  color?: string;
}

const StyledLoader = styled('div')<Props>`
  width: ${({ size }) => Number(size) * 3 + 10}px;
  height: ${({ size }) => (Number(size) > 7 ? size : 8)}px;
  line-height: ${({ size }) => size}px;
  display: flex;
  @keyframes animateOpacity {
    25% {
      opacity: 0.25;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  div,
  &:before,
  &:after {
    content: '';
    display: inline-block;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    opacity: 1;
    animation: animateOpacity 0.6s infinite;
  }

  div {
    margin: 0 5px;
    animation-delay: 0.2s;
  }

  &:after {
    animation-delay: 0.4s;
  }
`;

export const Loader = ({ size = 8, color = 'black', ...props }: Props) => (
  <StyledLoader size={size} color={color} {...props}>
    <div />
  </StyledLoader>
);
