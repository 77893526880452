import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import React from 'react';

import { getColor } from '../../styles';
import { Loader } from '../loader';

const ERROR_MESSAGE = 'Failed to retrieve data.';
const NO_DATA_MESSAGE = "We're unable to display any data. Please choose a different time range.";
const NO_SELECTION_MESSAGE = 'Please select a metric to display its data.';

const StyledLoader = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 100;
  width: 60px;
  height: 20px;
`;

const MessageContainer = styled(Box)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 150px;
  margin: auto;
  z-index: 100;
  height: 38px;
  width: max-content;
  border-radius: 10px;
  background-color: ${getColor('WHITE')};
  box-shadow: 0 6px 14px 0 rgba(176, 180, 171, 0.2);
  text-align: center;
`;

type Props = {
  isLoading: boolean;
  hasNoData: boolean;
  hasError?: boolean;
  hasNoSelection?: boolean;
};

export const ChartAlert = ({ isLoading, hasNoData, hasError, hasNoSelection }: Props) => {
  const message = hasNoData
    ? NO_DATA_MESSAGE
    : hasError
    ? ERROR_MESSAGE
    : hasNoSelection
    ? NO_SELECTION_MESSAGE
    : '';

  return isLoading ? (
    <StyledLoader data-test-class="loader-chart">
      <Loader size={16} color="orange" />
    </StyledLoader>
  ) : !isEmpty(message) ? (
    <MessageContainer p={1}>
      <Typography variant="caption">{message}</Typography>
    </MessageContainer>
  ) : null;
};
