import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';

import kpisDefinitions from '../../constants/kpi';
import { useAppSelector } from '../../hooks/useAppSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { useGetSiteKpis } from '../../store/api/endpoints/site';
import { selectedKpisSelector } from '../../store/ui/selectors';
import type { KpiDefinition } from '../../types/Kpi';
import { Card } from '../card';
import { Icon } from '../icon';
import { KpisDrawer } from './KpisDrawer';

type SelectedKpi = KpiDefinition & { value: string };

export interface Props {
  siteId: string;
  hasSolarCapacityMetrics: boolean;
  hasExpectedProductionMetrics: boolean;
}

export const KpisCard = ({ siteId, ...rest }: Props) => {
  const { width } = useWindowSize();
  const isSizeMediumOrAbove = width >= 960;

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

  const { data: kpis, isLoading } = useGetSiteKpis({ siteId });

  const selectedKpis = useAppSelector(selectedKpisSelector);

  const selectedKpisDefintions = useMemo(() => {
    const output: SelectedKpi[] = [];
    const sections = Object.keys(kpisDefinitions);

    selectedKpis.forEach((id) => {
      sections.forEach((section) => {
        const kpiDefinition = kpisDefinitions[section].items.find((item) => item.name === id);

        if (!!kpiDefinition) {
          const valueNumber = kpis?.find((item) => item.kpiId === id)?.value;
          // isNil returns false for null or undefined, but true for value === 0, ironically
          const valueString = !isNil(valueNumber)
            ? kpiDefinition.unit.formatter(valueNumber).toString()
            : '--';

          output.push({ ...kpiDefinition, value: valueString });
        }
      });
    });

    return output;
  }, [kpis, selectedKpis]);

  return (
    <>
      <Card
        name="Metrics"
        headerIconElement={<Icon icon="kpis purple" />}
        collapsible={false}
        timezone={null}
        isLoading={isLoading}
        headerButtonsListProps={
          isSizeMediumOrAbove
            ? [
                {
                  text: 'Select Metrics',
                  onClick: () => setIsOpenDrawer(true),
                  startIcon: <Icon icon="kpis black" />,
                  endIcon: <Icon icon="arrow drop down" width={12} />,
                },
              ]
            : undefined
        }
        bodySlotElement={
          <Box
            sx={{
              display: 'flex',
              flexDirection: { sm: 'row', xs: 'column' },
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              pb: 1,
            }}
          >
            {selectedKpisDefintions.map((kpi) => (
              <Box key={`kpi-${kpi.name}`} data-test-id={`kpi-${kpi.name}`} sx={{ py: 1, pr: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Icon icon={kpi.icon} />
                    <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
                      {kpi.value}
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>{kpi.unit.symbol}</Typography>
                  </Box>
                  <Typography sx={{ fontSize: '14px' }}>{kpi.title}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        }
      />
      <KpisDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} hasConsumptionData {...rest} />
    </>
  );
};
