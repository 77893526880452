export type SiteFilterId =
  | 'sort'
  | 'countryCode'
  | 'state'
  | 'city'
  | 'zip'
  | 'installDateFrom'
  | 'installDateTo'
  | 'sensorInstallDate'
  | 'status'
  | 'capabilities'
  | 'perPage'
  | 'page';

export interface SiteFilter {
  id: SiteFilterId;
  title: string;
  value?: any;
  label?: string; // @ presentation version of value: "pvl" > "Solar"
}

type Status = 'all' | 'online' | 'offline';
type Capability = 'pvl' | 'battery';

// @todo this is not yet implemented on BE
export interface SiteStatusFilter extends SiteFilter {
  value?: Status;
}

// @todo this is not yet implemented on BE
export interface SiteCapabilityFilter extends SiteFilter {
  value?: Capability;
}

export const CountryFilter: SiteFilter = {
  id: 'countryCode',
  title: 'Country',
};

export const StateFilter: SiteFilter = {
  id: 'state',
  title: 'State/Province',
};

export const CityFilter: SiteFilter = {
  id: 'city',
  title: 'City',
};

export const ZipFilter: SiteFilter = {
  id: 'zip',
  title: 'Zip/Postal code',
};

export const InstallDateFromFilter: SiteFilter = {
  id: 'installDateFrom',
  title: 'Date installed from',
};

export const InstallDateToFilter: SiteFilter = {
  id: 'installDateTo',
  title: 'Date installed to',
};

// this works in conjunction with InstallDateFilter
// and is utilized in store/sites/epics loadFleetSitesEpic
// to format the correct query for the BE
export const SensorInstallDateFilter: SiteFilter = {
  id: 'sensorInstallDate',
  title: 'Date sensor installed',
};

// --

export const StatusFilter: SiteStatusFilter = {
  id: 'status',
  title: 'System Status',
};

export const StatusFilterAll: SiteStatusFilter = {
  ...StatusFilter,
  value: 'all',
  label: 'All',
};

export const StatusFilterOnline: SiteStatusFilter = {
  ...StatusFilter,
  value: 'online',
  label: 'Online',
};

export const StatusFilterOffline: SiteStatusFilter = {
  ...StatusFilter,
  value: 'offline',
  label: 'Offline',
};

// --

export const CapabilityFilter: SiteCapabilityFilter = {
  id: 'capabilities',
  title: 'Capability',
};

export const CapabilityFilterSolar: SiteCapabilityFilter = {
  ...CapabilityFilter,
  value: 'pvl',
  label: 'Solar',
};

export const CapabilityFilterStorage: SiteCapabilityFilter = {
  ...CapabilityFilter,
  value: 'battery',
  label: 'Storage',
};
