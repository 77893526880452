/**
 * User Roles
 *
 * In descending order of feature availability
 */

export enum UserRole {
  SUPPORT_SUPER_ADMIN = 'SUPPORT_SUPER_ADMIN',
  SUPPORT_ADMIN = 'SUPPORT_ADMIN',
  SUPPORT_AGENT = 'SUPPORT_AGENT',
  SUPPORT_VIEWER = 'SUPPORT_VIEWER',
  INSTALLER_ADMIN = 'INSTALLER_ADMIN',
  INSTALLER_VIEWER = 'INSTALLER_VIEWER',
  INSTALLER_SITE_ENGINEER = 'INSTALLER_SITE_ENGINEER',
}

export interface UserRoleDefinition {
  name: string;
}
