import { createAsyncAction } from 'typesafe-actions';

import type {
  CompanyAlertsFailedResponse,
  CompanyAlertsRequest,
  CompanyAlertsSuccessResponse,
} from '../../types/Alert';

export const loadCompanyAlerts = createAsyncAction(
  'LOAD_COMPANY_ALERTS_REQUEST',
  'LOAD_COMPANY_ALERTS_SUCCESS',
  'LOAD_COMPANY_ALERTS_FAILURE',
)<CompanyAlertsRequest, CompanyAlertsSuccessResponse, CompanyAlertsFailedResponse>();
