import { AppBar, Box, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { LinkProps } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { useRoutes } from '../../../hooks/useRoutes';
import { accountUserInfoSelector } from '../../../store/account/selectors';
import { logout } from '../../../store/auth/actions';
import { getColor, up } from '../../../styles';
import { Button } from '../../button';
import { Icon } from '../../icon';

const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-root {
    padding: 0;
  }
`;

// @todo i don't like this magic number margin here
const StyledNavContainer = styled('div')`
  display: flex;

  ${up('md')} {
    margin-left: 235px;
  }
`;

const StyledNavButton = styled(Button)<LinkProps>`
  &.MuiButton-root {
    border: none;
    background-color: ${getColor('WHITE', 0.18)};
    align-items: center;

    &:hover {
      background-color: ${getColor('WHITE', 0.24)};
    }

    &:focus {
      background-color: ${getColor('WHITE', 0.24)};
    }
  }

  img {
    margin-right: 8px;
  }
`;

const StyledMenuIconContainer = styled('div')`
  margin-top: 3px;
  margin-left: 8px;
  cursor: pointer;
`;

interface Props {
  onClick(): void;
}

export const SupportAppBar = ({ onClick }: Props) => {
  const Routes = useRoutes();
  const dispatch = useDispatch();
  const userInfo = useSelector(accountUserInfoSelector);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = useCallback(
    (event) => {
      setMenuAnchorEl(event.currentTarget);
    },
    [setMenuAnchorEl],
  );

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, [setMenuAnchorEl]);

  const handleLogout = useCallback(async () => {
    dispatch(logout());
  }, [dispatch]);

  const ROOT_TEST_ID = 'support-app-bar';

  return (
    <StyledAppBar position="sticky" color="secondary" data-test-id={`${ROOT_TEST_ID}`}>
      <Toolbar variant="dense" onClick={onClick}>
        <StyledNavContainer>
          <StyledNavButton
            variant="contained"
            component={RouterLink}
            to={Routes.CompanyList}
            data-test-id={`${ROOT_TEST_ID}-companies-button`}
          >
            <Icon icon="company" size={20} />
            Companies
          </StyledNavButton>
          <Box ml={1}>
            <StyledNavButton
              variant="contained"
              component={RouterLink}
              to={Routes.UsersList}
              data-test-id={`${ROOT_TEST_ID}-users-button`}
            >
              <Icon icon="account" size={20} />
              Users
            </StyledNavButton>
          </Box>
        </StyledNavContainer>
        <Box flexGrow={1} />
        <Box display="flex" alignItems="center" color="white">
          <Typography
            variant="caption"
            sx={{
              color: 'inherit',
              textTransform: 'uppercase',
              display: { xs: 'none', md: 'block' },
            }}
          >
            {userInfo.firstName} {userInfo.lastName}
          </Typography>
          <StyledMenuIconContainer
            onClick={handleMenuOpen}
            data-test-id={`${ROOT_TEST_ID}-profile-kebob-menu`}
          >
            <Icon icon="more menu white" rotate={0} size={22} />
          </StyledMenuIconContainer>
        </Box>
        <Menu
          id="support-menu"
          data-test-id={`${ROOT_TEST_ID}-support-menu`}
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          keepMounted
        >
          <MenuItem component={RouterLink} to={Routes.Profile} onClick={handleMenuClose}>
            My Profile
          </MenuItem>
          <MenuItem onClick={handleLogout}>Sign out</MenuItem>
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
};
