import { produce } from 'immer';
import { createReducer, getType } from 'typesafe-actions';

import type { SignUpFailedResponse } from '../../types/SignUp';
import * as actions from './actions';

export interface Error {
  message: string;
  name: string;
  stack: string;
}

export interface SignUpState {
  loading: boolean;
  userId: string;
  validationError?: SignUpFailedResponse;
  error?: Error;
}

const INITIAL_STATE: SignUpState = {
  loading: false,
  userId: '',
};

export default createReducer(INITIAL_STATE, {
  [getType(actions.signUpRequest.request)]: produce((state: SignUpState) => {
    state.loading = true;
    state.error = undefined;
    state.validationError = undefined;
    state.userId = '';
  }),
  [getType(actions.signUpRequest.success)]: produce(
    (state: SignUpState, { payload }: ReturnType<typeof actions.signUpRequest.success>) => {
      state.loading = false;
      state.error = undefined;
      state.validationError = undefined;
      state.userId = payload.userId;
    },
  ),
  [getType(actions.signUpRequest.failure)]: produce(
    (state: SignUpState, { payload }: ReturnType<typeof actions.signUpRequest.failure>) => {
      state.loading = false;
      state.error = payload;
    },
  ),
  [getType(actions.signUpValidationError)]: produce(
    (state: SignUpState, { payload }: ReturnType<typeof actions.signUpValidationError>) => {
      state.loading = false;
      state.validationError = payload;
    },
  ),
});
