export const valueToHexString = (value: number): string | null => {
  return value ? `0x${value.toString(16).toUpperCase().padStart(4, '0')}` : null;
};

export const numberToFixed = (
  value: number,
  fixed = 2,
  noValuePlaceholder: string | number = 0,
) => {
  return value ? Number(value.toFixed(fixed)) : noValuePlaceholder;
};
