import React from 'react';
import { useDispatch } from 'react-redux';

import { setSelectedFilters } from '../../store/sites/actions';
import { Alert as AlertComponent } from '../alert';
import { SitesAlertContainer } from './sitesAlertContainer';

export const SitesNotFoundAlert = () => {
  const dispatch = useDispatch();
  const handleClearFilters = () => dispatch(setSelectedFilters([]));

  return (
    <SitesAlertContainer>
      <AlertComponent
        toastProps={{ type: 'error' }}
        title="The filters you applied returned zero sites. Revise, or clear all filters and try again."
        buttons={[{ children: 'Clear filters', onClick: handleClearFilters }]}
      />
    </SitesAlertContainer>
  );
};
