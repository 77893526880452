import {
  Box,
  Divider,
  Drawer,
  Hidden,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { ifProp } from 'styled-tools';

import { useCompanyContext } from '../../../hooks/useCompanyContext';
import { useRoutes } from '../../../hooks/useRoutes';
import { logout } from '../../../store/auth/actions';
import { COLORS, getColor, zIndex } from '../../../styles';
import { useViewMode } from '../../../styles/stylingUtils';
import { UIFeature } from '../../../types/UIFeature';
import { Button } from '../../button';
import { GatedFeature } from '../../gatedFeature';
import { UserHeader } from '../UserHeader';

// @todo move to global setting of MUI Backdrop styles
const useStyles = makeStyles(() => ({
  backdrop: {
    backgroundColor: getColor('OFF_WHITE', 0.5),
  },
}));

export const StyledDrawer = styled(Drawer)`
  z-index: ${zIndex.AccountDrawer} !important;

  .MuiDrawer-paperAnchorLeft {
    border-radius: 0 0 8px 0;
    margin-left: 220px !important;
    height: auto;
  }

  .MuiDrawer-paperAnchorRight {
    padding-top: 40px;
  }

  .MuiPaper-elevation16 {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1) !important;
  }
`;

const StyledMenuItem = styled(MenuItem)<{ component?: React.ElementType; to?: string }>`
  font-size: 11px;
  font-weight: 700;
  color: ${COLORS.MEDIUM_GREY};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: ${(props) => props.theme.spacing(1)} ${(props) => props.theme.spacing(3)};
`;

const StyledDivider = styled(Divider)<{ $tall?: boolean }>`
  margin: ${ifProp('$tall', '30px 0', '10px 0')};
`;

interface AccountSettingsDrawerProps {
  isOpen: boolean;
  onClose(): void;
}

export const AccountSettingsDrawer = ({ isOpen, onClose }: AccountSettingsDrawerProps) => {
  const Routes = useRoutes();
  const styles = useStyles();
  const dispatch = useDispatch();
  const viewMode = useViewMode();
  const { companyId } = useCompanyContext();

  // @todo fix this. no idea why this is doing what it's doing.
  const backdropProps = useMemo(
    () => ({
      classes: {
        root: styles.backdrop,
      },
    }),
    [styles.backdrop],
  );

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <StyledDrawer
      disableEnforceFocus
      anchor={viewMode === 'desktop' ? 'left' : 'right'}
      open={isOpen}
      onClose={onClose}
      BackdropProps={backdropProps}
    >
      {viewMode === 'desktop' && (
        <GatedFeature
          uiFeature={UIFeature.SUPPORT_VIEW}
          onAllowed={() => <Toolbar variant="dense" />}
        />
      )}
      <Box pt={8} pb={5}>
        <Box px={3}>
          <Hidden mdUp>
            <UserHeader toggleDrawer={onClose} hideSettingsButton hideCompanyContext={false} />
            <StyledDivider $tall />
          </Hidden>
        </Box>
        <Box px={3} my={1}>
          <Typography variant="body2" fontWeight="bold">
            Account Settings
          </Typography>
        </Box>
        <MenuList>
          <GatedFeature
            uiFeature={UIFeature.COMPANY_VIEW}
            onAllowed={() => (
              <StyledMenuItem
                component={Link}
                to={generatePath(Routes.CompanyDetails, { companyId })}
                onClick={onClose}
                data-test-id="account-settings-company-details-button"
              >
                Company Details
              </StyledMenuItem>
            )}
          />
          <GatedFeature
            uiFeature={UIFeature.USER_VIEW}
            onAllowed={() => (
              <StyledMenuItem
                component={Link}
                to={generatePath(Routes.CompanyUsers, { companyId })}
                onClick={onClose}
                data-test-id="account-settings-company-users-button"
              >
                Company Users
              </StyledMenuItem>
            )}
          />
          <GatedFeature
            uiFeature={UIFeature.COMPANY_ASSOCIATIONS_VIEW}
            onAllowed={() => (
              <StyledMenuItem
                component={Link}
                to={generatePath(Routes.CompanyAssociations, { companyId })}
                onClick={onClose}
              >
                Company Associations
              </StyledMenuItem>
            )}
          />
        </MenuList>
        <GatedFeature
          uiFeature={UIFeature.SUPPORT_VIEW}
          onDisallowed={() => (
            <>
              <Box px={3}>
                <StyledDivider />
              </Box>
              <MenuList>
                <StyledMenuItem
                  component={Link}
                  to={Routes.Profile}
                  onClick={onClose}
                  data-test-id="account-settings-my-profile-button"
                >
                  My Profile
                </StyledMenuItem>
              </MenuList>
            </>
          )}
        />
        <Box px={3}>
          <Box mt={2}>
            <Button
              color="primary"
              size="small"
              onClick={handleLogout}
              data-test-id="logout-button"
            >
              Sign Out Of PWRFleet
            </Button>
          </Box>
        </Box>
      </Box>
    </StyledDrawer>
  );
};
