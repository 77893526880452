import type {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_KPIS } from '../../constants/kpi';
import { LocalStorageItem } from '../../constants/localStorage';
import { defaultSitesTableColumnVisibilityModel } from '../../constants/table/sitesTable/visibilityModel';
import { INITIAL_SORT_COLUMN } from '../../constants/userColumns';
import type { KpiId } from '../../types/Kpi';
import type { FilterModel, TableSort } from '../../types/Table';
import { SortOrder } from '../../utils/sort';

/*
 * Redux slice for ui state that needs to be persisted as user navigates app,
 * most if not all other reducers (which fetch data) should be migrated to
 * the rtk query api and/or apiSlices if it is refactored
 */

interface Selection {
  id: string;
  name: string;
}

export interface State {
  selections: {
    site: Selection | null;
  };
  features: {
    search: { value: string };
    kpisCard: {
      selectedKpis: KpiId[];
    };
    usersTable: {
      sort: TableSort; // legacy table
    };
    companySites: {
      companyId: string | null;
      viewMode: 'table' | 'map';
      sitesTable: {
        sortModel: GridSortModel;
        paginationModel: GridPaginationModel;
        filterModel: FilterModel;
        columnVisibilityModel: GridColumnVisibilityModel;
      };
    };
  };
}

const storedSitesTableColumnVisibilityModel = localStorage.getItem(
  LocalStorageItem.SITES_TABLE_VISIBILITY_MODEL,
);

const initialSitesTableColumnVisibilityModel = !storedSitesTableColumnVisibilityModel
  ? defaultSitesTableColumnVisibilityModel
  : JSON.parse(storedSitesTableColumnVisibilityModel);

const initialState: State = {
  selections: {
    site: null,
  },
  features: {
    search: { value: '' },
    kpisCard: {
      selectedKpis: INITIAL_KPIS,
    },
    usersTable: {
      sort: {
        sortColumn: INITIAL_SORT_COLUMN,
        sortOrder: SortOrder.ASC,
      },
    },
    companySites: {
      companyId: null,
      viewMode: 'table',
      sitesTable: {
        sortModel: [],
        paginationModel: { page: 0, pageSize: 10 },
        filterModel: { items: [] },
        columnVisibilityModel: initialSitesTableColumnVisibilityModel,
      },
    },
  },
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    updateSelectedSite(state, action: PayloadAction<Selection | null>) {
      const site = action.payload;
      state.selections.site = site;
    },
    updateSearchValue(state, action: PayloadAction<string>) {
      const searchValue = action.payload;
      state.features.search.value = searchValue;
    },
    updateSelectedKpis(state, action: PayloadAction<KpiId[]>) {
      const selectedKpis = action.payload;
      state.features.kpisCard.selectedKpis = selectedKpis;
    },
    updateUsersTableSort(state, action: PayloadAction<TableSort>) {
      const sort = action.payload;
      state.features.usersTable.sort = sort;
    },
    updateCompanySitesCompanyId(state, action: PayloadAction<string>) {
      const companyId = action.payload;
      state.features.companySites.companyId = companyId;
    },
    updateCompanySitesViewMode(state, action: PayloadAction<'table' | 'map'>) {
      const viewMode = action.payload;
      state.features.companySites.viewMode = viewMode;
    },
    updateSitesTableSortModel(state, action: PayloadAction<GridSortModel>) {
      const sortModel = action.payload;
      state.features.companySites.sitesTable.sortModel = sortModel;
    },
    updateSitesTablePaginationModel(state, action: PayloadAction<GridPaginationModel>) {
      const paginationModel = action.payload;
      state.features.companySites.sitesTable.paginationModel = paginationModel;
    },
    updateSitesTableFilterModel(state, action: PayloadAction<FilterModel>) {
      const filterModel = action.payload;
      state.features.companySites.sitesTable.filterModel = filterModel;
    },
    clearTemporarySitesTableModels(state) {
      state.features.companySites.sitesTable.sortModel = [];
      state.features.companySites.sitesTable.paginationModel = { page: 0, pageSize: 10 };
      state.features.companySites.sitesTable.filterModel = { items: [] };
    },
    updateSitesTableColumnVisibilityModel(state, action: PayloadAction<GridColumnVisibilityModel>) {
      const columnVisibilityModel = action.payload;
      state.features.companySites.sitesTable.columnVisibilityModel = columnVisibilityModel;
    },
  },
});

export const {
  updateSelectedSite,
  updateSearchValue,
  updateSelectedKpis,
  updateUsersTableSort,
  updateCompanySitesCompanyId,
  updateCompanySitesViewMode,
  updateSitesTableSortModel,
  updateSitesTablePaginationModel,
  updateSitesTableFilterModel,
  clearTemporarySitesTableModels,
  updateSitesTableColumnVisibilityModel,
} = slice.actions;

export default slice.reducer;
