import {
  Cancel as CancelIcon,
  Close as CloseIcon,
  Download as DownloadIcon,
  Sync as SyncIcon,
} from '@mui/icons-material';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

import { COLORS } from '../../styles';
import { truncateStringInternally } from '../../utils';

enum ModalState {
  CLOSED,
  DELETE,
  DOWNLOAD,
  VIEW,
}

const useStyles = makeStyles({
  rotateIcon: {
    animation: '$spinAttachmentIcon 2s linear infinite',
  },
  '@keyframes spinAttachmentIcon': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});

interface Props {
  id: string; // for data-test-id
  name: string;
  actionId: string;
  deleteAction?: {
    loading: boolean;
    onClick: (id: string) => void;
  };
  downloadAction?: {
    loading: boolean;
    onClick: (id: string) => void;
  };
}

export const PWRattachment = ({ id, name, actionId, deleteAction, downloadAction }: Props) => {
  const hasDeleteAction = !!deleteAction;
  const hasDownloadAction = !!downloadAction;

  const classes = useStyles();

  const [modalState, setModalState] = useState<ModalState>(ModalState.CLOSED);

  return (
    <>
      <Box
        data-test-id={`${id}-container`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '320px',
          border: `1px solid ${COLORS.SILVER}`,
          borderRadius: '8px',
          p: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 0.5, mr: 1 }}>
          <Typography variant="body2">{truncateStringInternally(name, 20)}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {hasDownloadAction && (
            <Box
              data-test-id={`${id}-download-modal-open-action`}
              onClick={() => setModalState(ModalState.DOWNLOAD)}
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', mr: 0.5 }}
            >
              {downloadAction.loading ? (
                <SyncIcon className={classes.rotateIcon} />
              ) : (
                <DownloadIcon />
              )}
            </Box>
          )}
          {hasDeleteAction && (
            <Box
              data-test-id={`${id}-delete-modal-open-action`}
              onClick={() => setModalState(ModalState.DELETE)}
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', mr: 0.5 }}
            >
              {deleteAction.loading ? <SyncIcon className={classes.rotateIcon} /> : <CancelIcon />}
            </Box>
          )}
        </Box>
      </Box>
      {modalState !== ModalState.CLOSED && (
        <Dialog
          open={true} // using this prop for conditional rendering causes buggy closing behavior
        >
          <Box sx={{ minWidth: '512px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, pr: 2 }}>
              <Box
                data-test-id={`${id}-modal-close-action`}
                onClick={() => setModalState(ModalState.CLOSED)}
                sx={{ cursor: 'pointer' }}
              >
                <CloseIcon />
              </Box>
            </Box>
            <Box sx={{ pt: 2, px: 6, pb: 4 }}>
              {modalState === ModalState.DOWNLOAD && (
                <>
                  <Box pb={4}>
                    <Typography textAlign="center">"{name}"</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <Button
                      data-test-id={`${id}-download-action`}
                      variant="outlined"
                      onClick={() => {
                        setModalState(ModalState.CLOSED);
                        downloadAction?.onClick(actionId);
                      }}
                    >
                      Download
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setModalState(ModalState.CLOSED)}
                    >
                      Close
                    </Button>
                  </Box>
                </>
              )}
              {modalState === ModalState.DELETE && (
                <>
                  <Box pb={4}>
                    <Typography gutterBottom>Are you sure you want to delete "{name}"?</Typography>
                    <Typography>This action cannot be undone.</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <Button
                      data-test-id={`${id}-delete-action`}
                      variant="outlined"
                      onClick={() => {
                        setModalState(ModalState.CLOSED);
                        deleteAction?.onClick(actionId);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setModalState(ModalState.CLOSED)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};
