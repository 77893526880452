import { createAction, createAsyncAction } from 'typesafe-actions';

import type {
  SiteListExportErrorResponse,
  SiteListExportRequest,
  SiteListExportSuccessResponse,
  SitesRequest,
  SitesResponse,
} from '../../types/Site';
import type { SiteFilter } from '../../types/SiteFilter';
import type { SortOrder } from '../../utils/sort';

interface SitesSortInterface {
  property: string;
  order: SortOrder;
}

export const loadFleetSites = createAsyncAction(
  'LOAD_FLEET_SITES_REQUEST',
  'LOAD_FLEET_SITES_SUCCESS',
  'LOAD_FLEET_SITES_FAILURE',
)<SitesRequest, SitesResponse, Error>();

export const setSelectedColumns = createAction(
  'SITES_SET_SELECTED_COLUMNS',
  (columns: string[]) => columns,
)<string[]>();

export const setSitesSort = createAction(
  'SITES_SET_SORT',
  ({ property, order }: SitesSortInterface) => ({
    property,
    order,
  }),
)<SitesSortInterface>();

export const setSelectedFilters = createAction(
  'SITES_SET_SELECTED_FILTERS',
  (filters: SiteFilter[]) => filters,
)<SiteFilter[]>();

export const createSiteListExport = createAsyncAction(
  'CREATE_SITE_LIST_EXPORT_REQUEST',
  'CREATE_SITE_LIST_EXPORT_SUCCESS',
  'CREATE_SITE_LIST_EXPORT_FAILURE',
)<SiteListExportRequest, SiteListExportSuccessResponse, SiteListExportErrorResponse>();
