import { isEmpty } from 'lodash';

import { GENERAC_CONNECTIVITY_VIDEO } from '../../constants/general';
import { useAlert } from '../../hooks/useAlert';
import { useExactRouteMatch } from '../../hooks/useExactRouteMatch';
import { useRoutes } from '../../hooks/useRoutes';
import { useGetSiteStatus } from '../../store/api/endpoints/site';
import { Alert } from '../../types/ScreenAlerts';
import { SystemStatusName } from '../../types/SiteDetails';
import { Icon } from '../icon';

export const NeverConnectedAlert = ({ siteId }: { siteId: string }) => {
  const Routes = useRoutes();

  const isSiteRouteMatch = useExactRouteMatch(Routes.SiteDashboard);
  const isDevicesRouteMatch = useExactRouteMatch(Routes.SiteDevices);
  const isRouteApplicable = isSiteRouteMatch || isDevicesRouteMatch;

  const { data: siteStatusData } = useGetSiteStatus({ siteId });
  const neverConnectedSystems = siteStatusData?.systems.filter(
    (system) => system.statusName === SystemStatusName.NEVER_CONNECTED,
  );
  const hasNeverConnectedSystem = !isEmpty(neverConnectedSystems);
  const isEverySystemNeverConnected =
    siteStatusData?.systems.length === neverConnectedSystems?.length;

  const isAlertApplicable = isRouteApplicable && hasNeverConnectedSystem;

  useAlert(
    {
      alert: Alert.NeverConnectedError,
      condition: isAlertApplicable,
    },
    {
      title: isEverySystemNeverConnected
        ? 'We’re unable to display any data.'
        : "We're unable to display all data.",
      message: isEverySystemNeverConnected
        ? "Please check that all equipment is connected to the internet. Refer to the Installer's Manual or to our video instructions."
        : "One or more systems were never connected to the internet. Please check that all equipment is connected to the internet. Refer to the Installer's Manual or to our video instructions.",
      buttons: [
        {
          size: 'small',
          variant: 'outlined',
          color: 'secondary',
          whiteBackground: true,
          target: '_blank',
          href: GENERAC_CONNECTIVITY_VIDEO,
          endIcon: <Icon icon="external link" />,
          children: 'VIEW VIDEO',
        },
      ],
      closeButton: true,
    },
  );
  return null;
};
