// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type { Company } from '../../types/Company';
import { SortOrder } from '../../utils/sort';
import * as actions from './actions';

export type SelectedCheckBoxes = {
  [key: string]: boolean | undefined;
};

export interface CompaniesState {
  items: Company[];
  loading: boolean;
  loadingFinished: boolean;
  error: string | null;
  selectedSortProperty: string; // @todo
  selectedSortOrder: SortOrder; // @todo
  selectedCompanyIds: SelectedCheckBoxes;
  companiesMergeInProgress: boolean;
  companiesMergeFinished: boolean;
}

const INITIAL_STATE: CompaniesState = {
  items: [],
  loading: false,
  loadingFinished: false,
  error: null,
  selectedSortProperty: 'name', // @todo
  selectedSortOrder: SortOrder.ASC, // @todo
  selectedCompanyIds: {},
  companiesMergeInProgress: false,
  companiesMergeFinished: false,
};

export type CompaniesActions = ActionType<typeof actions>;

export default createReducer<CompaniesState, CompaniesActions>(INITIAL_STATE, {
  [getType(actions.toggleCompanyCheckbox)]: produce(
    (state: CompaniesState, action: ReturnType<typeof actions.toggleCompanyCheckbox>) => {
      state.selectedCompanyIds[action.payload] = !state.selectedCompanyIds[action.payload];
    },
  ),
  [getType(actions.mergeCompaniesAction.request)]: produce((state: CompaniesState) => {
    state.companiesMergeFinished = false;
    state.companiesMergeInProgress = true;
  }),
  [getType(actions.mergeCompaniesAction.success)]: produce((state: CompaniesState) => {
    state.companiesMergeFinished = true;
    state.companiesMergeInProgress = false;
    state.selectedCompanyIds = {};
  }),
  [getType(actions.mergeCompaniesAction.failure)]: produce((state: CompaniesState) => {
    state.companiesMergeFinished = true;
    state.companiesMergeInProgress = false;
  }),
  [getType(actions.clearMergeVariables)]: produce((state: CompaniesState) => {
    state.companiesMergeFinished = false;
    state.companiesMergeInProgress = false;
  }),
});
