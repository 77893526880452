import type { PaginationProps, SelectChangeEvent } from '@mui/material';
import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import type {
  ChangeEvent,
  ComponentProps,
  ComponentType,
  MouseEvent,
  ReactElement,
  Ref,
} from 'react';
import React, { forwardRef, useCallback, useMemo, useRef } from 'react';

import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../constants/table';
import { COLORS, getColor, ThemeDimensions } from '../../styles';
import { useViewMode } from '../../styles/stylingUtils';
import type { RowComponentProps, TableColumn } from '../../types/NewTable';
import type { PropertySelector } from '../../utils/sort';
import { SortOrder } from '../../utils/sort';
import { Icon } from '../icon';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles(() => ({
  sortCell: {
    backgroundColor: COLORS.WHITE,
    borderBottom: `2px solid ${COLORS.OFF_WHITE}`,
  },
  transparentRow: {
    backgroundColor: 'transparent',
  },
  headerCell: {
    height: 24,
  },
  clickable: { cursor: 'pointer' },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  toolbar: {
    paddingRight: 'unset',
    paddingLeft: 'unset',
    minHeight: ThemeDimensions.TABLE_PAGINATION_HEIGHT,
    justifyContent: 'center',
  },
}));

const StyledIcon = styled(Icon)<{ $active: boolean }>`
  background-color: ${({ $active }) => getColor($active ? 'BLACK' : 'SILVER')};
  width: 15px;
  height: 9px;
`;

const StyledTableContainer = styled(TableContainer)<{ $maxHeight?: number | string }>`
  max-height: ${({ $maxHeight }) => {
    if ($maxHeight) {
      return typeof $maxHeight === 'number' ? `${$maxHeight}px` : $maxHeight;
    }
    return 'unset';
  }};
`;

const StyledTable = styled(Table)`
  background-color: transparent;

  & .MuiTableRow-root {
    background-color: ${COLORS.WHITE};

    & td:first-child.selectable {
      border-left: 4px solid ${COLORS.WHITE};
      border-right: 4px solid ${COLORS.WHITE};
    }

    &$selected {
      background-color: ${COLORS.SHADOW_GREY};

      & td:first-child {
        border-left: 4px solid ${COLORS.TABLE_ROW_SELECTED} !important;
        border-right: 4px solid ${COLORS.SHADOW_GREY};
      }
    }

    &:hover {
      background-color: ${COLORS.TABLE_ROW_HOVER};
    }
  }

  & .MuiTableCell-root {
    height: 38px;
    border-bottom: 2px solid ${COLORS.OFF_WHITE};
    padding: 6px 12px;

    &.MuiTableCell-head {
      height: 24px;
    }
  }
`;

type CommonProps<R> = {
  columns: TableColumn<R>[];
  allowSelectAll?: boolean;
  stickyHeader?: boolean;
};

type TableHeadProps<R> = CommonProps<R> & {
  onSelectAllClick: (e: ChangeEvent<HTMLInputElement>) => void;
  order?: SortOrder;
  orderBy?: string;
  numSelected: number;
  rowCount: number;
  align?: 'center' | 'left' | 'right';
  onRequestSort?: (
    event: MouseEvent<HTMLElement>,
    property: string,
    sort?: PropertySelector<R>,
  ) => void;
};

const EnhancedTableHead = <R,>({
  columns,
  onSelectAllClick,
  align,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  allowSelectAll,
  stickyHeader,
}: TableHeadProps<R>) => {
  const styles = useStyles();
  const hasSortingRow = columns.some((c) => c.sortable) || allowSelectAll;
  const hasSubtitle = columns.some((c) => c.unit?.symbol);
  const createSortHandler =
    (property: string, sortBy?: PropertySelector<R>) => (event: MouseEvent<HTMLElement>) => {
      onRequestSort?.(event, property, sortBy);
    };

  return (
    <TableHead className={stickyHeader ? styles.stickyHeader : undefined}>
      <TableRow className={styles.transparentRow}>
        {allowSelectAll ? <TableCell /> : null}
        {columns.map(({ id, label, TableCellProps, TypographyProps, unit }, index) => (
          <TableCell key={`column-${id}-${index}`} align={align} {...TableCellProps}>
            <Typography
              color="textSecondary"
              sx={{ fontSize: 11, fontWeight: 'bold', textTransform: 'uppercase' }}
              {...TypographyProps}
            >
              {label}
            </Typography>
            {hasSubtitle ? (
              <Typography fontSize={10} color="textSecondary">
                {unit?.symbol || <br />}
              </Typography>
            ) : null}
          </TableCell>
        ))}
      </TableRow>
      {hasSortingRow ? (
        <TableRow className={styles.transparentRow}>
          {allowSelectAll ? (
            <TableCell padding="checkbox" className={clsx('selectable')}>
              <Checkbox
                color="default"
                size="small"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all items on the current page' }}
              />
            </TableCell>
          ) : null}
          {columns.map(({ id, sortable, sortBy, TableCellProps }, index) => {
            const active = orderBy === id;
            return (
              <TableCell
                key={`sort-column-${id}-${index}`}
                align="center"
                className={clsx(styles.sortCell, sortable ? styles.clickable : undefined)}
                {...TableCellProps}
                sortDirection={orderBy === id ? order : false}
                onClick={sortable ? createSortHandler(id, sortBy) : undefined}
              >
                {sortable ? (
                  <Box display="flex" justifyContent="center">
                    <StyledIcon
                      $active={active}
                      hasWrapper
                      icon="arrow drop down"
                      rotate={active && order === SortOrder.ASC ? 180 : 0}
                    />
                  </Box>
                ) : null}
              </TableCell>
            );
          })}
        </TableRow>
      ) : null}
    </TableHead>
  );
};

type IdExtractorFunc<R> = (row: R) => string;
type IdExtractor<R> = keyof R | IdExtractorFunc<R>;

type Props<R> = CommonProps<R> & {
  rows: R[];
  activeId?: string;
  selectedIds?: string[];
  onRowSelect?: (ids: string[]) => void;
  onRowClick?: (row: R, index: number) => void;
  PaginationProps?: Omit<PaginationProps, 'page' | 'count'> & {
    rowsPerPage?: number;
    page: number;
    count: number;
    rowsPerPageOptions?: number[];
    onRowsPerPageChange?: (event: SelectChangeEvent<unknown>, perPage: number) => void;
    showTotalCount?: boolean;
  };
  HeadProps?: Pick<ComponentProps<typeof EnhancedTableHead>, 'order' | 'orderBy'> & {
    align?: 'center' | 'left' | 'right';
    onRequestSort?: (
      event: MouseEvent<HTMLElement>,
      property: string,
      sortBy?: PropertySelector<R>,
    ) => void;
  };
  RowComponent: ComponentType<RowComponentProps<R>>;
  selectableRows?: boolean;
  idExtractor: IdExtractor<R>;
  containerMaxHeight?: number | string;
};

const NewTableWithoutRef = <R,>(
  {
    rows,
    columns,
    activeId,
    selectedIds = [],
    onRowSelect,
    onRowClick,
    HeadProps,
    PaginationProps,
    RowComponent,
    allowSelectAll,
    idExtractor,
    containerMaxHeight,
    stickyHeader = false,
    ...rest
  }: Props<R>,
  ref?: Ref<HTMLDivElement>,
) => {
  const styles = useStyles();
  const viewMode = useViewMode();
  const isMobile = viewMode === 'mobile';
  const { onRequestSort, order, orderBy, align = 'center' } = { ...HeadProps };
  const {
    onChange,
    rowsPerPage = 10,
    page = 1,
    count = 0,
    onRowsPerPageChange,
    rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS,
    showTotalCount = true,
    ...restPaginationProps
  } = { ...PaginationProps };
  const pageCount = Math.ceil(count / rowsPerPage);
  const tableRef = useRef<HTMLTableElement>(null);
  const getId = useCallback(
    (row: R): string => {
      if (typeof idExtractor === 'function') {
        return idExtractor(row);
      }
      return row[idExtractor] as unknown as string;
    },
    [idExtractor],
  );
  const isSelected = useCallback((id: string) => selectedIds.includes(id), [selectedIds]);
  const isActive = useCallback((id: string) => activeId === id, [activeId]);
  const numSelectedOnPage = useMemo(
    () => rows?.filter((row) => isSelected(getId(row))).length,
    [rows, isSelected, getId],
  );
  const currentPageIds = useMemo(() => rows.map((a) => getId(a)), [rows, getId]);

  const handleRowClick = (row: R, index: number) => () => {
    if (onRowSelect) {
      const id = getId(row);
      const selected = isSelected(id);
      if (selected) {
        onRowSelect?.(selectedIds.filter((v) => v !== id));
      } else {
        onRowSelect?.([...selectedIds, id]);
      }
    }
    onRowClick?.(row, index);
  };

  const selectAll = () => {
    onRowSelect?.([...new Set([...selectedIds, ...currentPageIds])]);
  };

  const unSelectAll = () => {
    onRowSelect?.(currentPageIds.filter((a) => !currentPageIds.includes(a)));
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      return selectAll();
    }
    unSelectAll();
  };

  const handleOnPageChange = (event: ChangeEvent<unknown>, page: number) => {
    if (tableRef && tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    onChange?.(event, page);
  };

  const handleOnRowsPerPageChange = (event: SelectChangeEvent<unknown>) => {
    const rowsPerPage = event.target.value as number;
    onRowsPerPageChange?.(event, rowsPerPage);
  };

  return (
    <>
      <StyledTableContainer $maxHeight={containerMaxHeight} ref={ref}>
        <StyledTable
          ref={tableRef}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          stickyHeader={stickyHeader}
          size="small"
          {...rest}
        >
          <EnhancedTableHead
            columns={columns}
            numSelected={numSelectedOnPage}
            order={order}
            orderBy={orderBy}
            align={align}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={onRequestSort}
            rowCount={rows?.length || 0}
            allowSelectAll={allowSelectAll}
            stickyHeader={stickyHeader}
          />
          <TableBody>
            {rows.map((row, index) => {
              const id = getId(row);
              const isItemSelected = isSelected(id);
              const isItemActive = isActive(id);
              const hasClickHandler = !!(onRowSelect || onRowClick);
              const classes = clsx(
                hasClickHandler ? styles.clickable : undefined,
                isItemActive ? 'active' : undefined,
              );
              return (
                <RowComponent
                  key={id}
                  row={row}
                  index={index}
                  columns={columns}
                  TableRowProps={{
                    selected: isItemSelected,
                    'aria-checked': isItemSelected,
                    onClick: hasClickHandler ? handleRowClick(row, index) : undefined,
                    hover: true,
                    className: classes,
                  }}
                />
              );
            })}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      <Toolbar className={styles.toolbar}>
        {onChange ? (
          <>
            {!isMobile ? <Box flex={1} /> : null}
            <Pagination
              page={page}
              onChange={handleOnPageChange}
              count={pageCount}
              {...restPaginationProps}
            />
            {!isMobile ? (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  flex={1}
                  justifyContent="flex-end"
                >
                  {rowsPerPageOptions?.length ? (
                    <>
                      <Typography noWrap variant="body2">
                        Rows per page:
                      </Typography>
                      <Box sx={{ mx: 0.5, pl: 1 }}>
                        <FormControl variant="standard">
                          <Select
                            id="table-rows-per-page-select"
                            sx={{ fontSize: 14 }}
                            value={rowsPerPage}
                            onChange={handleOnRowsPerPageChange}
                            disableUnderline
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'center',
                                horizontal: 'center',
                              },
                              transformOrigin: {
                                vertical: 'center',
                                horizontal: 'center',
                              },
                            }}
                          >
                            {rowsPerPageOptions.map((option, i) => (
                              <MenuItem
                                key={`table-rows-per-page-select-option-${option}${i}`}
                                id={`item-${option}-${i}`}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </>
                  ) : null}
                  {showTotalCount && (
                    <Typography variant="body2">
                      {rowsPerPageOptions?.length && 'of '} {count} total
                    </Typography>
                  )}
                </Box>
              </>
            ) : null}
          </>
        ) : null}
      </Toolbar>
    </>
  );
};

export const NewTable = forwardRef(NewTableWithoutRef) as <R>(
  p: Props<R> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
