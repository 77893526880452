/* eslint-disable unused-imports/no-unused-vars */
import { useLocation } from 'react-router-dom';

import { trackPage as messagingClientTrackPage } from '../../messagingClient';

export const PageTracker = () => {
  const location = useLocation();

  messagingClientTrackPage();

  return null;
};
