import { Close as CloseIcon } from '@mui/icons-material';
import type { IconButtonProps, Theme } from '@mui/material';
import { Box, Dialog, GlobalStyles, IconButton } from '@mui/material';
import { css, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import chroma from 'chroma-js'; // gradient helper lib
import type { ComponentProps } from 'react';
import React, { useMemo } from 'react';

import { COLORS, down, up } from '../../styles';
import { AlertContainer } from '../../types/ScreenAlerts';
import { AlertsContainer } from '../alertsContainer';

// @todo reevaluate this styling implmentation here
//  we could probably move some of this to the StyledDialog block just below
const StyledGlobalStyles = ({ backdropBlur, theme }: { backdropBlur: boolean; theme: Theme }) => {
  const conditionalCss = `
  #root {
    ${backdropBlur && `filter: blur(20px);`}
  }

  .MuiDialog-paper {
    position: static !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  ${up('md')({ theme })} {
    .MuiDialog-paper {
      width: 100%;
    }
  }

  ${down('sm')({ theme })} {
    .MuiDialog-paper {
      top: 45px !important;
    }
  }

  ${down('xs')({ theme })} {
    .MuiDialog-paper {
      top: 30px !important;
    }
  }
`;
  return <GlobalStyles styles={conditionalCss} />;
};

/**
 * allowBackgroundClickthrough will allow clicks to pass-through an invisible Modal background layer
 *   that is hidden with "hideBackdrop"
 * this is currently used for the Alert Details Modal, which is draggable and allows users to select
 *   alerts from the table UI underneath the modal
 */
const StyledDialog = styled(Dialog)<{ $width?: string; $allowBackgroundClickthrough?: boolean }>`
  ${({ $width }) =>
    $width
      ? css`
          .MuiDialog-paper {
            width: ${$width} !important;
          }
        `
      : ''}

  ${({ $allowBackgroundClickthrough }) =>
    $allowBackgroundClickthrough
      ? css`
          &.MuiDialog-root {
            pointer-events: none;
          }

          .MuiPaper-root {
            pointer-events: all;
          }
        `
      : ''}
`;

export type ModalProps = Omit<ComponentProps<typeof Dialog>, 'open'> & {
  open?: boolean;
  children: React.ReactNode;
  onClose?(): void;
  fullscreen?: boolean;
  hideCloseButton?: boolean;
  disableCloseButton?: boolean;
  width?: string;
  roundedCorner?: boolean;
  darkBackdrop?: boolean;
  backdropBlur?: boolean;
  hideBackdrop?: boolean;
  CloseIconButtonProps?: IconButtonProps;
};

const defaultPaperProps = {
  elevation: 3,
  style: { borderRadius: 10 },
};

export const Modal = ({
  children,
  onClose,
  fullscreen = false,
  open = true,
  hideCloseButton,
  disableCloseButton,
  maxWidth = 'lg',
  width,
  roundedCorner = true,
  darkBackdrop = false,
  backdropBlur = true,
  hideBackdrop = false,
  CloseIconButtonProps,
  ...props
}: ModalProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isFullScreen = fullscreen || isSmall;
  const paperProps = roundedCorner
    ? defaultPaperProps
    : { ...defaultPaperProps, style: { borderRadius: 'none' } };

  const backdropProps = useMemo(
    () => ({
      sx: {
        backgroundColor: darkBackdrop
          ? chroma(COLORS.BLACK).alpha(0.5).hex()
          : chroma(COLORS.OFF_WHITE).alpha(0.5).hex(),
      },
    }),
    [darkBackdrop],
  );

  const handleClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onClose?.(event, reason);
  };

  return (
    <>
      {open ? <StyledGlobalStyles backdropBlur={backdropBlur} theme={theme} /> : null}
      <StyledDialog
        scroll="body"
        open={open}
        maxWidth={maxWidth}
        onClose={handleClose}
        disableEnforceFocus
        fullScreen={isFullScreen}
        PaperProps={paperProps}
        hideBackdrop={hideBackdrop}
        slotProps={{
          backdrop: backdropProps,
        }}
        $allowBackgroundClickthrough={hideBackdrop}
        $width={width}
        {...props}
      >
        <AlertsContainer containerId={AlertContainer.Modal} />
        {!hideCloseButton && (
          <Box sx={{ position: 'relative', zIndex: 2 }}>
            <IconButton
              size="small"
              data-testid="modal-close-button"
              onClick={onClose}
              disabled={disableCloseButton}
              sx={{ position: 'absolute', top: 8, right: 8, ...CloseIconButtonProps?.sx }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
        {children}
      </StyledDialog>
    </>
  );
};
