import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { trackEvent } from '../../analyticsClient';
import METRICS, {
  EXPECTED_PRODUCTION_KPIS,
  INITIAL_KPIS,
  KPI_SECTION_AGGREGATE_METRICS,
  KPI_SECTION_CONSUMPTION,
  KPI_SECTION_INVERTER_ENERGY,
  KPI_SECTION_SOLAR_ABSOLUTE_YIELD,
  KPI_SECTION_SOLAR_PERFORMANCE_RATIO,
  KPI_SECTION_SOLAR_SPECIFIC_YIELD,
  KPI_SECTION_STORAGE,
  SOLAR_CAPACITY_KPIS,
} from '../../constants/kpi';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { updateSelectedKpis } from '../../store/ui';
import { selectedKpisSelector } from '../../store/ui/selectors';
import { AnalyticsEvent, AnalyticsProperty } from '../../types/Analytics';
import type { KpiDefinition, KpiId } from '../../types/Kpi';
import { getUnitTitle } from '../../utils';
import { Button } from '../button';
import { DrawerSection, DrawerSectionContainer, TopDrawer } from '../drawer';
import { PWRcheckbox } from '../pwrCheckbox';

const MAX_SELECTABLE_METRICS = 5;

const getMetricsSectionsList = (siteHasConsumptionData: boolean): string[] => [
  KPI_SECTION_SOLAR_ABSOLUTE_YIELD,
  KPI_SECTION_SOLAR_SPECIFIC_YIELD,
  KPI_SECTION_SOLAR_PERFORMANCE_RATIO,
  ...(siteHasConsumptionData ? [KPI_SECTION_CONSUMPTION] : []),
  KPI_SECTION_STORAGE,
  ...(siteHasConsumptionData ? [KPI_SECTION_AGGREGATE_METRICS] : []),
  KPI_SECTION_INVERTER_ENERGY,
];

interface KpisDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  hasConsumptionData: boolean;
  hasSolarCapacityMetrics: boolean;
  hasExpectedProductionMetrics: boolean;
}

export const KpisDrawer = ({
  isOpen,
  setIsOpen,
  hasConsumptionData,
  hasSolarCapacityMetrics,
  hasExpectedProductionMetrics,
}: KpisDrawerProps) => {
  const dispatch = useAppDispatch();

  const [unappliedSelectedKpis, setUnappliedSelectedKpis] = useState<KpiId[]>([]);

  const selectedKpis = useAppSelector(selectedKpisSelector);

  const selectableLimitReached = unappliedSelectedKpis.length >= MAX_SELECTABLE_METRICS;

  const handleClose = () => {
    setUnappliedSelectedKpis(selectedKpis);
    setIsOpen(false);
  };

  const handleApply = () => {
    dispatch(updateSelectedKpis(unappliedSelectedKpis));
    setIsOpen(false);

    // [analytics] Site KPI Columns Update
    trackEvent(AnalyticsEvent.SiteKpiColumnsUpdate, {
      [AnalyticsProperty.TableColumns]: unappliedSelectedKpis,
    });
  };

  const handleRestoreDefaults = () => {
    dispatch(updateSelectedKpis(INITIAL_KPIS));
    setIsOpen(false);

    // [analytics] Site KPI Columns Update
    trackEvent(AnalyticsEvent.SiteKpiColumnsUpdate, {
      [AnalyticsProperty.TableColumns]: INITIAL_KPIS,
    });
  };

  const setValue = (kpiId: KpiId, isChecked: boolean) => {
    if (isChecked) {
      setUnappliedSelectedKpis([...unappliedSelectedKpis, kpiId]);
    } else {
      setUnappliedSelectedKpis(unappliedSelectedKpis.filter((item) => item !== kpiId));
    }
  };

  useEffect(() => {
    setUnappliedSelectedKpis(selectedKpis);
  }, [selectedKpis]);

  return (
    <TopDrawer
      title="Choose metrics"
      subtitle="(a maximum of 5 metrics can be displayed at a time)"
      isOpen={isOpen}
      onClose={handleClose}
      actionButtons={
        <>
          <Button variant="text" color="inherit" size="small" onClick={handleRestoreDefaults}>
            Restore defaults
          </Button>
          <Button color="primary" size="small" onClick={handleApply}>
            Apply
          </Button>
        </>
      }
    >
      <DrawerSectionContainer $column>
        {getMetricsSectionsList(hasConsumptionData).map((s) => {
          const section = METRICS[s];
          if (!section) {
            return null;
          }
          return (
            <DrawerSection title={section.title} key={`section_${s}`}>
              {section.items.map((metric: KpiDefinition) => {
                const value = !!unappliedSelectedKpis.find((n) => metric.name === n);
                let isAvailable = true;

                if (SOLAR_CAPACITY_KPIS.indexOf(metric.name) > -1 && !hasSolarCapacityMetrics) {
                  isAvailable = false;
                } else if (
                  EXPECTED_PRODUCTION_KPIS.indexOf(metric.name) > -1 &&
                  !hasExpectedProductionMetrics
                ) {
                  isAvailable = false;
                }

                const disabled = (!value && selectableLimitReached) || !isAvailable;

                return (
                  <Box key={`item-${metric.name}`} data-test-id={`item-${metric.name}`}>
                    <PWRcheckbox
                      name={metric.name}
                      isDisabled={disabled}
                      label={
                        <>
                          <Typography
                            component="span"
                            sx={{ color: 'inherit', fontSize: '12px', fontWeight: 'bold' }}
                          >
                            {metric.title.toUpperCase()}
                          </Typography>
                          <Typography
                            component="span"
                            sx={{ color: 'inherit', fontSize: '11px' }}
                          >{` (${getUnitTitle(metric)})`}</Typography>
                        </>
                      }
                      checked={value}
                      onChange={(e) => setValue(metric.name, e.target.checked)}
                    />
                  </Box>
                );
              })}
            </DrawerSection>
          );
        })}
      </DrawerSectionContainer>
    </TopDrawer>
  );
};
