import { Box, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import React from 'react';

// import DismissIcon from "@mui/icons-material/NotificationsPaused";
// import DismissOffIcon from "@mui/icons-material/NotificationsOff";
import { AlertDetailsTab } from '../../types/Alert';
// import { COLORS } from "../../styles";

const tabs: { value: AlertDetailsTab; label: string; disabled?: boolean }[] = [
  { value: AlertDetailsTab.DETAILS, label: 'Details' },
  { value: AlertDetailsTab.TROUBLESHOOTING, label: 'Troubleshooting' },
  { value: AlertDetailsTab.HISTORY, label: 'Alert History' },
  { value: AlertDetailsTab.EVENT_LOG, label: 'Event Log' },
  { value: AlertDetailsTab.NOTES, label: 'Notes', disabled: true },
];

export interface Props {
  currentTab: AlertDetailsTab;
  onTabChange: (tab: AlertDetailsTab) => void;
}

export const Navigation = (props: Props) => {
  const { currentTab, onTabChange } = props;

  const handleTabChange = (event: React.SyntheticEvent, tab: AlertDetailsTab) => {
    onTabChange(tab);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <MuiTabs
        indicatorColor="primary"
        textColor="inherit"
        onChange={handleTabChange}
        value={currentTab}
      >
        {tabs
          .filter((tab) => !tab.disabled)
          .map((tab) => {
            return (
              <MuiTab
                key={`alert_tab_${tab.value}`}
                value={tab.value}
                label={tab.label}
                disableRipple
              />
            );
          })}
      </MuiTabs>
      {/**
      <Box mr={2}>
        <Tooltip title="Dismiss Alert" placement="top">
          <IconButton onClick={onDismiss} size="small" disabled={dismissed || dismissLoading}>
            {dismissed ? <DismissOffIcon /> : <DismissIcon style={{ color: COLORS.BLACK }} />}
          </IconButton>
        </Tooltip>
      </Box>
       */}
    </Box>
  );
};
