import { Box, IconButton, Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import type { ComponentProps, ReactNode } from 'react';
import React from 'react';
import type { TypeOptions } from 'react-toastify';
import { ifProp } from 'styled-tools';

import { COLORS, getColor, ThemeDimensions } from '../../styles';
import { Button } from '../button';
import { Icon } from '../icon';

type Props = {
  title: ReactNode;
  message?: ReactNode;
  closeButton?: boolean;
  children?: ReactNode;
  closeToast?: () => void;
  buttons?: ComponentProps<typeof Button>[];
  hideIcon?: boolean;
  toastProps?: {
    type: 'error' | 'success';
  };
};

const getIcon = (type: TypeOptions) => {
  switch (type) {
    case 'error':
      return 'alert error';
    case 'success':
      return 'alert success';
  }
};

const getBackgroundColor = (type: TypeOptions) => {
  switch (type) {
    case 'error':
      return COLORS.BLACK;
    case 'success':
      return COLORS.WHITE;
  }
};

const getFontColor = (type: TypeOptions) => {
  switch (type) {
    case 'error':
      return 'inherit';
    default:
      return 'textPrimary';
  }
};

const StyledButton = styled(Button)<{ $whiteColor?: boolean }>`
  ${ifProp(
    '$whiteColor',
    css`
      color: ${getColor('WHITE')};
    `,
  )}
`;

export const Alert = ({
  title,
  message,
  closeButton,
  children,
  toastProps = { type: 'success' },
  closeToast,
  buttons,
  hideIcon,
  ...props
}: Props) => {
  const type = toastProps.type;
  const icon = type && getIcon(type);
  const backgroundColor = type && getBackgroundColor(type);
  const fontColor = type && getFontColor(type);
  const buttonsCount = buttons?.length || 0;

  return (
    <Box
      sx={{
        py: children ? 2 : 3,
        pr: children ? 2 : 3,
        pl: 2,
        bgcolor: backgroundColor,
        maxWidth: children
          ? ThemeDimensions.ALERT_WITH_CONTENT_MAX_WIDTH
          : ThemeDimensions.ALERT_MAX_WIDTH,
      }}
      data-test-class="custom-alert-container"
      {...props}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          {icon ? <Box mr={2}>{!hideIcon ? <Icon icon={icon} /> : null}</Box> : null}
          <Box color="background.paper">
            <Typography variant="subtitle2" color={fontColor} data-test-class="alert-body">
              {title}
            </Typography>
          </Box>
        </Box>
        {buttonsCount === 1 && !message ? (
          <Box pl={2}>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              whiteBackground
              {...buttons?.[0]}
            />
          </Box>
        ) : null}
        {closeButton ? (
          <Box ml={2} alignSelf={buttonsCount === 1 ? 'unset' : 'flex-start'}>
            <IconButton size="small" onClick={closeToast} data-test-class="alert-close">
              <Icon icon="close gray" />
            </IconButton>
          </Box>
        ) : null}
      </Box>
      {buttonsCount > 1 || children || message ? (
        <Box mt={1} color="background.paper">
          {message ? (
            <Box pl={5}>
              <Typography variant="body1" color={fontColor}>
                {message}
              </Typography>
            </Box>
          ) : null}
          {children ? <Box pl={5}>{children}</Box> : null}
          {buttonsCount && (message || buttonsCount > 1) ? (
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: buttonsCount === 2 ? 'row' : 'column', md: 'row' }}
              justifyContent={{ sm: buttonsCount === 2 ? 'space-around' : 'unset', md: 'flex-end' }}
              mt={2}
            >
              {buttons?.map((buttonProps, i) => {
                const isLast = buttonsCount - 1 === i;
                return (
                  <Box
                    key={i}
                    display="flex"
                    flexDirection="column"
                    ml={{ md: 2 }}
                    mb={!isLast ? { xs: 2, sm: 2, md: 0, lg: 0, xl: 0 } : undefined}
                  >
                    <StyledButton
                      variant="outlined"
                      color="secondary"
                      $whiteColor={!isLast}
                      whiteBackground={isLast}
                      {...buttonProps}
                    />
                  </Box>
                );
              })}
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};
