import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import type { ComponentProps, ReactNode } from 'react';
import React, { useContext } from 'react';

import { Button } from '../button';
import { WizardContext, WizardStepBase } from '../wizard';

interface ButtonWithTestID extends ComponentProps<typeof Button> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'data-test-id'?: string;
}

type Props = ComponentProps<typeof WizardStepBase> & {
  id: number;
  title?: string;
  buttons?: ButtonWithTestID[];
  secondaryActions?: ReactNode;
};

export const WizardStep = ({ title, id, children, buttons, secondaryActions }: Props) => {
  const { steps, title: wizardTitle, titleTypographyProps, testId } = useContext(WizardContext);
  const { label: stepLabel, hideStepper: hideCurrentStepper } =
    steps?.find((s) => s.id === id) ?? {};
  const nonHiddenSteps = steps?.filter((s) => !s.hideStepper);
  const activeStepperStep = nonHiddenSteps?.findIndex((s) => s.id === id);
  return (
    <WizardStepBase id={id}>
      <Box p={4} data-test-id={testId}>
        <Box mt={2} mb={1} display="flex">
          <Typography
            variant="subtitle1"
            {...titleTypographyProps}
            data-test-id={testId ? `${testId}-title` : undefined}
          >
            {title || wizardTitle || stepLabel}
          </Typography>
        </Box>
        {steps?.length && !hideCurrentStepper ? (
          <Box>
            <Stepper activeStep={activeStepperStep}>
              {nonHiddenSteps?.map(({ id, label }) => (
                <Step key={id}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        ) : null}
        <Box mt={2}>{children}</Box>
        {secondaryActions || buttons ? (
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex">{secondaryActions}</Box>
            <Box display="flex">
              {buttons?.map((buttonProps, i) => {
                return (
                  <Box key={i} display="flex" flexDirection="column" ml={2}>
                    <Button variant="outlined" color="secondary" size="small" {...buttonProps} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : null}
      </Box>
    </WizardStepBase>
  );
};
