import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';

import { HOURS_UNTIL_DEVICE_DISCONNECTED } from '../constants/devices';
import { DeviceTypeDefinitions } from '../constants/deviceTypes';
import type { DeviceSettingMetadata } from '../types/DeviceSettings';
import { SettingsMetaDataType } from '../types/DeviceSettings';
import { DeviceType } from '../types/DeviceType';
import { REGEX_ONLY_NUMBERS } from './regex';

export const getDeviceTypeDefinitionFromDeviceType = (deviceType: DeviceType) => {
  return DeviceTypeDefinitions[deviceType] ?? DeviceTypeDefinitions[DeviceType.UNKNOWN];
};

export const validateDeviceSetting = (
  setting: DeviceSettingMetadata,
  allSettings: DeviceSettingMetadata[],
): string | undefined => {
  const lf = new Intl.ListFormat('en');

  if (
    !isNil(setting.constraints.minValue) &&
    Number(setting.value) < setting.constraints.minValue
  ) {
    return `Value must be greater than or equal to ${setting.constraints.minValue}`;
  }

  if (
    !isNil(setting.constraints.maxValue) &&
    Number(setting.value) > setting.constraints.maxValue
  ) {
    return `Value must be less than or equal to ${setting.constraints.maxValue}`;
  }

  const settingsViolatingGreaterOrEqualThanConstraints =
    setting.constraints?.greaterOrEqualThan.filter((settingName) => {
      const settingValue = allSettings.find((s) => s.name === settingName)?.value;
      return Number(settingValue) > Number(setting.value);
    });

  if (!isEmpty(settingsViolatingGreaterOrEqualThanConstraints)) {
    const greaterOrEqualSettingLabels = settingsViolatingGreaterOrEqualThanConstraints.map(
      (settingName) => allSettings.find((s) => s.name === settingName)?.label,
    );
    return `Value must be greater than or equal to ${lf.format(
      greaterOrEqualSettingLabels as string[],
    )}`;
  }

  const settingViolatingLessOrEqualThanConstraints = setting.constraints?.lessOrEqualThan.filter(
    (settingName) => {
      const settingValue = allSettings.find((s) => s.name === settingName)?.value;
      return Number(settingValue) < Number(setting.value);
    },
  );

  if (!isEmpty(settingViolatingLessOrEqualThanConstraints)) {
    const lessOrEqualSettingLabels = settingViolatingLessOrEqualThanConstraints.map(
      (settingName) => allSettings.find((s) => s.name === settingName)?.label,
    );

    return `Value must be less than or equal to ${lf.format(lessOrEqualSettingLabels as string[])}`;
  }

  if (
    setting.type === SettingsMetaDataType.INTEGER &&
    !REGEX_ONLY_NUMBERS.test(setting.value.toString())
  ) {
    return `Value must be an integer`;
  }

  if (
    setting.type === SettingsMetaDataType.STRING &&
    setting.constraints.regexMatch &&
    !new RegExp(setting.constraints.regexMatch).test(setting.value.toString())
  ) {
    return `Value must be in the correct format`;
  }
};

export const getDeviceSettingHelperText = (setting: DeviceSettingMetadata) => {
  return !isNil(setting.constraints.minValue) && !isNil(setting.constraints.maxValue)
    ? `${setting.constraints.minValue} - ${setting.constraints.minValue < 0 ? '+' : ''}${
        setting.constraints.maxValue
      }`
    : '';
};

export const getIsDeviceDisconnected = (
  lastHeardOffsetDateTime: string,
  siteTimezone: string,
): boolean => {
  // Parse the lastHeardOffsetDateTime using the specified site timezone
  const lastHeardTime: DateTime = DateTime.fromISO(lastHeardOffsetDateTime, { zone: siteTimezone });

  // Get the current time in the specified site timezone and subtract the HOURS_UNTIL_DEVICE_OFFLINE
  const comparisonTime: DateTime = DateTime.now()
    .setZone(siteTimezone)
    .minus({ hours: HOURS_UNTIL_DEVICE_DISCONNECTED });

  // Compare if the lastHeardTime is before the comparisonTime
  return lastHeardTime < comparisonTime;
};
