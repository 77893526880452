import type { Unit } from '../types/Unit';

const getUnitTitle = ({ unit, unitType }: { unit?: Unit; unitType?: string }) => {
  let subtitle = '';
  if (unit) {
    subtitle = unit.symbol;
    if (unitType) {
      subtitle += ` ${unitType}`;
    }
  }
  return subtitle;
};

export default getUnitTitle;
