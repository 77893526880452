import { makeStyles } from '@mui/styles';

import { COLORS } from '../../styles';

export const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
  },
  buttonDefault: {
    width: 40,
    height: 40,
    zIndex: 1,
    margin: 0,
    padding: 0,
    boxShadow: 'none',
    border: `1px solid ${COLORS.SILVER}`,
    '&:hover': {
      boxShadow: 'none',
    },
  },
  buttonPrimary: {
    backgroundColor: COLORS.WHITE,
    '&:hover': {
      backgroundColor: COLORS.WHITE,
    },
  },
  buttonError: {
    backgroundColor: COLORS.ERROR,
    '&:hover': {
      backgroundColor: COLORS.ERROR,
    },
  },
  buttonSuccess: {
    backgroundColor: COLORS.ERROR,
    '&:hover': {
      backgroundColor: COLORS.ERROR,
    },
  },
  buttonDisabled: {
    backgroundColor: COLORS.SILVER,
    '&:hover': {
      backgroundColor: COLORS.SILVER,
    },
  },
  fabProgress: {
    width: 40,
    height: 40,
    borderRadius: 40,
    backgroundColor: COLORS.WHITE,
    color: COLORS.GREEN,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  iconProgress: {
    position: 'absolute',
    zIndex: 2,
    top: 12,
    left: 12,
  },
});
