import jwt from 'jsonwebtoken';

import type { AccessTokenPayload, DecodedAccessToken } from '../types/AccessToken';
import type { AuthData, AuthRefreshTokenResponse, AuthTokenResponse } from '../types/Auth';
import type { UserPermission } from '../types/UserPermission';
import { getUIFeaturesList } from './uiFeatureHelpers';

const REFRESH_TOKEN_STORE_NAME = 'refreshToken';

export const setRefreshTokenToStore = (token: string) => {
  localStorage.setItem(REFRESH_TOKEN_STORE_NAME, token);
};

export const getRefreshTokenFromStore = () => {
  return localStorage.getItem(REFRESH_TOKEN_STORE_NAME);
};

export const clearRefreshTokenInStore = () => {
  return localStorage.removeItem(REFRESH_TOKEN_STORE_NAME);
};

export const hasRefreshTokenInStore = (): boolean => {
  const token = getRefreshTokenFromStore();
  return !!token && token.length > 0;
};

export const decodeJWTToken = (token: string): DecodedAccessToken => {
  return jwt.decode(token, { complete: true }) as DecodedAccessToken;
};

export const getAuthorizationToken = (accessToken: string) => `Bearer ${accessToken}`;

export const getAuthDataFromAccessToken = (
  authTokenResponse: AuthTokenResponse | AuthRefreshTokenResponse,
): AuthData => {
  const { accessToken, userId } = authTokenResponse;
  const decodedAccessToken = decodeJWTToken(accessToken);
  const accessTokenPayload = decodedAccessToken.payload as AccessTokenPayload;

  /**
   * company_id only exists in the access token for INSTALLER users
   */
  const {
    role: userRole,
    ui_type: userInterfaceType,
    company_id: companyId,
    permissions,
  } = accessTokenPayload;
  const userPermissions = permissions.split(',') as UserPermission[];
  const uiFeatures = getUIFeaturesList(userPermissions, userInterfaceType);

  return { userId, userInterfaceType, userRole, userPermissions, companyId, uiFeatures };
};
