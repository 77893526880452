import { CompanyAccessLevel } from '../types/CompanyAccessLevel';
import type { UIFeatureDefinition } from '../types/UIFeature';
import { UIFeature } from '../types/UIFeature';
import { UserInterfaceType } from '../types/UserInterfaceType';
import { UserPermission } from '../types/UserPermission';

/**
 * UI Features
 *
 * these are the features that require permissions and/or access-level
 * checks before access is allowed
 */
const UIFeatureDefinitions: Record<keyof typeof UIFeature, UIFeatureDefinition> = {
  // --
  // Site Features
  // --
  [UIFeature.SITE_VIEW]: {
    name: 'View Site',
    requiredPermissions: [UserPermission.SITE_VIEW],
  },
  [UIFeature.SITE_EDIT]: {
    name: 'Edit Site',
    requiredPermissions: [UserPermission.SITE_EDIT],
    requiredAccessLevels: [
      CompanyAccessLevel.EDITOR,
      CompanyAccessLevel.OWNER,
      CompanyAccessLevel.ADMIN,
    ],
  },
  [UIFeature.SITE_EMAIL_EDIT]: {
    name: 'Edit Site Email Address',
    requiredPermissions: [UserPermission.SITE_EDIT],
    requiredAccessLevels: [
      CompanyAccessLevel.EDITOR,
      CompanyAccessLevel.OWNER,
      CompanyAccessLevel.ADMIN,
    ],
    requiredUserInterfaceType: UserInterfaceType.SUPPORT,
  },
  [UIFeature.SITE_SHARE]: {
    name: 'Share Site',
    requiredPermissions: [UserPermission.SITE_SHARE],
    requiredAccessLevels: [CompanyAccessLevel.OWNER, CompanyAccessLevel.ADMIN],
  },
  [UIFeature.SITE_DELETE]: {
    // @note not implemented yet
    name: 'Delete Site',
    requiredPermissions: [UserPermission.SITE_DELETE],
    requiredUserInterfaceType: UserInterfaceType.SUPPORT,
  },
  [UIFeature.SITE_ADD]: {
    name: 'Add Site',
    requiredPermissions: [UserPermission.SITE_EDIT],
  },
  [UIFeature.SITE_MOVE]: {
    name: 'Move Site',
    requiredPermissions: [UserPermission.SITE_MOVE],
    requiredUserInterfaceType: UserInterfaceType.SUPPORT,
  },
  [UIFeature.SITE_ADDRESS_EDIT]: {
    name: 'Edit Site Address',
    requiredPermissions: [UserPermission.SITE_EDIT],
    requiredUserInterfaceType: UserInterfaceType.SUPPORT,
  },
  // --
  // System Features
  // --
  [UIFeature.SYSTEM_ADD]: {
    name: 'Add System',
    requiredPermissions: [UserPermission.SYSTEM_ADD],
  },
  [UIFeature.SYSTEM_DELETE]: {
    name: 'Delete System',
    requiredPermissions: [UserPermission.SYSTEM_DELETE],
    requiredUserInterfaceType: UserInterfaceType.SUPPORT,
  },
  [UIFeature.SYSTEM_MOVE]: {
    // @note disabled. waiting on endpoint dev
    name: 'Move System',
    requiredPermissions: [UserPermission.SYSTEM_MOVE],
    requiredUserInterfaceType: UserInterfaceType.SUPPORT,
  },
  // --
  // Company Features
  // --
  [UIFeature.COMPANY_VIEW]: {
    name: 'View Company',
    requiredPermissions: [UserPermission.COMPANY_VIEW],
  },
  [UIFeature.COMPANY_EDIT]: {
    name: 'Edit Company',
    requiredPermissions: [UserPermission.COMPANY_EDIT],
  },
  [UIFeature.COMPANY_BULK_EDIT]: {
    name: 'Bulk Edit Companies',
    requiredPermissions: [UserPermission.COMPANY_BULK_EDIT],
  },
  [UIFeature.COMPANY_BULK_MERGE]: {
    name: 'Merge Companies',
    requiredPermissions: [UserPermission.COMPANY_BULK_EDIT],
  },
  [UIFeature.COMPANY_ASSOCIATIONS_EDIT]: {
    name: 'Create/Edit/Remove Company Associations',
    requiredPermissions: [UserPermission.COMPANY_EDIT],
  },
  [UIFeature.COMPANY_ASSOCIATIONS_VIEW]: {
    name: 'View Company Associations',
    requiredPermissions: [UserPermission.COMPANY_VIEW],
  },
  // --
  // User Features
  // --
  [UIFeature.USER_VIEW]: {
    name: 'View User',
    requiredPermissions: [UserPermission.USER_VIEW],
  },
  [UIFeature.USER_EDIT]: {
    name: 'Edit User',
    requiredPermissions: [UserPermission.USER_EDIT],
  },
  [UIFeature.USER_INVITE]: {
    name: 'Invite Users',
    requiredPermissions: [UserPermission.USER_EDIT],
  },
  // --
  // Support Features
  // @note SUPPORT_ALERTS_VIEW will be replaced with feature toggles in the near future
  // and this UIFeature can be deleted
  [UIFeature.SUPPORT_ALERTS_VIEW]: {
    name: 'View Alerts',
    requiredUserInterfaceType: UserInterfaceType.SUPPORT,
  },
  [UIFeature.SUPPORT_VIEW]: {
    name: 'View Support Layout',
    requiredUserInterfaceType: UserInterfaceType.SUPPORT,
  },
  [UIFeature.SUPPORT_ALL_USERS_VIEW]: {
    name: 'View All Users',
    requiredUserInterfaceType: UserInterfaceType.SUPPORT,
    requiredPermissions: [UserPermission.USER_VIEW],
  },
  [UIFeature.SUPPORT_ALL_COMPANIES_VIEW]: {
    name: 'View All Companies',
    requiredUserInterfaceType: UserInterfaceType.SUPPORT,
    requiredPermissions: [UserPermission.COMPANY_VIEW],
  },
  [UIFeature.SUPPORT_USER_EDIT]: {
    name: 'Edit Support User',
    requiredPermissions: [UserPermission.USER_EDIT_SUPPORT],
  },
  [UIFeature.SUPPORT_USER_INVITE]: {
    name: 'Invite Support Users',
    requiredPermissions: [UserPermission.USER_EDIT_SUPPORT],
  },
  [UIFeature.ALERT_DISMISS]: {
    name: 'Dismiss Alert',
    requiredPermissions: [UserPermission.ALERT_DISMISS],
  },
  [UIFeature.DEVICE_DELETE]: {
    name: 'Delete Device',
    requiredPermissions: [UserPermission.DEVICE_DELETE],
  },
  [UIFeature.POWERCORE_SWAP]: {
    name: 'Swap Power Core',
    requiredPermissions: [UserPermission.POWERCORE_SWAP],
  },
  [UIFeature.MCU_SWAP]: {
    name: 'Swap Load Manager MCU',
    requiredPermissions: [UserPermission.MCU_SWAP],
  },
  [UIFeature.ODIN_CALENDAR]: {
    name: 'Odin Calendar',
    requiredPermissions: [UserPermission.ODIN_VIEW],
  },
  // Load Manager Features
  [UIFeature.LOAD_MANAGER_PAIRING_EDIT]: {
    name: 'Edit Load Manager Pairing',
    requiredPermissions: [UserPermission.LOAD_MANAGER_PAIRING_EDIT],
  },
  [UIFeature.LOAD_MANAGER_DETAILS_VIEW]: {
    name: 'View Load Manager Live Details',
    requiredPermissions: [UserPermission.LOAD_MANAGER_DETAILS_VIEW],
  },
  [UIFeature.AFU_CAMPAIGN_MANAGER_CREATE]: {
    name: 'Create new campaign',
    requiredPermissions: [UserPermission.AFU_CAMPAIGN_MANAGER_CREATE],
  },
  [UIFeature.AFU_CAMPAIGN_MANAGER_VIEW]: {
    name: 'View running campaigns',
    requiredPermissions: [UserPermission.AFU_CAMPAIGN_MANAGER_VIEW],
  },
  [UIFeature.LOAD_MANAGER_SUBMIT_FETCH_LOG_JOB]: {
    name: 'Submit request to fetch load manager logs',
    requiredPermissions: [UserPermission.LOAD_MANAGER_FETCH_LOG],
  },
  // Support Portal Features
  [UIFeature.CASE_CREATE]: {
    name: 'Create Support Case',
    requiredPermissions: [UserPermission.CASE_CREATE],
  },
  [UIFeature.ADD_ES2]: {
    name: 'Add ES2 to the site',
    requiredUserInterfaceType: UserInterfaceType.SUPPORT,
    requiredPermissions: [UserPermission.SITE_EDIT],
  },
};

export default UIFeatureDefinitions;
