import { DateTime } from 'luxon';

import type {
  GetSiteAlertsResponse,
  TransformedGetSiteAlertsResponse,
} from '../../types/api/getSiteAlerts';

export const transformSiteAlertsResponse = (
  response: GetSiteAlertsResponse,
): TransformedGetSiteAlertsResponse => {
  const { alerts, ...rest } = response;

  return {
    alerts: alerts.map((alert) => {
      const { alertId, openedAt, transitionedAt, ...rest } = alert;
      const openedAtDate = DateTime.fromSeconds(openedAt).toJSDate();

      return {
        id: alertId,
        openedAt: openedAtDate,
        closedAt: DateTime.fromSeconds(transitionedAt).toJSDate(),
        duration: openedAtDate,
        ...rest,
      };
    }),
    ...rest,
  };
};
