import type { System } from '../types/SiteDetails';
import { StatusType, SystemStatusName, SystemStatusTitle } from '../types/SiteDetails';

export const getSystemStatusText = (system: System, hasError: boolean) => {
  if (hasError) {
    return SystemStatusTitle.STATUS_ERROR; // server error
  } else if (system.statusName === SystemStatusName.DISCONNECTED) {
    return SystemStatusTitle.DISCONNECTED; // system level status that takes priority
  } else if (system.statusName === SystemStatusName.NEVER_CONNECTED) {
    return SystemStatusTitle.NEVER_CONNECTED; // system level status that takes priority
  } else if (!system.inverter || !system.inverter.statusName) {
    return SystemStatusTitle.STATUS_ERROR; // guard against nonexistent inverter object
  } else if (system.inverter.statusName === 'STATUS_CODE_NOT_FOUND') {
    return `${SystemStatusTitle[system.inverter.statusName]}: ${system.inverter.statusCode}`;
  } // displays user-friendly version of STATUS_CODE_NOT_FOUND along with statusCode
  return SystemStatusTitle[system.inverter.statusName]; // user-friendly version of inverter statusName
};

export const getSystemStatusIcon = (system: System, hasError: boolean) => {
  if (hasError) {
    return 'transient'; // server error
  } else if (system.statusName === SystemStatusName.DISCONNECTED) {
    return 'no connectivity'; // system level status that takes priority
  } else if (system.statusName === SystemStatusName.NEVER_CONNECTED) {
    return 'never connected'; // system level status that takes priority
  }
  // inverter statusTypes that have associated icons
  switch (system.inverter?.statusType) {
    case StatusType.GRID_CONNECTED:
      return 'check';
    case StatusType.ISLANDED:
      return 'islanding';
    case StatusType.ERROR:
      return 'error';
    case StatusType.TRANSIENT:
      return 'transient';
    case StatusType.DISABLED:
      return 'disabled';
  }
};
