import { useGetSite, useGetSiteStatus } from '../store/api/endpoints/site';
import { useSiteContext } from './useSiteContext';

const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const useSiteTimezone = (): string => {
  const { siteId = '' } = useSiteContext();
  const { data: site } = useGetSite({ siteId });
  const { data: siteStatus } = useGetSiteStatus({ siteId });

  const siteTimezone = site?.timezone ?? siteStatus?.timezone;

  return siteTimezone ?? BROWSER_TIMEZONE;
};
