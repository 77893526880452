import type { IconButtonProps, TooltipProps } from '@mui/material';
import { IconButton as MuiIconButton } from '@mui/material';

import { Icon } from '../icon';
import type { IconType } from '../icon/icons';
import { PWRtooltip } from '../pwrTooltip';

interface Props extends IconButtonProps {
  icon: IconType;
  tooltipMessage: string;
  tooltipPlacement?: TooltipProps['placement'];
}

export const IconButton = ({
  icon,
  tooltipMessage,
  tooltipPlacement = 'top',
  ...iconButtonProps
}: Props) => {
  return (
    <PWRtooltip message={tooltipMessage} placement={tooltipPlacement}>
      <MuiIconButton {...iconButtonProps}>
        <Icon icon={icon} />
      </MuiIconButton>
    </PWRtooltip>
  );
};
