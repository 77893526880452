import type { CheckboxProps } from '@mui/material';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import type { ReactNode } from 'react';

import { COLORS } from '../../styles';

interface Props extends CheckboxProps {
  label: string | ReactNode;
  isDisabled?: boolean;
  hasError?: boolean;
}

export const PWRcheckbox = ({
  label,
  hasError = false,
  isDisabled = false,
  color = 'default',
  size = 'small',
  ...checkboxProps
}: Props) => {
  const overrideColor = hasError ? COLORS.ERROR : isDisabled ? COLORS.LIGHT_GREY : 'inherit';

  return (
    <FormControlLabel
      label={<Typography sx={{ color: overrideColor }}>{label}</Typography>}
      control={
        <Checkbox
          color={color}
          size={size}
          disabled={isDisabled}
          sx={{ color: overrideColor }}
          inputProps={{
            // @ts-expect-error
            'data-test-id': `checkbox-${checkboxProps.name}`,
          }}
          {...checkboxProps}
        />
      }
    />
  );
};
