import type { TypographyProps } from '@mui/material';
import type { ComponentProps, ReactElement } from 'react';
import React, { createContext } from 'react';

import { Modal } from '../modal';

type IdType = string | number;

type WizardStepProps = {
  id: IdType;
  children: React.ReactNode;
};

export type Step = {
  id: number;
  label: string;
  hideStepper?: boolean;
};

type WizardContextType = {
  steps?: Step[];
  title?: string;
  titleTypographyProps?: TypographyProps;
  testId?: string;
};

type WizardProps = WizardContextType &
  Omit<ComponentProps<typeof Modal>, 'children'> & {
    children: ReactElement<WizardStepProps> | ReactElement<WizardStepProps>[];
    activeId: IdType;
    titleTypographyProps?: TypographyProps;
  };

export const WizardContext = createContext<WizardContextType>({
  steps: [],
  title: '',
  titleTypographyProps: {},
  testId: undefined,
});

const WizardStepImpl = ({ children }: WizardStepProps) => {
  return <>{children}</>;
};

const WizardImpl = ({
  children,
  activeId,
  steps,
  title,
  titleTypographyProps,
  testId,
  ...props
}: WizardProps) => {
  const childrenSteps = React.Children.toArray(children);
  const activeStep = childrenSteps.find((childStep) => {
    // a typescript issue (now fixed) with accessing childStep.props came up here after upgrading to @types/react v17
    // @see https://stackoverflow.com/questions/52524093/react-children-toarray-cant-find-props-on-children-using-typescript
    if (React.isValidElement<WizardStepProps>(childStep)) {
      return childStep.props.id === activeId;
    } else {
      return false;
    }
  });

  return (
    <Modal {...props}>
      <WizardContext.Provider value={{ steps, title, titleTypographyProps, testId }}>
        {activeStep}
      </WizardContext.Provider>
    </Modal>
  );
};

export const WizardStepBase = React.memo(WizardStepImpl);
export const Wizard = React.memo(WizardImpl);
