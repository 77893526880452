import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import type { ComponentProps } from 'react';
import React from 'react';

import { getColor } from '../../styles';
import { Icon } from '../icon';

const StyledBackOnTopButton = styled(Fab)`
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  height: 48px;
  width: 48px;
  background-color: ${getColor('WHITE')} !important;
`;

export const BackToTop = () => {
  const anchor = document.getElementById('back-to-top-anchor');
  if (anchor) {
    anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

type Props = Omit<ComponentProps<typeof Fab>, 'children'>;

export const BackOnTopButton = (props: Props) => {
  return (
    <StyledBackOnTopButton {...props} onClick={BackToTop}>
      <Icon icon="chevron down" rotate={180} size={30} />
    </StyledBackOnTopButton>
  );
};
