import type { ComponentProps } from 'react';

import { Alert } from '../alert';
import { SupportLink } from '../supportLink';

type AlertProps = ComponentProps<typeof Alert>;

export const CommonErrorAlert = ({ title, ...props }: AlertProps) => {
  return (
    <Alert
      title={
        <>
          {title} Please try again, or contact <SupportLink />.
        </>
      }
      data-test-class="alert-common"
      closeButton
      {...props}
    />
  );
};
