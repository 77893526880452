import { AlertDetailsTab, AlertsTab } from '../types/Alert';

interface Definition {
  name: string;
}
export const AnalyticsAlertsTableTab: { [key in AlertsTab]: Definition } = {
  [AlertsTab.ACTIVE]: {
    name: 'Active Alerts',
  },
  [AlertsTab.HISTORY]: {
    name: 'All Alerts',
  },
  [AlertsTab.PINNED]: {
    name: 'Pinned Alerts',
  },
  [AlertsTab.DISMISSED]: {
    name: 'Dismissed Alerts',
  },
};

export const AnalyticsAlertModalTab: { [key in AlertDetailsTab]: Definition } = {
  [AlertDetailsTab.DETAILS]: {
    name: 'Alert Details',
  },
  [AlertDetailsTab.TROUBLESHOOTING]: {
    name: 'Alert Troubleshooting',
  },
  [AlertDetailsTab.HISTORY]: {
    name: 'Alert History',
  },
  [AlertDetailsTab.EVENT_LOG]: {
    name: 'Alert Event Log',
  },
  [AlertDetailsTab.NOTES]: {
    name: 'Alert Notes',
  },
};
