import { first } from 'lodash';

import type { LoadManagerConfig } from '../types/api/getLoadManagerConfigs';
import type { System } from '../types/SiteDevices';

export const getIsPairedLoadManager = (system: System, loadManagerConfig: LoadManagerConfig) => {
  const { inverters, beacons } = system.devices;
  const beacon = first(beacons);
  const inverter = first(inverters);

  if (!beacon || !inverter) {
    return false;
  }

  const hasBeaconConfig = beacon.deviceRcpn === loadManagerConfig.essConfig?.beaconRcpn;
  const hasInverterConfig = inverter.deviceRcpn === loadManagerConfig.essConfig?.inverterRcpn;

  return hasInverterConfig && hasBeaconConfig;
};
