import type { ActionsObservable, Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import type { Observable } from 'rxjs';
import { from } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';
import type { Action } from 'typesafe-actions';
import { isActionOf } from 'typesafe-actions';

import { trackEvent } from '../../analyticsClient';
import {
  getCompanyUsers,
  getPendingCompanyUsers,
  inviteUsers,
  removeCompanyUser as apiRemoveCompanyUser,
  updateCompanyUserRole as apiUpdateCompanyUserRole,
} from '../../api';
import { AnalyticsEvent, AnalyticsProperty } from '../../types/Analytics';
import type {
  CompanyUsersFailedResponse,
  CompanyUsersSuccessResponse,
  PendingCompanyUsersFailedResponse,
  PendingCompanyUsersSuccessResponse,
  RemoveCompanyUserSuccessResponse,
  UpdateCompanyUserRoleRequest,
  UpdateCompanyUserRoleSuccessResponse,
} from '../../types/CompanyUser';
import { CompanyUserStatus } from '../../types/CompanyUser';
import { AlertContainer } from '../../types/ScreenAlerts';
import type { UserInviteSuccessResponse } from '../../types/UserInvite';
import { callActionAlert } from '../../utils/alerts';
import { userIdSelector } from '../auth/selectors';
import { companyIdSelector } from '../company/selectors';
import {
  loadCompanyUsers,
  loadPendingCompanyUsers,
  removeCompanyUser,
  sendInvites,
  updateCompanyUserRole,
} from './actions';
import { companyUserSelector, pendingCompanyUserSelector } from './selectors';

/**
 *
 * loadCompanyUsersEpic will be called explicitly via dispatch(loadCompanyUsers.request)
 * or automatically following an updateCompanyUserRole.success to load new User's role data
 */
const loadCompanyUsersEpic: Epic = (action$, $state) =>
  action$.pipe(
    filter(
      isActionOf([
        loadCompanyUsers.request,
        updateCompanyUserRole.success,
        removeCompanyUser.success,
      ]),
    ),
    mergeMap((action) => {
      let companyId = companyIdSelector($state.value);
      if (isActionOf(loadCompanyUsers.request)(action)) {
        companyId = action.payload.companyId;
      }
      return from(getCompanyUsers(companyId)).pipe(
        map((a) => loadCompanyUsers.success(a as CompanyUsersSuccessResponse)),
        catchError(async (e: CompanyUsersFailedResponse) => loadCompanyUsers.failure(e)),
      );
    }),
  );

const loadPendingCompanyUsersEpic: Epic = (action$, $state) =>
  action$.pipe(
    filter(isActionOf([loadPendingCompanyUsers.request, removeCompanyUser.success])),
    mergeMap((action) => {
      let companyId = companyIdSelector($state.value);
      if (isActionOf(loadPendingCompanyUsers.request)(action)) {
        companyId = action.payload.companyId;
      }
      return from(getPendingCompanyUsers(companyId)).pipe(
        map((a) => loadPendingCompanyUsers.success(a as PendingCompanyUsersSuccessResponse)),
        catchError(async (e: PendingCompanyUsersFailedResponse) =>
          loadPendingCompanyUsers.failure(e.response?.data || null),
        ),
      );
    }),
  );

const sendInvitesEpic: Epic = (action$: ActionsObservable<Action>, state$): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(sendInvites.request)),
    mergeMap((action): Observable<Action> => {
      const {
        payload: { emails, role },
      } = action;
      const userId = userIdSelector(state$.value);
      const companyId = companyIdSelector(state$.value);
      return from(
        inviteUsers(userId, {
          companyId,
          signupLink: `${window.location.origin}/signup`,
          users: emails.map((email) => ({ email, role })),
        }),
      ).pipe(
        map((res) => sendInvites.success(res as UserInviteSuccessResponse)),
        catchError(async (error) => sendInvites.failure(error?.response?.data)),
        mergeMap((res) => {
          callActionAlert(res, sendInvites.failure, {
            successText: `${emails.join(', ')} ${
              emails.length > 1 ? 'have' : 'has'
            } successfully been invited to this PWRfleet account.`,
            errorText: 'Failed to send invites.',
            errorContainerId: AlertContainer.Modal,
          });
          // [analytics] User Invite
          trackEvent(AnalyticsEvent.UserInvite, {
            [AnalyticsProperty.UserRole]: role,
            [AnalyticsProperty.Count]: emails.length,
          });
          return [res, loadPendingCompanyUsers.request({ companyId })];
        }),
      );
    }),
  );
};

const updateCompanyUserRoleEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> =>
  action$.pipe(
    filter(isActionOf(updateCompanyUserRole.request)),
    mergeMap(({ payload }: { payload: UpdateCompanyUserRoleRequest }): Observable<Action> => {
      return from(apiUpdateCompanyUserRole(payload)).pipe(
        map((res) => updateCompanyUserRole.success(res as UpdateCompanyUserRoleSuccessResponse)),
        catchError(async (error) => updateCompanyUserRole.failure(error?.response.data)),
      );
    }),
  );

const afterUpdateCompanyUserRoleEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf([updateCompanyUserRole.success, updateCompanyUserRole.failure])),
    map((response) => {
      callActionAlert(response, updateCompanyUserRole.failure, {
        successText: "User's role has been updated",
        errorText: "Failed to update user's role",
      });
    }),
    ignoreElements(),
  );

const removeCompanyUserEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> =>
  action$.pipe(
    filter(isActionOf(removeCompanyUser.request)),
    mergeMap(({ payload: { userId } }: { payload: { userId: string } }): Observable<Action> => {
      return from(apiRemoveCompanyUser({ userId })).pipe(
        map((res) => {
          // [analytics] User Delete
          const commonUser = companyUserSelector(state$.value, { userId });
          const pendingUser = pendingCompanyUserSelector(state$.value, { userId });
          const status = commonUser ? CompanyUserStatus.Active : CompanyUserStatus.Pending;
          const user = commonUser || pendingUser;
          trackEvent(AnalyticsEvent.UserDelete, {
            [AnalyticsProperty.UserRole]: user?.role,
            [AnalyticsProperty.UserStatus]: status,
          });
          return removeCompanyUser.success(res as RemoveCompanyUserSuccessResponse);
        }),
        catchError(async (error) => removeCompanyUser.failure(error?.response.data)),
      );
    }),
  );

const afterRemoveCompanyUserEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf([removeCompanyUser.success, removeCompanyUser.failure])),
    map((response) => {
      callActionAlert(response, removeCompanyUser.failure, {
        successText: 'User has been removed',
        errorText: 'Failed to remove user',
      });
    }),
    ignoreElements(),
  );

export default combineEpics(
  loadCompanyUsersEpic,
  loadPendingCompanyUsersEpic,
  sendInvitesEpic,
  updateCompanyUserRoleEpic,
  afterUpdateCompanyUserRoleEpic,
  removeCompanyUserEpic,
  afterRemoveCompanyUserEpic,
);
