import { Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

import { trackEvent } from '../../analyticsClient';
import { COLORS } from '../../styles';
import { AnalyticsEvent } from '../../types/Analytics';
import { PWRbutton } from '../pwrButton';

export const AddSiteButton = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push({ search: 'add-site-modal' });
    trackEvent(AnalyticsEvent.AddSiteButtonClick);
  };

  return (
    <PWRbutton
      text="Add Site"
      variant="contained"
      startIcon={<AddIcon />}
      onClick={handleClick}
      data-test-class="add-site-btn"
    />
  );
};

export const RefreshButton = () => {
  const history = useHistory();

  return (
    <PWRbutton
      text="Refresh Page"
      variant="outlined"
      color="secondary"
      startIcon={<RefreshIcon />}
      onClick={() => history.go(0)}
      sx={{ background: COLORS.WHITE }}
      data-test-class="refresh-page-btn"
    />
  );
};
