import { Checkbox } from '@mui/material';
import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { SelectedCheckBoxes } from '../../store/companies';
import { toggleCompanyCheckbox } from '../../store/companies/actions';
import {
  selectedCompaniesSelector,
  selectedSortOrderSelector,
  selectedSortPropertySelector,
} from '../../store/companies/selectors';
import { useViewMode } from '../../styles/stylingUtils';
import type { Company } from '../../types/Company';
import type { TableColumn, TablePresenters } from '../../types/Table';
import { UIFeature } from '../../types/UIFeature';
import type { SortOrder } from '../../utils/sort';
import { GatedFeature } from '../gatedFeature';
import { Table } from '../legacyTable';

const companyNamePresenter = (companyName: string, company: Company) => {
  const city = company.address?.city || '';
  return (
    <div>
      <div>{companyName}</div>
      {city && <div>{city}</div>}
    </div>
  );
};

const companyCheckBoxPresenter =
  (state: SelectedCheckBoxes, toggleById: (companyId: string) => void) => (companyId: string) => {
    return (
      <GatedFeature
        uiFeature={UIFeature.COMPANY_BULK_EDIT}
        onAllowed={() => (
          <Checkbox
            color="default"
            size="small"
            checked={!!state[companyId]}
            onClick={(e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLLabelElement>) =>
              e.stopPropagation()
            }
            onChange={() => toggleById(companyId)}
            inputProps={
              // Pass through the test-id to the underlying <Input> component, not the MUI abstractions on top of it
              //@ts-expect-error The MUI input component typing does not account for passing through HTML data attributes, but this DOES work as expected
              { 'data-test-id': `company-checkbox-${companyId}` }
            }
          />
        )}
      />
    );
  };

const presenters: TablePresenters<Company> = {};

const mobilePresenters: TablePresenters<Company> = {
  companyName: companyNamePresenter,
};

export interface Props {
  columns: TableColumn[];
  rows: Company[];
  onRowClick?: (e: unknown, row: Company) => void;
  style?: Record<string, unknown>;
}

export const CompaniesTable = (props: Props) => {
  const viewMode = useViewMode();
  const orderBy = useSelector(selectedSortPropertySelector);
  const order: SortOrder = useSelector(selectedSortOrderSelector);
  const selectedCompanies = useSelector(selectedCompaniesSelector);
  const dispatch = useDispatch();

  const toggleCheckbox = useCallback(
    (companyId: string) => {
      dispatch(toggleCompanyCheckbox(companyId));
    },
    [dispatch],
  );

  const currentPresenters = {
    ...(viewMode === 'mobile' ? mobilePresenters : presenters),
    companyId: companyCheckBoxPresenter(selectedCompanies, toggleCheckbox),
  };

  // @todo sort not yet implemented
  const handleRequestSort = useCallback(() => {
    return null;
  }, []);

  return (
    <Table
      {...props}
      presenters={currentPresenters}
      order={order}
      orderBy={orderBy}
      onRequestSort={handleRequestSort}
    />
  );
};
