import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { trackEvent } from '../../analyticsClient';
import { AnalyticsAlertModalTab } from '../../constants/analyticsTab';
import { useAppSelector } from '../../hooks/useAppSelector';
import {
  loadAlertCompanyInfo,
  loadAlertDetails,
  loadAlertHistory,
  resetAlertDetailsState,
  resetAlertEventLogState,
  resetAlertHistoryState,
} from '../../store/alertDetails/actions';
import {
  alertCompanyInfoSelector,
  alertDetailsErrorSelector,
  alertDetailsSelector,
  alertHistoryItemsSelector,
  alertHistoryPerPageSelector,
  alertTypeInfoErrorSelector,
  alertTypeInfoSelector,
  isLoadingAlertCompanyInfoSelector,
  isLoadingAlertDetailsSelector,
  isLoadingAlertHistorySelector,
  isLoadingAlertTypeInfoSelector,
} from '../../store/alertDetails/selectors';
import { useGetDeviceStatus } from '../../store/api/endpoints/device';
import { useGetSite, useGetSiteStatus } from '../../store/api/endpoints/site';
import { AlertDetailsTab } from '../../types/Alert';
import { AnalyticsEvent, AnalyticsPageName, AnalyticsProperty } from '../../types/Analytics';
import { Modal } from './modal';

const DEFAULT_TAB = AlertDetailsTab.DETAILS;

interface Props {
  alertId: string;
  open: boolean;
  onClose: () => void;
  siteId: string | undefined;
  isSiteLevel?: boolean;
}

export const AlertDetailsModal = ({
  alertId,
  open,
  onClose,
  siteId: siteIdProp,
  isSiteLevel = false,
}: Props) => {
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState<AlertDetailsTab>(DEFAULT_TAB);

  const alertDetails = useAppSelector(alertDetailsSelector);
  const isLoadingAlertDetails = useAppSelector(isLoadingAlertDetailsSelector);
  const companyInfo = useAppSelector(alertCompanyInfoSelector);
  const isLoadingCompanyInfo = useAppSelector(isLoadingAlertCompanyInfoSelector);
  const alertDetailsError = useAppSelector(alertDetailsErrorSelector);
  const alertTypeInfo = useAppSelector(alertTypeInfoSelector);
  const isLoadingAlertTypeInfo = useAppSelector(isLoadingAlertTypeInfoSelector);
  const alertTypeInfoError = useAppSelector(alertTypeInfoErrorSelector);
  const alertHistoryItems = useAppSelector(alertHistoryItemsSelector);
  const alertHistoryPerPage = useAppSelector(alertHistoryPerPageSelector);
  const isLoadingAlertHistory = useAppSelector(isLoadingAlertHistorySelector);

  const siteId = siteIdProp ?? alertDetails?.siteId;
  const { data: siteDetails, isLoading: isLoadingSiteDetails } = useGetSite(
    { siteId: siteId ?? '' },
    { skip: !open || !siteId },
  );
  const { data: siteStatusData, isLoading: isLoadingSiteStatus } = useGetSiteStatus(
    { siteId: siteId ?? '' },
    { skip: !open || !siteId },
  );
  const { data: deviceStatus, isLoading: isLoadingDeviceStatus } = useGetDeviceStatus(
    { siteId: siteId ?? '', deviceId: alertDetails?.deviceId ?? '' },
    { skip: !open || !siteId || !alertDetails?.deviceId },
  );
  const systems = siteStatusData?.systems ?? [];
  const systemInfo = systems.find((system) => system.systemId === alertDetails?.systemId) ?? null;

  const resetReduxState = useCallback(() => {
    dispatch(resetAlertDetailsState());
    dispatch(resetAlertHistoryState());
    dispatch(resetAlertEventLogState());
  }, [dispatch]);

  useEffect(() => {
    if (open && siteId) {
      trackEvent(AnalyticsEvent.PageView, {
        [AnalyticsProperty.PageName]: AnalyticsPageName.AlertDetailsModal,
        [AnalyticsProperty.PageTab]: AnalyticsAlertModalTab[currentTab].name,
        [AnalyticsProperty.SiteId]: siteId,
      });
    }
  }, [siteId, currentTab, open, alertId]);

  // @todo alert history
  // @todo alert event log
  useEffect(() => {
    // if a site id is passed, we can load the site details immediately
    // if not, load the alertDetails first then use that siteId to load siteDetails
    if (open && alertId) {
      resetReduxState();
      setCurrentTab(DEFAULT_TAB);
      dispatch(loadAlertDetails.request({ alertId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertId, open]);

  useEffect(() => {
    if (open && siteId) {
      if (!companyInfo) {
        dispatch(loadAlertCompanyInfo.request({ siteId }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, open, isSiteLevel, companyInfo]);

  const handleTabChanged = (tab: AlertDetailsTab) => {
    setCurrentTab(tab);
    // @todo load data for new tab
    switch (tab) {
      case AlertDetailsTab.HISTORY:
        if (!alertHistoryItems?.length) {
          dispatch(loadAlertHistory.request({ alertId, page: 1, perPage: alertHistoryPerPage }));
        }
        break;
    }
  };

  return (
    <Modal
      alertId={alertId}
      open={open}
      alertDetails={alertDetails}
      siteDetails={siteDetails ?? null}
      alertHistoryItems={alertHistoryItems}
      companyInfo={companyInfo}
      alertTypeInfo={alertTypeInfo}
      deviceStatus={deviceStatus ?? null}
      systemInfo={systemInfo}
      currentTab={currentTab}
      isLoadingAlertDetails={isLoadingAlertDetails}
      isLoadingSiteDetails={isLoadingSiteDetails}
      isLoadingCompanyInfo={isLoadingCompanyInfo}
      isLoadingDeviceStatus={isLoadingDeviceStatus}
      isLoadingAlertTypeInfo={isLoadingAlertTypeInfo}
      isLoadingAlertHistory={isLoadingAlertHistory}
      isLoadingSiteStatus={isLoadingSiteStatus}
      alertDetailsError={alertDetailsError}
      alertTypeInfoError={alertTypeInfoError}
      onTabChanged={handleTabChanged}
      onClose={onClose}
      onExited={() => resetReduxState()}
      isDraggable
    />
  );
};
