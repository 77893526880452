import type { ActionsObservable, Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import type { Observable } from 'rxjs';
import { from } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';
import type { Action } from 'typesafe-actions';
import { isActionOf } from 'typesafe-actions';

import { getCompany, getCompanyFleets, getUserCompanies, updateCompanyData } from '../../api';
import { callActionAlert } from '../../utils/alerts';
import { loadCompany, loadCompanyFleets, loadUserCompanies, updateCompany } from './actions';
import { companyIdSelector } from './selectors';

const loadCompanyFleetsEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(loadCompanyFleets.request)),
    mergeMap(({ payload: { companyId } }) =>
      from(getCompanyFleets(companyId)).pipe(
        map(loadCompanyFleets.success),
        catchError(async (e) => loadCompanyFleets.failure(e)),
      ),
    ),
  );

const companyNameEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadCompanyFleets.success)),
    mergeMap(() => {
      const companyId = companyIdSelector(state$.value) || '';
      return from(getCompany(companyId)).pipe(
        map(loadCompany.success),
        catchError(async (error) => loadCompany.failure(error)),
      );
    }),
  );

const companyDetailsEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(loadCompany.request)),
    mergeMap(({ payload: companyId }) =>
      from(getCompany(companyId)).pipe(
        map(loadCompany.success),
        catchError(async (e) => loadCompany.failure(e)),
      ),
    ),
  );

const loadUserCompaniesEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(loadUserCompanies.request)),
    mergeMap((action) => {
      const userId = action.payload;
      return from(getUserCompanies(userId)).pipe(
        map(loadUserCompanies.success),
        catchError(async (e) => loadUserCompanies.failure(e)),
      );
    }),
  );

const afterLoadUserCompaniesEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadUserCompanies.success)),
    mergeMap(() => {
      const companyId = state$.value.company.id;
      return from(getCompanyFleets(companyId)).pipe(
        map(loadCompanyFleets.success),
        catchError(async (e) => loadCompanyFleets.failure(e)),
      );
    }),
  );

const updateCompanyEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(updateCompany.request)),
    mergeMap((action): Observable<Action> => {
      const { companyId, body } = action.payload;
      return from(updateCompanyData(companyId, body)).pipe(
        mergeMap(() => [updateCompany.success({ companyId }), loadCompany.request(companyId)]),
        catchError(async (error) => updateCompany.failure(error)),
      );
    }),
  );
};

const updateCompanyNotificationEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf([updateCompany.success, updateCompany.failure])),
    map((action) => {
      callActionAlert(action, updateCompany.failure, {
        successText: 'Your company details have been successfully updated.',
        errorText: 'Your company details have not been updated.',
      });
    }),
    ignoreElements(),
  );

export default combineEpics(
  companyNameEpic,
  companyDetailsEpic,
  loadCompanyFleetsEpic,
  loadUserCompaniesEpic,
  afterLoadUserCompaniesEpic,
  updateCompanyEpic,
  updateCompanyNotificationEpic,
);
