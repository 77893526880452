import { string as YupString } from 'yup';

/**
 * @param value | string
 * @param validator | function
 * @returns string[]
 *
 * Takes in a string of comma separated values,
 *   splits into array by ',' character
 *   trims whitespace from each entry
 *   filters out empty strings
 */
export const parseCsvStringToCleanArray = (
  value: string,
  validator?: (value: string) => boolean,
) => {
  return value
    .split(',')
    .map((v) => v.trim()) // trim whitespace
    .filter((v) => v) // filter out empty strings
    .filter((v) => (validator ? validator(v) : true)); // run validator function (if supplied)
};

/**
 * @todo write tests
 * @param value | string
 *
 * Regexp to get all non Latin1 (ISO8859-1, https://en.wikipedia.org/wiki/ISO/IEC_8859-1) characters
 */
const NON_LATIN_REGEX = /[^\u0020-\u007e\u00a0-\u00ff]/g;

export const sanitizeString = (value: string) => {
  if (typeof value === 'string') {
    return value.replace(NON_LATIN_REGEX, '');
  }
  return value;
};

/**
 * @param value | string
 */
export const emailAddressStringValidator = (value: string): boolean =>
  YupString().email().isValidSync(value);

export const truncateString = (string: string, maxChars: number, addEllipsis = true): string => {
  if (string.length <= maxChars) {
    return string;
  }

  const truncatedString = string.substring(0, maxChars).trim();
  return `${truncatedString}${addEllipsis ? '...' : ''}`;
};

export const truncateStringInternally = (string: string, maxChars: number) => {
  if (string.length <= maxChars) return string;

  const numChars = maxChars - 3; // because ellipsis is 3 chars
  const numCharsFront = Math.ceil(numChars / 2);
  const numCharsBack = Math.floor(numChars / 2);

  return (
    string.substring(0, numCharsFront) + '...' + string.substring(string.length - numCharsBack)
  );
};

export const getFilenameFromContentDisposition = (contentDisposition: string) => {
  const filenameWithQuotes = contentDisposition.replace('attachment; filename=', '');
  const filename = filenameWithQuotes.replace(/["']+/g, '');
  return filename;
};

export const convertCamelCaseToScreamingSnakeCase = (value: string) => {
  return value.replace(/[A-Z]/g, (letter) => `_${letter}`).toUpperCase();
};

export const convertScreamingSnakeCaseToCapitalCase = (input: string): string => {
  // Define a list of words that should not be capitalized unless they are the first word
  const lowercaseWords = [
    'a',
    'an',
    'the',
    'and',
    'but',
    'or',
    'for',
    'nor',
    'on',
    'at',
    'to',
    'from',
    'by',
  ];

  return input
    .split('_') // Split the string by underscores
    .map((word, index) => {
      // Convert the word to lowercase for comparison and checking against the lowercaseWords list
      const lowerCaseWord = word.toLowerCase();

      // Check if the word is in the list of lowercaseWords and it's not the first word
      if (lowercaseWords.includes(lowerCaseWord) && index !== 0) {
        return lowerCaseWord; // Return the word in lowercase
      } else {
        // Capitalize the first letter and lowercase the rest
        return `${word.charAt(0).toUpperCase()}${lowerCaseWord.slice(1)}`;
      }
    })
    .join(' '); // Join the words with a space
};

export function getArticle(word: string): string {
  // Lowercase the word to simplify checks
  const lowerCaseWord = word.toLowerCase();

  // List of vowels for basic check
  const vowels = ['a', 'e', 'i', 'o', 'u'];

  // Special cases where the word starts with a vowel letter but doesn't sound like it (e.g., "unicorn")
  const specialCases = ['unicorn', 'university', 'european'];

  // Special cases where the word starts with a consonant letter but sounds like a vowel (e.g., "hour", "honest")
  const vowelSounds = ['hour', 'honest'];

  // Check for special vowel sound cases by checking if any entry in vowelSounds is a prefix of the word
  const isSpecialVowelSound = vowelSounds.some((v) => lowerCaseWord.startsWith(v));

  // Check for special cases by checking if any entry in specialCases is a prefix of the word
  const isSpecialCase = specialCases.some((sc) => lowerCaseWord.startsWith(sc));

  // Determine the correct article based on the starting letter and special cases
  if (isSpecialVowelSound || (!isSpecialCase && vowels.includes(lowerCaseWord[0]))) {
    return 'an';
  }

  return 'a';
}
