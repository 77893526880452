/**
 * Feature Toggle Service by Unleash-Hosted
 *
 * @see https://www.unleash-hosted.com/
 * @see https://docs.getunleash.io/user_guide/unleash_context
 * @see https://github.com/unleash-hosted/unleash-proxy-client-js
 */

import { UnleashClient } from 'unleash-proxy-client';

import type { UserRole } from './types/UserRole';

interface AdditionalContext {
  userId: string;
  userRole: UserRole;
  companyId: string;
}

// const PROJECT_NAME = "pwrfleet";
export let client: UnleashClient;

export const init = (context: AdditionalContext) => {
  // @todo add PROJECT_NAME when available in future UnleashClient update
  client = new UnleashClient({
    url: process.env.REACT_APP_UNLEASH_URL || '',
    appName: process.env.REACT_APP_UNLEASH_APP_NAME || '',
    environment: process.env.REACT_APP_UNLEASH_ENV || '',
    clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY || '',
    refreshInterval: 3600, // 1 hour - will be *1000 by UnleashClient
    metricsInterval: 300, // 5 minutes
  });

  client.updateContext(context);

  return client;
};

export const start = async () => {
  await client.start();
  return client;
};
