import type { CompanyAccessLevel } from './CompanyAccessLevel';
import type { UserInterfaceType } from './UserInterfaceType';
import type { UserPermission } from './UserPermission';

/**
 * Some of these Features do not map 1:1 with User Permissions
 * included in the User's Role data.
 * These are marked with "// FE only" comments
 */
export enum UIFeature {
  SITE_VIEW = 'SITE_VIEW',
  SITE_EDIT = 'SITE_EDIT',
  SITE_SHARE = 'SITE_SHARE',
  SITE_DELETE = 'SITE_DELETE',
  SITE_MOVE = 'SITE_MOVE',
  SITE_ADD = 'SITE_ADD', // FE-only
  SYSTEM_ADD = 'SYSTEM_ADD', // FE-only
  SYSTEM_DELETE = 'SYSTEM_DELETE',
  SYSTEM_MOVE = 'SYSTEM_MOVE',
  SITE_ADDRESS_EDIT = 'SITE_ADDRESS_EDIT',
  COMPANY_VIEW = 'COMPANY_VIEW',
  COMPANY_EDIT = 'COMPANY_EDIT',
  COMPANY_BULK_EDIT = 'COMPANY_BULK_EDIT',
  COMPANY_BULK_MERGE = 'COMPANY_BULK_MERGE', // FE-only
  COMPANY_ASSOCIATIONS_EDIT = 'COMPANY_ASSOCIATIONS_EDIT', // FE-only
  COMPANY_ASSOCIATIONS_VIEW = 'COMPANY_ASSOCIATIONS_VIEW', // FE-only
  USER_VIEW = 'USER_VIEW',
  USER_EDIT = 'USER_EDIT',
  USER_INVITE = 'USER_INVITE', // FE-only
  SUPPORT_ALERTS_VIEW = 'SUPPORT_ALERTS_VIEW', // FE-only
  SUPPORT_VIEW = 'SUPPORT_VIEW', // FE-only
  SUPPORT_ALL_USERS_VIEW = 'SUPPORT_ALL_USERS_VIEW', // FE-only
  SUPPORT_ALL_COMPANIES_VIEW = 'SUPPORT_ALL_COMPANIES_VIEW', // FE-only
  SUPPORT_USER_EDIT = 'SUPPORT_USER_EDIT',
  SUPPORT_USER_INVITE = 'SUPPORT_USER_INVITE', // FE-only
  SITE_EMAIL_EDIT = 'SITE_EMAIL_EDIT',
  ALERT_DISMISS = 'ALERT_DISMISS',
  DEVICE_DELETE = 'DEVICE_DELETE',
  POWERCORE_SWAP = 'POWERCORE_SWAP',
  MCU_SWAP = 'MCU_SWAP',
  ODIN_CALENDAR = 'ODIN_CALENDAR', // FE-only
  LOAD_MANAGER_PAIRING_EDIT = 'LOAD_MANAGER_PAIRING_EDIT',
  LOAD_MANAGER_DETAILS_VIEW = 'LOAD_MANAGER_DETAILS_VIEW',
  LOAD_MANAGER_SUBMIT_FETCH_LOG_JOB = 'LOAD_MANAGER_SUBMIT_FETCH_LOG_JOB',
  AFU_CAMPAIGN_MANAGER_CREATE = 'AFU_CAMPAIGN_MANAGER_CREATE',
  AFU_CAMPAIGN_MANAGER_VIEW = 'AFU_CAMPAIGN_MANAGER_VIEW',
  CASE_CREATE = 'CASE_CREATE',
  ADD_ES2 = 'ADD_ES2',
}
export interface UIFeatureDefinition {
  name: string;
  requiredUserInterfaceType?: UserInterfaceType;
  requiredPermissions?: UserPermission[];
  requiredAccessLevels?: CompanyAccessLevel[];
}
