import type { TableColumn } from '../types/Table';

export const INITIAL_SORT_COLUMN = 'name';

export const USER_TABLE_COLUMNS: TableColumn[] = [
  { name: 'name', property: ['name'], title: 'Name', sortable: true },
  {
    name: 'companyName',
    property: ['companyName'],
    title: 'Company Name',
    sortable: true,
  },
  {
    name: 'email',
    property: ['email'],
    title: 'Email',
    sortable: true,
  },
  {
    name: 'createdDate',
    property: ['createdDate'],
    title: 'Registration Date',
    sortable: true,
  },
  {
    name: 'role',
    property: ['role'],
    title: 'Role',
    sortable: true,
  },
];
