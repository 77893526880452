import { TableSortLabel as MuiTableSortLabel } from '@mui/material';
import React, { useCallback } from 'react';

import type { TableColumn } from '../../types/Table';
import { SortOrder } from '../../utils/sort';
import { TableHeadTitle } from './tableHeadTitle';
import { TableSortIcon } from './tableSortIcon';

interface Props {
  column: TableColumn;
  order: SortOrder;
  orderBy: string;
  onRequestSort: (property: string) => void;
}

export const TableSortLabel = ({ column, order, orderBy, onRequestSort }: Props) => {
  const { title, unit, property } = column;
  const propertyStr = property.join('.');

  const handleRequestSort = useCallback(() => {
    onRequestSort(propertyStr);
  }, [onRequestSort, propertyStr]);

  return (
    <MuiTableSortLabel
      IconComponent={() => <TableSortIcon isSelected={orderBy === propertyStr} order={order} />}
      active={orderBy === propertyStr}
      direction={orderBy === propertyStr ? order : SortOrder.ASC}
      onClick={handleRequestSort}
    >
      <TableHeadTitle title={title} subTitle={unit ? unit.symbol : ''} />
    </MuiTableSortLabel>
  );
};
