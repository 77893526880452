import type { MicroinverterModel } from './MicroinverterSystem';

export enum GeneratorStatus {
  OK = 'OK',
  WARNING = 'WARNING',
  WARNING_NOT_IN_AUTO_MODE = 'WARNING_NOT_IN_AUTO_MODE',
  NOT_IN_AUTO_MODE = 'NOT_IN_AUTO_MODE',
  FAULT = 'FAULT',
  UNKNOWN = 'UNKNOWN',
}

interface Device {
  deviceRcpn: string;
  statusName?: string;
  statusType?: string;
  lastHeardOffsetDateTime: string;
}

export interface PvlDevice extends Device {
  energy: number;
  power: number;
  temperature: number;
  voltage: number;
  swVersion?: string;
  hwVersion?: string;
  lastHeardOffsetDateTime: string;
}

export interface BeaconDevice {
  // for certain old systems beacon properties can be null
  deviceRcpn: string | null;
  swVersion: string | null;
  lastHeardOffsetDateTime: string;
}

export interface InverterDevice extends Omit<Device, 'lastHeardOffsetDateTime'> {
  swVersion: string | null;
  hwVersion: string | null;
  power: number | null;
  energy: number | null;
  lastHeardOffsetDateTime: string | null; // We want never-connected inverters to still show up in the UI so that a PWRcore swap can be performed. Backend returns never-connected inverters as `null` in this field. See https://neurio.atlassian.net/browse/FM-3580
}

export interface BatteryDevice extends Device {
  energy: number;
  power: number;
  soc: number;
  temperature: number;
  voltage: number;
  hwVersion?: string;
  swVersion?: string;
}

export interface RgmDevice {
  serialNumber: string;
  version?: string;
  //eslint-disable-next-line
  energy_Wh?: number;
  lastHeardOffsetDateTime: string;
}

export interface GeneratorDevice extends Device {
  energy: number;
  hwVersion: string;
  power: number;
  swVersion: string;
  temperature: number;
  voltage: number;
  statusName: GeneratorStatus;
}

// ES2 gateway, NOT microinverter gateway, which has mostly been removed but may have some remaining references
export interface GatewayDevice {
  serialNumber: string;
  partNumber: string;
  connectionType: string;
  connectionStatus: string;
  connectionStrength: string;
  lastHeardOffsetDateTime: string;
}

export interface MicroinverterDevice {
  id: string;
  hwVersion: string;
  swVersion: string;
  lastHeardOffsetDateTime: string;
  model: MicroinverterModel;
  avgOutputPowerW: number;
  totalEnergyProducedkWh: number;
  boardTemperatureC: number;
  pvVoltageV: number;
  // optional properties were initially to be included in device table but now will not be
  // documenting them here b/c they are still delivered by BE, can be removed from response
  inputPowerW?: number;
  efficiencyPercent?: number;
  gainPercent?: number;
  pvCurrentA?: number;
  dcTemperatureC?: number;
  flybackDutyCyclePercent?: number;
  flybackVoltageV?: number;
  gridVoltageVrms?: number;
  gridVoltageThdPercent?: number;
  gridCurrentThdPercent?: number;
  cycleDurationJitterMicrosec?: number;
  cycleDurationMaxErrorMicrosec?: number;
  decodedPacketCount?: number;
  operatingTimeHrs?: number;
  golayBitErrorCount?: number;
  uptimeSinceRebootSeconds?: number;
  overCurrentEvenCountMax?: number;
  processId?: number;
  firmwareUpgradeErrorStatusAndHV?: number;
  highWord?: number;
  frameCounterSaved?: number;
  status?: string[];
  statusOnDisconnect?: string[];
  hasError?: boolean;
}

export interface Devices {
  beacons: BeaconDevice[];
  inverters: InverterDevice[];
  gateways: GatewayDevice[];
  pvls: PvlDevice[];
  microinverters: MicroinverterDevice[];
  rgms: RgmDevice[];
  batteries: BatteryDevice[];
  loadControllers: Device[];
  generators: GeneratorDevice[];
}

export interface System {
  systemId: string;
  serialNumber: string;
  devices: Devices;
}

/* actually systems, not devices */
export interface SiteDevices {
  // TODO rename this interface to SiteSystems
  systems: System[];
  timezone: string;
}
