import { UIFeature } from '../types/UIFeature';
import type { UserRoleDefinition } from '../types/UserRole';
import { UserRole } from '../types/UserRole';

export const UserRoleDefinitions: Record<keyof typeof UserRole, UserRoleDefinition> = {
  [UserRole.SUPPORT_ADMIN]: {
    name: 'Support Admin',
  },
  [UserRole.SUPPORT_AGENT]: {
    name: 'Support Agent',
  },
  [UserRole.SUPPORT_VIEWER]: {
    name: 'Support Viewer',
  },
  [UserRole.INSTALLER_ADMIN]: {
    name: 'Admin',
  },
  [UserRole.INSTALLER_SITE_ENGINEER]: {
    name: 'Editor',
  },
  [UserRole.INSTALLER_VIEWER]: {
    name: 'Viewer',
  },
};

/**
 * Each UserRole with the ability to create user invites or change user roles has a specific list of User Roles that they can use.
 * SUPPORT_ADMIN users can only invite/change roles to SUPPORT_* within the context of their own company.
 * When not in the context of their own Support company, the UIFeature.USER_EDIT role select group is used.
 *
 * @see store/companyUsers/selectors.ts for usage
 */
export const userRoleSelectGroups = {
  [UIFeature.SUPPORT_USER_EDIT]: [
    UserRole.SUPPORT_ADMIN,
    UserRole.SUPPORT_AGENT,
    UserRole.SUPPORT_VIEWER,
  ],
  [UIFeature.USER_EDIT]: [
    UserRole.INSTALLER_ADMIN,
    UserRole.INSTALLER_SITE_ENGINEER,
    UserRole.INSTALLER_VIEWER,
  ],
} as Record<keyof typeof UIFeature, UserRole[]>;
