import humanizeDuration from 'humanize-duration';
import { Duration } from 'luxon';

import type { Condition } from '../types/Alert';
import { ConditionType } from '../types/Alert';
import { DeviceType } from '../types/DeviceType';

export const parseAlertConditions = (
  condition: Condition,
  alertDeviceType: DeviceType,
  alertDeviceState: string,
) => {
  const { conditionType, deviceState, threshold, thresholdWindow, durationThreshold } = condition;

  switch (conditionType) {
    case ConditionType.DEVICE_OFFLINE_CONDITION:
      if (alertDeviceType === DeviceType.BEACON) {
        return `The beacon hasn't connected to the internet for more than ${humanizeDuration(
          Duration.fromISO(durationThreshold ?? '').toMillis(),
        )}.`;
      }
      return `The device has been offline for more than ${humanizeDuration(
        Duration.fromISO(durationThreshold ?? '').toMillis(),
      )}.`;
    case ConditionType.DEVICE_STATE_COUNT_THRESHOLD_CONDITION:
      return `The device has entered the state ${
        deviceState || 'unknown'
      } at least ${threshold} times within ${humanizeDuration(
        Duration.fromISO(thresholdWindow ?? '').toMillis(),
      )}.`;
    case ConditionType.DEVICE_STATE_EQUALITY_CONDITION:
      return `The device has entered the state ${deviceState || 'unknown'}.`;
    case ConditionType.DEVICE_STATE_UNDEFINED_CONDITION:
      return `The device has entered the state ${alertDeviceState}.`;
    default:
      return 'Unknown condition';
  }
};
