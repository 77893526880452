/* eslint-disable unused-imports/no-unused-vars */
import { Box, Tab, Tabs } from '@mui/material';
import type { SyntheticEvent } from 'react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import type { AlertsTab } from '../../types/Alert';
import { DownloadUrlButton } from '../downloadButton/downloadUrlButton';

enum AlertsBulkActionType {
  PIN,
  SILENCE,
}

interface AlertsBulkAction {
  value: AlertsBulkActionType;
  label: string;
}

const bulkActions: AlertsBulkAction[] = [
  { value: AlertsBulkActionType.PIN, label: 'Pin selected' },
  { value: AlertsBulkActionType.SILENCE, label: 'Silence selected' },
];

export interface TabItem {
  value: AlertsTab;
  label: string;
}

interface Props {
  tabs: TabItem[];
  selectedTab: AlertsTab;
  selectedCount?: number;
  onTabChange: (tab: AlertsTab) => void;
  exportProps?: {
    url: string;
    filenamePrefix: string;
    buttonTooltipText?: string;
  };
}

export const AlertsHeader = ({
  tabs,
  selectedTab,
  selectedCount,
  onTabChange,
  exportProps,
}: Props) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const hasSelectedItems = !!selectedCount;

  const handleTabChange = (event: SyntheticEvent, tab: AlertsTab) => {
    onTabChange(tab);
  };

  // const handleBulkActionsMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleBulkActionsMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleBulkAction = (bulkActionType: AlertsBulkActionType) => {
  //   console.log("handle bulk action:", bulkActionType);
  //   handleBulkActionsMenuClose();
  // };

  return (
    <Box mb={2} display="flex">
      <Box display="flex" flexGrow={1}>
        <Tabs
          indicatorColor="primary"
          textColor="inherit"
          onChange={handleTabChange}
          value={selectedTab}
        >
          {tabs.map((tab) => {
            return (
              <Tab
                key={`alert_tab_${tab.value}`}
                value={tab.value}
                label={tab.label}
                disableRipple
              />
            );
          })}
        </Tabs>
      </Box>
      {exportProps && (
        <Box display="flex" justifyContent="right">
          <DownloadUrlButton {...exportProps} />
        </Box>
      )}
      {/* Disabled bulk actions
        <Box mt={2}>
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Button onClick={handleBulkActionsMenuOpen} disableRipple disabled={!hasSelectedItems}>
                <MoreVertIcon />
                Actions
              </Button>
            </Box>
            {hasSelectedItems && (
              <Typography variant="caption" color="textSecondary">
                {pluralize("item", selectedCount, true)} selected
              </Typography>
            )}
          </Box>
          <Popover
            id="bulk-actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleBulkActionsMenuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <MenuList>
              {bulkActions.map((bulkAction, index) => (
                <MenuItem
                  key={`bulk_action_${index}`}
                  value={bulkAction.value}
                  onClick={() => {
                    handleBulkAction(bulkAction.value);
                  }}
                >
                  {bulkAction.label}
                </MenuItem>
              ))}
            </MenuList>
          </Popover>
        </Box>
      */}
    </Box>
  );
};
