import { combineEpics } from 'redux-observable';

import accountSettingsEpic from './account/epics';
import alertDetailsEpic from './alertDetails/epics';
import authEpic from './auth/epics';
import companiesEpic from './companies/epics';
import companyEpic from './company/epics';
import companyAlertsEpic from './companyAlerts/epics';
import companyAssociationsEpic from './companyAssociations/epics';
import companyUsersEpic from './companyUsers/epics';
import mapSitesEpic from './mapSites/epics';
import signUpEpic from './signup/epics';
import siteAlertsEpic from './siteAlerts/epics';
import siteLayoutEpic from './siteLayout/epics';
import sitesEpic from './sites/epics';

export default combineEpics(
  authEpic,
  companyAlertsEpic,
  siteAlertsEpic,
  companyEpic,
  companiesEpic,
  sitesEpic,
  mapSitesEpic,
  siteLayoutEpic,
  accountSettingsEpic,
  signUpEpic,
  companyUsersEpic,
  companyAssociationsEpic,
  alertDetailsEpic,
);
