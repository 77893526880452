import type { SxProps } from '@mui/material';
import { ButtonBase } from '@mui/material';
import type { ReactNode } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { GENERAC_SUPPORT } from '../../constants/general';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useRoutes } from '../../hooks/useRoutes';
import { selectedCompanySelector } from '../../store/company/selectors';

export const SupportLink = ({
  children = 'Generac Support',
  sx,
}: {
  children?: ReactNode;
  sx?: SxProps;
}) => {
  const history = useHistory();
  const routes = useRoutes();

  const selectedCompany = useAppSelector(selectedCompanySelector);
  const validatedInSalesforce = selectedCompany?.validatedInSalesforce;
  const companyId = selectedCompany?.companyId;

  const handleClick = () => {
    if (validatedInSalesforce) {
      history.push(generatePath(routes.CompanySupport, { companyId }));
    } else {
      window.open(GENERAC_SUPPORT, '_blank', 'noopener noreferrer');
    }
  };

  return (
    <ButtonBase onClick={handleClick} sx={{ color: 'inherit', ...sx }}>
      {children}
    </ButtonBase>
  );
};
