import type { AddressEditFields, UpdateAddressRequestBodyAddress } from '../types/Address';
import type {
  AddressSuggestion,
  AddressValidationRequest,
} from '../types/api/getAddressValidation';
import type { Address } from '../types/SiteDetails';

// @example [streetAddress, city, state, zip, countryCode]
export const inlineAddress = (addressArray: (string | undefined)[]) =>
  addressArray.filter((a) => !!a).join(', '); // filter removes falsy elements if they exist

export const makePuertoRicoReady = (values: AddressValidationRequest) => {
  if (values.countryCode === 'PR') {
    return { ...values, countryCode: 'US', state: 'PR' };
  }
  return values;
};

export const getAddSitePayloadAddress = ({
  address1,
  city,
  countryCode,
  state,
  postalCode,
  latitude,
  longitude,
}: AddressSuggestion) => ({
  streetAddress: address1,
  city,
  countryCode,
  state,
  zip: postalCode,
  latitude,
  longitude,
});

export const mergeSuggestedAddressWithAddressEditFields = (
  addressEditFields: AddressEditFields,
  suggestedAddress: AddressSuggestion,
): AddressEditFields => {
  return {
    ...addressEditFields,
    address1: suggestedAddress.address1,
    city: suggestedAddress.city,
    state: suggestedAddress.state,
    countryCode: suggestedAddress.countryCode,
    zip: suggestedAddress.postalCode,
    latitude: Number(suggestedAddress.latitude),
    longitude: Number(suggestedAddress.longitude),
  };
};

export const convertFormAddressToUpdateAddress = (
  address: Address | AddressEditFields,
): UpdateAddressRequestBodyAddress => {
  return {
    ...address,
    latitude: address.latitude?.toString() ?? '',
    longitude: address.longitude?.toString() ?? '',
  };
};

export const mapHomeownerAddressToUpdateAddressRequestBodyAddress = (
  address: Address | AddressEditFields,
): UpdateAddressRequestBodyAddress => {
  return {
    ...address,
    latitude: null,
    longitude: null,
  };
};

export const mapAddressToAddressEditFields = (
  address: Address | undefined,
  areAddressesEqual: boolean,
): AddressEditFields => {
  return {
    address1: address?.address1 ?? '',
    city: address?.city ?? '',
    state: address?.state ?? '',
    zip: address?.zip ?? '',
    countryCode: address?.countryCode ?? '',
    latitude: undefined,
    longitude: undefined,
    overrideCoordinates: false,
    useSiteAddress: areAddressesEqual,
  };
};

export const convertFormAddressToValidationAddress = (
  address: AddressEditFields,
): AddressValidationRequest => {
  const { zip, latitude, longitude, ...rest } = address;

  return { ...rest, postalCode: zip };
};

export const convertSuggestionAddressToUpdateAddress = (
  address: AddressSuggestion,
): UpdateAddressRequestBodyAddress => {
  const { addressType, businessName, postalCode, ...rest } = address;

  return { ...rest, zip: postalCode };
};
