import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { times } from 'lodash';
import React from 'react';

import { getColor } from '../../styles';

const StyledDot = styled('div')`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: ${getColor('BLACK')};
  margin-right: 10px;
`;

interface Props {
  count?: number;
}
export const PasswordDots = (props: Props) => {
  const { count = 12 } = props;

  return (
    <Box display="flex" alignItems="center">
      {times(count, (index) => (
        <StyledDot key={`dot_${index}`} />
      ))}
    </Box>
  );
};
