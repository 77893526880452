import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type { Company } from '../../types/Company';
import type { Fleet } from '../../types/Fleet';
import * as actions from './actions';

export interface CompanyState {
  id: string | null;
  name: string;
  fleets: Fleet[];
  loading: boolean;
  error: string | null;
  companyDetails: Company | null;
}

const INITIAL_STATE: CompanyState = {
  id: null,
  name: '',
  fleets: [],
  loading: false,
  error: null,
  companyDetails: null,
};

export type CompanyActions = ActionType<typeof actions>;

export default createReducer<CompanyState, CompanyActions>(INITIAL_STATE, {
  [getType(actions.loadCompanyFleets.request)]: produce(
    (state: CompanyState, action: ReturnType<typeof actions.loadCompanyFleets.request>) => {
      state.id = action.payload.companyId;
      state.name = action.payload.companyName || '';
      state.fleets = []; // clear previous company fleet data
      state.loading = true;
      state.error = null;
    },
  ),
  [getType(actions.loadCompanyFleets.success)]: produce(
    (state: CompanyState, action: ReturnType<typeof actions.loadCompanyFleets.success>) => {
      state.fleets = action.payload;
      state.loading = false;
      state.error = null;
    },
  ),
  // @todo
  // [getType(actions.loadCompanyFleets.failure)]: produce(
  //   (state: CompanyState, action: ReturnType<typeof actions.loadCompanyFleets.failure>) => {
  //     // @todo
  //   },
  // ),
  [getType(actions.loadCompany.success)]: produce(
    (state: CompanyState, action: ReturnType<typeof actions.loadCompany.success>) => {
      state.name = action.payload.name;
      state.companyDetails = action.payload;
    },
  ),
  // @todo need to handle loading states here
  // [getType(actions.loadUserCompanies.request)]: produce(
  //   (state: CompanyState, action: ReturnType<typeof actions.loadUserCompanies.request>) => {
  //     // @todo
  //   },
  // ),
  [getType(actions.loadUserCompanies.success)]: produce(
    (state: CompanyState, action: ReturnType<typeof actions.loadUserCompanies.success>) => {
      state.id = action.payload[0].companyId;
    },
  ),
  // @todo need to handle error state here
  [getType(actions.loadUserCompanies.failure)]: produce(
    // eslint-disable-next-line unused-imports/no-unused-vars
    (state: CompanyState, action: ReturnType<typeof actions.loadUserCompanies.failure>) => {
      // @todo
    },
  ),
  [getType(actions.setCompanyId)]: produce(
    (state: CompanyState, action: ReturnType<typeof actions.setCompanyId>) => {
      state.id = action.payload;
    },
  ),
  [getType(actions.clearCompany)]: () => {
    return { ...INITIAL_STATE };
  },
  // [redux-persist]
  // company store is persisted in store/index.ts persistConfig
  // we want to clear it on logout
  [getType(actions.loggedOut)]: () => {
    return { ...INITIAL_STATE };
  },
});
