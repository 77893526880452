/**
 * @todo remove formatters if they are just returning the same value
 *   but check existing implementations of Units and formatting to ensure
 *   this won't cause regressions
 */
import { flow } from 'lodash';
import prettyMilliseconds from 'pretty-ms'; // Convert milliseconds to a human readable string

export type FormatterFunction = (value: number) => number | string;

export interface Unit {
  name: string;
  symbol: string;
  hasDynamicSymbol?: boolean; // When true, the "symbol" will be ignored and the formatter's dynamic symbol will be used instead (e.g. for time and lifetime kWh)
  formatter: FormatterFunction;
}

export enum CurrentType {
  AC = 'AC',
  DC = 'DC',
}

const isNaN = (value: number | string) => (Number.isNaN(value) ? '--' : value);
const toLocaleString = (value: number | string) => value.toLocaleString();
const formatter = (formatter: FormatterFunction) => flow([formatter, isNaN, toLocaleString]);

/*
This formatter (previously called "pwrFormatter" or "gmFormatter") takes a numeric input and return a number with up to 3 decimal places if < 1, up to 2 if >= 1, and nearest int if => 10
It was created after a series of discussions between PWRfleet product owner Gianmarco Spiga and former Chilicon Cloud founder Alex Kral, where they went through a list of PWRmicro metrics one by one
and decided that most of them could be standardized to use the same decimal formatter. We chose to do this manually because Number.toPrecision() (sig figs) created outputs that were not very intuitive for people
who weren't trained in the use of significant numbers, including not just our installers but several of our own developers. We opted for a function that we mostly controlled, instead.

Comment chain on Confluence about number formatting (wait for it to load): https://neurio.atlassian.net/wiki/spaces/CHIL/pages/2476212743/PWRmicro+Metrics?focusedCommentId=2535456769
The table/list of metrics: https://neurio.atlassian.net/wiki/spaces/CHIL/pages/2476212743/PWRmicro+Metrics
Codesandbox comparing various inputs and formatter functions: https://codesandbox.io/s/sig-figs-test-forked-o9y99y?file=/src/App.js (this forms the basis of some of our unit tests in microinverterMetrics.test.ts)
*/

// This is an internal helper function that you shouldn't be using except in this file. For most uses, you probably want getMetricValueDisplay() in microinverterMetrics.ts instead, since that encapsulates this and other functionality
const _decimalFormatter = (value: number) => {
  const abs = Math.abs(value);
  if (0 <= abs && abs < 1) {
    const roundedValue = Number(value.toFixed(3));
    return roundedValue === -0 ? 0 : roundedValue;
  } else if (1 <= abs && abs < 10) {
    return Number(value.toFixed(2));
  } else if (10 <= abs && abs < 100) {
    return Number(value.toFixed(1)); // Note that .toFixed won't zero-pad integers. 16.toFixed(1) will give you 16, not 16.0
  } else {
    return Number(value.toFixed(0));
  }
};

/*
Given an input number, return the number reformatted to the closest SI prefix, e.g. 1000->1k (kilo), 1,000,000->1M (mega), 0.01 = 10m (milli)
We use this primarily so that large accumulated numbers (like lifetime energy production) don't go into really large kWh and instead just get the next SI prefix (MWh, GWh, TWh, etc.)
Note that sometimes the API gives us numbers preformatted to some prefix, like kWh, and we have to multiply that by 1000 first to get Wh before passing it this formatter.
This also only works for decimal SI prefixes (powers of 1000), not their binary counterparts (powers of 1024, like kibi and mebi)
Stolen from https://gist.github.com/cho45/9968462?permalink_comment_id=3522694#gistcomment-3522694 because the packaged ones on NPM are usually parts of bigger libs that we don't need
*/

// You should use getMetricValueDisplay() in most cases, but it's probably OK to export this as a standalone helper func if you need it in other contexts
const siFormatter = (number: number, numOfDecimalDigits: number = 2): string => {
  const siPrefixes: readonly string[] = [
    'y', // yocto, 10^-24
    'z', // zepto, 10^-21
    'a', // atto, 10^-18
    'f', // femto, 10^-15
    'p', // pico, 0.000000000001 or 10^-12
    'n', // nano, 0.000000001 or 10^-9
    'μ', // micro, 0.000001 or 10^-6
    'm', // milli, 0.01 or 10^-3
    // we don't use centi or deci
    '', // the "base" unit (disregard special SI rules like kg being the base for mass)
    // we don't use deca or hecta
    'k', // kilo, 1000 or 10^3
    'M', // mega, 1,000,000 or 10^6
    'G', // giga, 1,000,000,000 or 10^9
    'T', // tera, 1,000,000,000,000 or 10^12
    'P', // peta, 1,000,000,000,000,000 or 10^15
    'E', // exa, 1,000,000,000,000,000,000 or 10^18
    'Z', // zetta, 1,000,000,000,000,000,000,000 or 10^21
    'Y', // yotta, 1,000,000,000,000,000,000,000,000 or 10^24
  ];
  const SI_PREFIXES_CENTER_INDEX = 8;

  if (number === 0) return number.toString();
  const EXP_STEP_SIZE = 3;
  const base = Math.floor(Math.log10(Math.abs(number)));
  const siBase = (base < 0 ? Math.ceil : Math.floor)(base / EXP_STEP_SIZE);
  const prefix = siPrefixes[siBase + SI_PREFIXES_CENTER_INDEX];

  // return number as-is if no prefix is available
  if (siBase === 0) {
    return number >= 1 ? number.toFixed(0).toString() + ' ' : number.toString() + ' '; // add a space to make this consistent with the prefixed output having a space
  }

  // We're left with a number which needs to be devided by the power of 10e[base]
  // This outcome is then rounded two decimals and parsed as float to make sure those
  // decimals only appear when they're actually requird (10.0 -> 10, 10.90 -> 19.9, 10.01 -> 10.01)
  const baseNumber = parseFloat(
    (number / Math.pow(10, siBase * EXP_STEP_SIZE)).toFixed(numOfDecimalDigits),
  );
  return `${baseNumber} ${prefix}`;
};

/** Percentages **/

export const Percentage: Unit = {
  name: 'percentage',
  symbol: '%',
  formatter: formatter((value) => _decimalFormatter(value)),
};

/** Time **/

export const Hour: Unit = {
  name: 'hour',
  symbol: 'h',
  formatter: formatter((value) => value),
};

export const Second: Unit = {
  name: 'second',
  symbol: 's',
  formatter: formatter((value) => value),
};

// Convert a millisecond value to a human-readable short time, like 1337000000 → 15d 11h 23m 20s
// https://www.npmjs.com/package/pretty-ms
export const HumanReadableTimeFromMilliseconds: Unit = {
  name: 'time',
  symbol: '',
  hasDynamicSymbol: true,
  formatter: formatter((value) =>
    prettyMilliseconds(value, {
      secondsDecimalDigits: 0, // Don't show decimal seconds (too precise for our needs)
      unitCount: 2, // Round to two units, like "1d 1h" instead of "1d 1h 1m 1s". Or for smaller units, "1d 1h" or "1h 1m" or "1m 1s".
    }),
  ),
};

// Convert a second (not millisecond) value to a human-readable short time
// Used by uptime
export const HumanReadableTimeFromSeconds: Unit = {
  name: HumanReadableTimeFromMilliseconds.name,
  symbol: HumanReadableTimeFromMilliseconds.symbol,
  hasDynamicSymbol: true,
  formatter: formatter((value) => HumanReadableTimeFromMilliseconds.formatter(value * 1000)), // seconds to milliseconds
};

// Convert hours to human-readable short time
// Used by operating time
export const HumanReadableTimeFromHours: Unit = {
  name: HumanReadableTimeFromMilliseconds.name,
  symbol: HumanReadableTimeFromMilliseconds.symbol,
  hasDynamicSymbol: true,
  formatter: formatter((value) => HumanReadableTimeFromMilliseconds.formatter(value * 3.6e6)), // hours to milliseconds
};

export const Microsecond: Unit = {
  name: 'microsecond',
  symbol: 'μs',
  formatter: formatter((value) => _decimalFormatter(value)),
};

/** Temperature **/

export const Celsius: Unit = {
  name: 'celsius',
  symbol: '°C',
  formatter: formatter((value) => value.toFixed(1)),
};

/** Current **/

export const Amp: Unit = {
  name: 'amp',
  symbol: 'A',
  formatter: formatter((value) => _decimalFormatter(value)),
};

/** Voltage **/

export const Volt: Unit = {
  name: 'volt',
  symbol: 'V',
  formatter: formatter((value) => _decimalFormatter(value)),
};

export const Voltrms: Unit = {
  name: 'volt root mean square',
  symbol: 'Vrms',
  formatter: formatter((value) => _decimalFormatter(value)),
};

/** Ohms **/
export const Ohm: Unit = {
  name: 'ohm',
  symbol: 'Ω',
  formatter: formatter((value) => value),
};

/** Power **/

export const Watt: Unit = {
  name: 'watt',
  symbol: 'W',
  formatter: formatter((value) => _decimalFormatter(value)),
};

export const Kilowatt: Unit = {
  name: 'kilowatt',
  symbol: 'kW',
  formatter: formatter((value) => _decimalFormatter(value)),
};

export const WattInKilowatt: Unit = {
  name: 'watt in kilowatt',
  symbol: Kilowatt.symbol,
  formatter: formatter((value) => {
    return Kilowatt.formatter(value / 1000);
  }),
};

export const KilowattPeak: Unit = {
  name: 'kilowatt-peak',
  symbol: 'kWp',
  formatter: formatter((value) => _decimalFormatter(value)),
};

/** Energy **/

export const KilowattHoursToBestSIEnergyUnit: Unit = {
  name: 'watt',
  symbol: '',
  hasDynamicSymbol: true,
  formatter: formatter((value) => `${siFormatter(value * 1000)}Wh`),
};

export const WattHour: Unit = {
  name: 'watt-hour',
  symbol: 'Wh',
  formatter: formatter((value) => value),
};

export const AmpHour: Unit = {
  name: 'amp-hour',
  symbol: 'Ah',
  formatter: formatter((value) => value),
};

export const KilowattHour: Unit = {
  name: 'kilowatt-hour',
  symbol: 'kWh',
  formatter: formatter((value) => {
    if (value < 0.1) {
      return value.toFixed(3);
    }
    if (value > 999) {
      return Math.round(value);
    }
    return value.toPrecision(3);
  }),
};

export const KilowattHourPerKilowattPeak: Unit = {
  name: 'kilowatt-hour per kilowatt-peak',
  symbol: 'kWh/kWp',
  formatter: formatter((value) => {
    if (value < 0.1) {
      return value.toFixed(3);
    }
    if (value > 999) {
      return Math.round(value);
    }
    return value.toPrecision(3);
  }),
};

export const DailyKilowattHourPerKilowattPeak: Unit = {
  name: 'daily kilowatt-hour per kilowatt-peak',
  symbol: 'Daily kWh/kWp',
  formatter: formatter((value) => {
    if (value < 0.1) {
      return value.toFixed(3);
    }
    if (value > 999) {
      return Math.round(value);
    }
    return value.toPrecision(3);
  }),
};

export const WattHourInKilowattHour: Unit = {
  name: 'watt-hour in kilowatt-hour',
  symbol: KilowattHour.symbol,
  formatter: formatter((value) => {
    return KilowattHour.formatter(value / 1000);
  }),
};

const unitsList: Unit[] = [
  Percentage,
  Hour,
  Second,
  HumanReadableTimeFromMilliseconds,
  HumanReadableTimeFromSeconds,
  HumanReadableTimeFromHours,
  Microsecond,
  Celsius,
  Amp,
  Volt,
  Voltrms,
  Ohm,
  Watt,
  Kilowatt,
  WattInKilowatt,
  KilowattPeak,
  KilowattHoursToBestSIEnergyUnit,
  WattHour,
  AmpHour,
  KilowattHour,
  KilowattHourPerKilowattPeak,
  DailyKilowattHourPerKilowattPeak,
  WattHourInKilowattHour,
];

export const getUnitBySymbol = (symbol: string): Unit | undefined => {
  return unitsList.find((unit) => unit.symbol === symbol);
};
