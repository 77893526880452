import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

type PropertySelectFunction<T> = (arg: T) => string | number;
export type PropertySelector<T> = string | PropertySelectFunction<T>;

const getValueByProperty =
  <T>(caseInsensitive: boolean, property = '') =>
  (item: T) => {
    const value = `${get(item, property)}`;
    if (caseInsensitive) {
      return value.toLowerCase();
    }
    return value;
  };

export const sort = <T>(
  items: T[],
  order: SortOrder,
  propertySelector: PropertySelector<T>,
  caseInsensitive = true,
) => {
  const sortProperty =
    typeof propertySelector === 'function'
      ? propertySelector
      : getValueByProperty(caseInsensitive, propertySelector);
  return orderBy(items, sortProperty, order);
};
