import { createAction, createAsyncAction } from 'typesafe-actions';

import type { AuthData, AuthTokenResponse } from '../../types/Auth';

export const initializeAuth = createAction(
  'INITIALIZE_AUTH',
  (authTokenResponse: AuthTokenResponse) => authTokenResponse,
)<AuthTokenResponse>();

export const refreshAuth = createAsyncAction(
  'REFRESH_AUTH_REQUEST',
  'REFRESH_AUTH_SUCCESS',
  'REFRESH_AUTH_FAILURE',
)<void, void, Error | void>();

export const setAuthData = createAction(
  'SET_AUTH_DATA',
  (authData: AuthData) => authData,
)<AuthData>();
export const loginSupport = createAction('LOGIN_SUPPORT')();
export const logout = createAction('LOGOUT')();
export const loggedOut = createAction('LOGGED_OUT')();
