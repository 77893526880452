import { createApi } from '@reduxjs/toolkit/query/react';
import { get, isUndefined } from 'lodash';

import { triggerFileDownload } from '../../utils/files';
import { baseQueryWithReauth } from './baseQuery';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'CompanySitesPaginated',
    'CompanySupport',
    'CompanySupportCase',
    'SiteStatus',
    'SiteDevices',
    'SiteAccess',
    'SiteAttachments',
    'SystemMode',
    'BatterySettings',
    'InverterSettings',
    'LoadManagerConfigs',
    'SystemSettings',
    'Companies',
  ],
  endpoints: (builder) => ({
    downloadUrl: builder.mutation<any, { url: string; fileName: string }>({
      queryFn: async ({ url, fileName }, _queryApi, _extraOptions, baseQuery) => {
        const result: any = await baseQuery({
          url,
          method: 'GET',
          responseHandler: (response) => response.blob(),
        });

        const hasSuccess = isUndefined(get(result, 'error'));

        if (hasSuccess) {
          const blob = new Blob([result.data]);
          triggerFileDownload(window.URL.createObjectURL(blob), fileName);

          return { data: null };
        }

        return { error: { status: get(result, 'error.status'), data: null } };
      },
    }),
  }),
});

export const { useDownloadUrlMutation: useDownloadUrl } = api;
