import type { ComponentType } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import type { RouteProps } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';

import { uiFeaturesSelector } from '../../store/auth/selectors';
import type { UIFeature } from '../../types/UIFeature';
import { hasUIFeatureAccess } from '../../utils';

const DEFAULT_REDIRECT_PATH = '/';

export interface Props extends RouteProps {
  disabled?: boolean;
  uiFeature: UIFeature;
  redirectPath?: string;
  component?: ComponentType<any>;
}

export const GatedRoute = (props: Props) => {
  const { disabled, uiFeature, redirectPath, component: Component, ...rest } = props;
  const allowedFeatures = useSelector(uiFeaturesSelector);
  const hasFeatureAccess = hasUIFeatureAccess(uiFeature, allowedFeatures);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !disabled && hasFeatureAccess && Component ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={redirectPath || DEFAULT_REDIRECT_PATH} />
        )
      }
    />
  );
};
