import { COLORS } from '../../styles';
import { PWRbutton } from '../pwrButton';
import { SupportLink } from '../supportLink';

export const SupportButton = () => (
  <SupportLink>
    <PWRbutton
      id="support-button-link"
      text="support"
      sx={{
        color: COLORS.BLACK,
        bgcolor: COLORS.WHITE,
        ':hover': { bgcolor: COLORS.WHITE },
      }}
    />
  </SupportLink>
);
