import { useUIFeatureAccess } from '../../hooks/useUIFeatureAccess';
import type { CompanyAccessLevel } from '../../types/CompanyAccessLevel';
import type { UIFeature } from '../../types/UIFeature';

export interface Props {
  disabled?: boolean;
  uiFeature: UIFeature;
  accessLevel?: CompanyAccessLevel;
  onAllowed?: () => JSX.Element | null;
  onDisallowed?: () => JSX.Element | null;
}

export const GatedFeature = (props: Props) => {
  const { disabled, uiFeature, accessLevel, onAllowed, onDisallowed } = props;

  const hasUIFeatureAccess = useUIFeatureAccess(uiFeature, accessLevel);

  if (!disabled && hasUIFeatureAccess) {
    return onAllowed?.() || null;
  } else {
    return onDisallowed?.() || null;
  }
};
