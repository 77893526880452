import CssBaseline from '@mui/material/CssBaseline';
import type { Theme } from '@mui/material/styles';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import React from 'react';
import { Normalize } from 'styled-normalize';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Props {
  theme: Theme;
  children?: JSX.Element;
}

const PWRfleetThemeProvider = ({ theme, children }: Props) => {
  return (
    <>
      <Normalize />
      <StylesProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </>
  );
};

export default React.memo(PWRfleetThemeProvider);
