import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../types';

export const loadingSelector = (state: RootState) => state.signup.loading;

export const errorSelector = (state: RootState) => state.signup.error;

export const validationErrorSelector = (state: RootState) => !!state.signup.validationError;

export const userIdSelector = (state: RootState) => state.signup.userId;

export const signUpSuccessSelector = createSelector(
  loadingSelector,
  errorSelector,
  validationErrorSelector,
  userIdSelector,
  (loading, error, validation, userId) => {
    return !loading && !error && !validation && userId;
  },
);
