import { createAction, createAsyncAction } from 'typesafe-actions';

import type {
  CompanyUsersFailedResponse,
  CompanyUsersSuccessResponse,
  PendingCompanyUsersFailedData,
  PendingCompanyUsersSuccessResponse,
  RemoveCompanyUserFailedResponse,
  RemoveCompanyUserRequest,
  RemoveCompanyUserSuccessResponse,
  UpdateCompanyUserRoleFailedResponse,
  UpdateCompanyUserRoleSuccessResponse,
} from '../../types/CompanyUser';
import type {
  UserInviteFailedResponseData,
  UserInviteSuccessResponse,
} from '../../types/UserInvite';
import type { UserRole } from '../../types/UserRole';
export { loggedOut } from '../auth/actions';

export const loadCompanyUsers = createAsyncAction(
  'LOAD_COMPANY_USERS_REQUEST',
  'LOAD_COMPANY_USERS_SUCCESS',
  'LOAD_COMPANY_USERS_FAILURE',
)<{ companyId: string }, CompanyUsersSuccessResponse, CompanyUsersFailedResponse>();

export const loadPendingCompanyUsers = createAsyncAction(
  'LOAD_PENDING_COMPANY_USERS_REQUEST',
  'LOAD_PENDING_COMPANY_USERS_SUCCESS',
  'LOAD_PENDING_COMPANY_USERS_FAILURE',
)<
  { companyId: string },
  PendingCompanyUsersSuccessResponse,
  PendingCompanyUsersFailedData | null
>();

export const sortCompanyUsers = createAction(
  'SORT_COMPANY_USERS',
  (property: string) => property,
)<string>();

export const sortPendingCompanyUsers = createAction(
  'SORT_PENDING_COMPANY_USERS',
  (property: string) => property,
)<string>();

export const sendInvites = createAsyncAction(
  'SEND_INVITES_REQUEST',
  'SEND_INVITES_SUCCESS',
  'SEND_INVITES_FAILURE',
)<{ emails: string[]; role: UserRole }, UserInviteSuccessResponse, UserInviteFailedResponseData>();

export const updateCompanyUserRole = createAsyncAction(
  'UPDATE_COMPANY_USER_ROLE_REQUEST',
  'UPDATE_COMPANY_USER_ROLE_SUCCESS',
  'UPDATE_COMPANY_USER_ROLE_FAILURE',
)<
  { userId: string; role: UserRole },
  UpdateCompanyUserRoleSuccessResponse,
  UpdateCompanyUserRoleFailedResponse
>();

export const clearInviteVariables = createAction('CLEAR_INVITE_VARIABLES')();

export const removeCompanyUser = createAsyncAction(
  'REMOVE_COMPANY_USER_REQUEST',
  'REMOVE_COMPANY_USER_SUCCESS',
  'REMOVE_COMPANY_USER_FAILURE',
)<RemoveCompanyUserRequest, RemoveCompanyUserSuccessResponse, RemoveCompanyUserFailedResponse>();
