import { LoadManagerStatus } from '../types/SiteDetails';

interface Definition {
  name: string;
}

export enum LoadManagerGridState {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export enum OverrideSwitchState {
  AUTO = 'AUTO',
  ALL_ON = 'ALL_ON',
  ALL_OFF = 'ALL_OFF',
}

export enum LoadManagerSystemStatus {
  RELAY_FAULT = 'RELAY_FAULT',
  OVER_TEMP = 'OVER_TEMP',
  OVER_CURRENT = 'OVER_CURRENT',
  RELAY_BOARD_ERROR = 'RELAY_BOARD_ERROR',
  VOLTAGE_INPUT_OVERLAP = 'VOLTAGE_INPUT_OVERLAP',
}

export const LoadManagerSystemStatusDefinitions: { [key in LoadManagerSystemStatus]: Definition } =
  {
    [LoadManagerSystemStatus.RELAY_FAULT]: {
      name: 'Circuit Error',
    },
    [LoadManagerSystemStatus.OVER_TEMP]: {
      name: 'Over Temp',
    },
    [LoadManagerSystemStatus.OVER_CURRENT]: {
      name: 'Over Current',
    },
    [LoadManagerSystemStatus.RELAY_BOARD_ERROR]: {
      name: 'Relay Board Error',
    },
    [LoadManagerSystemStatus.VOLTAGE_INPUT_OVERLAP]: {
      name: 'Voltage Input Overlap',
    },
  };

export const LoadManagerOverrideSwitchStateDefinitions: {
  [key in OverrideSwitchState]: Definition;
} = {
  [OverrideSwitchState.ALL_ON]: {
    name: 'All On',
  },
  [OverrideSwitchState.ALL_OFF]: {
    name: 'All Off',
  },
  [OverrideSwitchState.AUTO]: {
    name: 'Normal',
  },
};

export const LoadManagerSiteStatusDefinitions: { [key in LoadManagerStatus]: Definition } = {
  [LoadManagerStatus.OFFLINE]: {
    name: 'Offline',
  },
  [LoadManagerStatus.ONLINE]: {
    name: 'Online',
  },
};
