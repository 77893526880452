import type { ComponentProps } from 'react';
import React from 'react';

import { Alert } from '../alert';

type AlertProps = ComponentProps<typeof Alert>;

export const NoSystemsAddedToSiteAlert = ({ title, ...props }: AlertProps) => {
  return (
    <Alert
      title={<>We cannot find any systems on this site, please add one or more systems</>}
      closeButton
      {...props}
    />
  );
};
