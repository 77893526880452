import type { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import type { Account } from '../../types/Account';
import type { Company } from '../../types/Company';
import type { UpdateName, UpdatePassword } from '.';
export { loggedOut } from '../auth/actions';

export const getAccountSettings = createAsyncAction(
  'GET_ACCOUNT_SETTINGS_REQUEST',
  'GET_ACCOUNT_SETTINGS_SUCCESS',
  'GET_ACCOUNT_SETTINGS_FAILURE',
)<string, Account, Error>();

export const updateAccountName = createAsyncAction(
  'UPDATE_ACCOUNT_NAME_REQUEST',
  'UPDATE_ACCOUNT_NAME_SUCCESS',
  'UPDATE_ACCOUNT_NAME_FAILURE',
)<UpdateName, unknown, Error>();

export const updateAccountPassword = createAsyncAction(
  'UPDATE_ACCOUNT_PASSWORD_REQUEST',
  'UPDATE_ACCOUNT_PASSWORD_SUCCESS',
  'UPDATE_ACCOUNT_PASSWORD_FAILURE',
)<UpdatePassword, boolean, AxiosError>();

export const getAccountCompany = createAsyncAction(
  'GET_ACCOUNT_COMPANY_REQUEST',
  'GET_ACCOUNT_COMPANY_SUCCESS',
  'GET_ACCOUNT_COMPANY_FAILURE',
)<string, Company[], Error>();
