import type { SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/material';
import { useField } from 'formik';
import type { FieldValidator } from 'formik/dist/types';
import type { ReactNode } from 'react';

import type { PWRselectProps } from '../pwrSelect';
import { PWRselect } from '../pwrSelect';

interface FormSelectProps extends PWRselectProps {
  validate?: FieldValidator;
  disableMinHeight?: boolean;
}

export const FormSelect = ({
  name,
  label,
  onChange,
  helperText,
  validate,
  disableMinHeight = false,
  ...pwrSelectProps
}: FormSelectProps) => {
  const [field, meta] = useField({ name, validate });

  const hasError = !!meta.touched && !!meta.error;
  const errorMessage = meta.error;
  const text = hasError ? errorMessage : helperText;

  const handleChange = onChange
    ? (event: SelectChangeEvent<unknown>, child: ReactNode) => {
        field.onChange(event);
        onChange(event, child);
      }
    : field.onChange;

  return (
    <Box sx={{ minHeight: disableMinHeight ? 'none' : '96px' }}>
      <PWRselect
        {...field}
        label={label}
        error={hasError}
        helperText={text}
        {...pwrSelectProps}
        onChange={handleChange}
      />
    </Box>
  );
};
