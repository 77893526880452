export enum SolarFilterOptions {
  UNKNOWN = 'UNKNOWN',
  YES = 'YES',
  NO = 'NO',
}

export interface UtilitiesRequest {
  zipcode: string;
}

export interface Utility {
  utilityId: number;
  name: string;
}
