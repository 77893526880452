import { DeviceType } from '../types/DeviceType';
import type { SystemDetails } from '../types/Site';
import { SystemType } from '../types/System';
import type { SystemInfo } from '../types/SystemInfo';
import {
  REGEX_ES2_GATEWAY_SERIAL_V1,
  REGEX_ES2_GATEWAY_SERIAL_V2,
  REGEX_INVERTER_SERIAL,
  REGEX_LOAD_MANAGER_SERIAL,
  REGEX_MICROINVERTER_GATEWAY_SERIAL,
} from './regex';

export const isLoadManager = (serialNumber: string) => REGEX_LOAD_MANAGER_SERIAL.test(serialNumber);
export const isInverter = (serialNumber: string) => REGEX_INVERTER_SERIAL.test(serialNumber);
export const isMicroinverterGateway = (serialNumber: string) =>
  REGEX_MICROINVERTER_GATEWAY_SERIAL.test(serialNumber);
export const isES2Gateway = (serialNumber: string) =>
  REGEX_ES2_GATEWAY_SERIAL_V1.test(serialNumber) || REGEX_ES2_GATEWAY_SERIAL_V2.test(serialNumber);

export const isSystemLoadManager = ({ serialNumber }: SystemDetails) => isLoadManager(serialNumber);
export const isSystemInverter = ({ serialNumber }: SystemDetails) =>
  isInverter(serialNumber) && !isLoadManager(serialNumber);
export const isSystemMicroinverterGateway = ({ serialNumber }: SystemDetails) =>
  isMicroinverterGateway(serialNumber);
export const isSystemES2Gateway = ({ serialNumber }: SystemDetails) => isES2Gateway(serialNumber);

export const getSystemInfoBySerial = (serialNumber: string): SystemInfo => {
  switch (true) {
    case isLoadManager(serialNumber):
      return {
        type: DeviceType.LOAD_MANAGER,
        title: 'Load Manager',
        iconName: 'load manager card',
        systemType: SystemType.LOAD_MANAGER,
      };
    case isInverter(serialNumber):
      return {
        type: DeviceType.INVERTER,
        title: 'Inverter',
        iconName: 'inverter card',
        systemType: SystemType.ESS,
      };
    case isES2Gateway(serialNumber):
      return {
        type: DeviceType.GATEWAY_PENGUIN,
        title: 'Gateway',
        systemType: SystemType.ES2,
        iconName: 'gateway',
      };
    default:
      return {
        type: DeviceType.UNKNOWN,
        iconName: null,
        systemType: SystemType.UNKNOWN,
      };
  }
};
