import type { TextFieldProps } from '@mui/material';
import { TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ChangeEvent } from 'react';
import React from 'react';

import { getColor } from '../../styles';
import { sanitizeString } from '../../utils';

const TextField = styled(MuiTextField)`
  & .MuiFormHelperText-root {
    color: ${({ error }) => getColor(error ? 'ERROR' : 'BLACK')} !important;
  }
`;

export type PWRfleetInputProps = TextFieldProps & {
  sanitize?: boolean;
};

export const Input = ({ sanitize, ...props }: PWRfleetInputProps) => {
  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.target.value = sanitizeString(e.target.value);
    props.onChange?.(e);
  };
  return (
    <TextField
      variant="standard"
      {...props}
      onChange={sanitize ? handleOnChange : props.onChange}
      inputProps={{
        'data-test-id': `input-${props.name}`,
        ...props.inputProps,
      }}
    />
  );
};
