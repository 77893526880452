import { Button as MuiButton } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import type { ComponentProps } from 'react';
import React from 'react';

import { Icon } from '../icon';
import { Loader } from '../loader';

type Props = ComponentProps<typeof MuiButton> & {
  chevron?: boolean;
  isLoading?: boolean;
  width?: number;
  whiteBackground?: boolean;
  target?: string;
  rel?: string;
};

const StyledIcon = styled(Icon)`
  margin-left: 9px;
`;

const StyledMuiButton = styled(MuiButton)<{ $whiteBackground?: boolean; $width?: number }>`
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `}
  ${({ $whiteBackground }) =>
    $whiteBackground &&
    css`
      background-color: white !important;
    `}
`;

export const Button = ({
  children,
  chevron,
  variant = 'outlined',
  color = 'primary',
  isLoading,
  width,
  whiteBackground,
  ...props
}: Props) => {
  return (
    <StyledMuiButton
      variant={variant}
      color={color}
      disableElevation
      disableRipple
      disableFocusRipple
      $whiteBackground={chevron || whiteBackground}
      $width={width}
      {...props}
    >
      {isLoading ? (
        <Loader color="orange" size={8} />
      ) : (
        <>
          {children}
          {chevron ? <StyledIcon icon="chevron down" /> : null}
        </>
      )}
    </StyledMuiButton>
  );
};
