/**
 * @see https://neurio.atlassian.net/wiki/spaces/FM/pages/1991835835/PWRfleet+Analytics
 *
 * PWRfleet uses Mixpanel for analytics
 *
 * Instead of relying on magic strings throughout the codebase, we will define
 * event names here and access them via typed enums. Whenever a new event/property/etc
 * is needed, it should be added here first.
 */

// maintain alphabetical order
export enum AnalyticsEvent {
  AccessTokenRefresh = 'Access Token Refresh',
  AddSiteButtonClick = 'Add Site Button Click',
  AddSite = 'Add Site',
  AddSystemButtonClick = 'Add System Button Click',
  AddSystem = 'Add System',
  AddressEdit = 'Address Edit',
  AlertDetailsClick = 'Alert Details Click',
  ApiError = 'API Error',
  ChartDisplayTypeUpdate = 'Chart Display Type Update',
  ChartBreakdownViewToggle = 'Chart Breakdown View Toggle',
  ChartLegendToggle = 'Chart Legend Toggle',
  ChartSettingsUpdate = 'Chart Settings Update',
  ChartTimeframeSelect = 'Chart Timeframe Select',
  CompanyAssociationAccept = 'Company Association Accept',
  CompanyAssociationRemove = 'Company Association Remove',
  CompanyAssociationRequest = 'Company Association Request',
  CompanySupportCaseCreate = 'Company Create Support Case',
  DeleteEquipment = 'Delete Equipment',
  DeleteEquipmentButtonClick = 'Delete Equipment Button Click',
  DevicesPageTabClick = 'Devices Tab Click',
  DevicesPageDropdownClick = 'Devices Page Dropdown Click',
  DeviceSettingChange = 'Device Setting Change',
  DevicesChartView = 'View Devices Chart',
  DeviceConfigurationButtonClick = 'Device Configuration Button Click',
  LMMCUSwap = 'LM MCU Swap',
  LMMCUSwapButtonClick = 'LM MCU Swap Button Click',
  LMEditPairing = 'LM Edit Pairing',
  LMEditPairingButtonClick = 'LM Edit Pairing Button Click',
  LMViewDetails = 'LM View Details',
  LMViewDetailsButtonClick = 'LM View Details Button Click',
  LogIn = 'Log In',
  LogInButtonClick = 'Log In Button Click',
  LogOut = 'Log Out',
  PageView = 'Page View',
  ProvideFeedbackButtonClick = 'Provide Feedback Button Click',
  SalesforceValidatedCompanyAppLoad = 'Salesforce Validated Company Loaded App',
  SignUp = 'SignUp',
  SignUpAppButtonClick = 'Sign Up App Button Click',
  SignUpButtonClick = 'Sign Up Button Click',
  SignUpFormView = 'Sign Up Form View',
  SingleDeviceChartButtonClick = 'Single Device Chart Button Click',
  SingleDeviceChartMetricChange = 'Single Device Chart Metric Change',
  SingleDeviceChartTimeframeChange = 'Single Device Chart Timeframe Change',
  SiteDetailsUpdate = 'Site Details Update',
  SiteKpiColumnsUpdate = 'Site KPI Columns Update',
  SiteListColumnsUpdate = 'Site List Columns Update',
  SiteMove = 'Site Move',
  SiteMoveButtonClick = 'Site Move Button Click',
  SiteMoveFormCancel = 'Site Move Form Cancel',
  SiteMoveFormSave = 'Site Move Form Save',
  SiteShare = 'Site Share',
  SystemModeEventsClick = 'System Mode Events Click',
  UpdatePowerCore = 'Update Power Core',
  UpdatePowerCoreAndBeacon = 'Update Power Core and Beacon',
  UpdatePowerCoreButtonClick = 'Update Power Core Button Click',
  UserDelete = 'User Delete',
  UserInvite = 'User Invite',
  ViewArrayLayoutButtonClick = 'View Array Layout Button Click',
}

// maintain alphabetical order
export enum AnalyticsProperty {
  AlertId = 'Alert Id',
  AddressType = 'Address Type',
  ApiCallName = 'API Call Name',
  ApiErrorCode = 'API Error Code',
  ApiErrorMessage = 'API Error Message',
  ChartTimeframe = 'Chart Timeframe',
  ChartType = 'Chart Type',
  CompanyId = 'Company Id',
  CompanyName = 'Company Name',
  Count = 'Count',
  DeviceType = 'Device Type',
  DeviceId = 'Device RCPn/Id',
  DevicesPageTab = 'Devices Page Tab',
  DevicesChartView = 'Devices Chart View',
  DeviceSettingName = 'Device Setting Name',
  DeviceSettingValue = 'Device Setting Value',
  EmptySite = 'Empty Site',
  EndDate = 'End Date',
  Granularity = 'Granularity',
  PageName = 'Page Name',
  PageTab = 'Page Tab',
  Platform = 'Platform',
  SignUpMethod = 'Sign Up Method',
  SingleDeviceChartMetric = 'Single Device Chart Metric',
  SingleDeviceChartTimeframe = 'Single Device Chart Timeframe',
  SiteDetailsAttribute = 'Site Details Attribute',
  SiteId = 'Site Id',
  SystemId = 'System Id',
  StartDate = 'Start Date',
  SystemMode = 'System Mode',
  TableColumns = 'Table Columns',
  ToggleState = 'Toggle State',
  UserAccessLevel = 'User Access Level',
  UserId = '$user_id', // mixpanel prop
  UserRole = 'User Role',
  UserStatus = 'User Status',
  UserType = 'User Type',
  ProvidedDealerId = 'Provided Dealer ID', // Whether the installer provided a Generac dealer ID during the SignUp event
  ChartDisplayType = 'Chart Display Type',
  ChartDataType = 'Chart Data Type',
  Success = 'Success',
  SupportCaseRequestType = 'Support Case Request Type',
  RequestResponseTime = 'Request Response Time',
}

export type AnalyticsProperties<P> = {
  [K in AnalyticsProperty]?: P;
};

// maintain alphabetical order
export enum AnalyticsPageName {
  AlertDetailsModal = 'Alert Details Modal',
  CompanyAlerts = 'Company Alerts',
  CompanySupportCaseList = 'Company Customer Case List',
  CompanySupportCase = 'Company Support Case',
  SiteAccess = 'Site Access',
  SiteAlerts = 'Site Alerts',
  SiteDashboard = 'Site Dashboard',
  SiteDetails = 'Site Details',
  SiteDevices = 'Site Devices',
  SiteDeviceMetrics = 'Site Device Metrics',
  SiteDeviceConfiguration = 'Site Device Configuration',
  SiteConfiguration = 'Site Configuration',
  SiteFirmware = 'Site Firmware',
  SiteLayout = 'Site Layout',
  SingleDeviceChart = 'Single Device Chart',
}

export enum AnalyticsToggleState {
  OFF = 'Off',
  ON = 'On',
}
