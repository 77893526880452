import type { DurationLikeObject } from 'luxon';

import { Granularity } from '../types/SiteMetrics';

export type GranularityOption = {
  value: Granularity;
  label: string;
  interval: {
    unit: keyof DurationLikeObject;
    count: number;
  };
};

const GranularityOptions: GranularityOption[] = [
  {
    value: Granularity.MINUTES,
    label: '5 MIN',
    interval: {
      unit: 'minutes',
      count: 5,
    },
  },
  {
    value: Granularity.HOURS,
    label: 'HOUR',
    interval: {
      unit: 'hours',
      count: 1,
    },
  },
  {
    value: Granularity.DAYS,
    label: 'DAY',
    interval: {
      unit: 'days',
      count: 1,
    },
  },
  {
    value: Granularity.MONTHS,
    label: 'MONTH',
    interval: {
      unit: 'months',
      count: 1,
    },
  },
];

export default GranularityOptions;
