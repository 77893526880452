import type { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { get, isUndefined, orderBy } from 'lodash';

import type { User } from '../../types/api/getAllUsers';
import type { EssConfig, LoadManagerConfig } from '../../types/api/getLoadManagerConfigs';
import type { SiteSystem } from '../../types/api/getSiteSystems';
import type {
  AttachmentUploadRequest,
  AttachmentUploadResponse,
} from '../../types/api/siteAttachments';
import { SystemType } from '../../types/System';
import type { TableSort } from '../../types/Table';
import { sortUsers } from './utils';

// note: ts says await baseQuery has no effect, but without it the UI will render with no data

export const uploadAttachmentQueryFn = async (
  args: AttachmentUploadRequest,
  _queryApi: unknown,
  _extraOptions: unknown,
  baseQuery: (
    args: string | FetchArgs,
  ) => QueryReturnValue<AttachmentUploadResponse, FetchBaseQueryError, {}>,
) => {
  const { siteId, file } = args;

  const body = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function (event) {
      if (!event.target || !event.target.result) {
        return reject({});
      }

      const data = event.target.result;
      const encryptedData = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(data.toString()));
      return resolve({
        siteId,
        fileName: file.name,
        sizeBytes: file.size,
        md5: encryptedData.toString(),
        file: file,
      });
    };
    reader.readAsBinaryString(file);
  });

  const response = await baseQuery({
    method: 'POST',
    url: `/sites/v2/${siteId}/attachment`,
    body,
  });

  if (!!response.data) {
    // requests to s3 with auth token fail, use axios to bypass
    await axios.create({ timeout: 45000 }).put(response.data.putUrl, file);
  }

  return response;
};

type SiteSystemReturnValue = QueryReturnValue<SiteSystem[], FetchBaseQueryError, {}>;
type EssConfigReturnValue = QueryReturnValue<EssConfig[], FetchBaseQueryError, {}>;
type BaseQueryReturnValue = SiteSystemReturnValue | EssConfigReturnValue;
type QueryFnReturnValue = Promise<QueryReturnValue<LoadManagerConfig[], FetchBaseQueryError, {}>>;

export const getLoadManagerConfigsQueryFn = async (
  { siteId }: { siteId: string },
  queryApi: BaseQueryApi,
  _extraOptions: unknown,
  baseQuery: (args: string | FetchArgs) => BaseQueryReturnValue,
): QueryFnReturnValue => {
  const store = queryApi.getState();
  const siteSystemsFromStore: SiteSystem[] | undefined = get(
    store,
    `api.queries.getSiteSystems({"siteId":"${siteId}"}).data`,
  );

  let siteSystems: SiteSystem[] = [];
  if (!isUndefined(siteSystemsFromStore)) {
    // if store has data use it, explicitly checking for undefined since an empty array is valid
    siteSystems = siteSystemsFromStore;
  } else {
    const response = await baseQuery({
      url: `/sites/v1/${siteId}/systems`,
    });
    const siteSystemsFromBaseQuery = response.data as SiteSystem[] | undefined;

    if (!isUndefined(siteSystemsFromBaseQuery)) {
      // explicitly checking for undefined because data === undefined when promise is pending, and response could be falsy
      siteSystems = siteSystemsFromBaseQuery;
    }
  }

  const loadManagerSystems = siteSystems.filter(
    (system) => system.systemType === SystemType.LOAD_MANAGER,
  );

  return Promise.all(
    loadManagerSystems.map(async (system) => {
      const response = await baseQuery({
        url: `/loadcontroller/v1/systems/essConfig`,
        params: { systemId: system.systemId },
      });

      const essConfig = response.data as EssConfig | undefined;

      return {
        ...response,
        data: {
          systemId: system.systemId,
          serialNumber: system.serialNumber,
          essConfig,
        },
      };
    }),
  )
    .then((responses) => {
      return { data: responses.map((response) => response.data) };
    })
    .catch((error) => {
      return { error };
    });
};

type GetAllUsersQueryReturnValue = QueryReturnValue<User[], FetchBaseQueryError, {}>;

export const getAllUsersQueryFn = async (
  _arg: {},
  queryApi: BaseQueryApi,
  _extraOptions: unknown,
  baseQuery: (args: string | FetchArgs) => GetAllUsersQueryReturnValue,
): Promise<GetAllUsersQueryReturnValue> => {
  const store = queryApi.getState();
  const allUsersFromStore = get(store, 'api.queries.getAllUsers({}).data');
  const usersTableSort: TableSort = get(store, 'ui.features.usersTable.sort');
  const { sortColumn, sortOrder } = usersTableSort;

  let users: User[] = [];
  if (!isUndefined(allUsersFromStore)) {
    // if store has data use it, explicitly checking for undefined since an empty array is valid
    users = allUsersFromStore;
  } else {
    const response = await baseQuery({
      url: '/user-ms/v2/users/all',
    });
    const allUsersFromBaseQuery = response.data;

    if (!isUndefined(allUsersFromBaseQuery)) {
      // explicitly checking for undefined because data === undefined when promise is pending, and response could be falsy
      users = allUsersFromBaseQuery;
    }
  }

  return {
    data: orderBy(users, (user: User) => sortUsers(user, sortColumn), sortOrder),
  };
};
