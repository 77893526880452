import { Box, Chip } from '@mui/material';
import { isEmpty } from 'lodash';

import type { FilterModel } from '../../types/Table';
import { truncateString } from '../../utils';
import { Flex } from '../flex';
import { PWRlink } from '../pwrLink';

interface FilterChipsProps {
  filterModel: FilterModel;
  handleDeleteFilter: (field: string) => void;
  handleClearAllFilters: () => void;
}

export const FilterChips = ({
  filterModel,
  handleDeleteFilter,
  handleClearAllFilters,
}: FilterChipsProps) => (
  <Flex sx={{ alignItems: 'center' }}>
    {filterModel.items.map((item) => (
      <Box pr={2} key={item.field}>
        <Chip
          color="info"
          size="small"
          data-test-id={`${item.field}-filter-chip`}
          label={truncateString(
            `${item.value?.columnHeaderName}: ${item.value?.valueFilterChip}`,
            35,
          )}
          onDelete={() => handleDeleteFilter(item.field)}
        />
      </Box>
    ))}
    {!isEmpty(filterModel.items) && (
      <Box pr={2}>
        <PWRlink
          id="filter-clear-all-btn"
          button
          underline="none"
          handleClick={handleClearAllFilters}
          sx={{ fontWeight: 'bold' }}
        >
          Clear All
        </PWRlink>
      </Box>
    )}
  </Flex>
);
