import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { DATE_TIME_WITH_TIMEZONE_FORMAT } from '../../constants/dates';
import { useInterval } from '../../hooks/useInterval';
import { PWRtooltip } from '../pwrTooltip';

const DEFAULT_REFRESH_INTERVAL = 10000; // 10 seconds

export interface Props {
  timestamp: number | null;
  timezone?: string;
  prefixText?: string;
  format?: string;
  refreshInterval?: number;
  tooltipProps?: any;
  typographyProps?: any;
  withoutAgoSuffix?: boolean;
}

export const TimeLastHeardTooltip = (props: Props) => {
  const { typographyProps } = props;

  return (
    <>
      <Typography sx={{ display: 'inline-block', mr: 1 }} {...typographyProps}>
        {props.prefixText || 'Last heard:'}
      </Typography>
      <TimeagoTooltip {...props} />
    </>
  );
};

export const TimeagoTooltip = ({
  timestamp,
  timezone,
  format = DATE_TIME_WITH_TIMEZONE_FORMAT,
  refreshInterval = DEFAULT_REFRESH_INTERVAL,
  tooltipProps,
  typographyProps,
  withoutAgoSuffix = false,
}: Props) => {
  const [resetCount, setResetCount] = useState<number>(0);
  const timeString = !timestamp ? 'Never' : DateTime.fromMillis(timestamp).toRelative();
  const formattedTimeString = withoutAgoSuffix ? timeString?.replace(' ago', '') : timeString;

  const tooltipMessage = !timestamp
    ? 'This device was registered to your system, but has never communicated with our servers. Please contact Generac Support for assistance.'
    : DateTime.fromMillis(timestamp).setZone(timezone).toFormat(format);

  // this will ensure rerendering of the timeago value to keep it updated
  useInterval(() => {
    setResetCount(resetCount + 1);
  }, refreshInterval);

  return (
    <PWRtooltip
      message={tooltipMessage}
      placement="right"
      {...tooltipProps}
      tooltipSx={{
        fontSize: '12px',
        textAlign: 'left',
        borderRadius: '4px',
        p: 1,
      }}
    >
      <Typography
        sx={{ display: 'inline-block', borderBottom: '0.5px dotted #ddd', cursor: 'pointer' }}
        {...typographyProps}
      >
        {formattedTimeString}
      </Typography>
    </PWRtooltip>
  );
};
