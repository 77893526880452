import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import React from 'react';

interface Props {
  title?: string;
  children: ReactNode;
}

const ItemsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  & > * {
    margin-top: 7px;
  }
`;

export const DrawerSection = (props: Props) => {
  const { title, children } = props;

  return (
    <Box mb={4} display="flex" flexDirection="column">
      {title && <Typography variant="subtitle2">{title}</Typography>}
      <ItemsContainer>{children}</ItemsContainer>
    </Box>
  );
};
