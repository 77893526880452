import { styled } from '@mui/material/styles';
import WojtekmajReactCalendar from 'react-calendar';

import { COLORS } from '../../styles';

/**
 * WARNING: YOU PROBABLY DON'T WANT TO USE THIS COMPONENT DIRECTLY. LOOK AT ZonedDateRangePicker FIRST TO SEE IF THAT BETTER MEETS YOUR NEEDS
 * This is just a lower-level component, a restyled version of Wojtekmaj's `react-calendar`, but without the extensive custom logic we implemented for PWRfleet.
 * */
export const StyledReactCalendar = styled(WojtekmajReactCalendar)`
  // See original LESS source at node_modules/react-calendar/dist/Calendar.less
  // The LESS gets converted into CSS, which we import as default styles above.
  // Then we apply these styles as overrides on top of the defaults to make it look more like the standard MUI date picker. Not everything is 100% the same but it's close.

  &.react-calendar {
    border: none;
    font-family: Nunito Sans, sans-serif !important;

    .react-calendar__viewContainer {
      font-size: 14px;
    }

    .react-calendar__navigation__label__labelText {
      font-size: 24px;
      foint-weight: bold;
    }

    .react-calendar__month-view__weekdays {
      text-transform: none;
      color: ${COLORS.MEDIUM_GREY};
      font-size: unset;
      font-weight: normal;

      abbr[title] {
        text-decoration: none;
      }
    }

    .react-calendar__tile {
      height: 47px; // closest to a perfect circle i could get
      width: 47px;
      border-radius: 50%;
      background: none;
      padding: 0;
      color: ${COLORS.BLACK};

      abbr {
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        line-height: 45px;
        padding: 1px;
      }

      :disabled {
        :hover abbr {
          border: 0;
          padding: 1px;
        }
      }

      :hover {
        abbr {
          padding: 0;
          border: 1px solid ${COLORS.ORANGE};
        }
      }

      &--now,
      &--now:enabled {
        color: ${COLORS.ORANGE};
        font-weight: bolder;
      }

      &--active {
        background: ${COLORS.BLACK};
        color: ${COLORS.WHITE};

        :hover,
        :focus {
          background: ${COLORS.LIGHT_GREY};
        }
      }

      &--range {
        background: ${COLORS.LIGHT_GREY};
        color: ${COLORS.BLACK};
        border-radius: 0;
      }

      &--rangeEnd {
        border-radius: 0 50% 50% 0;
      }

      &--rangeStart {
        border-radius: 50% 0 0 50%;
      }

      &--rangeStart,
      &--rangeEnd {
        abbr {
          background: ${COLORS.BLACK};
          color: ${COLORS.WHITE};
        }
      }

      &--rangeBothEnds {
        border-radius: 50%;
      }
    }

    &&--selectRange {
      // while selecting a date range
      .react-calendar__tile {
        &--hover:enabled {
          background: ${COLORS.LIGHT_GREY};
          color: ${COLORS.BLACK};
          border-radius: 0;
        }

        &--hoverStart:enabled,
        &.earliest-valid {
          border-radius: 50% 0 0 50%;
        }

        &--hoverEnd:enabled,
        &.latest-valid {
          border-radius: 0 50% 50% 0;
        }

        &--hoverBothEnds:enabled {
          border-radius: 50%;
        }
      }
    }

    .react-calendar__tile {
      .invalid,
      :disabled {
        text-decoration: line-through;
        color: ${COLORS.LIGHT_GREY};
        cursor: not-allowed;
      }
    }

    .react-calendar__navigation {
      button {
        &.react-calendar__navigation__arrow {
          font-size: 30px;
          border-radius: 50%;
        }

        &.react-calendar__navigation__label {
          font-size: 12pt;
          color: ${COLORS.BLACK};
          font-weight: normal;
        }

        :disabled {
          background: none;
        }
      }
    }
  }

  &.react-calendar--doubleView {
    color: red;

    .react-calendar__navigation__label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      .react-calendar__navigation__label {
        &__labelText {
          display: flex;
          width: 50%;
          justify-content: center;

          &--from {
            padding-right: 44px;
          }

          &--to {
            padding-left: 44px;
          }
        }

        &__divider {
          display: none;
        }
      }
    }
  }
`;
