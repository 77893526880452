import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type { SiteAlert, SiteAlertsFailedResponse } from '../../types/Alert';
import * as actions from './actions';

export interface SiteAlertsState {
  items: SiteAlert[];
  page: number;
  perPage: number;
  totalResults: number;
  loading: boolean;
  error: SiteAlertsFailedResponse | null;
}

const INITIAL_STATE: SiteAlertsState = {
  items: [],
  page: 1,
  perPage: 10,
  totalResults: 0,
  loading: false,
  error: null,
};

export type SiteAlertsActions = ActionType<typeof actions>;

export default createReducer<SiteAlertsState, SiteAlertsActions>(INITIAL_STATE, {
  [getType(actions.loadSiteAlerts.request)]: produce((state: SiteAlertsState) => {
    state.loading = true;
    state.error = null;
  }),
  [getType(actions.loadSiteAlerts.success)]: produce(
    (state: SiteAlertsState, action: ReturnType<typeof actions.loadSiteAlerts.success>) => {
      const { alerts, page, perPage, totalResults } = action.payload;
      state.items = alerts;
      state.page = page;
      state.perPage = perPage;
      state.totalResults = totalResults;
      state.loading = false;
    },
  ),
  [getType(actions.loadSiteAlerts.failure)]: produce(
    (state: SiteAlertsState, action: ReturnType<typeof actions.loadSiteAlerts.failure>) => {
      state.loading = false;
      state.error = action.payload;
    },
  ),
});
