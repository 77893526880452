import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { TypographyProps } from '@mui/material/Typography';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';
import React from 'react';

const ActionsContainer = styled('div')`
  flex-shrink: 0;
`;

export interface Props {
  title: string;
  titleTypographyProps?: TypographyProps;
  actions?: ReactNode;
}

export const ScreenHeader = (props: Props) => {
  const { title, titleTypographyProps = { variant: 'h2' }, actions, ...rest } = props;

  // @todo implement react-helmet to update <head /> for each screen

  return (
    <Box display="flex" alignItems="center" mb={5} {...rest}>
      <Typography {...titleTypographyProps}>{title}</Typography>
      <Box flexGrow={1} />
      {actions && <ActionsContainer>{actions}</ActionsContainer>}
    </Box>
  );
};
