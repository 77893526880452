import type { ButtonProps } from '@mui/material';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ElementType } from 'react';
import { prop } from 'styled-tools';

import { COLORS } from '../../styles';
import { Loader } from '../loader';

export interface PWRbuttonProps extends ButtonProps {
  text: string;
  isLoading?: boolean;
  backgroundColor?: 'orange' | 'teal' | 'black';
  component?: ElementType; // HTML tag to use for component (if not <button>)
}

const backgroundColors = {
  orange: COLORS.ORANGE,
  teal: COLORS.TEAL,
  black: COLORS.BLACK,
};

/*
 * some props (such as sx) are not passed down properly to styled components, so
 * using styled for oulined button as well, so that the same props are valid no
 * matter which variant is used, can also add outlined button styles here later
 */

const StyledOutlinedButton = styled(Button)`
  height: 40px;
  font-size: 16px;
`;

const StyledContainedButton = styled(Button)<{ $color: string }>`
  height: 40px;
  font-size: 16px;
  box-shadow: none !important;
  background-color: ${prop('$color')} !important;
  border: none;
`;

export const PWRbutton = ({
  id,
  text,
  isLoading,
  backgroundColor,
  ...buttonProps
}: PWRbuttonProps) => {
  const isContained = buttonProps.variant === 'contained';
  const colorProperty = backgroundColor ? backgroundColor : 'orange';
  const color = backgroundColors[colorProperty];

  const loaderColor = colorProperty === 'orange' ? 'white' : 'orange';

  return isContained ? (
    <StyledContainedButton data-test-id={id} $color={color} {...buttonProps}>
      {isLoading ? <Loader color={loaderColor} size={8} /> : text}
    </StyledContainedButton>
  ) : (
    <StyledOutlinedButton data-test-id={id} {...buttonProps}>
      {isLoading ? <Loader color="orange" size={8} /> : text}
    </StyledOutlinedButton>
  );
};
