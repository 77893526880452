import type { Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getSiteAlerts } from '../../api';
import { LOADING_ERROR_MESSAGE } from '../../constants/dataLoadingMessages';
import type { SiteAlertsSuccessResponse } from '../../types/Alert';
import { Alert } from '../../types/ScreenAlerts';
import { customAlert } from '../../utils/alerts';
import type { RootAction } from '..';
import type { RootState } from '../types';
import { loadSiteAlerts } from './actions';
import { siteAlertsPerPageSelector } from './selectors';

type EpicType = Epic<RootAction, RootAction, RootState>;

const loadSiteAlertsEpic: EpicType = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadSiteAlerts.request)),
    mergeMap((action) => {
      const perPage = siteAlertsPerPageSelector(state$.value);
      return from(getSiteAlerts({ perPage, ...action.payload })).pipe(
        map((res) => loadSiteAlerts.success(res as SiteAlertsSuccessResponse)),
        catchError(async (e) => {
          customAlert(
            {
              title: LOADING_ERROR_MESSAGE,
              closeButton: true,
            },
            { type: 'error', toastId: Alert.SiteAlertsDataError },
          );
          return loadSiteAlerts.failure(e);
        }),
      );
    }),
  );

export default combineEpics(loadSiteAlertsEpic);
