import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type {
  CompanyUser,
  PendingCompanyUser,
  PendingCompanyUsersFailedData,
  UpdateCompanyUserRoleFailedResponse,
} from '../../types/CompanyUser';
import type { UserInviteFailedResponseData } from '../../types/UserInvite';
import { sort, SortOrder } from '../../utils/sort';
import * as actions from './actions';

export interface State {
  items: CompanyUser[];
  loading: boolean;
  error: string | null;
  selectedSortProperty: string;
  selectedSortOrder: SortOrder;
  sendingInvite: boolean;
  sendingInviteError: UserInviteFailedResponseData | null;
  inviteSent: boolean;
  pendingUsersItems: PendingCompanyUser[];
  pendingUsersLoading: boolean;
  pendingUsersError: PendingCompanyUsersFailedData | null;
  pendingUsersSortProperty: string;
  pendingUsersSortOrder: SortOrder;
  updateUserRoleLoading: boolean;
  updateUserRoleError: UpdateCompanyUserRoleFailedResponse | null;
}

const INITIAL_STATE: State = {
  items: [],
  loading: false,
  error: null,
  selectedSortProperty: 'name',
  selectedSortOrder: SortOrder.ASC,
  sendingInvite: false,
  sendingInviteError: null,
  inviteSent: false,
  pendingUsersItems: [],
  pendingUsersLoading: false,
  pendingUsersError: null,
  pendingUsersSortProperty: 'email',
  pendingUsersSortOrder: SortOrder.ASC,
  updateUserRoleLoading: false,
  updateUserRoleError: null,
};

export type Actions = ActionType<typeof actions>;

const getFullUserName = (user: CompanyUser) =>
  user.fullName || [user.firstName, user.lastName].filter(Boolean).join(' ').toLowerCase();

export default createReducer<State, Actions>(INITIAL_STATE, {
  [getType(actions.loadCompanyUsers.request)]: produce((state: State) => {
    state.loading = true;
    state.error = null;
  }),
  [getType(actions.loadCompanyUsers.success)]: produce(
    (state: State, action: ReturnType<typeof actions.loadCompanyUsers.success>) => {
      if (state.selectedSortProperty === 'name') {
        state.items = sort(action.payload, state.selectedSortOrder, getFullUserName);
      } else {
        state.items = sort(action.payload, state.selectedSortOrder, state.selectedSortProperty);
      }
      state.loading = false;
      state.error = null;
    },
  ),
  [getType(actions.loadCompanyUsers.failure)]: produce(
    (state: State, action: ReturnType<typeof actions.loadCompanyUsers.failure>) => {
      state.error = action.payload.message;
      state.loading = false;
    },
  ),
  [getType(actions.loadPendingCompanyUsers.request)]: produce((state: State) => {
    state.pendingUsersLoading = true;
    state.pendingUsersError = null;
  }),
  [getType(actions.loadPendingCompanyUsers.success)]: produce(
    (state: State, action: ReturnType<typeof actions.loadPendingCompanyUsers.success>) => {
      state.pendingUsersItems = sort(
        action.payload,
        state.pendingUsersSortOrder,
        state.pendingUsersSortProperty,
      );
      state.pendingUsersLoading = false;
      state.pendingUsersError = null;
    },
  ),
  [getType(actions.loadPendingCompanyUsers.failure)]: produce(
    (state: State, action: ReturnType<typeof actions.loadPendingCompanyUsers.failure>) => {
      state.pendingUsersError = action.payload;
      state.pendingUsersLoading = false;
    },
  ),
  [getType(actions.sortPendingCompanyUsers)]: produce(
    (state: State, action: ReturnType<typeof actions.sortPendingCompanyUsers>) => {
      if (state.pendingUsersSortProperty === action.payload) {
        state.pendingUsersSortOrder =
          state.pendingUsersSortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
      }
      state.pendingUsersSortProperty = action.payload;
      state.pendingUsersItems = sort(
        state.pendingUsersItems,
        state.pendingUsersSortOrder,
        action.payload,
      );
    },
  ),
  [getType(actions.sortCompanyUsers)]: produce(
    (state: State, action: ReturnType<typeof actions.sortCompanyUsers>) => {
      if (state.selectedSortProperty === action.payload) {
        state.selectedSortOrder =
          state.selectedSortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
      }
      state.selectedSortProperty = action.payload;

      if (action.payload === 'name') {
        state.items = sort(state.items, state.selectedSortOrder, getFullUserName);
      } else {
        state.items = sort(state.items, state.selectedSortOrder, action.payload);
      }
    },
  ),
  [getType(actions.sendInvites.request)]: produce((state: State) => {
    state.sendingInviteError = null;
    state.sendingInvite = true;
    state.inviteSent = false;
  }),
  [getType(actions.sendInvites.success)]: produce((state: State) => {
    state.sendingInvite = false;
    state.inviteSent = true;
  }),
  [getType(actions.sendInvites.failure)]: produce(
    (state: State, action: ReturnType<typeof actions.sendInvites.failure>) => {
      state.sendingInviteError = action.payload;
      state.sendingInvite = false;
      state.inviteSent = false;
    },
  ),
  [getType(actions.updateCompanyUserRole.request)]: produce((state: State) => {
    state.updateUserRoleLoading = true;
    state.updateUserRoleError = null;
  }),
  [getType(actions.updateCompanyUserRole.success)]: produce((state: State) => {
    state.updateUserRoleLoading = false;
    state.updateUserRoleError = null;
  }),
  [getType(actions.updateCompanyUserRole.failure)]: produce(
    (state: State, action: ReturnType<typeof actions.updateCompanyUserRole.failure>) => {
      state.updateUserRoleLoading = false;
      state.updateUserRoleError = action.payload;
    },
  ),
  [getType(actions.clearInviteVariables)]: produce((state: State) => {
    state.sendingInvite = false;
    state.sendingInviteError = null;
    state.inviteSent = false;
  }),
  [getType(actions.loggedOut)]: () => {
    return { ...INITIAL_STATE };
  },
});
