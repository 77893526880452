import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Icon } from '../../components';
import { down, getColor, up } from '../../styles';

export const StyledBoldTitle = styled(Typography)`
  font-weight: 700;
`;

export const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledLogo = styled('img')`
  margin: 40px auto;
  width: 90px;
  cursor: pointer;
`;

export const StyledSections = styled('div')`
  display: flex;
  max-width: ${({ theme }) => theme.breakpoints.values.lg}px;
  margin: 0 auto 40px;

  h2 {
    margin: 0;
  }

  form {
    > * {
      margin-top: 40px;
      display: block;
    }
  }

  ${down('sm')} {
    flex-direction: column;
  }
`;

export const StyledSection = styled('div')`
  flex: 1;
  padding: 0;

  &:first-of-type {
    padding-left: 20px;
    padding-right: 100px;

    ${down('sm')} {
      padding: 0 40px 40px;
    }
  }

  &:last-of-type {
    border-left: 2px dotted silver;
    padding-right: 20px;
    padding-left: 100px;

    ${down('sm')} {
      border-top: 2px dotted silver;
      border-left: none;
      padding: 40px 40px 0;
    }
  }

  &.success-section {
    max-width: 480px;
    border: 0;
    padding: 0 20px 40px;

    .success-section__button-container {
      text-align: center;
    }
  }
`;

export const StyledLoginButtons = styled('div')`
  button {
    display: flex;
    margin: 30px 0 0;

    ${down('sm')} {
      margin: 30px auto 0;
    }
  }
`;

export const StyledSignUpForm = styled('form')`
  h3 {
    margin-bottom: 0;
  }

  .form__field {
    margin: 30px 0;
  }

  .form__agreement {
    max-width: ${({ theme }) => theme.breakpoints.values.md}px;
    margin: 0 auto;
    padding: 0 40px 100px;
    justify-content: center;

    label {
      margin-bottom: 20px;
    }

    ${up('md')} {
      display: flex;
      flex-direction: column;
    }

    a {
      color: inherit;
    }
  }
`;

export const StyledAgreementLabel = styled(Typography)`
  color: ${getColor('MEDIUM_GREY')};
  ${up('md')} {
    display: inline-block;
  }
`;

export const StyledSignUpButtons = styled('div')`
  ${down('sm')} {
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px 0px;
    position: fixed;
    background: ${getColor('WHITE')};
  }

  button {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    min-width: 120px;

    & + button {
      margin-left: 20px;
    }
  }
`;
export const StyledLoginButtonContainer = styled('div')`
  text-align: center;
`;

export const StyledBackIcon = styled(Icon)`
  border: 1px solid ${getColor('MEDIUM_GREY')};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 6px;
  vertical-align: middle;
  margin-right: 0.75em;
`;

export const StyledBackButton = styled('a')`
  margin-top: 20px;
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  cursor: pointer;
`;

export const StyledStoreLinks = styled('div')`
  a:first-child {
    margin-right: 15px;
  }
`;
