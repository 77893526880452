import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { generatePath } from 'react-router-dom';

import { useRoutes } from '../../hooks/useRoutes';
import { getColor } from '../../styles';

interface Props {
  companiesCount: number;
  usersCount: number;
  targetCompanyId: string;
  companyName: string;
  isError: boolean;
}

const Link = styled('a')`
  color: ${getColor('BLACK')};
`;

export const MergeNotification = ({
  companiesCount,
  usersCount,
  targetCompanyId,
  companyName,
  isError,
}: Props) => {
  const Routes = useRoutes();

  return (
    <div>
      {isError ? (
        <Typography variant="subtitle2">Failed to merge.</Typography>
      ) : (
        <Typography variant="subtitle2">
          Success! {companiesCount} accounts and {usersCount} users have been merged into one
          account. Review the new{' '}
          <Link href={generatePath(Routes.CompanySitesList, { companyId: targetCompanyId })}>
            {companyName}
          </Link>{' '}
          account.
        </Typography>
      )}
    </div>
  );
};
