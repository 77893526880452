import { useRouteMatch } from 'react-router-dom';

import type { SiteContextParams } from '../types/Routes';
import { useRoutes } from './useRoutes';

/**
 * This hook parse siteId from url.
 **/

export const useSiteContext = (): Partial<SiteContextParams> => {
  const Routes = useRoutes();
  const match = useRouteMatch<SiteContextParams>(Routes.SiteDashboard);
  return {
    ...match?.params,
  };
};
