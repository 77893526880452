import { createAction, createAsyncAction } from 'typesafe-actions';

import type {
  SignUpFailedResponse,
  SignUpRequest,
  SignUpSuccessResponse,
} from '../../types/SignUp';
import type { Error } from '.';

export const signUpRequest = createAsyncAction(
  'SIGNUP_REQUEST',
  'SIGNUP_SUCCESS',
  'SIGNUP_FAILURE',
)<SignUpRequest, SignUpSuccessResponse, Error>();

export const signUpValidationError =
  createAction('SIGNUP_VALIDATION_ERROR')<SignUpFailedResponse>();
