/**
 * Analytics Service by Mixpanel
 *
 * @see https://developer.mixpanel.com/docs/javascript
 */

import mixpanel from 'mixpanel-browser';

import type { AnalyticsEvent, AnalyticsProperties } from './types/Analytics';

const DEBUG_MODE = false;
const PRODUCTION_MODE = process.env.NODE_ENV === 'production';

export const trackEvent = (
  eventName: AnalyticsEvent,
  eventProps?: AnalyticsProperties<unknown>,
) => {
  mixpanel.track(eventName, eventProps);
};

export const init = () => {
  const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN || '';

  mixpanel.init(mixpanelToken, {
    debug: !PRODUCTION_MODE && DEBUG_MODE,
  });
};

/**
 * Mixpanel will use ID Merge feature to track same user across pre and post-auth events
 * @see https://developer.mixpanel.com/docs/javascript#identify
 */
export const identify = (userId: string, identifyProps: AnalyticsProperties<unknown>) => {
  mixpanel.people.set(identifyProps);
  mixpanel.identify(userId);
};

/**
 * Register properties to be sent with every tracking event
 * @see https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelregister
 */
export const register = (registerProps: AnalyticsProperties<unknown>) => {
  mixpanel.register(registerProps);
};

/**
 * Clears super properties and generates a new random distinct_id for this instance
 * Only used when a user logs out
 * @see https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelreset
 */
export const reset = () => {
  mixpanel.reset();
};
