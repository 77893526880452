import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import React from 'react';

import { down } from '../../styles';

interface FormContentBlockProps {
  isEditing?: boolean;
  children: ReactNode;
}

const StyledFormContentBlock = styled(Box)`
  ${down('xs')} {
    width: 100%;
  }
`;

export const FormContentBlock = (props: FormContentBlockProps) => {
  const { isEditing = false, children } = props;

  return (
    <StyledFormContentBlock mb={2} sx={{ width: isEditing ? '60%' : null }}>
      {children}
    </StyledFormContentBlock>
  );
};
