import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../types';

export const companyIdSelector = (state: RootState) => state.company.id || '';
export const companyLoadingSelector = (state: RootState) => state.company.loading;
export const companyErrorSelector = (state: RootState) => state.company.error;

export const companyNameSelector = (state: RootState) => {
  if (state.company) {
    return state.company.name;
  }
  return '';
};

export const fleetSelector = (state: RootState) => {
  // returns first fleet of company
  if (state.company.fleets.length > 0) {
    return state.company.fleets[0];
  }
  return null;
};

export const fleetIdSelector = createSelector(fleetSelector, (fleet) => fleet?.id);

export const selectedCompanySelector = (state: RootState) => {
  return state.company.companyDetails;
};
