import { styled } from '@mui/material/styles';
import React from 'react';

import { SortOrder } from '../../utils/sort';
import { Icon } from '../icon';
import type { IconType } from '../icon/icons';

const StyledSortIcon = styled('div')`
  padding: 5px 0 0 10px;
  display: inline-flex;
`;

interface Props {
  isSelected: boolean;
  order: SortOrder;
}

export const TableSortIcon = ({ isSelected, order }: Props) => {
  let iconName = 'arrow down unselected' as IconType;
  if (isSelected) {
    iconName =
      order === SortOrder.ASC
        ? ('arrow up selected' as IconType)
        : ('arrow down selected' as IconType);
  }

  return (
    <StyledSortIcon>
      <Icon icon={iconName} />
    </StyledSortIcon>
  );
};
