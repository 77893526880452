/* eslint-disable @typescript-eslint/naming-convention */
import type { AxiosError } from 'axios';

import type { UpdateAddressRequestBodyAddress } from './Address';
import type { CommissioningStage } from './Commissioning';
import type { CompanyAccessLevel } from './CompanyAccessLevel';
import type { GeneratorStatus } from './SiteDevices';
import type { SystemType } from './System';
import type { SystemMode } from './SystemMode';

export enum LoadManagerStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export interface Contact {
  businessName: string | null;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  areaCode?: string; // deprecated, but still returned by API
}

export interface ExpectedProduction {
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
  annual: number;
}

export interface Address {
  address1: string;
  countryCode: string;
  state: string;
  city: string;
  zip: string;
  streetAddress: string;
  latitude: number;
  longitude: number;
}

export interface SiteDetails {
  accessLevel: CompanyAccessLevel;
  siteId: string;
  fleetId: string;
  siteName: string;
  nameplateSolarCapacity_kW: number;
  nameplateBatteryCapacity_kWh: number;
  siteAddress: Address;
  homeownerAddress: Address;
  expectedProduction: ExpectedProduction | null;
  createdAt: string;
  installDate: string | null; // null for empty sites
  updatedAt: string;
  notes: string;
  contact: Contact;
  timezone: string;
  utilityId: number | null;
  utilityName: string | null;
  loadControllerStatus: LoadManagerStatus | null;
  dcGeneratorStatus: GeneratorStatus | null;
  siteSystemTypes: SystemType[];
  commissioningStage: CommissioningStage;
  grid: 'Grid Connected' | 'Islanding' | null; // unused
}

// rename to SiteDetailsAttribute when legacy screens are deleted, enum should not be a verb
export enum SiteDetailsEditing {
  SiteName = 'siteName',
  SiteAddress = 'siteAddress',
  CoordinatesOverride = 'coordinatesOverride',
  ContactEmail = 'contactEmail',
  ContactFirstName = 'contactFirstName',
  ContactLastName = 'contactLastName',
  ContactPhone = 'contactPhone',
  ContactAddress = 'contactAddress',
  CreateTemporaryPassword = 'createTemporaryPassword',
  ExpectedProduction = 'expectedProduction',
  NameplateSolarCapacityKw = 'nameplateSolarCapacityKw',
  Notes = 'notes',
  AttachmentUpload = 'attachmentUpload',
  AttachmentDelete = 'attachmentsDelete',
}

export interface SiteRequestBody {
  siteName?: string;
  notes?: string;
  nameplateSolarCapacity_kW?: number;
  expectedProduction?: ExpectedProduction;
  contact?: UpdateSiteContactInterface;
  siteAddress?: UpdateAddressRequestBodyAddress;
  homeownerAddress?: UpdateAddressRequestBodyAddress;
}

export interface UpdateSiteContactInterface {
  phone?: string;
  firstName?: string;
  lastName?: string;
}

export const SystemStatusTitle = {
  NEVER_CONNECTED: 'NEVER CONNECTED',
  DISCONNECTED: 'NO CONNECTIVITY',
  UNKNOWN: 'UNKNOWN',
  DISABLED: 'DISABLED',
  INITIALIZING: 'INITIALIZING',
  POWERING_UP: 'POWERING UP',
  CONNECTING_TO_REBUS: 'CONNECTING TO REBUS',
  TESTING_REBUS: 'TESTING REBUS',
  LOW_REBUS_VOLTAGE: 'LOW REBUS VOLTAGE',
  WAITING: 'WAITING',
  CONNECTING_GRID: 'CONNECTING TO GRID',
  DISCONNECTING_GRID: 'DISCONNECTING FROM GRID',
  GRID_CONNECTED: 'GRID CONNECTED',
  ISLANDED: 'ISLANDED',
  GENERATOR_PARALLEL: 'GENERATOR PARALLEL',
  GROUND_FAULT: 'GROUND FAULT',
  ERROR: 'ERROR',
  ERROR_REBUS_FAULT: 'REBUS FAULT',
  INTERNAL_BUS_FAULT: 'INTERNAL BUS FAULT',
  CONFIGURATION_ERROR: 'CONFIGURATION ERROR',
  BAD_RESET_ERROR: 'BAD RESET ERROR',
  STATUS_CODE_NOT_FOUND: 'UNKNOWN STATE',
  ISLAND_OVERLOAD: 'ISLAND OVERLOAD',
  ISLANDED_AC_PV_OFF: 'ISLANDED AC PV OFF',
  ISLANDED_AC_PV_ON: 'ISLANDED AC PV ON',
  SYSMODE_CHANGE: 'SYSTEM MODE CHANGE',
  STATUS_ERROR: 'UNABLE TO RETRIEVE SYSTEM STATUS', // FE-only
};

export type InverterStatusName = keyof typeof SystemStatusTitle;

export enum DeviceStatusName {
  SUCCESS = 'SUCCESS',
  DISCONNECTED = 'DISCONNECTED',
  ERROR = 'ERROR',
}

export enum SystemStatusName {
  SUCCESS = 'SUCCESS',
  DISCONNECTED = 'DISCONNECTED',
  NEVER_CONNECTED = 'NEVER_CONNECTED',
}

export enum StatusType {
  TRANSIENT = 'TRANSIENT',
  DISABLED = 'DISABLED',
  GRID_CONNECTED = 'GRID_CONNECTED',
  ISLANDED = 'ISLANDED',
  ERROR = 'ERROR',
}

export type DeviceStatus = {
  statusName: DeviceStatusName;
  healthyDeviceCount: number;
  unhealthyDeviceCount: number;
};

export type System = {
  systemId: string;
  serialNumber: string;
  statusName: SystemStatusName;
  updatedAt: string;
  sysMode?: {
    mode: string;
    modeName: SystemMode;
    success?: boolean;
  };
  inverter: {
    statusName: InverterStatusName;
    statusCode: number;
    statusType: StatusType;
  } | null;
  battery: DeviceStatus | null;
  rgm: DeviceStatus | null;
  pvl: DeviceStatus | null;
  loadController: DeviceStatus | null;
  generator: DeviceStatus | null;
  gateway: {
    statusName: SystemStatusName;
    lastHeardUtc: string;
  } | null;
  microinverter: DeviceStatus | null;
};

export interface SiteStatus {
  siteId: string;
  systems: System[];
  timezone: string;
}

export interface UpdateHomeownerEmailRequest {
  siteId: string;
  oldEmail: string;
  newEmail: string;
}

export interface UpdateHomeownerEmailSuccess {
  status: number;
  id: string;
}

export interface UpdateHomeownerEmailError {
  status: number;
  message: string;
}

export type SiteAddressRequest = {
  sites: string[];
};
// https://neurio.atlassian.net/wiki/spaces/FM/pages/2192244968/Alerts+Phase+1+API+Helper+Endpoints#POST-Site-Address-for-List-of-Sites
export type SiteAddressSuccessResponse = {
  sites: {
    siteId: string;
    siteName: string;
    address1: string;
    address2?: string;
    zip: string;
    city: string;
    state: string;
    country: string;
    latitude: string;
    longitude: string;
    exists: boolean;
    timezone: string;
  }[];
};
export type SiteAddressFailedResponse = AxiosError;
export type SiteAddressResponse = SiteAddressSuccessResponse | SiteAddressFailedResponse;

export type ProductionFormValues = Omit<ExpectedProduction, 'annual'>;
