import { styled } from '@mui/material/styles';

import { down, getColor } from '../../styles';

export const SitesAlertContainer = styled('div')`
  width: 850px;
  height: 78px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 150px auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 8px 40px rgba(216, 216, 216, 0.5);
  background-color: ${getColor('WHITE')};

  ${down('sm')} {
    overflow: unset;
    width: 100%;
  }
`;
