import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';

import { DATE_TABLE_FORMAT } from '../../constants/dates';
import { USER_TABLE_COLUMNS } from '../../constants/userColumns';
import { UserRoleDefinitions as userRoles } from '../../constants/userRoles';
import { useAlert } from '../../hooks/useAlert';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useGetAllUsers } from '../../store/api/endpoints/user';
import { updateUsersTableSort } from '../../store/ui';
import { usersTableSortSelector } from '../../store/ui/selectors';
import type { User } from '../../types/api/getAllUsers';
import { Alert } from '../../types/ScreenAlerts';
import type { TablePresenters } from '../../types/Table';
import type { UserRole } from '../../types/UserRole';
import { SortOrder } from '../../utils/sort';
import { Table } from '../legacyTable';

const userNamePresenter = (_value: string, user: User) => {
  const { firstName, lastName } = user;
  if (firstName || lastName) {
    return `${firstName}${firstName && lastName && ' '}${lastName}`;
  }
  return null;
};

const rolePresenter = (role: UserRole) => {
  return userRoles[role]?.name || 'Unknown';
};

const registrationDatePresenter = (date: string) =>
  DateTime.fromISO(date).toFormat(DATE_TABLE_FORMAT);

const presenters: TablePresenters<User> = {
  name: userNamePresenter,
  createdDate: registrationDatePresenter,
  role: rolePresenter,
};

export interface Props {
  onRowClick?: (e: unknown, row: User) => void;
  style?: { [key: string]: string };
}

export const UsersTable = (props: Props) => {
  const dispatch = useDispatch();

  const usersTableSort = useAppSelector(usersTableSortSelector);

  const { data: users, isError: isErrorUsers, refetch } = useGetAllUsers({});

  const handleRequestSort = (newSortColumn: string) => {
    const { sortColumn, sortOrder } = usersTableSort;
    const oppositeSortOrder = sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    const newSortOrder = newSortColumn === sortColumn ? oppositeSortOrder : SortOrder.ASC;

    dispatch(updateUsersTableSort({ sortColumn: newSortColumn, sortOrder: newSortOrder }));
    refetch(); // does not actually refetch, since getAllUsersQueryFn reuses store
  };

  useAlert(
    { alert: Alert.UsersDataError, condition: !!isErrorUsers },
    { title: 'We could not retrieve Users.' },
  );

  return (
    <Table
      {...props}
      columns={USER_TABLE_COLUMNS}
      rows={users ?? []}
      presenters={presenters}
      order={usersTableSort.sortOrder}
      orderBy={usersTableSort.sortColumn}
      onRequestSort={handleRequestSort}
    />
  );
};
