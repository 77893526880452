import type { SelectChangeEvent } from '@mui/material';
import { TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DateTime } from 'luxon';
import type { ChangeEvent } from 'react';

import { DATE_TIME_FORMAT } from '../../constants/dates';
import { COLORS } from '../../styles';
import type { DeviceEvent } from '../../types/DeviceEvent';
import { DeviceType } from '../../types/DeviceType';
import type { RowComponentProps, TableColumn } from '../../types/NewTable';
import { getTimezoneDisplay } from '../../utils';
import { NewTable } from '../newTable';

const StyledTableRow = styled(TableRow)`
  .MuiTableCell-root {
    border-bottom: 1px solid ${COLORS.SHADOW_GREY};
    height: 24px;
  }
`;

enum TableColumnId {
  STARTED_AT = 'startedAt',
  STATE_NAME = 'stateName',
  STATE_CODE = 'stateCode',
  POWER = 'power',
  ENERGY = 'energy',
  VOLTAGE = 'voltage',
  CURRENT = 'current',
  TEMPERATURE = 'temperature',
}

/**
 * @see https://neurio.atlassian.net/browse/FM-2681
 * [temp-hide-pvl-data]
 */
const problematicValueColumns: TableColumnId[] = [
  TableColumnId.VOLTAGE,
  TableColumnId.CURRENT,
  TableColumnId.TEMPERATURE,
];

const columns: TableColumn<DeviceEvent>[] = [
  { id: TableColumnId.STARTED_AT, label: 'Timestamp' },
  { id: TableColumnId.STATE_NAME, label: 'Device State' },
  { id: TableColumnId.STATE_CODE, label: 'State Code' },
  { id: TableColumnId.POWER, label: 'Power (W)', TypographyProps: { noWrap: true } },
  { id: TableColumnId.ENERGY, label: 'Energy (Wh)', TypographyProps: { noWrap: true } },
  { id: TableColumnId.VOLTAGE, label: 'Voltage (V)', TypographyProps: { noWrap: true } },
  { id: TableColumnId.CURRENT, label: 'Current (A)', TypographyProps: { noWrap: true } },
  { id: TableColumnId.TEMPERATURE, label: 'Temp (C)', TypographyProps: { noWrap: true } },
];

const RowComponent = ({
  row,
  TableRowProps,
}: RowComponentProps<
  DeviceEvent & { timezone: string | undefined; deviceType: DeviceType | undefined }
>) => {
  const {
    timezone,
    deviceType,
    startedAt,
    stateName,
    stateCode,
    powerW,
    energyWh,
    voltageV,
    currentA,
    temperatureC,
  } = row;

  return (
    <StyledTableRow {...TableRowProps} data-test-class={`device-event-log-row`}>
      <TableCell data-test-class={`device-event-log-col-startedAt`}>
        {DateTime.fromMillis(startedAt).setZone(timezone).toFormat(DATE_TIME_FORMAT)}
      </TableCell>
      <TableCell data-test-class={`device-event-log-col-stateName`}>{stateName}</TableCell>
      <TableCell data-test-class={`device-event-log-col-stateCode`}>{stateCode}</TableCell>
      <TableCell data-test-class={`device-event-log-col-power`}>{powerW}</TableCell>
      <TableCell data-test-class={`device-event-log-col-energy`}>{energyWh}</TableCell>
      {/* [temp-hide-pvl-data] */}
      {deviceType !== DeviceType.PVLINK && (
        <TableCell data-test-class={`device-event-log-col-voltage`}>{voltageV}</TableCell>
      )}
      {deviceType !== DeviceType.PVLINK && (
        <TableCell data-test-class={`device-event-log-col-current`}>{currentA}</TableCell>
      )}
      {deviceType !== DeviceType.PVLINK && (
        <TableCell data-test-class={`device-event-log-col-temperature`}>{temperatureC}</TableCell>
      )}
    </StyledTableRow>
  );
};

const idExtractor = (row: DeviceEvent) => `${row.startedAt}_${row.stateCode}`;

export interface Props {
  deviceId?: string;
  deviceType?: DeviceType;
  deviceEvents: DeviceEvent[];
  timezone?: string;
  totalResults: number;
  currentPage: number;
  perPage: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange?: (perPage: number) => void;
}

export const DeviceEventLogTable = (props: Props) => {
  const {
    deviceEvents,
    deviceType,
    timezone,
    totalResults,
    currentPage,
    perPage,
    onPageChange,
    onRowsPerPageChange,
    deviceId,
  } = props;

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    onPageChange(page);
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent<unknown>, perPage: number) => {
    onRowsPerPageChange?.(perPage);
  };

  const formattedRows = deviceEvents.map((event) => ({
    ...event,
    timezone,
    deviceType,
  }));

  let formattedColumns = [...columns];

  /* [temp-hide-pvl-data] */
  if (deviceType === DeviceType.PVLINK) {
    formattedColumns = formattedColumns.filter((column) => {
      return !problematicValueColumns.includes(column.id as TableColumnId);
    });
  }

  formattedColumns = formattedColumns.map((column) => {
    if (column.id === TableColumnId.STARTED_AT) {
      return {
        ...column,
        label: `${column.label} (${getTimezoneDisplay(timezone)})`,
      };
    } else {
      return column;
    }
  });

  return (
    <NewTable
      data-test-id={deviceId ? `device-event-log-${deviceId}` : undefined}
      columns={formattedColumns}
      rows={formattedRows}
      RowComponent={RowComponent}
      idExtractor={idExtractor}
      stickyHeader
      HeadProps={{ align: 'left' }}
      PaginationProps={{
        count: totalResults,
        onChange: handlePageChange,
        page: currentPage,
        rowsPerPage: perPage,
        onRowsPerPageChange: handleRowsPerPageChange,
        showTotalCount: true,
      }}
    />
  );
};
