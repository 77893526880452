import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface Props {
  bufferTime?: number;
}

export const SuspenseLoader = ({ bufferTime = 100 }: Props) => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setShowLoader(true);
    }, bufferTime);
    return () => clearTimeout(timerId);
  }, [bufferTime]);

  return showLoader ? <LinearProgress /> : null;
};
