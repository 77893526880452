import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type { AuthData } from '../../types/Auth';
import type { UIFeature } from '../../types/UIFeature';
import { UserInterfaceType } from '../../types/UserInterfaceType';
import type { UserPermission } from '../../types/UserPermission';
import { UserRole } from '../../types/UserRole';
import * as actions from './actions';

export interface Error {
  message: string;
  name: string;
  stack: string;
}

export const initialError: Error = {
  message: '',
  name: '',
  stack: '',
};

// @note we may need to extend AuthInitializer here to add more attributes
type AuthState = AuthData & {
  isLoggedIn: boolean;
  isAuthenticating: boolean;
};

const INITIAL_STATE: AuthState = {
  isLoggedIn: false,
  isAuthenticating: false,
  // -- below is AuthData
  userId: '',
  userInterfaceType: UserInterfaceType.INSTALLER,
  userRole: UserRole.INSTALLER_VIEWER,
  userPermissions: [] as UserPermission[],
  uiFeatures: [] as UIFeature[],
  companyId: '',
};

export type AuthActions = ActionType<typeof actions>;

export default createReducer(INITIAL_STATE, {
  [getType(actions.initializeAuth)]: produce((state: AuthState) => {
    state.isAuthenticating = true;
  }),
  [getType(actions.refreshAuth.request)]: produce((state: AuthState) => {
    state.isAuthenticating = true;
  }),
  [getType(actions.refreshAuth.failure)]: produce((state: AuthState) => {
    state.isAuthenticating = false;
    state.isLoggedIn = false;
  }),
  [getType(actions.setAuthData)]: produce(
    (state: AuthState, { payload }: ReturnType<typeof actions.setAuthData>) => {
      state.userId = payload.userId;
      state.userInterfaceType = payload.userInterfaceType;
      state.userRole = payload.userRole;
      state.userPermissions = payload.userPermissions;
      state.uiFeatures = payload.uiFeatures;

      /**
       * companyId will only be present here for INSTALLER users
       * INSTALLER users belong to, and can view, only one company
       *
       * while SUPPORT users technically belong to a single company, it is not important for PWRfleet UX
       * as they can traverse across multiple companies in a session
       */
      state.companyId = payload.companyId || '';

      /**
       * @note: because the app now depends on user role/permissions data parsed from the accessToken
       * the user should only be considered "logged in" AFTER these permissions are set
       *
       * - on intitial login, this process will fire from the authCallback:initializeAuth action
       * - on refresh, this process will fire from the index.tsx:start():refreshAuth action (if a refreshToken is found in localStorage)
       */
      state.isAuthenticating = false;
      state.isLoggedIn = true;
    },
  ),
});
