import type { AxiosError } from 'axios';
import type { Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getCompanyAlerts, getSiteAddress } from '../../api';
import { LOADING_ERROR_MESSAGE } from '../../constants/dataLoadingMessages';
import type { CompanyAlertsSuccessResponse } from '../../types/Alert';
import { Alert } from '../../types/ScreenAlerts';
import type { SiteAddressSuccessResponse } from '../../types/SiteDetails';
import { customAlert } from '../../utils/alerts';
import { loadCompanyAlerts } from './actions';
import { companyAlertsPerPageSelector } from './selectors';

/**
 * https://redux-observable.js.org/docs/recipes/ErrorHandling.html
 * Here we placed the catchError() inside our mergeMap(), but after our AJAX call; this is important because if we
 * let the error reach the action$.pipe(), it will terminate it and no longer listen for new actions.
 **/

const errorCatcher = (e: AxiosError) => {
  customAlert(
    {
      title: LOADING_ERROR_MESSAGE,
      closeButton: true,
    },
    { type: 'error', toastId: Alert.CompanyAlertsDataError },
  );
  return of(loadCompanyAlerts.failure(e));
};

const loadCompanyAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadCompanyAlerts.request)),
    mergeMap((action) => {
      const perPage = companyAlertsPerPageSelector(state$.value);
      return from(getCompanyAlerts({ perPage, ...action.payload })).pipe(
        map((data) => loadCompanyAlerts.success(data as CompanyAlertsSuccessResponse)),
        catchError(errorCatcher),
      );
    }),
    // @todo: this block can be removed when the first request gives complete information
    mergeMap((res) => {
      const isError = isActionOf(loadCompanyAlerts.failure)(res);
      const loadCompanyAlertsSuccessAction = res as ReturnType<typeof loadCompanyAlerts.success>;
      const { payload } = loadCompanyAlertsSuccessAction;
      if (isError || payload.alerts?.length === 0) {
        return of(res);
      }
      return from(getSiteAddress({ sites: payload.alerts?.map((a) => a.siteId) })).pipe(
        // modify loadCompanyAlerts.success payload adding additional data
        map((res) => ({
          ...loadCompanyAlertsSuccessAction,
          payload: {
            ...payload,
            alerts: payload.alerts?.map((alert) => {
              const details = (res as SiteAddressSuccessResponse).sites.find(
                (s) => s.siteId === alert.siteId,
              );
              if (!details) {
                return alert;
              }
              const { siteName = '', exists, siteId, ...restAddress } = details;
              return {
                ...alert,
                siteName,
                address: restAddress,
              };
            }),
          },
        })),
        catchError(errorCatcher),
      );
    }),
  );

export default combineEpics(loadCompanyAlertsEpic);
