import { createAsyncAction } from 'typesafe-actions';

import type {
  ArrayLayout,
  SiteLayoutFailedResponse,
  SiteLayoutSaveRequest,
  SiteLayoutSuccessResponse,
  SiteLayoutTelemetryFailedResponse,
  SiteLayoutTelemetryRequest,
  SiteLayoutTelemetrySuccessResponse,
} from '../../types/SiteLayout';

export const loadSiteLayout = createAsyncAction(
  'LOAD_SITE_LAYOUT_REQUEST',
  'LOAD_SITE_LAYOUT_SUCCESS',
  'LOAD_SITE_LAYOUT_FAILURE',
)<string, SiteLayoutSuccessResponse, SiteLayoutFailedResponse>();

export const saveSiteLayout = createAsyncAction(
  'SAVE_SITE_LAYOUT_REQUEST',
  'SAVE_SITE_LAYOUT_SUCCESS',
  'SAVE_SITE_LAYOUT_FAILURE',
)<{ siteId: string; body: SiteLayoutSaveRequest }, ArrayLayout, SiteLayoutFailedResponse>();

export const loadSiteLayoutTelemetry = createAsyncAction(
  'LOAD_SITE_LAYOUT_TELEMETRY_REQUEST',
  'LOAD_SITE_LAYOUT_TELEMETRY_SUCCESS',
  'LOAD_SITE_LAYOUT_TELEMETRY_FAILURE',
)<
  { body: SiteLayoutTelemetryRequest },
  SiteLayoutTelemetrySuccessResponse,
  SiteLayoutTelemetryFailedResponse
>();
