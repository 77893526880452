import { DateTime } from 'luxon';

import { TIMEZONE_ABBREVIATED_FORMAT } from '../constants/dates';
import type { TimezonelessDate } from '../types/Date';

export const getTimezoneDisplay = (timezone: string | undefined) => {
  if (!timezone) {
    return 'UTC';
  }

  const dateTime = DateTime.now().setZone(timezone ?? 'UTC');
  if (!dateTime.isValid) {
    return '';
  }

  return dateTime.toFormat(TIMEZONE_ABBREVIATED_FORMAT);
};

export const convertIso8601ToMilli = (timestamp: string): number | null => {
  const dateTime = DateTime.fromISO(timestamp);

  if (!dateTime.isValid) {
    return null;
  }

  return dateTime.valueOf();
};

export const convertNativeDateToTimezonelessDate = (date: Date): TimezonelessDate => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
};

export const convertTimezonelessDateToDateTime = (
  timezonelessDate: TimezonelessDate,
  siteTimezone: string,
): DateTime => {
  return DateTime.fromObject(
    {
      year: timezonelessDate.year,
      month: timezonelessDate.month,
      day: timezonelessDate.day,
    },
    {
      zone: siteTimezone,
    },
  ).startOf('day');
};
