import { AppBar, Container, Hidden } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { ReactNode } from 'react';
import React, { useState } from 'react';

import type { FleetTheme } from '../../styles';
import { useViewMode } from '../../styles/stylingUtils';
import { UIFeature } from '../../types/UIFeature';
import { GatedFeature } from '../gatedFeature';
import { Icon } from '../icon';
import { ScreenLoader } from '../screenLoader';
import { AccountSettingsDrawer } from './AccountSettingsDrawer';
import { MobileMenuBar } from './MobileMenuBar';
import { Navbar } from './Navbar';
import { SupportAppBar } from './SupportAppBar';

interface LayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: FleetTheme) => {
  return {
    root: {
      // @note why was the entire app semibold???
      // fontWeight: 600,
    },
    container: {
      display: 'flex',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        marginLeft: 220,
      },
    },
    nav: {
      position: 'fixed',
      top: 0,
    },
    main: {
      overflow: 'auto',
      flex: 1,
    },
    moreMenuButton: {
      float: 'right',
      '& img': {
        marginBottom: -7,
      },
    },
  };
});

export const UiLayout = ({ children }: LayoutProps) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const viewMode = useViewMode();
  const styles = useStyles({ viewMode });

  const toggleDrawer = (hide?: boolean) => {
    if (hide) {
      return setDrawerIsOpen(false);
    }
    return setDrawerIsOpen(!drawerIsOpen);
  };

  return (
    <>
      <Container maxWidth="xl" disableGutters>
        <ScreenLoader />
      </Container>
      <div className={styles.root}>
        <GatedFeature
          uiFeature={UIFeature.SUPPORT_VIEW}
          onAllowed={() => <SupportAppBar onClick={() => setDrawerIsOpen(false)} />}
        />
        <Hidden mdUp>
          <AppBar position="sticky" color="default">
            <Container disableGutters>
              <div
                className={styles.moreMenuButton}
                data-test-id="account-settings-button"
                onClick={() => toggleDrawer()}
              >
                <Icon icon="more menu" rotate={90} />
              </div>
              <MobileMenuBar toggleDrawer={toggleDrawer} />
            </Container>
          </AppBar>
        </Hidden>
        <Hidden mdDown>
          <Navbar className={styles.nav} toggleDrawer={toggleDrawer} />
        </Hidden>
        <div className={styles.container} id="screen">
          <main className={styles.main}>{children}</main>
        </div>
      </div>
      <AccountSettingsDrawer isOpen={drawerIsOpen} onClose={toggleDrawer} />
    </>
  );
};
