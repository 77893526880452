import type { ChipProps as MuiChipProps, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { Box, Chip, TextField, Typography } from '@mui/material';
import { isEmpty, uniq } from 'lodash';
import React from 'react';

import { KeyboardKeys } from '../../constants/keyboard';
import { emailAddressStringValidator, parseCsvStringToCleanArray } from '../../utils';
import { Button } from '../button';

// --

const DEFAULT_TEXT_FIELD_HELPER_TEXT = 'Enter email addresses separated by a comma';
const DEFAULT_SUBMIT_BUTTON_TEXT = 'Add';
const DEFAULT_EMAIL_VALIDATOR = emailAddressStringValidator;

interface Props {
  onUpdate: (items: string[]) => void;
  helperText?: string;
  submitButtonText?: string;
  itemsWithError?: string[];
  errorMessage?: string;
  inputItemValidator?: (value: string) => boolean;
  TextFieldProps?: MuiTextFieldProps;
  ChipProps?: MuiChipProps;
}

export const ChipListInput = (props: Props) => {
  const {
    onUpdate,
    helperText = DEFAULT_TEXT_FIELD_HELPER_TEXT,
    submitButtonText = DEFAULT_SUBMIT_BUTTON_TEXT,
    inputItemValidator = DEFAULT_EMAIL_VALIDATOR,
    itemsWithError,
    errorMessage,
    TextFieldProps,
    ChipProps,
    ...rest
  } = props;

  const [inputValue, setInputValue] = React.useState<string>('');
  const [items, setItems] = React.useState<string[]>([]);

  // tell parent component that items have updated
  React.useEffect(() => {
    onUpdate(items);
  }, [items, onUpdate]);

  const inputAsArray: string[] = React.useMemo(() => {
    return parseCsvStringToCleanArray(inputValue, inputItemValidator);
  }, [inputValue, inputItemValidator]);

  // if inputAsArray has items, it means the input has already been cleaned up,
  //  with individual items validated
  const isInputValid: boolean = React.useMemo(() => {
    return !isEmpty(inputAsArray);
  }, [inputAsArray]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmitInput = () => {
    if (!isEmpty(items)) {
      // append + dedupe items to existing items array
      setItems(uniq([...items, ...inputAsArray]));
    } else {
      // just create new items array from inputAsArray
      setItems(uniq(inputAsArray));
    }

    setInputValue('');
  };

  const handleAddItemsClick = () => {
    if (!isInputValid) {
      return;
    }

    handleSubmitInput();
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // if current input is invalid, or the key pressed was not 'Enter', do nothing
    if (!isInputValid || event.key !== KeyboardKeys.ENTER) {
      return;
    }

    handleSubmitInput();
  };

  const handleItemDelete = (item: string) => {
    const newItems = items.filter((i) => i !== item);
    setItems(newItems);
  };

  return (
    <Box {...rest}>
      <Box sx={{ display: 'flex' }}>
        <TextField
          value={inputValue}
          variant="standard"
          autoComplete="email"
          autoFocus
          fullWidth
          helperText={helperText}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          {...TextFieldProps}
        />
        <Button sx={{ ml: 2 }} size="small" onClick={handleAddItemsClick} disabled={!isInputValid}>
          {submitButtonText}
        </Button>
      </Box>
      {!isEmpty(items) && (
        <Box sx={{ mt: 2 }}>
          {items.map((item, index) => {
            const hasError = itemsWithError?.includes(item);

            return (
              <Chip
                sx={{ m: 0.5 }}
                key={`chip-${index}`}
                label={item}
                onDelete={() => handleItemDelete(item)}
                color={hasError ? 'error' : 'default'}
                {...ChipProps}
              />
            );
          })}
        </Box>
      )}
      {errorMessage && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};
