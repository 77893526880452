import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import COLUMNS, { MOBILE_COLUMNS, TABLET_COLUMNS } from '../../constants/siteColumns';
import type { ViewMode } from '../../styles/stylingUtils';
import { InstallDateFromFilter, InstallDateToFilter } from '../../types/SiteFilter';
import type { TableColumn } from '../../types/Table';
import type { RootState } from '../types';

export const sitesSelector = (state: RootState) => state.sites.items;
export const sitesPageSelector = (state: RootState) => state.sites.page;
export const sitesPerPageSelector = (state: RootState) => state.sites.perPage;
export const totalSitesSelector = (state: RootState) => state.sites.total;
export const sitesLoadingSelector = (state: RootState) => state.sites.loading;
export const sitesLoadingFinishedSelector = (state: RootState) => state.sites.loadingFinished;
export const sitesErrorSelector = (state: RootState) => state.sites.error;
export const siteListExportLoadingSelector = (state: RootState) =>
  state.sites.siteListExportLoading;

export const siteByIdSelector = createSelector(
  (state: RootState, { siteId }: { siteId: string }) => siteId,
  sitesSelector,
  (siteId, sites) => {
    if (sites && sites.length) {
      return sites.find((s) => s.siteId === siteId);
    }
  },
);

export const selectedColumnsSelector = (state: RootState) => [
  ...COLUMNS.forced.items.map((item) => item.name),
  ...state.sites.selectedColumns,
];
export const selectedFiltersSelector = (state: RootState) => state.sites.selectedFilters;
export const selectedSortPropertySelector = (state: RootState) => state.sites.selectedSortProperty;
export const selectedSortOrderSelector = (state: RootState) => state.sites.selectedSortOrder;

export const hasMultipleSitePagesSelector = createSelector(
  totalSitesSelector,
  sitesPerPageSelector,
  (total, perPage) => total > perPage,
);

export const columnsSelector = createSelector(
  (state: RootState, { mode }: { mode: ViewMode }) => {
    if (mode === 'tablet') {
      return TABLET_COLUMNS;
    } else if (mode === 'mobile') {
      return MOBILE_COLUMNS;
    }
    return state.sites.selectedColumns;
  },
  (selectedColumns) => {
    const sections = Object.keys(COLUMNS);
    let resultColumns: TableColumn[] = [];
    sections.forEach((sectionKey) => {
      const { items } = COLUMNS[sectionKey];
      // if items are from the 'forced' section, do not filter them out out
      // even if they do not exist within 'selectedColumns'
      const columns =
        sectionKey === 'forced'
          ? [...items]
          : items.filter((i) => selectedColumns.includes(i.name));
      resultColumns = [...resultColumns, ...columns];
    });
    return resultColumns;
  },
);

export const newTableColumnsSelector = createSelector(columnsSelector, (columns) =>
  columns.map(({ name, title, sortable, property, unit }) => ({
    id: name,
    label: title,
    sortable,
    property,
    unit,
    TypographyProps: { noWrap: true },
  })),
);

export const selectedFiltersCSVSelector = (root: RootState) => {
  if (root.sites.selectedFilters.length === 0) {
    return '';
  }
  return root.sites.selectedFilters.reduce((array, item) => {
    if (item.id === InstallDateFromFilter.id || item.id === InstallDateToFilter.id) {
      return Object.assign({}, array, {
        [item.id]: DateTime.local(item.value).setLocale('en-US').toLocaleString(DateTime.DATE_FULL),
      });
    }
    return Object.assign({}, array, { [item.id]: item.value });
  }, {});
};
