import type { GridFilterItem } from '@mui/x-data-grid-pro';
import type { ComponentType, ReactNode } from 'react';

import type { SortOrder } from '../utils/sort';
import type { Unit } from './Unit';

export interface FilterItem extends GridFilterItem {
  value?: {
    // headerName from column object, native type is string | undefined, but must be passed
    columnHeaderName: string | undefined;
    valueFilterChip: string; // human friendly value that appears on filter chip
    valueQueryParam: string; // for API
  }; // making value optional to align with native type, but must be passed
}

export interface FilterModel {
  items: FilterItem[];
}

export interface TableSort {
  sortColumn: string;
  sortOrder: SortOrder;
}

export enum Align {
  INHERIT = 'inherit',
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify',
}

export interface TableColumn {
  name: string;
  property: string[];
  sortable?: boolean;
  title: string;
  unit?: Unit;
  unitType?: string;
  align?: Align;
}

export interface TableColumns {
  [key: string]: {
    title: string;
    items: TableColumn[];
  };
}

export type BaseRow = {
  [key: string]: any;
};

export type PresenterFunctionType = (v: any, r: any) => ReactNode;
export type PresenterComponentType<R> = {
  Component: ComponentType<{ value: any; row: R }>;
};
export type PresenterType<R> = PresenterFunctionType | PresenterComponentType<R>;

export interface TablePresenters<R> {
  [key: string]: PresenterType<R>;
}

export interface PresenterProps<R, P extends keyof R> {
  value: R[P];
  row: R;
}
