import { AnalyticsPageName } from '../types/Analytics';
import { useExactRouteMatch } from './useExactRouteMatch';
import { useRoutes } from './useRoutes';

export const usePageName = () => {
  const Routes = useRoutes();
  const pages = {
    [AnalyticsPageName.SiteAccess]: useExactRouteMatch(Routes.SiteAccess),
    [AnalyticsPageName.SiteAlerts]: useExactRouteMatch(Routes.SiteAlerts),
    [AnalyticsPageName.SiteDashboard]: useExactRouteMatch(Routes.SiteDashboard),
    [AnalyticsPageName.SiteDevices]: useExactRouteMatch([
      Routes.SiteDevices,
      Routes.SiteDevicesMetrics,
      Routes.SiteDevicesConfig,
    ]),
    [AnalyticsPageName.SingleDeviceChart]: useExactRouteMatch(Routes.SingleDeviceChart),
    [AnalyticsPageName.SiteDetails]: useExactRouteMatch(Routes.SiteDetails),
    [AnalyticsPageName.SiteConfiguration]: useExactRouteMatch(Routes.SiteConfiguration),
    [AnalyticsPageName.SiteFirmware]: useExactRouteMatch(Routes.SiteFirmware), // feature removed
    [AnalyticsPageName.SiteLayout]: useExactRouteMatch(Routes.SiteLayout), // feature removed
  };
  return {
    pageName: Object.keys(pages).find((key) => pages[key as keyof typeof pages]),
    pages,
  };
};
