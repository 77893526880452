import { createAsyncAction } from 'typesafe-actions';

import type { MapSite } from '../../types/Site';
export { loggedOut } from '../auth/actions';

export const loadFleetMapSites = createAsyncAction(
  'LOAD_FLEET_MAP_SITES_REQUEST',
  'LOAD_FLEET_MAP_SITES_SUCCESS',
  'LOAD_FLEET_MAP_SITES_FAILURE',
)<string, MapSite[], Error>();
