import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { CompanyDashboard } from '../constants/routes';
import { isSupportModeSelector, userCompanyIdSelector } from '../store/auth/selectors';
import { companyIdSelector } from '../store/company/selectors';
import type { CompanyContextParams } from '../types/Routes';

/**
 * This hook parse companyId from url or get it from redux state.
 * Some routes do not contain companyId, so for INSTALLER users "tokenCompanyId" will be used for such routes
 * and for SUPPORT users "selectedCompanyId" will be used
 **/

export const useCompanyContext = (): Partial<CompanyContextParams> => {
  const tokenCompanyId = useSelector(userCompanyIdSelector);
  const selectedCompanyId = useSelector(companyIdSelector);
  const isSupportMode = useSelector(isSupportModeSelector);
  const match = useRouteMatch<CompanyContextParams>(CompanyDashboard);
  const companyId = isSupportMode ? match?.params.companyId || selectedCompanyId : tokenCompanyId;
  return {
    companyId,
  };
};
