import type { GridSortModel } from '@mui/x-data-grid-pro';
import type { FetchArgs } from '@reduxjs/toolkit/dist/query';
import { first, isEmpty } from 'lodash';

import type { User } from '../../types/api/getAllUsers';
import type { GetSitesPaginatedRequest } from '../../types/api/getCompanySites';
import type { GetSiteAlertsArgs } from '../../types/api/getSiteAlerts';
import type { SiteChartDataRequest } from '../../types/api/getSiteChartData';
import type { RequestCompanySitesCsvRequest } from '../../types/api/requestCompanySitesCsv';
import type { CompanySupportCasesArgs } from '../../types/CompanySupport';
import { ChartType } from '../../types/SiteMetrics';
import type { FilterModel } from '../../types/Table';
import { convertCamelCaseToScreamingSnakeCase } from '../../utils';

export const getSiteAlertsFetchArgs = ({
  siteId,
  paginationModel,
  sortModel,
  activeOnly,
}: GetSiteAlertsArgs) => {
  const sortItem = first(sortModel);
  const sort = sortItem ? `${sortItem?.field}:${sortItem?.sort}` : null;

  const params = {
    page: paginationModel.page + 1,
    perPage: paginationModel.pageSize,
    ...(sort && { sort }),
    ...(activeOnly && { status: 'ACTIVE' }),
  };

  return { url: `/alerts/v1/sites/${siteId}`, params };
};

const getSortValueFromSortModel = (sortModel: GridSortModel) => {
  if (isEmpty(sortModel)) {
    // fallback for safety since sort is a required query parameter
    // also, this runs on initialization since sortModel must be
    // initialized as an empty array for the DataGrid native sorting to work
    return 'installedDate:DESC';
  }

  const sortField = first(sortModel)?.field;
  const sortDirection = first(sortModel)?.sort?.toUpperCase();

  return `${sortField}:${sortDirection}`;
};

const getFilterQueryFromFilterModel = (filterModel: FilterModel) => {
  const filters = filterModel.items.map((item) => `${item.field}=${item.value?.valueQueryParam}`);
  return isEmpty(filters) ? '' : `&${filters.join('&')}`;
};

export const getCompanySitesPaginatedUrl = ({
  fleetId,
  sortModel,
  paginationModel,
  filterModel,
}: GetSitesPaginatedRequest): string => {
  const perPageValue = paginationModel.pageSize;
  const pageValue = paginationModel.page + 1; // Mui Datagrid is zero-indexed, API is not

  const sortValue = getSortValueFromSortModel(sortModel);
  const filterQuery = getFilterQueryFromFilterModel(filterModel);

  const sortQuery = `sort=${sortValue}`;
  const pageQuery = `perPage=${perPageValue}&page=${pageValue}`;

  const queryParameters = `${sortQuery}&${pageQuery}` + filterQuery;

  return `/fleets/v4/${fleetId}/sites/paginated?${queryParameters}`;
};

export const getCompanySitesCsvUrl = ({
  companyId,
  sortModel,
  filterModel,
}: RequestCompanySitesCsvRequest): string => {
  const sortValue = getSortValueFromSortModel(sortModel);
  const filterQuery = getFilterQueryFromFilterModel(filterModel);

  const queryParameters = `sort=${sortValue}` + filterQuery;

  return `/company/v2/${companyId}/sites/download?${queryParameters}`;
};

export const getCompanySupportCasesUrl = ({
  companyId,
  filterModel,
  sortModel,
  paginationModel,
}: CompanySupportCasesArgs): FetchArgs => {
  const params: { [key: string]: any } = {
    limit: paginationModel.pageSize,
    offset: paginationModel.page * paginationModel.pageSize,
  };

  filterModel.items.forEach((filter) => {
    const value = filter.value?.valueQueryParam;
    if (!value || value.length < 1) {
      return;
    }

    if (filter.operator === 'contains' || filter.operator === 'is') {
      params[filter.field] = value;
    } else if (filter.operator === 'onOrAfter') {
      params[`${filter.field}Start`] = value;
    } else if (filter.operator === 'onOrBefore') {
      params[`${filter.field}End`] = value;
    }
  });

  if (!isEmpty(sortModel[0])) {
    const fieldScreamingSnakeCase = convertCamelCaseToScreamingSnakeCase(sortModel[0].field);
    params.sortBy = fieldScreamingSnakeCase;

    if (sortModel[0].sort === 'asc') {
      params.descending = false;
    }
  }

  return { url: `/pwrfleet-ui/v1/companies/${companyId}/cases`, params };
};

export const getSiteChartDataUrl = (
  args: Omit<SiteChartDataRequest, 'granularity' | 'dateFrom' | 'dateTo'>,
) => {
  let url = `/hist/v2/sites/${args.siteId}/${args.metric}`;

  // enpoint is not consistent, for production and consumption it expects these paths, and for (all) devices it expects no path
  if (args.type === ChartType.PRODUCTION) {
    url += '/production/solar';
  } else if (args.type === ChartType.CONSUMPTION) {
    url += '/consumption';
  }

  return url;
};

export const sortUsers = (user: User, column: string) => {
  if (column === 'name') {
    return user.lastName;
  }

  return user[column as keyof User];
};
