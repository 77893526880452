import { isEmpty } from 'lodash';
import { number, string } from 'yup';

import type { StateInterface } from '../constants/countries';
import type { AddressEditFields, HomeownerFormValues, SiteFormValues } from '../types/Address';
import type { Contact } from '../types/SiteDetails';
import { isLatitude, isLongitude } from './coordinates';
import { REGEX_LEGAL_NAME, REGEX_PHONE_NUMBER, REGEX_ZIP_CANADA, REGEX_ZIP_US } from './regex';
import { getArticle } from './stringHelpers';

export const getRequiredMessage = (field: string = '') =>
  `Please enter ${!isEmpty(field) ? `${getArticle(field ?? '')} ` : ''}${field}`;
export const getMinMessage = (field: string, min: number) =>
  `${field} must contain at least ${min} characters`;
export const getMaxMessage = (field: string, max: number) =>
  `${field} must be ${max} characters or less`;

export const validateState = (value: string, states: StateInterface[], errStr: string) => {
  let error;
  if (states && states.length && !value) {
    error = getRequiredMessage(errStr);
  }
  return error;
};

export const siteNameValidationSchema = string()
  .required(getRequiredMessage('site name'))
  .min(3, ({ min }) => getMinMessage('Site name', min));

export const legalNameValidationSchema = string().matches(
  REGEX_LEGAL_NAME,
  'Must not include special characters',
);

export const addressValidationSchema = string()
  .required(getRequiredMessage('Address'))
  .min(3, ({ min }) => getMinMessage('Address', min))
  .max(50, ({ max }) => getMaxMessage('Address', max));

export const countryValidationSchema = string()
  .required(getRequiredMessage('site country'))
  .min(2, ({ min }) => getMinMessage('Site country', min));

export const cityValidationSchema = string()
  .required(getRequiredMessage('City'))
  .min(2, ({ min }) => getMinMessage('City', min));

export const latitudeValidationSchema = number().when('overrideCoordinates', {
  is: true,
  then: number()
    .required(getRequiredMessage('latitude'))
    .test('latitude', 'Must be a number between -90 and 90', isLatitude),
});

export const longitudeValidationSchema = number().when('overrideCoordinates', {
  is: true,
  then: number()
    .required(getRequiredMessage('longitude'))
    .test('longitude', 'Must a number between -180 and 180', isLongitude),
});

export const getZipValidationSchema = (countyCodeFieldName: string) =>
  string()
    .required(getRequiredMessage('Zip or postal code'))
    .min(3, ({ min }) => getMinMessage('Zip or postal code', min))
    .max(10, ({ max }) => getMaxMessage('Zip or postal code', max))
    .when(countyCodeFieldName, {
      is: 'US',
      then: string().matches(REGEX_ZIP_US, 'Please enter a valid US ZIP or postal code'), // Specifically ZIP (#####) or ZIP+4 (#####-####)
      otherwise: string().matches(REGEX_ZIP_CANADA, 'Please enter a valid Canadian postal code'), // For better user-facing validation
    });

export const stateValidationSchema = string()
  .required(getRequiredMessage('State or province'))
  .min(1, getRequiredMessage('State or province'));

export const expectedProductionValidationSchema = number()
  .required('Required')
  .min(0, 'Must be a positive number')
  .max(100000, 'Must be less than 100,000');

export const nameplateSolarCapacityValidationSchema = number()
  .required('Required')
  .positive('Must be a positive number')
  .lessThan(100, 'Must be less than 100');

export const notesValidationSchema = string().max(65000, 'Must be less than 65,000 characters');

export const phoneValidationSchema = string()
  .required(getRequiredMessage('area code and phone number'))
  .matches(REGEX_PHONE_NUMBER, 'Please use a valid 10+ digits phone number')
  .min(10, ({ min }) => getMinMessage('Area code and phone number', min));

export const homeownerEmailValidationSchema = string()
  .email(getRequiredMessage('valid email'))
  .required('Please enter an email for the Homeowner');

/**
 * @deprecated Use stateValidationSchema instead, we see PR as USA state now
 */
export const getStateValidationSchema = (countyCodeFieldName: string) =>
  string().when(countyCodeFieldName, {
    is: 'PR',
    then: string(),
    otherwise: string()
      .required(getRequiredMessage('site state or province'))
      .min(1, getRequiredMessage('site state or province')),
  });

export const splitSiteFormValues = (
  values: SiteFormValues,
): [string, AddressEditFields, boolean?] => {
  const { siteName, overrideCoordinates, useSiteAddress: _, ...siteAddress } = values;

  return [siteName, siteAddress, overrideCoordinates];
};

export const splitHomeownerFormValues = (
  values: HomeownerFormValues,
): [Omit<Contact, 'email'>, AddressEditFields, string, boolean?] => {
  const { firstName, lastName, email, phone, businessName, useSiteAddress, ...homeownerAddress } =
    values;
  const contact = { firstName, lastName, phone, businessName };
  return [contact, homeownerAddress, email, useSiteAddress];
};
