import type { SelectProps } from '@mui/material';
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import { isEmpty } from 'lodash';
import slug from 'slug';

import type { SelectOption } from '../../types/Select';
import { PWRlabel } from '../pwrLabel';

export interface PWRselectProps extends SelectProps {
  id: string;
  name: string;
  options: SelectOption[];
  label: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  helperText?: string;
}

export const PWRselect = ({
  id,
  name,
  options,
  label,
  required = true,
  helperText,
  error,
  ...props
}: PWRselectProps) => {
  return (
    <FormControl fullWidth>
      <PWRlabel htmlFor={id} $required={required}>
        {label}
      </PWRlabel>
      <Select
        inputProps={{ 'data-test-id': `form-input-${slug(name)}` }} // Lowercase inputProps affects that actual DOM element <input>, which lets us add the test ID on the actual input field itself and not one of the MUI abstractions above it
        id={id}
        name={name}
        required={required}
        error={error}
        {...props}
      >
        {!isEmpty(options) ? (
          options.map((option) => (
            <MenuItem
              key={`option-${option.value}`}
              data-test-id={`option-${option.value}`}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>None</MenuItem>
        )}
      </Select>
      <FormHelperText
        data-test-id={`form-select-${slug(name)}-helper-text`}
        error={error}
        sx={{ fontSize: '12px', marginLeft: 0 }}
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};
