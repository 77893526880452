export enum Granularity {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
  MONTHS = 'months',
}

export enum ChartMetric {
  ENERGY = 'energy',
  POWER = 'power',
}

export enum ChartType {
  PRODUCTION = 'production',
  CONSUMPTION = 'consumption',
  DEVICES = 'devices',
  SINGLE_DEVICE = 'single device',
}

export enum ChartDisplayType {
  LINE = 'line',
  BAR = 'bar',
}

export enum ChartPanDirection {
  PREVIOUS = 1,
  NEXT = -1,
}

export enum ChartTimeframeType {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  DAYS_7 = '7days',
  DAYS_30 = '30days',
  MONTHS_12 = '12months',
}
