import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import { INITIAL_TABLET_COLUMNS } from '../../constants/siteColumns';
import type { Site } from '../../types/Site';
import type { SiteFilter } from '../../types/SiteFilter';
import { SortOrder } from '../../utils/sort';
import * as actions from './actions';

export interface SitesState {
  items: Site[];
  page: number;
  perPage: number;
  total: number;
  loading: boolean;
  loadingFinished: boolean;
  error: string | null;
  selectedColumns: string[];
  selectedFilters: SiteFilter[];
  selectedSortProperty: string;
  selectedSortOrder: SortOrder;
  siteListExportLoading: boolean;
}

const INITIAL_STATE: SitesState = {
  items: [],
  page: 0,
  perPage: 10,
  total: 0,
  loading: false,
  loadingFinished: false,
  error: null,
  selectedColumns: INITIAL_TABLET_COLUMNS,
  selectedSortProperty: 'installedDate',
  selectedSortOrder: SortOrder.DESC,
  selectedFilters: [],
  siteListExportLoading: false,
};

export type SitesActions = ActionType<typeof actions>;

export default createReducer<SitesState, SitesActions>(INITIAL_STATE, {
  [getType(actions.loadFleetSites.request)]: produce((state: SitesState) => {
    state.loading = true;
    state.error = null;
    state.loadingFinished = false;
  }),
  [getType(actions.loadFleetSites.success)]: produce(
    (state: SitesState, action: ReturnType<typeof actions.loadFleetSites.success>) => {
      const { data, page, perPage, total } = action.payload;
      state.loading = false;
      state.items = data;
      state.page = page;
      state.perPage = perPage;
      state.total = total;
      state.loadingFinished = true;
    },
  ),
  [getType(actions.loadFleetSites.failure)]: produce(
    (state: SitesState, action: ReturnType<typeof actions.loadFleetSites.failure>) => {
      // @todo handle for specific Errors
      state.error = action.payload.message;
      state.loading = false;
      state.loadingFinished = true;
    },
  ),
  [getType(actions.setSelectedColumns)]: produce(
    (state: SitesState, action: ReturnType<typeof actions.setSelectedColumns>) => {
      state.selectedColumns = action.payload;
    },
  ),
  [getType(actions.setSelectedFilters)]: produce(
    (state: SitesState, action: ReturnType<typeof actions.setSelectedFilters>) => {
      state.selectedFilters = action.payload;
    },
  ),
  [getType(actions.setSitesSort)]: produce(
    (state: SitesState, action: ReturnType<typeof actions.setSitesSort>) => {
      return {
        ...state,
        selectedSortProperty: action.payload.property,
        selectedSortOrder: action.payload.order,
      };
    },
  ),
  [getType(actions.createSiteListExport.request)]: produce((state: SitesState) => {
    state.siteListExportLoading = true;
  }),
  [getType(actions.createSiteListExport.success)]: produce((state: SitesState) => {
    state.siteListExportLoading = false;
  }),
  [getType(actions.createSiteListExport.failure)]: produce((state: SitesState) => {
    state.siteListExportLoading = false;
  }),
});
