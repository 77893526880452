import {
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { getColor } from '../../styles';
import type { TableColumn } from '../../types/Table';
import type { SortOrder } from '../../utils/sort';
import { TableHeadTitle } from './tableHeadTitle';
import { TableSortLabel } from './tableSortLabel';

const StyledTableHead = styled(MuiTableHead)``;

const StyledHeaderCell = styled(MuiTableCell)`
  background-color: ${getColor('OFF_WHITE')};
  z-index: 1;
  white-space: nowrap;
  vertical-align: top;
  border: none !important;

  .MuiTableSortLabel-icon {
    opacity: 0.5;
  }

  .MuiTableSortLabel-root {
    align-items: flex-start;
    height: 30px;
    width: 100%;
    justify-content: space-between;
  }
`;

interface Props {
  columns: TableColumn[];
  order?: SortOrder;
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  inline?: boolean;
}

export const TableHead = (props: Props) => {
  const { columns, order, orderBy, onRequestSort, inline } = props;

  return (
    <StyledTableHead>
      <MuiTableRow>
        {columns.map((column: TableColumn) => {
          const { name, title, sortable, unit } = column;
          return (
            <StyledHeaderCell
              key={name}
              align="left"
              padding="normal"
              sortDirection={orderBy === name ? order : false}
            >
              {sortable && order && orderBy && onRequestSort ? (
                <TableSortLabel
                  column={column}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={onRequestSort}
                />
              ) : (
                <TableHeadTitle title={title} subTitle={unit ? unit.symbol : ''} inline={inline} />
              )}
            </StyledHeaderCell>
          );
        })}
      </MuiTableRow>
    </StyledTableHead>
  );
};
