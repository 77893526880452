import type { IconType } from '../components/icon/icons';
import type { SiteDeviceV2 } from '../types/api/getSiteSystems';
import { DeviceType } from '../types/DeviceType';
import type { Devices } from '../types/SiteDevices';

export interface DeviceTypeDefinition {
  name: string;
  formatName?: (arg0: SiteDeviceV2) => string;
  formatSettingsName?: (arg0: SiteDeviceV2) => string;
  icon?: IconType;
  headerIcon?: IconType;
  /**
   * stateKey is the plural device name, used to link these definitions to data from
   * selectors that use the /devices response, which only provides the device name
   * as a (plural) object key, hacky but there's no other option.
   */
  stateKey?: keyof Devices;
  deleteReqUrlDir?: string; // used to assemble URL for delete device endpoint
}

export const DeviceTypeDefinitions: { [key in DeviceType]: DeviceTypeDefinition } = {
  [DeviceType.BEACON]: {
    name: 'Beacon',
    icon: 'beacon',
    stateKey: 'beacons',
    headerIcon: 'beacon card',
  },
  [DeviceType.GATEWAY_PENGUIN]: {
    name: 'Gateway',
    icon: 'gateway',
    headerIcon: 'gateway',
    formatSettingsName: () => `Gateway configuration`,
  },
  [DeviceType.INVERTER_LEANCORE]: {
    name: 'Inverter',
    icon: 'inverter',
    headerIcon: 'inverter card',
  },
  [DeviceType.DISCONNECT_SWITCH_MANTA]: {
    name: 'Smart Disconnect Switch',
    icon: 'manta',
    headerIcon: 'manta',
    formatSettingsName: () => `SDS configuration`,
  },
  [DeviceType.BATTERY_R2_BATTERY]: {
    name: 'Battery',
    icon: 'battery',
    headerIcon: 'battery card',
    formatName: (battery: SiteDeviceV2) => `Battery ${battery.deviceId} Overview`,
  },
  [DeviceType.ICM]: {
    name: 'ICM',
  },
  [DeviceType.INVERTER]: {
    name: 'Inverter',
    icon: 'inverter',
    stateKey: 'inverters',
    headerIcon: 'inverter card',
  },
  [DeviceType.RGM]: {
    name: 'RGM',
    icon: 'rgm',
    stateKey: 'rgms',
    headerIcon: 'rgm card',
  },
  [DeviceType.BATTERY]: {
    name: 'Battery',
    icon: 'battery',
    stateKey: 'batteries',
    deleteReqUrlDir: 'battery',
    headerIcon: 'battery card',
  },
  [DeviceType.PVLINK]: {
    name: 'PV Link',
    icon: 'solar',
    stateKey: 'pvls',
    deleteReqUrlDir: 'pvlink',
    headerIcon: 'pvlink card',
  },
  [DeviceType.GENERATOR]: {
    name: 'DC Generator',
    icon: 'generator',
    stateKey: 'generators',
    deleteReqUrlDir: 'generator',
  },
  [DeviceType.LOAD_MANAGER]: {
    name: 'Load Manager',
    icon: 'load manager',
    stateKey: 'loadControllers',
    headerIcon: 'load manager card',
  },
  [DeviceType.MICROINVERTER_GATEWAY]: {
    name: 'Microinverter Gateway',
    icon: 'beacon',
    stateKey: 'gateways',
  },
  [DeviceType.MICROINVERTER]: {
    name: 'Microinverter',
    icon: 'microinverter',
    stateKey: 'microinverters',
    deleteReqUrlDir: 'microinverter',
  },
  [DeviceType.ACC_GENERATOR]: {
    name: 'AC Generator',
    icon: 'ac generator',
    headerIcon: 'ac generator',
  },
  [DeviceType.ACC_PV]: {
    name: 'AC Solar',
    icon: 'pvlink card',
    headerIcon: 'pvlink card',
  },
  [DeviceType.UNKNOWN]: {
    name: 'Unknown Device',
  },
};

export default DeviceTypeDefinitions;
