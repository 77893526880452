import { createAction, createAsyncAction } from 'typesafe-actions';

import type {
  CompaniesMergeFailedResponse,
  CompaniesMergeRequest,
  CompaniesMergeSuccessResponse,
} from '../../types/CompaniesMerge';
import type { Company } from '../../types/Company';

export const loadCompanies = createAsyncAction(
  'LOAD_COMPANIES_REQUEST',
  'LOAD_COMPANIES_SUCCESS',
  'LOAD_COMPANIES_FAILURE',
)<void, Company[], Error>();

export const toggleCompanyCheckbox = createAction(
  'TOGGLE_COMPANY_CHECKBOX',
  (companyId: string) => companyId,
)<string>();

export const mergeCompaniesAction = createAsyncAction(
  'MERGE_COMPANIES_REQUEST',
  'MERGE_COMPANIES_SUCCESS',
  'MERGE_COMPANIES_FAILURE',
)<CompaniesMergeRequest, CompaniesMergeSuccessResponse, CompaniesMergeFailedResponse>();

export const clearMergeVariables = createAction('CLEAR_MERGE_VARIABLES')();
