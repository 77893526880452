import React from 'react';
import { useHistory } from 'react-router-dom';

import { Alert as AlertComponent } from '../alert';
import { SitesAlertContainer } from './sitesAlertContainer';

export const SitesEmptyAlert = () => {
  const history = useHistory();
  const handleRedirect = () => history.push({ search: 'add-site-modal' });

  return (
    <SitesAlertContainer>
      <AlertComponent
        toastProps={{ type: 'error' }}
        title="You don’t have any sites in your PWRfleet account yet. Let’s get started and add some!"
        buttons={[{ children: 'Add Site', onClick: handleRedirect }]}
      />
    </SitesAlertContainer>
  );
};
