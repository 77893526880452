import { Box, ButtonBase, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCompanyContext } from '../../../hooks/useCompanyContext';
import { loadCompany } from '../../../store/company/actions';
import { selectedCompanySelector } from '../../../store/company/selectors';
import { getColor } from '../../../styles';
import { UIFeature } from '../../../types/UIFeature';
import { GatedFeature } from '../../gatedFeature';
import { Icon } from '../../icon';
import companyLogoPlaceholderImage from '../Navbar/company_logo_placeholder.png';

interface Props {
  hideSettingsButton?: boolean;
  hideCompanyContext: boolean;
  toggleDrawer: (hide?: boolean) => void;
}

const NavProfileLogo = styled('img')`
  display: block;
  background-color: ${getColor('BLACK')};
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin: 0 auto 10px;
`;

interface LocationState {
  companyName?: string;
  companyId?: string;
}

export const UserHeader = ({ hideSettingsButton, hideCompanyContext, toggleDrawer }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation<LocationState | undefined>();
  const locationState = location.state;
  const { companyId = locationState?.companyId } = useCompanyContext();
  const companyDetails = useSelector(selectedCompanySelector);
  const companyName = companyDetails?.name || locationState?.companyName;

  // initial load of the company data
  useEffect(() => {
    if (companyId) {
      dispatch(loadCompany.request(companyId));
    }
  }, [dispatch, companyId]);

  return (
    <Box sx={{ textAlign: 'center', alignItems: 'center', maxWidth: '220px' }}>
      <GatedFeature
        uiFeature={UIFeature.SUPPORT_VIEW}
        onAllowed={() => <Toolbar variant="dense" />}
      />
      {!hideCompanyContext && (
        <>
          <NavProfileLogo src={companyLogoPlaceholderImage} alt="Company Logo" />
          <Box display="flex" alignItems="center" textAlign="center" width={{ md: '100%' }}>
            {!hideSettingsButton && <Box width={24} />}
            <Box flexGrow={1}>
              <Typography variant="subtitle1" sx={{ lineHeight: 1.25, wordBreak: 'break-word' }}>
                {companyName}
              </Typography>
            </Box>
            {!hideSettingsButton && (
              <ButtonBase
                data-test-id="account-settings-button"
                onClick={() => toggleDrawer()}
                sx={{ borderRadius: '4px' }}
              >
                <Icon icon="more menu black" rotate={0} />
              </ButtonBase>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
