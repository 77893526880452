import { Breadcrumbs, Link as MUILink } from '@mui/material';
import { findKey, isEmpty } from 'lodash';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import { useAppSelector } from '../../hooks/useAppSelector';
import { useTab } from '../../hooks/useTab';
import { useGetSite, useGetSiteSystems } from '../../store/api/endpoints/site';
import { selectedCompanySelector } from '../../store/company/selectors';
import { selectedSiteSelector } from '../../store/ui/selectors';
import { COLORS } from '../../styles';
import type { DeviceType } from '../../types/DeviceType';
import { getDeviceTypeDefinitionFromDeviceType } from '../../utils/devices';

export const INACTIVE_DYNAMIC_PARAMS = ['deviceType'];

interface PwrwheatProps {
  breadcrumbs: any;
  hideStaticParams?: boolean;
}

// The actual logic that handles the breadcrumbs. Separated out from the exporter because of currying
const PWRwheat = ({ breadcrumbs, hideStaticParams = false }: PwrwheatProps): JSX.Element => {
  let reactRouterPathParams = useParams(); // Get the dynamic parts of the URL and their names, like {companyId: blah, siteId: blah, rcpn: blah}
  const { params: devicesTabParams } = useTab();
  const currentLocation = useLocation();

  const selectedSite = useAppSelector(selectedSiteSelector);
  const siteId = selectedSite?.id ?? '';
  const selectedCompany = useAppSelector(selectedCompanySelector);

  const { data: siteData } = useGetSite({ siteId }, { skip: !siteId });
  const { data: systems } = useGetSiteSystems({ siteId }, { skip: !siteId });

  const siteName = selectedSite?.name ?? siteData?.siteName ?? 'This site';

  if (!isEmpty(devicesTabParams)) {
    reactRouterPathParams = devicesTabParams;
  }

  // Dynamic crumbs resolver for the dynamic params in the URL (like company or site ID).
  // Given the params from `useParams()`, match those property names and return a dynamic string that will be formed into a crumb
  const dynamicCrumbsByParamNameAndValue = (
    paramName: string,
    paramValue: string,
  ): string | undefined => {
    switch (paramName) {
      case 'companyId':
        return selectedCompany?.name ?? 'This company'; // Owning company because there can be more than one company associated with a site
      case 'siteId':
        return siteName;
      case 'caseId':
        return `Case ${paramValue}`; // For viewing individual Supportal cases
      case 'systemId':
        const systemId = paramValue.toLowerCase();
        const system = systems?.find((system) => system.systemId === systemId);

        return system?.serialNumber ?? 'This system';
      case 'deviceType':
        const deviceDefinition = getDeviceTypeDefinitionFromDeviceType(paramValue as DeviceType);

        return deviceDefinition.name;
      case 'deviceId':
        return paramValue;
    }

    return undefined;
  };

  /* `withbreadcrumbs()` by itself works well for basic use, but it doesn't allow the parsing of dynamic route components (companyId, siteId, etc.) unless they're defined in an extended
   * react-router routeConfig object, which we can't easily do because of our custom implementation of bifurcated routes for Support vs Installers in constants/routes.ts. So we have to extend
   * its logic and process each individual crumb by itself to return what we want. This is NOT an elegant solution, just a temporary workaround until we can upgrade to RR6 or another lib */
  const getCurrentCrumb = (breadcrumb: any) => {
    const possibleParamKey = getDynamicParamKey(breadcrumb);
    if (possibleParamKey) {
      const currentUrlPart = breadcrumb.props.children;
      // If so, look it up and return a dynamic output, usually the actual site or company name
      if (typeof possibleParamKey === 'string') {
        return dynamicCrumbsByParamNameAndValue(possibleParamKey, currentUrlPart);
      }
    }

    return breadcrumb;
  };

  const getDynamicParamKey = (breadcrumb: any): string | undefined => {
    if (breadcrumb.props?.children && typeof breadcrumb.props.children === 'string') {
      // The current breadcrumb as a string instead of a React element
      const currentUrlPart = breadcrumb.props.children;

      // See if it matches our known dynamic params
      const possibleParamKey = findKey(
        reactRouterPathParams,
        (val: string | undefined) =>
          typeof val === 'string' ? val.toLowerCase() === currentUrlPart.toLowerCase() : undefined, // withbreadcrumbs() changes the case on these strings, possibly to make them human-readable, which screws with our lookups. Technically this could result in accidental collisions but I don't think our identifiers are case-dependent. I hope.
      );

      return possibleParamKey;
    }
  };

  return (
    <Breadcrumbs>
      {/*@ts-ignore the {match, breadcrumb} type isn't specified by the withBreadcrumbs lib, but it works */}
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        const dynamicParamKey = getDynamicParamKey(breadcrumb);
        if (hideStaticParams && !dynamicParamKey) {
          return null;
        }

        return (
          <MUILink
            component={NavLink}
            key={index}
            to={match.url}
            exact={false}
            strict={false}
            activeStyle={{ color: 'black', textDecoration: 'none' }}
            isActive={(potentialMatch: any) =>
              potentialMatch.url === currentLocation.pathname ||
              (!!dynamicParamKey && INACTIVE_DYNAMIC_PARAMS.includes(dynamicParamKey))
            } // potentialMatch's `path` has encoded backslashes, so use the URL instead
            data-test-id={`breadcrumb-level-${index}`}
            sx={{ color: COLORS.LIGHT_TEAL, textDecorationColor: COLORS.TEAL }}
          >
            {getCurrentCrumb(breadcrumb)}
          </MUILink>
        );
      })}
    </Breadcrumbs>
  );
};

/** A temporary breadcrumb display component, hopefully replaced soon by the built-in breadcrumbs in React-Router v6
 This version was ugily hacked together using the 3rd party package `react-router-breadcrumbs-hoc` (`withBreadcrumbs`) and `useParams` from RR v5
 It will easily fall out of sync if we add new routes in `constants/routes.ts` and don't define their crumbs here.
 This is tech debt we should refactor ASAP. -Roger Feb 2023
 */
export const PWRcrumbs = withBreadcrumbs(undefined, {
  excludePaths: ['/'],
})(PWRwheat);
