import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type {
  ArrayLayout,
  ArrayLayoutTelemetry,
  SiteLayoutFailedResponse,
  SiteLayoutTelemetryFailedResponse,
} from '../../types/SiteLayout';
import * as actions from './actions';

export interface SiteLayoutState {
  layout: {
    data: ArrayLayout | null;
    loading: boolean;
    saving: boolean;
    notFound: boolean;
    error: SiteLayoutFailedResponse | null;
  };
  telemetry: {
    data: ArrayLayoutTelemetry | null;
    loading: boolean;
    notFound: boolean;
    error: SiteLayoutTelemetryFailedResponse | null;
  };
}

const INITIAL_STATE: SiteLayoutState = {
  layout: {
    data: null,
    loading: false,
    saving: false,
    notFound: false,
    error: null,
  },
  telemetry: {
    data: null,
    loading: false,
    notFound: false,
    error: null,
  },
};

const {
  request: loadLayoutRequest,
  success: loadLayoutSuccess,
  failure: loadLayoutFailure,
} = actions.loadSiteLayout;

const {
  request: saveLayoutRequest,
  success: saveLayoutSuccess,
  failure: saveLayoutFailure,
} = actions.saveSiteLayout;

const {
  request: loadLayoutTelemetryRequest,
  success: loadLayoutTelemetrySuccess,
  failure: loadLayoutTelemetryFailure,
} = actions.loadSiteLayoutTelemetry;

export type SiteLayoutActions = ActionType<typeof actions>;

// --

export default createReducer<SiteLayoutState, SiteLayoutActions>(INITIAL_STATE, {
  [getType(loadLayoutRequest)]: produce((state: SiteLayoutState) => {
    // clears previous layout when new site is loading it's own layout
    // this can also be done manually when unmounting a SiteLayout screen
    // but for now this is fine.
    state.layout.data = null;
    state.layout.loading = true;
    state.layout.error = null;
    state.layout.notFound = false;
  }),
  [getType(loadLayoutSuccess)]: produce(
    (state: SiteLayoutState, action: ReturnType<typeof loadLayoutSuccess>) => {
      state.layout.data = action.payload;
      state.layout.loading = false;
      state.layout.notFound = false;
      state.layout.error = null;
    },
  ),
  [getType(loadLayoutFailure)]: produce(
    (state: SiteLayoutState, action: ReturnType<typeof loadLayoutFailure>) => {
      state.layout.data = null;
      state.layout.loading = false;
      state.layout.notFound = action.payload.response?.status === 404;
      state.layout.error = action.payload;
    },
  ),
  // --
  [getType(saveLayoutRequest)]: produce((state: SiteLayoutState) => {
    state.layout.saving = true;
    state.layout.error = null;
  }),
  [getType(saveLayoutSuccess)]: produce(
    (state: SiteLayoutState, action: ReturnType<typeof saveLayoutSuccess>) => {
      state.layout.data = action.payload;
      state.layout.saving = false;
      state.layout.error = null;
    },
  ),
  [getType(saveLayoutFailure)]: produce(
    (state: SiteLayoutState, action: ReturnType<typeof saveLayoutFailure>) => {
      state.layout.saving = false;
      state.layout.error = action.payload;
    },
  ),
  [getType(loadLayoutTelemetryRequest)]: produce((state: SiteLayoutState) => {
    state.telemetry.data = null;
    state.telemetry.loading = true;
    state.telemetry.error = null;
    state.telemetry.notFound = false;
  }),
  [getType(loadLayoutTelemetrySuccess)]: produce(
    (state: SiteLayoutState, action: ReturnType<typeof loadLayoutTelemetrySuccess>) => {
      state.telemetry.data = action.payload;
      state.telemetry.loading = false;
      state.telemetry.notFound = false;
      state.telemetry.error = null;
    },
  ),
  [getType(loadLayoutTelemetryFailure)]: produce(
    (state: SiteLayoutState, action: ReturnType<typeof loadLayoutTelemetryFailure>) => {
      state.telemetry.data = null;
      state.telemetry.loading = false;
      state.telemetry.notFound = action.payload.response?.status === 404;
      state.telemetry.error = action.payload;
    },
  ),
});
