/**
 * Column microinverterMetricDefinitions for the All Sites (sites list) table
 */

import type { TableColumns } from '../types/Table';
import {
  CurrentType,
  DailyKilowattHourPerKilowattPeak,
  Kilowatt,
  KilowattHour,
  KilowattHourPerKilowattPeak,
  Percentage,
} from '../types/Unit';

export const COLUMNS_SECTION_SITE_INFO = 'siteInfo';
export const COLUMNS_SECTION_CONSUMPTION = 'consumption';
export const COLUMNS_SECTION_SOLAR_ABSOLUTE_YIELD = 'solarProductionAbsoluteYield';
export const COLUMNS_SECTION_SOLAR_SPECIFIC_YIELD = 'solarProductionSpecificYield';
export const COLUMNS_SECTION_SOLAR_PERFORMANCE_RATIO = 'solarProductionPerformanceRatio';
export const COLUMNS_SECTION_STORAGE = 'storage';
export const COLUMNS_SECTION_AGGREGATE_METRICS = 'aggregateMetrics';
export const COLUMNS_SECTION_INVERTER_ENERGY = 'inverterEnergy';

export const MOBILE_COLUMNS = ['status', 'siteName', 'capability', 'soc'];
export const TABLET_COLUMNS = [
  'status',
  'siteName',
  'systemTypes',
  'city',
  'installedDate',
  'capability',
  'yieldToday',
  'soc',
];

export const INITIAL_TABLET_COLUMNS = [
  'status',
  'siteName',
  'systemTypes',
  'city',
  'installedDate',
  'capability',
  'yieldToday',
  'yieldYesterday',
  'yieldThisMonth',
  'soc',
];

//@todo : reinstate sorting once performance issues are solved

export default {
  forced: {
    title: '',
    items: [
      { name: 'status', property: ['status'], title: 'Status', sortable: false },
      { name: 'siteName', property: ['siteName'], title: 'Site Name', sortable: true },
      { name: 'city', property: ['siteAddress', 'city'], title: 'City', sortable: true },
      {
        name: 'installedDate',
        property: ['installedDate'],
        title: 'Date Installed',
        sortable: true,
      },
      { name: 'systemTypes', property: ['systemTypes'], title: 'System Type', sortable: false },
      {
        name: 'capability',
        property: ['capability'],
        title: 'Capability',
        sortable: false,
      },
    ],
  },
  [COLUMNS_SECTION_SITE_INFO]: {
    title: 'Site info',
    items: [
      {
        name: 'state',
        property: ['siteAddress', 'state'],
        title: 'State/Province',
        sortable: true,
      },
      {
        name: 'zip',
        property: ['siteAddress', 'zip'],
        title: 'Zip code',
        sortable: true,
      },
      {
        name: 'streetAddress',
        property: ['siteAddress', 'streetAddress'],
        title: 'Street address',
        sortable: true,
      },
      {
        name: 'name',
        property: ['contact', 'name'],
        title: 'Homeowner name',
        sortable: true,
      },
      {
        name: 'email',
        property: ['contact', 'email'],
        title: 'Homeowner email',
        sortable: true,
      },
      {
        name: 'installedPV',
        property: ['installedPV'],
        title: 'Nameplate PV capacity',
        unit: Kilowatt,
        sortable: true,
      },
      {
        name: 'installedStorage',
        property: ['installedStorage'],
        title: 'Installed storage capacity',
        unit: KilowattHour,
        sortable: false,
      },
    ],
  },
  [COLUMNS_SECTION_CONSUMPTION]: {
    title: 'Consumption',
    items: [
      {
        name: 'consumptionToday',
        property: ['consumption', 'consumptionToday'],
        title: 'Consumption today',
        unit: KilowattHour,
        unitType: CurrentType.AC,
        sortable: false,
      },
      {
        name: 'consumptionYesterday',
        property: ['consumption', 'consumptionYesterday'],
        title: 'Consumption yesterday',
        unit: KilowattHour,
        unitType: CurrentType.AC,
        sortable: false,
      },
      {
        name: 'consumptionThisMonth',
        property: ['consumption', 'consumptionThisMonth'],
        title: 'Consumption this month',
        unit: KilowattHour,
        unitType: CurrentType.AC,
        sortable: false,
      },
      {
        name: 'consumptionLastMonth',
        property: ['consumption', 'consumptionLastMonth'],
        title: 'Consumption last month',
        unit: KilowattHour,
        unitType: CurrentType.AC,
        sortable: false,
      },
      {
        name: 'consumptionThisYear',
        property: ['consumption', 'consumptionThisYear'], // @todo missing from endpoint
        title: 'Consumption this year',
        unit: KilowattHour,
        unitType: CurrentType.AC,
        sortable: false,
      },
    ],
  },
  [COLUMNS_SECTION_SOLAR_ABSOLUTE_YIELD]: {
    title: 'Solar production (absolute yield)',
    items: [
      {
        name: 'yieldToday',
        property: ['generation', 'yieldToday'],
        title: 'Yield today',
        unit: KilowattHour,
        sortable: false,
      },
      {
        name: 'yieldYesterday',
        property: ['generation', 'yieldYesterday'],
        title: 'Yield yesterday',
        unit: KilowattHour,
        sortable: false,
      },
      {
        name: 'yieldThisMonth',
        property: ['generation', 'yieldThisMonth'],
        title: 'Yield this month',
        unit: KilowattHour,
        sortable: false,
      },
      {
        name: 'yieldLastMonth',
        property: ['generation', 'yieldLastMonth'],
        title: 'Yield last month',
        unit: KilowattHour,
        sortable: false,
      },
      {
        name: 'yieldThisYear',
        property: ['generation', 'yieldThisYear'],
        title: 'Yield this year',
        unit: KilowattHour,
        sortable: false,
      },
    ],
  },
  [COLUMNS_SECTION_SOLAR_SPECIFIC_YIELD]: {
    title: 'Solar production (specific yield)',
    items: [
      {
        name: 'performanceToday',
        property: ['generation', 'performanceToday'],
        title: 'Perf. today',
        unit: KilowattHourPerKilowattPeak,
        sortable: false,
      },
      {
        name: 'performanceYesterday',
        property: ['generation', 'performanceYesterday'],
        title: 'Perf. yesterday',
        unit: KilowattHourPerKilowattPeak,
        sortable: false,
      },
      {
        name: 'avgPerformanceThisMonth',
        property: ['generation', 'avgPerformanceThisMonth'],
        title: 'Avg. perf. this month',
        unit: DailyKilowattHourPerKilowattPeak,
        sortable: false,
      },
      {
        name: 'avgPerformanceLastMonth',
        property: ['generation', 'avgPerformanceLastMonth'],
        title: 'Avg. perf. last month',
        unit: DailyKilowattHourPerKilowattPeak,
        sortable: false,
      },
      {
        name: 'avgPerformanceThisYear',
        property: ['generation', 'avgPerformanceThisYear'],
        title: 'Avg. perf. this year',
        unit: DailyKilowattHourPerKilowattPeak,
        sortable: false,
      },
    ],
  },
  [COLUMNS_SECTION_SOLAR_PERFORMANCE_RATIO]: {
    title: 'Solar production (performance ratio)',
    items: [
      {
        name: 'performanceRatioThisMonth',
        property: ['generation', 'performanceRatioThisMonth'],
        title: 'Perf. ratio this month',
        unit: Percentage,
        sortable: false,
      },
      {
        name: 'performanceRatioLastMonth',
        property: ['generation', 'performanceRatioLastMonth'],
        title: 'Perf. ratio last month',
        unit: Percentage,
        sortable: false,
      },
    ],
  },
  [COLUMNS_SECTION_STORAGE]: {
    title: 'Storage',
    items: [{ name: 'soc', property: ['soc'], title: 'SoC', unit: Percentage, sortable: false }],
  },
  [COLUMNS_SECTION_AGGREGATE_METRICS]: {
    title: 'Aggregate metrics',
    items: [
      {
        name: 'selfConsumptionToday',
        property: ['aggregateMetrics', 'selfConsumptionToday'],
        title: 'Self consumption today',
        unit: Percentage,
        sortable: false,
      },
      {
        name: 'selfConsumptionYesterday',
        property: ['aggregateMetrics', 'selfConsumptionYesterday'],
        title: 'Self consumption yesterday',
        unit: Percentage,
        sortable: false,
      },
      {
        name: 'selfConsumptionMonth',
        property: ['aggregateMetrics', 'selfConsumptionMonth'],
        title: 'Self consumption current month',
        unit: Percentage,
        sortable: false,
      },
      {
        name: 'selfConsumptionYear',
        property: ['aggregateMetrics', 'selfConsumptionYear'],
        title: 'Self consumption current year',
        unit: Percentage,
        sortable: false,
      },
    ],
  },
  [COLUMNS_SECTION_INVERTER_ENERGY]: {
    title: 'Site Energy',
    items: [
      {
        name: 'producedThisMonth',
        property: ['inverterEnergy', 'producedThisMonth'],
        title: 'Energy Produced This Month',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'producedLastMonth',
        property: ['inverterEnergy', 'producedLastMonth'],
        title: 'Energy Produced Last Month',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'producedLifetime',
        property: ['inverterEnergy', 'producedLifetime'],
        title: 'Energy Produced Lifetime',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'importedThisMonth',
        property: ['inverterEnergy', 'importedThisMonth'],
        title: 'Energy Imported This Month',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'importedLastMonth',
        property: ['inverterEnergy', 'importedLastMonth'],
        title: 'Energy Imported Last Month',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
      {
        name: 'importedLifetime',
        property: ['inverterEnergy', 'importedLifetime'],
        title: 'Energy Imported Lifetime',
        unit: KilowattHour,
        unitType: CurrentType.AC,
      },
    ],
  },
} as TableColumns;
