import type { RootState } from '../types';

export const accountUserInfoSelector = (state: RootState) => ({
  firstName: state?.account?.account?.firstName || '',
  lastName: state?.account?.account?.lastName || '',
  email: state?.account?.account?.email || '',
});

export const accountCompanySelector = (state: RootState) => state.account.accountCompany;
export const accountCompanyIdSelector = (state: RootState) =>
  state.account.accountCompany?.companyId;
export const accountCompanyNameSelector = (state: RootState) =>
  state.account.accountCompany?.name || '';
