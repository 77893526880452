import type { RefObject } from 'react';
import { useMemo } from 'react';

import useWindowSize from './useWindowSize';

/**
 * This hook returns the maximum height of a table dynamically at current view height
 * Maximum height is necessary for scrollable table with sticky header
 * windowHeightThreshold and defaultHeight are used to avoid table from
 * having little height when the browser height is really small
 **/

interface Props {
  tableRef: RefObject<HTMLElement>;
  windowHeightThreshold?: number;
  defaultHeight?: string | number;
  offsetBottom?: number;
}
export const useTableMaxHeight = ({
  tableRef,
  windowHeightThreshold = 800,
  defaultHeight = '70vh',
  offsetBottom = 16,
}: Props) => {
  const { height } = useWindowSize();
  const offsetTop = tableRef?.current?.getBoundingClientRect().top || 0;

  return useMemo(
    () => (height < windowHeightThreshold ? defaultHeight : height - offsetTop - offsetBottom),
    [height, offsetTop, offsetBottom, defaultHeight, windowHeightThreshold],
  );
};
