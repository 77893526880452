import { KeyboardArrowUp as ArrowUpIcon } from '@mui/icons-material';
import { Box, ButtonBase, Collapse, IconButton, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';

import { COLORS } from '../../styles';
import { Flex } from '../flex';
import { Icon } from '../icon';
import type { IconType } from '../icon/icons';
import { ReadOnlyContainer } from '../readOnlyContainer';

interface MobileViewCardProps {
  title: string;
  isCollapsible?: boolean;
  handleClick?: () => void;
  titleIcon?: IconType;
  subtitleSlotRows?: ReactNode[][];
  bodySlotRows?: ReactNode[][];
  iconsSlotLeft?: IconType[];
  iconsSlotRight?: IconType[];
  collapseSlotRows?: ReactNode[][];
}

/*
 * using key={index} which should be avoided whenever possible, but since
 * ReactNodes have no other unique identifier, and the use case for this
 * component is to pass static, presentational arrays of data, should be fine
 */

export const MobileTableCard = ({
  title,
  handleClick,
  isCollapsible = false,
  titleIcon,
  subtitleSlotRows = [],
  bodySlotRows = [],
  collapseSlotRows = [],
}: MobileViewCardProps) => {
  const hasClickHandler = !!handleClick;
  const [isBodyCollapsed, setIsBodyCollapsed] = useState<boolean>(true);

  const handleCardClick = useCallback(() => {
    if (hasClickHandler) {
      handleClick();
    } else if (isCollapsible) {
      setIsBodyCollapsed(!isBodyCollapsed);
    }
  }, [handleClick, isCollapsible, isBodyCollapsed]);

  return (
    <ButtonBase
      onClick={handleCardClick}
      sx={{ width: '100%', display: 'block', bgcolor: COLORS.WHITE, borderRadius: '16px', p: 2 }}
    >
      <Flex sx={{ justifyContent: 'space-between', pb: '4px' }}>
        <Typography
          sx={{
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left',
            textDecoration: hasClickHandler ? 'underline' : 'none',
          }}
        >
          {title}
        </Typography>
        <Box>
          {isCollapsible ? (
            <IconButton
              onClick={() => setIsBodyCollapsed(!isBodyCollapsed)}
              sx={{ width: 20, height: 20, ml: 2 }}
            >
              <ArrowUpIcon
                sx={{
                  fontSize: 24,
                  rotate: isBodyCollapsed ? '180deg' : 0,
                }}
              />
            </IconButton>
          ) : (
            titleIcon && (
              <Box sx={{ width: 24 }}>
                <Icon icon={titleIcon} />
              </Box>
            )
          )}
        </Box>
      </Flex>
      {subtitleSlotRows.map((row, index) => (
        <Flex
          key={index}
          sx={{ minHeight: '20px', fontSize: '14px', justifyContent: 'space-between', gap: 1 }}
        >
          {row.map((item, index) => (
            <Box
              key={index}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {item}
            </Box>
          ))}
        </Flex>
      ))}
      {bodySlotRows.map((row, index) => (
        <Flex
          key={index}
          sx={{
            minHeight: '20px',
            fontSize: '14px',
            color: COLORS.MEDIUM_EMPHASIS,
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          {row.map((item, index) => (
            <Box
              key={index}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {item}
            </Box>
          ))}
        </Flex>
      ))}
      {!isEmpty(collapseSlotRows) && (
        <Collapse in={!isBodyCollapsed} sx={{ pt: 1 }}>
          <ReadOnlyContainer sx={{ fontSize: '14px', p: 2 }}>
            {collapseSlotRows.map((row, index) => (
              <Flex key={index} sx={{ minHeight: '20px', justifyContent: 'space-between', gap: 1 }}>
                {row.map((item, index) => (
                  <Box key={index}>{item}</Box>
                ))}
              </Flex>
            ))}
          </ReadOnlyContainer>
        </Collapse>
      )}
    </ButtonBase>
  );
};
