import { Box, DialogContent, Typography } from '@mui/material';
import React, { Fragment } from 'react';

import { COLORS } from '../../styles';
import type { AlertDetails, AlertTypeInfo, TroubleshootingStep } from '../../types/Alert';
import { Loader } from '../loader';
import { ErrorMsg } from './errorMsg';

export interface Props {
  alertDetails: AlertDetails | null;
  alertDetailsError: string | null;
  alertTypeInfo: AlertTypeInfo | null;
  isLoadingAlertTypeInfo: boolean;
  alertTypeInfoError: string | null;
  height: number | 'unset';
}

const renderNotes = (notes: string[] | undefined) => {
  if (notes && notes.length > 0) {
    return (
      <Box display="flex" flexDirection="row" my={1}>
        <Typography variant="body2" fontWeight="bold">
          Notes:
        </Typography>
        <Box ml={1}>
          {notes.map((note) => (
            <Typography variant="body2" key={note}>
              {note}
            </Typography>
          ))}
        </Box>
      </Box>
    );
  }
  return null;
};

const renderSteps = (steps: TroubleshootingStep[] | undefined) => {
  if (steps && steps.length > 0) {
    return (
      <ul>
        {steps.map((step: TroubleshootingStep, index: number) => {
          const { content, steps, notes } = step;
          return (
            <Fragment key={index}>
              <li>{content}</li>
              {renderSteps(steps)}
              {renderNotes(notes)}
            </Fragment>
          );
        })}
      </ul>
    );
  }
  return null;
};

export const TroubleshootingContent = (props: Props) => {
  const { height, alertTypeInfo, alertDetailsError, isLoadingAlertTypeInfo, alertTypeInfoError } =
    props;
  const showContent = !alertDetailsError && !alertTypeInfoError && alertTypeInfo ? true : false;

  return (
    <DialogContent dividers style={{ height }}>
      <Box my={1}>
        {!isLoadingAlertTypeInfo ? (
          showContent ? (
            <Typography variant="body2" component="ol" pl="12px">
              {alertTypeInfo?.procedures.map(
                ({ procedure, name, content, steps, notes }, index) => {
                  return (
                    <Box mb={2} key={`${procedure}-${index}`}>
                      <li style={{ marginBottom: '4px' }}>
                        <Typography variant="subtitle2">{name}</Typography>
                        {content && (
                          <Box mt={0.5} mb={1}>
                            <Typography variant="body2">{content}</Typography>
                          </Box>
                        )}
                        {renderSteps(steps)}
                        {renderNotes(notes)}
                      </li>
                    </Box>
                  );
                },
              )}
            </Typography>
          ) : (
            <ErrorMsg />
          )
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height={height}>
            <Loader size={14} color={COLORS.LIGHT_ORANGE} />
          </Box>
        )}
      </Box>
    </DialogContent>
  );
};
