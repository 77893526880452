/**
 * @todo remove this component and rebuild single use case
 *
 * we are using this custom component in one location (SitesContainer)
 * for an element that is not really a "Tab", but just two Icon Buttons next to each other.
 * we can delete this component and style the single use case.
 */

import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { getColor } from '../../styles';

interface TabProps {
  tabIndex?: number;
  selected?: boolean;
  children: ReactNode | string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>, value: string) => void;
  value?: string;
}

interface TabsProps {
  children: ReactNode;
  value: string | number;
  onChange?: (e: React.MouseEvent<HTMLDivElement>, value: string) => void;
}

const StyledTabs = styled('div')`
  height: 31px;
  display: inline-flex;
  div {
    border: 1px solid #e3e6df;
  }
  & :first-child {
    border-radius: 4px 0 0 4px;
  }

  & :last-child {
    border-radius: 0 4px 4px 0;
  }
`;

const StyledTab = styled('div')<TabProps>`
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  width: 41px;
  cursor: pointer;
  background-color: ${(p) => (p.selected ? getColor('LIGHT_GREY') : getColor('WHITE'))};
`;

export const IconTabs = ({ children, onChange, value, ...props }: TabsProps) => {
  const [currentValue, setValue] = useState(value);
  const handleTabClick = useCallback(
    (e, value) => {
      setValue(value);
      onChange?.(e, value);
    },
    [setValue, onChange],
  );

  useEffect(() => {
    if (value) {
      setValue(value);
    }
  }, [value, setValue]);
  return (
    <StyledTabs {...props}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            onClick: handleTabClick,
            tabIndex: index,
            selected: currentValue === child.props.value,
          });
        }
      })}
    </StyledTabs>
  );
};

export const IconTab = ({ onClick, value, ...props }: TabProps) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      onClick?.(e, value!);
    },
    [value, onClick],
  );
  return <StyledTab onClick={handleClick} {...props} />;
};
