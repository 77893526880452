import type { SxProps } from '@mui/material';
import { Box, IconButton } from '@mui/material';

import { COLORS } from '../../styles';

export interface StepInfo {
  isUnlocked: boolean;
  name: string;
}

interface PWRstepperProps {
  steps: StepInfo[];
  currentStep: string;
  handleStepChange: (newStep: string) => void;
}

const unlockedStepSx: SxProps = {
  background: COLORS.BLACK,
  width: 16,
  height: 16,
};

const lockedStepSx: SxProps = {
  background: COLORS.LIGHT_GREY,
  width: 16,
  height: 16,
};

const activeStepSx: SxProps = {
  background: COLORS.WHITE,
  border: `4px solid ${COLORS.TEAL}`,
  width: 20,
  height: 20,
};

export const PWRstepper = ({ steps, currentStep, handleStepChange }: PWRstepperProps) => {
  const lastUnlockedStepIndex = steps.findLastIndex((step) => step.isUnlocked);
  const progressRatio = lastUnlockedStepIndex / (steps.length - 1);
  const filledAreaWidth = `calc(${progressRatio * 100}% - ${progressRatio * 32}px)`;

  return (
    <Box
      sx={{
        height: 48,
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 'calc(100% - 34px)',
          top: '22px',
          left: '16px',
          right: '18px',
          height: '4px',
          backgroundColor: COLORS.LIGHT_GREY,
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          width: filledAreaWidth,
          top: '22px',
          left: '16px',
          right: '18px',
          height: '4px',
          backgroundColor: COLORS.BLACK,
          zIndex: 2,
        }}
      />
      {steps.map((step, index) => {
        const isActive = step.name === currentStep;
        return (
          <IconButton
            sx={{ zIndex: step.isUnlocked ? 3 : 1, height: 48, width: 48 }}
            disabled={!step.isUnlocked}
            onClick={() => handleStepChange(step.name)}
            data-test-id={`pwr-stepper-step-${index}`}
            key={`pwr-stepper-step-${index}`}
          >
            <Box
              sx={{
                background: COLORS.BLACK,
                borderRadius: 16,
                ...(step.isUnlocked ? unlockedStepSx : lockedStepSx),
                ...(isActive ? activeStepSx : {}),
              }}
            />
          </IconButton>
        );
      })}
    </Box>
  );
};
