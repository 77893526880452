import type { SxProps } from '@mui/material';

import { COLORS } from '../../styles';
import { Flex } from '../flex';

interface ReadOnlyContainerProps {
  children: React.ReactNode;
  sx?: SxProps;
}

export const ReadOnlyContainer = ({ children, sx = {} }: ReadOnlyContainerProps) => {
  return (
    <Flex
      sx={{
        backgroundColor: COLORS.OFF_WHITE,
        borderRadius: '16px',
        p: 3,
        flexDirection: 'column',
        position: 'relative',
        ...sx,
      }}
    >
      {children}
    </Flex>
  );
};
