import { createAction, createAsyncAction } from 'typesafe-actions';

import type {
  AlertCompanyInfoFailedResponse,
  AlertCompanyInfoRequest,
  AlertCompanyInfoSuccessResponse,
  AlertDetailsFailedResponse,
  AlertDetailsRequest,
  AlertDetailsSuccessResponse,
  AlertInstanceHistoryFailedResponse,
  AlertInstanceHistoryRequest,
  AlertInstanceHistorySuccessResponse,
  AlertTypeInfoFailedResponse,
  AlertTypeInfoSuccessResponse,
} from '../../types/Alert';
import type {
  DeviceEventLogFailedResponse,
  DeviceEventLogRequest,
  DeviceEventLogSuccessResponse,
} from '../../types/DeviceEvent';

export const loadAlertDetails = createAsyncAction(
  'LOAD_ALERT_DETAILS_REQUEST',
  'LOAD_ALERT_DETAILS_SUCCESS',
  'LOAD_ALERT_DETAILS_FAILURE',
)<AlertDetailsRequest, AlertDetailsSuccessResponse, AlertDetailsFailedResponse>();

export const loadAlertCompanyInfo = createAsyncAction(
  'LOAD_ALERT_COMPANY_INFO_REQUEST',
  'LOAD_ALERT_COMPANY_INFO_SUCCESS',
  'LOAD_ALERT_COMPANY_INFO_FAILED',
)<AlertCompanyInfoRequest, AlertCompanyInfoSuccessResponse, AlertCompanyInfoFailedResponse>();

export const loadAlertTypeInfo = createAsyncAction(
  'LOAD_ALERT_TYPE_INFO_REQUEST',
  'LOAD_ALERT_TYPE_INFO_SUCCESS',
  'LOAD_ALERT_TYPE_INFO_FAILED',
)<string, AlertTypeInfoSuccessResponse, AlertTypeInfoFailedResponse>();

export const resetAlertDetailsState = createAction('RESET_ALERT_DETAILS_STATE')();
export const resetAlertCompanyInfoState = createAction('RESET_ALERT_COMPANY_INFO_STATE')();
export const resetAlertHistoryState = createAction('RESET_ALERT_HISTORY_STATE')();
export const resetAlertEventLogState = createAction('RESET_ALERT_EVENT_LOG_STATE')();

export const loadAlertHistory = createAsyncAction(
  'LOAD_ALERT_HISTORY_REQUEST',
  'LOAD_ALERT_HISTORY_SUCCESS',
  'LOAD_ALERT_HISTORY_FAILED',
)<
  AlertInstanceHistoryRequest,
  AlertInstanceHistorySuccessResponse,
  AlertInstanceHistoryFailedResponse
>();

export const loadAlertEventLog = createAsyncAction(
  'LOAD_ALERT_EVENT_LOG_REQUEST',
  'LOAD_ALERT_EVENT_LOG_SUCCESS',
  'LOAD_ALERT_EVENT_LOG_FAILED',
)<DeviceEventLogRequest, DeviceEventLogSuccessResponse, DeviceEventLogFailedResponse>();
