import { Typography } from '@mui/material';
import type { FieldProps, FormikProps } from 'formik';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import type { FormNameValues } from '../../store/account';
import { getAccountSettings, updateAccountName } from '../../store/account/actions';
import { accountUserInfoSelector } from '../../store/account/selectors';
import { userIdSelector, userRoleNameSelector } from '../../store/auth/selectors';
import { Button } from '../button';
import { FormContentBlock, FormHeader, FormItem } from '../form';
import { FormDiscardAlert } from '../formDiscardAlert';
import { Input } from '../input';

const PDSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
});

export const ProfileDetailsForm = () => {
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<FormNameValues>>(null);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [discardAlertIsOpen, setDiscardAlertIsOpen] = useState<boolean>(false);

  const userId = useSelector(userIdSelector);
  const userInfo = useSelector(accountUserInfoSelector);
  const userRoleName = useSelector(userRoleNameSelector);

  // --

  // initial load of the user data (account settings)
  useEffect(() => {
    dispatch(getAccountSettings.request(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    formRef?.current?.resetForm();
  }, [formRef, isEditing]);

  // --

  const handleDiscardAlert = useCallback(() => {
    const current = formRef?.current || { touched: {} };
    const isTouched = Object.keys(current.touched).length > 0;
    if (isTouched) {
      setDiscardAlertIsOpen(!discardAlertIsOpen);
    } else {
      setIsEditing(false);
    }
  }, [discardAlertIsOpen, formRef, setIsEditing]);

  const onSubmit = (values: FormNameValues) => {
    if (userId) {
      dispatch(
        updateAccountName.request({
          userId,
          firstName: values.firstName,
          lastName: values.lastName,
        }),
      );
      setIsEditing(false);
    }
  };

  // --

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      }}
      onSubmit={onSubmit}
      validationSchema={PDSchema}
      enableReinitialize
    >
      {(props) => {
        const handleDiscardForm = () => {
          setIsEditing(false);
          setDiscardAlertIsOpen(false);
          props.resetForm();
        };
        return (
          <Form>
            <FormDiscardAlert
              open={discardAlertIsOpen}
              onClose={() => setDiscardAlertIsOpen(false)}
              onDiscard={handleDiscardForm}
            />
            <FormHeader title="Personal details">
              {isEditing ? (
                <Button
                  color="secondary"
                  size="small"
                  onClick={handleDiscardAlert}
                  data-test-id="personal-details-cancel-button"
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => setIsEditing(true)}
                  data-test-id="personal-details-edit-button"
                >
                  Edit
                </Button>
              )}
            </FormHeader>
            {isEditing ? (
              <FormContentBlock isEditing>
                <FormItem input>
                  <Field name="firstName">
                    {({ field, form, meta }: FieldProps) => (
                      <Input
                        {...field}
                        fullWidth
                        label="First Name"
                        error={meta.touched && meta.error ? Boolean(form.errors.firstName) : false}
                        helperText={
                          meta.touched && meta.error && form.errors.firstName
                            ? String(form.errors.firstName)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <FormItem input>
                  <Field name="lastName">
                    {({ field, form, meta }: FieldProps) => (
                      <Input
                        {...field}
                        fullWidth
                        label="Last Name"
                        error={meta.touched && meta.error ? Boolean(form.errors.lastName) : false}
                        helperText={
                          meta.touched && meta.error && form.errors.lastName
                            ? String(form.errors.lastName)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <FormItem input>
                  <Field name="email">
                    {({ field, form, meta }: FieldProps) => (
                      <Input
                        {...field}
                        disabled
                        fullWidth
                        label="Email address"
                        error={meta.touched && meta.error ? Boolean(form.errors.email) : false}
                        helperText={
                          meta.touched && meta.error && form.errors.email
                            ? String(form.errors.email)
                            : ''
                        }
                      />
                    )}
                  </Field>
                </FormItem>
                <Button
                  width={200}
                  size="small"
                  color="primary"
                  type="submit"
                  data-test-id="personal-details-save-button"
                >
                  Save
                </Button>
              </FormContentBlock>
            ) : (
              <FormContentBlock>
                <FormItem title="Name">
                  <Typography data-test-id="personal-details-name">
                    {props.values.firstName} {props.values.lastName}
                  </Typography>
                </FormItem>
                <FormItem title="Email address">
                  <Typography>{props.values.email}</Typography>
                </FormItem>
                <FormItem title="Role">
                  <Typography>{userRoleName}</Typography>
                </FormItem>
              </FormContentBlock>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
