import type { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';

export const defaultSitesTableColumnVisibilityModel: GridColumnVisibilityModel = {
  siteName: true,
  city: true,
  status: true,
  installedDate: true,
  capability: true,
  streetAddress: false,
  state: false,
  zip: false,
  name: false,
  email: false,
  installedPV: false,
  installedStorage: false,
  systemTypes: false,
  commissioningStage: true,
  consumptionToday: false,
  consumptionYesterday: false,
  consumptionThisMonth: false,
  consumptionLastMonth: false,
  consumptionThisYear: false,
  yieldToday: true,
  yieldYesterday: true,
  yieldThisMonth: true,
  yieldLastMonth: false,
  yieldThisYear: false,
  performanceToday: false,
  performanceYesterday: false,
  avgPerformanceThisMonth: false,
  avgPerformanceLastMonth: false,
  avgPerformanceThisYear: false,
  performanceRatioThisMonth: false,
  performanceRatioLastMonth: false,
  selfConsumptionToday: false,
  selfConsumptionYesterday: false,
  selfConsumptionMonth: false,
  selfConsumptionYear: false,
  producedThisMonth: false,
  producedLastMonth: false,
  producedLifetime: false,
  importedThisMonth: false,
  importedLastMonth: false,
  importedLifetime: false,
  soc: true,
};
