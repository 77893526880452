import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { FeatureToggleList } from '../../types/FeatureToggle';

export interface FeatureTogglesState {
  featureList: FeatureToggleList;
  initialUpdateFinished: boolean;
}

const initialState: FeatureTogglesState = {
  featureList: [],
  initialUpdateFinished: false,
};

const slice = createSlice({
  name: 'featureToggles',
  initialState,
  reducers: {
    updateFeatureToggles(state, action: PayloadAction<FeatureToggleList>) {
      state.featureList = action.payload;
      state.initialUpdateFinished = true;
    },
    setFeatureTogglesFinishState(state) {
      state.initialUpdateFinished = true;
    },
  },
});

export const { updateFeatureToggles, setFeatureTogglesFinishState } = slice.actions;
export default slice.reducer;
