export const GENERAC_SUPPORT =
  'https://www.generac.com/dealers-installers/solar-battery-installer-support/pwrcell-technical-support';

export const GENERAC_PRODUCT_MANUALS =
  'https://www.generac.com/dealers-installers/solar-battery-installer-support/manuals-spec-sheets/';

export const GENERAC_FEEDBACK = 'https://pwrfleet.ideas.aha.io/';

export const GENERAC_PRIVACY_POLICY = 'https://www.generac.com/privacy-policy';

export const GENERAC_TERMS_PDF = 'https://generac.s3.amazonaws.com/Generac+Terms.pdf';

export const GENERAC_CONNECTIVITY_VIDEO =
  'https://www.youtube.com/watch?v=2HRPm8_Xsd4&amp%3Bfeature=youtu.be&ab_channel=GeneracCleanEnergy';

export const GENERAC_SUPPORT_PHONE_NUMBER = '+1 (888) 922-8482';
