import { Box, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { trackEvent } from '../../analyticsClient';
import COLUMNS, {
  COLUMNS_SECTION_AGGREGATE_METRICS,
  COLUMNS_SECTION_CONSUMPTION,
  COLUMNS_SECTION_INVERTER_ENERGY,
  COLUMNS_SECTION_SITE_INFO,
  COLUMNS_SECTION_SOLAR_ABSOLUTE_YIELD,
  COLUMNS_SECTION_SOLAR_PERFORMANCE_RATIO,
  COLUMNS_SECTION_SOLAR_SPECIFIC_YIELD,
  COLUMNS_SECTION_STORAGE,
  INITIAL_TABLET_COLUMNS,
} from '../../constants/siteColumns';
import { setSelectedColumns } from '../../store/sites/actions';
import { AnalyticsEvent, AnalyticsProperty } from '../../types/Analytics';
import type { TableColumn } from '../../types/Table';
import { getUnitTitle } from '../../utils';
import { Button } from '../button';
import { DrawerSection, DrawerSectionContainer, TopDrawer } from '../drawer';
import { PWRcheckbox } from '../pwrCheckbox';

const columnsSectionsList = [
  COLUMNS_SECTION_SITE_INFO,
  COLUMNS_SECTION_CONSUMPTION,
  COLUMNS_SECTION_SOLAR_ABSOLUTE_YIELD,
  COLUMNS_SECTION_SOLAR_SPECIFIC_YIELD,
  COLUMNS_SECTION_SOLAR_PERFORMANCE_RATIO,
  COLUMNS_SECTION_STORAGE,
  COLUMNS_SECTION_AGGREGATE_METRICS,
  COLUMNS_SECTION_INVERTER_ENERGY,
];

interface Props {
  initialColumns: string[];
  className?: string;
}

export const ManageColumns = ({ initialColumns, className }: Props) => {
  const dispatch = useDispatch();
  const [isOpen, toggleDrawer] = useState(false);
  const [columns, setColumns] = useState(initialColumns);

  const onCancel = useCallback(() => {
    toggleDrawer(false);
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(setSelectedColumns(columns));
    // [analytics] Site List Columns Update
    trackEvent(AnalyticsEvent.SiteListColumnsUpdate, {
      [AnalyticsProperty.TableColumns]: columns,
    });
    toggleDrawer(false);
  }, [dispatch, columns]);

  const onRestoreDefaults = useCallback(() => {
    // @todo should this eventually use view mode to determine
    // which initial columns to set?
    dispatch(setSelectedColumns(INITIAL_TABLET_COLUMNS));
    setColumns(INITIAL_TABLET_COLUMNS);
    // [analytics] Site List Columns Update
    trackEvent(AnalyticsEvent.SiteListColumnsUpdate, {
      [AnalyticsProperty.TableColumns]: INITIAL_TABLET_COLUMNS,
    });
    toggleDrawer(false);
  }, [dispatch]);

  const setValue = useCallback(
    (column, isChecked) => {
      if (isChecked) {
        setColumns([...columns, column]);
      } else {
        setColumns(columns.filter((c) => c !== column));
      }
    },
    [columns],
  );

  const ActionButtons = () => (
    <>
      <Button variant="text" color="inherit" size="small" onClick={onRestoreDefaults}>
        Restore defaults
      </Button>
      <Button size="small" onClick={onConfirm}>
        Apply
      </Button>
    </>
  );

  return (
    <>
      <TopDrawer
        title="Manage columns"
        onClose={onCancel}
        isOpen={isOpen}
        actionButtons={<ActionButtons />}
      >
        <DrawerSectionContainer $column>
          {columnsSectionsList.map((s) => {
            const section = COLUMNS[s];
            if (!section) {
              return null;
            }
            return (
              <DrawerSection title={section.title} key={`section_${s}`}>
                {section.items.map((col: TableColumn) => {
                  const value = !!columns.find((n) => col.name === n);

                  return (
                    <PWRcheckbox
                      key={col.name}
                      label={
                        <Box>
                          <Typography
                            component="span"
                            sx={{ fontSize: '12px', fontWeight: 'bold' }}
                          >
                            {col.title.toUpperCase()}
                          </Typography>
                          {col.unit && (
                            <Typography
                              component="span"
                              sx={{ fontSize: '11px' }}
                            >{` (${getUnitTitle(col)})`}</Typography>
                          )}
                        </Box>
                      }
                      checked={value}
                      onChange={(e) => setValue(col.name, e.target.checked)}
                    />
                  );
                })}
              </DrawerSection>
            );
          })}
        </DrawerSectionContainer>
      </TopDrawer>
      <Button
        className={className}
        color="secondary"
        size="small"
        chevron
        onClick={() => toggleDrawer(!isOpen)}
      >
        Manage columns
      </Button>
    </>
  );
};
