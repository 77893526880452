import { Box } from '@mui/material';
import { useField } from 'formik';
import type { ChangeEvent, FocusEvent } from 'react';

import type { PWRinputProps } from '../pwrInput';
import { PWRinput } from '../pwrInput';

interface FormInputProps extends PWRinputProps {
  disableMinHeight?: boolean;
  hideHelperText?: boolean;
  trimOnBlur?: boolean;
}

export const FormInput = ({
  name,
  label,
  onChange,
  onBlur,
  helperText,
  sanitize = true,
  disableMinHeight = false,
  hideHelperText = false,
  trimOnBlur = false,
  error = false,
  ...pwrInputProps
}: FormInputProps) => {
  const [field, meta, helpers] = useField(name);
  const hasError = (!!meta.touched && !!meta.error) || error;
  const errorMessage = meta.error || helperText;
  const text = hideHelperText ? undefined : hasError ? errorMessage : helperText;

  const handleChange = onChange
    ? (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        field.onChange(e);
        onChange(e);
      }
    : field.onChange;

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
    field.onBlur(e);

    if (trimOnBlur) {
      helpers.setValue(e.target.value.trim());
    }
  };

  return (
    <Box sx={{ minHeight: disableMinHeight ? 'none' : '96px' }}>
      <PWRinput
        {...field}
        label={label}
        error={hasError}
        helperText={text}
        sanitize={sanitize}
        {...pwrInputProps}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Box>
  );
};
