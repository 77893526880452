import { createSelector } from '@reduxjs/toolkit';

import { UserRoleDefinitions as userRoles } from '../../constants/userRoles';
import type { UIFeature } from '../../types/UIFeature';
import { UserInterfaceType } from '../../types/UserInterfaceType';
import type { UserPermission } from '../../types/UserPermission';
import type { UserRole } from '../../types/UserRole';
import type { RootState } from '../types';

export const userIdSelector = (state: RootState): string => state.auth.userId || '';
export const userCompanyIdSelector = (state: RootState): string => state.auth.companyId || '';
export const userRoleSelector = (state: RootState): UserRole => state.auth.userRole;
export const userInterfaceTypeSelector = (state: RootState): UserInterfaceType =>
  state.auth.userInterfaceType;
export const userPermissionsSelector = (state: RootState): UserPermission[] =>
  state.auth.userPermissions;
export const uiFeaturesSelector = (state: RootState): UIFeature[] => state.auth.uiFeatures;
export const isLoggedInSelector = (state: RootState): boolean => state.auth.isLoggedIn;
export const isAuthenticatingSelector = (state: RootState): boolean => state.auth.isAuthenticating;

// --

export const isSupportModeSelector = (state: RootState): boolean => {
  return state.auth.userInterfaceType === UserInterfaceType.SUPPORT;
};

export const userRoleNameSelector = createSelector(
  userRoleSelector,
  (userRole: UserRole) => userRoles[userRole]?.name || 'Unknown',
);
