import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableRow as MuiTableRow,
} from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { get } from 'lodash';
import type { MouseEvent, PropsWithChildren, ReactElement, Ref } from 'react';
import React, { forwardRef, Fragment, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';

import { down, getColor } from '../../styles';
import type {
  BaseRow,
  PresenterComponentType,
  PresenterFunctionType,
  PresenterProps,
  PresenterType,
  TableColumn,
  TablePresenters,
} from '../../types/Table';
import { Align } from '../../types/Table';
import { SortOrder } from '../../utils/sort';
import { Highlighter } from '../highlighter';
import { TableHead } from './tableHead';

const StyledTable = styled(MuiTable)`
  position: relative;
`;

const StyledTableBody = styled(MuiTableBody)`
  background-color: ${getColor('OFF_WHITE')};
`;

export const StyledTableRow = styled(MuiTableRow)<{ hover?: boolean }>`
  background-color: white;
  th,
  td {
    border: unset;
  }
  cursor: ${({ hover }) => (hover ? 'pointer' : 'default')};
`;

export const StyledTableCell = styled(MuiTableCell)`
  height: 48px;
  padding: 0 12px;
  color: ${getColor('BLACK')};
  font-weight: 600;
  font-size: 16px;

  ${down('xs')} {
    padding: 8px !important;
  }
`;

const StyledSpacer = styled('tr')`
  background-color: ${getColor('OFF_WHITE')};
  height: 4px;
`;

export interface TableProps<R> {
  id?: string;
  columns: TableColumn[];
  rows: R[];
  order?: SortOrder;
  orderBy?: string;
  presenters?: TablePresenters<R>;
  onRowClick?: (e: MouseEvent<any>, row: R) => void;
  onRequestSort?: (property: string) => void;
  style?: Record<string, unknown>;
  headInline?: boolean;
  highlightedText?: string;
  onEndReached?: () => void;
  rowIdExtractor?: (row: R) => string;
  sx?: SxProps;
}

type PresenterWrapperProps<R> = PresenterProps<R, keyof R> & {
  Presenter?: PresenterType<R>;
  highlightedText?: string;
};

const PresenterComponent = <R,>({
  value,
  row,
  Presenter,
  highlightedText,
}: PresenterWrapperProps<R>) => {
  if (typeof Presenter !== 'function' && (Presenter as PresenterComponentType<R>)?.Component) {
    const Component = (Presenter as PresenterComponentType<R>).Component;
    return <Component value={value} row={row} />;
  }
  return (
    <>
      {(Presenter as PresenterFunctionType)?.(value, row) ?? (
        <Highlighter textToHighlight={highlightedText}>{value}</Highlighter>
      )}
    </>
  );
};

const TableWithoutRef = <R,>(
  {
    id = 'table',
    columns,
    rows,
    order = SortOrder.ASC,
    orderBy,
    presenters,
    onRowClick,
    onRequestSort,
    style,
    children,
    headInline,
    highlightedText,
    onEndReached,
    rowIdExtractor,
    sx,
    ...props
  }: PropsWithChildren<TableProps<R>>,
  ref?: Ref<HTMLDivElement>,
) => {
  const [dense] = React.useState(false);

  const [initialColumns, setInitialColumns] = React.useState(columns);

  useEffect(() => {
    setInitialColumns(columns);
  }, [columns]);

  return (
    <MuiTableContainer ref={ref} style={style} sx={sx} {...props}>
      <div id="back-to-top-anchor" />
      <StyledTable
        stickyHeader
        aria-labelledby="tableTitle"
        size={dense ? 'small' : 'medium'}
        aria-label="enhanced table"
      >
        <TableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          columns={initialColumns}
          inline={headInline}
        />
        <StyledTableBody>
          {rows.map((row, index) => (
            <Fragment key={`row_${index}`}>
              <StyledTableRow
                id={rowIdExtractor?.(row)}
                hover={!!onRowClick}
                onClick={(e) => onRowClick?.(e, row)}
                data-test-id={`table-row-${index}`}
              >
                {initialColumns.map((column: TableColumn) => {
                  const { name, property, align = Align.LEFT } = column;
                  let value: any = get(row, property);

                  if (value == null) {
                    value = '--';
                  } else if (column.unit) {
                    value = column.unit.formatter(value);
                  }

                  return (
                    <StyledTableCell
                      data-test-id={`table-cell-${name}-${index}`}
                      key={`${id}_row_${index}_${name}_${property}`}
                      align={align}
                    >
                      <PresenterComponent
                        highlightedText={highlightedText}
                        value={value}
                        row={row}
                        Presenter={presenters?.[name]}
                      />
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
              <StyledSpacer>
                <td key={`row_${index}_spacer`} colSpan={initialColumns.length}>
                  {onEndReached && index === rows.length - 1 ? (
                    <Waypoint bottomOffset="-30%" onEnter={() => onEndReached()} />
                  ) : null}
                </td>
              </StyledSpacer>
            </Fragment>
          ))}
        </StyledTableBody>
      </StyledTable>
      {children}
    </MuiTableContainer>
  );
};

export const Table = forwardRef(TableWithoutRef) as <R extends BaseRow>(
  p: PropsWithChildren<TableProps<R>> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
