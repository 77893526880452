import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import React from 'react';

interface FormItemProps {
  title?: string;
  input?: boolean;
  children: ReactNode;
}

const StyledFormItemTitle = styled(Typography)`
  width: 140px;
  margin: 0;
`;

export const FormItem = (props: FormItemProps) => {
  const { title, input, children } = props;

  return (
    <Box display="flex" my={input ? 3 : 2}>
      {title && <StyledFormItemTitle color="textSecondary">{title}:</StyledFormItemTitle>}
      {children}
    </Box>
  );
};
