import { CalendarToday as CalendarTodayIcon } from '@mui/icons-material';
import type { SxProps } from '@mui/material';
import { Box, FormControl, Input, Popover, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import COUNTRIES from '../../constants/countries';
import { setSelectedFilters } from '../../store/sites/actions';
import type { TimezonelessDate } from '../../types/Date';
import type { SiteFilter } from '../../types/SiteFilter';
import {
  CityFilter,
  CountryFilter,
  InstallDateFromFilter,
  InstallDateToFilter,
  StateFilter,
  ZipFilter,
} from '../../types/SiteFilter';
import { Button } from '../button';
import { DatePicker } from '../datePicker';
import { DrawerSection, DrawerSectionContainer, TopDrawer } from '../drawer';
import { Select } from '../select';

const boxSx: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 3,
  pt: 1,
  pb: 2,
};

interface SingleDatePickerProps {
  type: string;
  dateFilter: SiteFilter | undefined;
  updateFilter: (date: DateTime) => void;
}

const SingleDatePicker = ({ type, dateFilter, updateFilter }: SingleDatePickerProps) => {
  const [dateToPickerAnchor, setDateToAnchorEl] = useState<Element | null>(null);

  const handleDateRangeSelect = (date: TimezonelessDate) => {
    const dateTime = DateTime.fromObject(date);

    updateFilter(dateTime);
    setDateToAnchorEl(null);
  };

  return (
    <>
      <Input
        id={`filter-date-${type}-input`}
        readOnly
        fullWidth
        value={!!dateFilter ? dateFilter?.value?.toLocaleString() : type}
        onClick={(event) => setDateToAnchorEl(event.currentTarget)}
        endAdornment={<CalendarTodayIcon sx={{ fontSize: '22px' }} />}
        sx={{ cursor: 'pointer' }}
        inputProps={{ style: { cursor: 'pointer' } }}
      />
      <Popover
        open={!!dateToPickerAnchor}
        anchorEl={dateToPickerAnchor}
        onClose={() => setDateToAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box p={4}>
          <DatePicker
            initialStartDateIso={new Date(new Date().setHours(0, 0, 0, 0)).toISOString()}
            initialEndDateIso={new Date(new Date().setHours(23, 59, 59, 999)).toISOString()}
            handleSelection={handleDateRangeSelect}
            handleCancel={() => setDateToAnchorEl(null)}
            selectRange={false}
          />
        </Box>
      </Popover>
    </>
  );
};

interface Props {
  initialFilters: SiteFilter[];
  className?: string;
}

export const ManageFilters = ({ initialFilters, className }: Props) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState(initialFilters);

  const countryFilter = filters.find((f) => f.id === CountryFilter.id);
  const stateFilter = filters.find((f) => f.id === StateFilter.id);
  const cityFilter = filters.find((f) => f.id === CityFilter.id);
  const zipFilter = filters.find((f) => f.id === ZipFilter.id);
  const dateFromFilter = filters.find((f) => f.id === InstallDateFromFilter.id);
  const dateToFilter = filters.find((f) => f.id === InstallDateToFilter.id);
  const filteredStates = COUNTRIES.find((country) => country.value === countryFilter?.value || '');
  const stateOptions = filteredStates ? filteredStates.states : [];

  const handleCancel = () => {
    // sets any unapplied state back to initial filters state
    setFilters(initialFilters);
    setIsOpen(false);
  };

  const handleConfirm = () => {
    dispatch(setSelectedFilters(filters));
    setIsOpen(false);
  };

  const handleClearAll = () => {
    dispatch(setSelectedFilters([]));
    setIsOpen(false);
  };

  const updateFilterValue = (filter: SiteFilter, value: any) => {
    if (!value) {
      return setFilters((prevFilters) => prevFilters.filter((f) => f.id !== filter.id)); // remove filter
    }

    const isExistingFilter = !!filters.find((f) => f.id === filter.id);

    if (isExistingFilter) {
      return setFilters((prevFilters) =>
        prevFilters.map((f) => {
          if (f.id === filter.id) {
            return {
              ...f,
              value,
            };
          }
          return f;
        }),
      );
    }

    return setFilters((prevFilters) => [...prevFilters, { ...filter, value }]);
  };

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  return (
    <>
      <TopDrawer
        title="Filter by"
        onClose={handleCancel}
        isOpen={isOpen}
        actionButtons={
          <>
            <Button variant="text" color="inherit" size="small" onClick={handleClearAll}>
              Clear all
            </Button>
            <Button color="primary" size="small" onClick={handleConfirm}>
              Apply
            </Button>
          </>
        }
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DrawerSectionContainer $column>
            <DrawerSection title="Geographic location">
              <Box sx={boxSx}>
                <FormControl fullWidth>
                  <Select
                    id={CountryFilter.id}
                    value={countryFilter?.value || ''}
                    options={[{ value: '', label: <em>None</em> }, ...COUNTRIES]}
                    label={CountryFilter.title}
                    onChange={(e) => updateFilterValue(CountryFilter, e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Select
                    id={StateFilter.id}
                    value={stateFilter?.value || ''}
                    options={[{ value: '', label: <em>None</em> }, ...stateOptions]}
                    label={StateFilter.title}
                    onChange={(e) => updateFilterValue(StateFilter, e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box sx={boxSx}>
                <TextField
                  variant="standard"
                  value={cityFilter?.value || ''}
                  label={CityFilter.title}
                  onChange={(e) => updateFilterValue(CityFilter, e.target.value)}
                  fullWidth
                />
                <TextField
                  variant="standard"
                  value={zipFilter?.value || ''}
                  label={ZipFilter.title}
                  onChange={(e) => updateFilterValue(ZipFilter, e.target.value)}
                  fullWidth
                />
              </Box>
            </DrawerSection>
            <DrawerSection title="Date installed">
              <Box sx={boxSx}>
                <SingleDatePicker
                  type="From"
                  dateFilter={dateFromFilter}
                  updateFilter={(date: DateTime) => updateFilterValue(InstallDateFromFilter, date)}
                />
                <SingleDatePicker
                  type="To"
                  dateFilter={dateToFilter}
                  updateFilter={(date: DateTime) => updateFilterValue(InstallDateToFilter, date)}
                />
              </Box>
            </DrawerSection>
          </DrawerSectionContainer>
        </Box>
      </TopDrawer>
      <Button
        className={className}
        color="secondary"
        size="small"
        chevron
        onClick={() => setIsOpen(!isOpen)}
      >
        Filter by
      </Button>
    </>
  );
};
