import { styled } from '@mui/material/styles';

import { getColor } from '../../styles';
import { Modal } from './modal';

export const InputModal = styled(Modal)`
  & .MuiPaper-root {
    max-width: 810px !important;
    border-radius: 8px !important;
    overflow: hidden;
    margin: 60px auto;
    max-height: none;
    height: fit-content;
    box-shadow: 0px 8px 40px ${getColor('LIGHT_GREY', 0.5)} !important;
  }
  & .MuiDialog-container {
    display: flex !important;
  }
`;
