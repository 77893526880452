import { createAction, createAsyncAction } from 'typesafe-actions';

import type {
  Company,
  CompanyId,
  UpdateCompanyFailedResponse,
  UpdateCompanyRequest,
} from '../../types/Company';
import type { Fleet } from '../../types/Fleet';

export { loggedOut } from '../auth/actions';

interface LoadCompanyFleetsError {
  companyId: string;
  error: Error;
}

export const loadCompanyFleets = createAsyncAction(
  [
    'LOAD_COMPANY_FLEETS_REQUEST',
    (companyId: string, companyName?: string) => ({ companyId, companyName }),
  ],
  ['LOAD_COMPANY_FLEETS_SUCCESS', (fleet: Fleet[]) => fleet],
  ['LOAD_COMPANY_FLEETS_FAILURE', (err: LoadCompanyFleetsError) => err],
)();

export const loadCompany = createAsyncAction(
  'LOAD_COMPANY_REQUEST',
  'LOAD_COMPANY_SUCCESS',
  'LOAD_COMPANY_FAILURE',
)<string, Company, Error>();

export const loadUserCompanies = createAsyncAction(
  'LOAD_USER_COMPANIES_REQUEST',
  'LOAD_USER_COMPANIES_SUCCESS',
  'LOAD_USER_COMPANIES_FAILURE',
)<string, CompanyId[], Error>();

// clearCompany is used to clear the current company state when in CSM mode
// and navigating between different companies/fleets
export const clearCompany = createAction('CLEAR_COMPANY')();

export const updateCompany = createAsyncAction(
  'UPDATE_COMPANY_REQUEST',
  'UPDATE_COMPANY_SUCCESS',
  'UPDATE_COMPANY_FAILURE',
)<UpdateCompanyRequest, { companyId: string }, UpdateCompanyFailedResponse>();

export const setCompanyId = createAction('SET_COMPANY_ID')<string>();
