import { Box } from '@mui/material';
import type { ComponentProps } from 'react';
import React from 'react';

const listStyle = { listStyleType: 'none' };

type Props = ComponentProps<typeof Box>;

export const SimpleList = (props: Props) => (
  <Box component="ul" pl={2} style={listStyle} {...props} />
);
