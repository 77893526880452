import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type {
  AlertCompanyInfo,
  AlertDetails,
  AlertInstanceHistory,
  AlertInstanceHistoryFailedResponse,
  AlertTypeInfo,
} from '../../types/Alert';
import type { DeviceEvent, DeviceEventLogFailedResponse } from '../../types/DeviceEvent';
import * as actions from './actions';

const DEFAULT_ALERT_HISTORY_PER_PAGE = 10;
const DEFAULT_ALERT_EVENT_LOGS_PER_PAGE = 10;
export interface AlertDetailsState {
  alertDetails: AlertDetails | null;
  alertTypeInfo: AlertTypeInfo | null;
  alertCompanyInfo: AlertCompanyInfo | null;
  alertHistoryItems: AlertInstanceHistory[] | null;
  alertEventLogItems: DeviceEvent[] | null;
  loadingAlertDetails: boolean;
  loadingAlertTypeInfo: boolean;
  loadingCompanyInfo: boolean;
  loadingAlertHistory: boolean;
  loadingAlertEventLog: boolean;
  alertDetailsError: string | null;
  alertTypeInfoError: string | null;
  alertCompanyInfoError: string | null;
  alertHistoryError: AlertInstanceHistoryFailedResponse | null;
  alertHistoryPage: number;
  alertHistoryPerPage: number;
  alertHistoryTotalResults: number;
  alertEventLogError: DeviceEventLogFailedResponse | null;
  alertEventLogPage: number;
  alertEventLogPerPage: number;
  alertEventLogTotalResults: number;
}

const INITIAL_STATE: AlertDetailsState = {
  alertDetails: null,
  alertTypeInfo: null,
  alertCompanyInfo: null,
  alertHistoryItems: null,
  alertEventLogItems: null,
  loadingAlertDetails: false,
  loadingAlertTypeInfo: false,
  loadingCompanyInfo: false,
  loadingAlertHistory: false,
  loadingAlertEventLog: false,
  alertDetailsError: null,
  alertTypeInfoError: null,
  alertCompanyInfoError: null,
  alertHistoryError: null,
  alertHistoryPage: 1,
  alertHistoryPerPage: DEFAULT_ALERT_HISTORY_PER_PAGE,
  alertHistoryTotalResults: 0,
  alertEventLogError: null,
  alertEventLogPage: 1,
  alertEventLogPerPage: DEFAULT_ALERT_EVENT_LOGS_PER_PAGE,
  alertEventLogTotalResults: 0,
};

export type AlertDetailsActions = ActionType<typeof actions>;

export default createReducer<AlertDetailsState, AlertDetailsActions>(INITIAL_STATE, {
  [getType(actions.loadAlertDetails.request)]: produce((state: AlertDetailsState) => {
    state.loadingAlertDetails = true;
    state.alertDetailsError = null;
  }),
  [getType(actions.loadAlertDetails.success)]: produce(
    (state: AlertDetailsState, action: ReturnType<typeof actions.loadAlertDetails.success>) => {
      state.alertDetails = { ...state.alertDetails, ...action.payload };
      state.loadingAlertDetails = false;
    },
  ),
  [getType(actions.loadAlertDetails.failure)]: produce(
    (state: AlertDetailsState, action: ReturnType<typeof actions.loadAlertDetails.failure>) => {
      state.loadingAlertDetails = false;
      state.alertDetailsError = action.payload.message;
    },
  ),
  [getType(actions.loadAlertTypeInfo.request)]: produce((state: AlertDetailsState) => {
    state.loadingAlertTypeInfo = true;
    state.alertTypeInfoError = null;
  }),
  [getType(actions.loadAlertTypeInfo.success)]: produce(
    (state: AlertDetailsState, action: ReturnType<typeof actions.loadAlertTypeInfo.success>) => {
      state.alertTypeInfo = action.payload;
      state.loadingAlertTypeInfo = false;
    },
  ),
  [getType(actions.loadAlertTypeInfo.failure)]: produce(
    (state: AlertDetailsState, action: ReturnType<typeof actions.loadAlertTypeInfo.failure>) => {
      state.loadingAlertTypeInfo = false;
      state.alertTypeInfoError = action.payload.message;
    },
  ),
  [getType(actions.loadAlertCompanyInfo.request)]: produce((state: AlertDetailsState) => {
    state.loadingCompanyInfo = true;
    state.alertCompanyInfoError = null;
  }),
  [getType(actions.loadAlertCompanyInfo.success)]: produce(
    (state: AlertDetailsState, action: ReturnType<typeof actions.loadAlertCompanyInfo.success>) => {
      state.alertCompanyInfo = action.payload;
      state.loadingCompanyInfo = false;
    },
  ),
  [getType(actions.loadAlertCompanyInfo.failure)]: produce(
    (state: AlertDetailsState, action: ReturnType<typeof actions.loadAlertCompanyInfo.failure>) => {
      state.loadingCompanyInfo = false;
      state.alertCompanyInfoError = action.payload.message;
    },
  ),
  [getType(actions.resetAlertDetailsState)]: produce((state: AlertDetailsState) => {
    state.alertDetails = null;
    state.alertDetailsError = null;
    state.loadingAlertDetails = false;
    state.alertTypeInfo = null;
    state.alertTypeInfoError = null;
    state.loadingAlertTypeInfo = false;
  }),
  [getType(actions.resetAlertCompanyInfoState)]: produce((state: AlertDetailsState) => {
    state.alertCompanyInfo = null;
    state.alertCompanyInfoError = null;
    state.loadingCompanyInfo = false;
  }),
  [getType(actions.resetAlertHistoryState)]: produce((state: AlertDetailsState) => {
    state.alertHistoryItems = null;
    state.alertHistoryError = null;
    state.loadingAlertHistory = false;
    state.alertHistoryPage = 1;
    state.alertHistoryPerPage = DEFAULT_ALERT_HISTORY_PER_PAGE;
    state.alertHistoryTotalResults = 0;
  }),
  [getType(actions.resetAlertEventLogState)]: produce((state: AlertDetailsState) => {
    state.alertEventLogItems = null;
    state.alertEventLogError = null;
    state.loadingAlertEventLog = false;
    state.alertEventLogPage = 1;
    state.alertEventLogPerPage = DEFAULT_ALERT_EVENT_LOGS_PER_PAGE;
    state.alertEventLogTotalResults = 0;
  }),
  [getType(actions.loadAlertHistory.request)]: produce((state: AlertDetailsState) => {
    state.loadingAlertHistory = true;
    state.alertHistoryError = null;
  }),
  [getType(actions.loadAlertHistory.success)]: produce(
    (state: AlertDetailsState, action: ReturnType<typeof actions.loadAlertHistory.success>) => {
      const { alerts, page, perPage, totalResults } = action.payload;
      state.alertHistoryItems = alerts;
      state.alertHistoryPage = page;
      state.alertHistoryPerPage = perPage;
      state.alertHistoryTotalResults = totalResults;
      state.loadingAlertHistory = false;
    },
  ),
  [getType(actions.loadAlertHistory.failure)]: produce(
    (state: AlertDetailsState, action: ReturnType<typeof actions.loadAlertHistory.failure>) => {
      state.loadingAlertHistory = false;
      state.alertHistoryError = action.payload;
    },
  ),
  [getType(actions.loadAlertEventLog.request)]: produce((state: AlertDetailsState) => {
    state.loadingAlertEventLog = true;
    state.alertEventLogError = null;
  }),
  [getType(actions.loadAlertEventLog.success)]: produce(
    (state: AlertDetailsState, action: ReturnType<typeof actions.loadAlertEventLog.success>) => {
      const { deviceStateLog, page, perPage, totalResults } = action.payload;
      state.alertEventLogItems = deviceStateLog;
      state.alertEventLogPage = page;
      state.alertEventLogPerPage = perPage;
      state.alertEventLogTotalResults = totalResults;
      state.loadingAlertEventLog = false;
    },
  ),
  [getType(actions.loadAlertEventLog.failure)]: produce(
    (state: AlertDetailsState, action: ReturnType<typeof actions.loadAlertEventLog.failure>) => {
      state.loadingAlertEventLog = false;
      state.alertEventLogError = action.payload;
    },
  ),
});
