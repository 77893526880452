import type { Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getAlertDetails,
  getAlertHistory,
  getAlertTypeInfoFromUrl,
  getDeviceEventLog,
  getSiteAssociations,
} from '../../api';
import { LOADING_ERROR_MESSAGE } from '../../constants/dataLoadingMessages';
import type {
  AlertCompanyInfoSuccessResponse,
  AlertDetailsSuccessResponse,
  AlertInstanceHistorySuccessResponse,
  AlertTypeInfoSuccessResponse,
} from '../../types/Alert';
import { CompanyAccessLevel } from '../../types/CompanyAccessLevel';
import type { DeviceEventLogSuccessResponse } from '../../types/DeviceEvent';
import { Alert } from '../../types/ScreenAlerts';
import type { SiteAssociationsSuccessResponse } from '../../types/SiteAssociation';
import { customAlert } from '../../utils/alerts';
import {
  loadAlertCompanyInfo,
  loadAlertDetails,
  loadAlertEventLog,
  loadAlertHistory,
  loadAlertTypeInfo,
} from './actions';

const showError = () =>
  customAlert(
    {
      title: LOADING_ERROR_MESSAGE,
      closeButton: true,
    },
    { type: 'error', toastId: Alert.SiteAlertDetailsError },
  );

const loadAlertDetailsEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(loadAlertDetails.request)),
    mergeMap((action) => {
      return from(getAlertDetails(action.payload)).pipe(
        mergeMap((data) => {
          const { alertTypeInfoUrl } = data as AlertDetailsSuccessResponse;
          return of(
            loadAlertDetails.success(data as AlertDetailsSuccessResponse),
            loadAlertTypeInfo.request(alertTypeInfoUrl),
          );
        }),
        catchError((e) => {
          showError();
          return of(loadAlertDetails.failure(e));
        }),
      );
    }),
  );

const loadAlertTypeInfoEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(loadAlertTypeInfo.request)),
    mergeMap((action) => {
      return from(getAlertTypeInfoFromUrl(action.payload)).pipe(
        map((data) => loadAlertTypeInfo.success(data as AlertTypeInfoSuccessResponse)),
        catchError((e) => {
          showError();
          return of(loadAlertTypeInfo.failure(e));
        }),
      );
    }),
  );

const loadAlertCompanyInfoEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(loadAlertCompanyInfo.request)),
    mergeMap((action) => {
      return from(getSiteAssociations(action.payload.siteId)).pipe(
        map((data) => {
          const { siteAccessDetailsList, registeringCompanyName } =
            data as SiteAssociationsSuccessResponse;
          const ownerCompany = siteAccessDetailsList.find(
            (association) => association.accessLevel === CompanyAccessLevel.OWNER,
          );
          const companyInfo = {
            installerCompanyName: ownerCompany?.companyName,
            ownerCompanyName: registeringCompanyName,
          };
          return loadAlertCompanyInfo.success(companyInfo as AlertCompanyInfoSuccessResponse);
        }),
        catchError((e) => {
          showError();
          return of(loadAlertCompanyInfo.failure(e));
        }),
      );
    }),
  );

const loadAlertHistoryEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(loadAlertHistory.request)),
    mergeMap((action) =>
      from(getAlertHistory(action.payload)).pipe(
        map((res) => loadAlertHistory.success(res as AlertInstanceHistorySuccessResponse)),
        catchError((e) => {
          showError();
          return of(loadAlertHistory.failure(e));
        }),
      ),
    ),
  );

const loadAlertEventLogEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(loadAlertEventLog.request)),
    mergeMap((action) =>
      from(getDeviceEventLog(action.payload)).pipe(
        map((res) => loadAlertEventLog.success(res as DeviceEventLogSuccessResponse)),
        catchError((e) => of(loadAlertEventLog.failure(e))),
      ),
    ),
  );

export default combineEpics(
  loadAlertDetailsEpic,
  loadAlertCompanyInfoEpic,
  loadAlertTypeInfoEpic,
  loadAlertHistoryEpic,
  loadAlertEventLogEpic,
);
