import type { DateTime } from 'luxon';
import type { ActionsObservable, Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import type { Observable } from 'rxjs';
import { EMPTY, from, of } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';
import type { Action } from 'typesafe-actions';
import { isActionOf } from 'typesafe-actions';

import { createSiteListExport as apiCreateSiteListExport, getFleetSites } from '../../api';
import type {
  SiteListExportErrorResponse,
  SiteListExportSuccessResponse,
  SiteQueryParams,
} from '../../types/Site';
import type { SiteFilter } from '../../types/SiteFilter';
import {
  InstallDateFromFilter,
  InstallDateToFilter,
  SensorInstallDateFilter,
} from '../../types/SiteFilter';
import { customAlert } from '../../utils/alerts';
import type { RootAction } from '..';
import { companyIdSelector, fleetIdSelector } from '../company/selectors';
import type { RootState } from '../types';
import type { SitesState } from '.';
import { createSiteListExport, loadFleetSites, setSelectedFilters, setSitesSort } from './actions';
import { sitesPerPageSelector } from './selectors';

type EpicType = Epic<RootAction, RootAction, RootState>;

const formatQueryParams = (sitesState: SitesState): SiteQueryParams => {
  const { selectedSortProperty, selectedSortOrder, selectedFilters } = sitesState;

  let queryParams = {} as SiteQueryParams;

  if (selectedSortProperty) {
    const propertyArray = selectedSortProperty.split('.');
    const lastElement = propertyArray[propertyArray.length - 1];
    queryParams.sort = `${lastElement}:${selectedSortOrder}`;
  }

  if (selectedFilters.length) {
    // @todo figure out why prettier won't allow me to assign "let" here
    const filterParams = {} as SiteQueryParams;
    selectedFilters.forEach(({ id, value }: SiteFilter) => {
      let formattedId = id;
      let formattedValue = value;

      // @todo clean up this ugly if/else and SiteFilters if
      // BE needs the FE to utilize new lt: gt: formatting elsewhere
      if (formattedId === InstallDateFromFilter.id) {
        // set dates to 00:00:00 of the date selected
        const startOfFromDate: DateTime = value.startOf('day');
        const fromISOString = startOfFromDate.toISO();
        formattedValue = `gt:${fromISOString}`;
        formattedId = SensorInstallDateFilter.id;
      } else if (id === InstallDateToFilter.id) {
        // @todo investigate why this is different from formattedId in the prior if
        // set dates to 23:59:59 of the date selected
        const endOfToDate: DateTime = value.endOf('day');
        const toISOString = endOfToDate.toISO();
        formattedValue = `lt:${toISOString}`;
        formattedId = SensorInstallDateFilter.id;
      }

      // handle csv of multiple values: capabilities: "pvl,solar"
      if (filterParams[formattedId]) {
        formattedValue = `${filterParams[formattedId]},${formattedValue}`;
      }

      filterParams[formattedId] = formattedValue;
    });
    queryParams = Object.assign(queryParams, filterParams);
  }

  return queryParams;
};

const loadFleetSitesEpic: EpicType = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadFleetSites.request)),
    mergeMap((action) => {
      const queryParams: SiteQueryParams = formatQueryParams(state$.value.sites);
      const perPage = sitesPerPageSelector(state$.value);
      return from(getFleetSites({ perPage, ...action.payload, ...queryParams })).pipe(
        map(loadFleetSites.success),
        catchError(async (e) => loadFleetSites.failure(e)),
      );
    }),
  );

const queryFleetSitesEpic: EpicType = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([setSitesSort, setSelectedFilters])),
    mergeMap(() => {
      const fleetId = fleetIdSelector(state$.value);
      const companyId = companyIdSelector(state$.value);
      if (fleetId) {
        return of(loadFleetSites.request({ fleetId, companyId, page: 1 }));
      }
      return EMPTY;
    }),
  );

const createSiteListExportEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(createSiteListExport.request)),
    mergeMap((action): Observable<Action> => {
      const queryParams: SiteQueryParams = formatQueryParams(state$.value.sites);
      return from(apiCreateSiteListExport(action.payload.companyId, queryParams)).pipe(
        map((a) => createSiteListExport.success(a as SiteListExportSuccessResponse)),
        catchError(async (error) => createSiteListExport.failure(error)),
      );
    }),
  );
};

const afterCreateSiteListExportEpic: EpicType = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([createSiteListExport.success, createSiteListExport.failure])),
    map((response) => {
      const companyName = state$.value.company.name;
      const isError = isActionOf(createSiteListExport.failure)(response);
      const type = isError ? 'error' : 'success';
      let title;

      if (isError) {
        const payload = response.payload as SiteListExportErrorResponse;
        const statusCode = payload.response?.data.status;
        if (statusCode === 423) {
          title = `There is already an export in progress for ${companyName}. You will be able to retry after it has completed.`;
        } else {
          title = 'There has been an error exporting the site list. Please try again.';
        }
      } else {
        const payload = response.payload as SiteListExportSuccessResponse;
        title = `CSV file will be sent to ${payload.emailSentTo} when available. This could take up to an hour.`;
      }

      customAlert({ title }, { type });
    }),
    ignoreElements(),
  );

export default combineEpics(
  loadFleetSitesEpic,
  queryFleetSitesEpic,
  createSiteListExportEpic,
  afterCreateSiteListExportEpic,
);
