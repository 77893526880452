/*
 * Analytics Service by ms clarity
 *
 * @see https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api
 */

import Clarity from '@microsoft/clarity';

const projectId = process.env.REACT_APP_CLARITY_PROJECT_ID;

export const init = () => {
  if (!projectId) {
    // eslint-disable-next-line no-console
    console.error('Clarity project ID is missing');
    return;
  }

  Clarity.init(projectId);
};
