/* eslint-disable @typescript-eslint/naming-convention */
import type { ActionsObservable, Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import type { Observable } from 'rxjs';
import { EMPTY, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import type { Action } from 'typesafe-actions';
import { isActionOf } from 'typesafe-actions';

import {
  identify as analyticsClientIdentify,
  register as analyticsClientRegister,
  reset as analyticsClientReset,
  trackEvent as analyticsClientTrackEvent,
} from '../../analyticsClient';
import { updateAccessToken } from '../../api';
import { setAccessToken } from '../../api/token';
import { identify as messagingClientIdentify } from '../../messagingClient';
import { AnalyticsEvent, AnalyticsProperty } from '../../types/Analytics';
import type { AuthRefreshTokenResponse } from '../../types/Auth';
import { UserInterfaceType } from '../../types/UserInterfaceType';
import {
  clearRefreshTokenInStore,
  getAuthDataFromAccessToken,
  setRefreshTokenToStore,
} from '../../utils';
import { loadUserCompanies } from '../company/actions';
import { initializeAuth, loggedOut, logout, refreshAuth, setAuthData } from './actions';

const initializeAuthEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> =>
  action$.pipe(
    filter(isActionOf(initializeAuth)),
    mergeMap(({ payload }): Observable<Action> => {
      const { refreshToken, userId, accessToken } = payload;
      const authData = getAuthDataFromAccessToken(payload);
      setAccessToken(accessToken);
      setRefreshTokenToStore(refreshToken);
      analyticsClientTrackEvent(AnalyticsEvent.LogIn);

      // when login for the first time or logout then re-login,
      // the login endpoint does not return token `createdAt` property and
      // this will fail the axios interceptor setup for token refresh mechanism
      // need to call 'refreshAuth' action to get the `createdAt`
      if (authData.userInterfaceType === UserInterfaceType.SUPPORT) {
        // empty action to satisfy action response needed here
        return of(setAuthData(authData));
      } else {
        // this will load the user companies [ companyId: ID, companyId: ID, ...]
        // after user companies load, the company epic will automatically load
        // the company fleets for companyId at index 0
        return of(setAuthData(authData), loadUserCompanies.request(userId));
      }
    }),
    catchError(async (e) => loadUserCompanies.failure(e)),
  );

const refreshAuthEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> =>
  action$.pipe(
    filter(isActionOf(refreshAuth.request)),
    mergeMap((): Observable<Action> => {
      return from(updateAccessToken()).pipe(
        map((authRefreshTokenResponse: AuthRefreshTokenResponse) => {
          const authData = getAuthDataFromAccessToken(authRefreshTokenResponse);
          analyticsClientTrackEvent(AnalyticsEvent.AccessTokenRefresh);
          return setAuthData(authData);
        }),
        catchError(async (e) => refreshAuth.failure(e)),
      );
    }),
  );

const logoutEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf([logout, refreshAuth.failure])),
    mergeMap((): Observable<Action> => {
      analyticsClientTrackEvent(AnalyticsEvent.LogOut);

      setAccessToken('');
      clearRefreshTokenInStore();
      analyticsClientReset();

      const query = new URLSearchParams({
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
        logout_uri: `${window.location.origin}/login`,
      });
      if (process.env.REACT_APP_VERCEL_URL && process.env.REACT_APP_COGNITO_AUTH_PROXY_URL) {
        // If we're running on Vercel, bypass the PWRfleet backend redirect and just go to the Cognito logout page
        query.set('response_type', 'code'); // Cognito needs this; normally the PWRfleet backend proxy will append it, but here we'll set it directly
        query.delete('logout_uri'); // Use redirect_uri for more flexibility
        query.set('redirect_uri', process.env.REACT_APP_COGNITO_AUTH_PROXY_URL); // Tell Cognito to redirect to our login proxy instead of myself
        query.set('state', `${encodeURIComponent(window.location.origin)}/login`); // Redirect here after Cognito
        window.location.href = `https://neurio-pwrview-stg.auth.us-east-1.amazoncognito.com/logout?${query}`;
      } else {
        // Or else... send this request to the backend, which redirects us back to the Cognito logout page
        window.location.href = `${process.env.REACT_APP_API_URL_PREFIX}/sessions/v1/pkce/signout?${query}`;
      }
      return of(loggedOut());
    }),
  );

const setupClientsEpic: Epic = (action$): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(setAuthData)),
    mergeMap((action): Observable<Action> => {
      const { userId, userRole } = action.payload;

      messagingClientIdentify(userId, {
        userRole,
      });
      analyticsClientIdentify(userId, {
        $user_id: userId,
        [AnalyticsProperty.UserRole]: userRole,
      });
      analyticsClientRegister({
        [AnalyticsProperty.UserRole]: userRole,
      });

      return EMPTY;
    }),
  );
};

export default combineEpics(initializeAuthEpic, refreshAuthEpic, logoutEpic, setupClientsEpic);
